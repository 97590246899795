import React from 'react';
import { createStyles, makeStyles, TableCell, TableHead, TableRow } from '@material-ui/core';

export type Column = {
  name: string;
  label: string;
  minWidth: number;
  align: 'right' | 'left' | 'center';
  disabled?: boolean;
};

export type BasicTableHeadProps = {
  columns: Column[];
};

const useStyles = makeStyles((theme) =>
  createStyles({
    head: {
      backgroundColor: theme.palette.grey['600'],
      color: 'white',
    },
  })
);

export const BasicTableHead: React.FC<BasicTableHeadProps> = (props) => {
  const classes = useStyles();

  return (
    <TableHead>
      <TableRow>
        {props.columns
          .filter((x) => x.disabled === undefined || !x.disabled)
          .map(({ name, align, minWidth, label }) => (
            <TableCell classes={classes} key={name} align={align} style={{ minWidth: minWidth }}>
              {label}
            </TableCell>
          ))}
      </TableRow>
    </TableHead>
  );
};
