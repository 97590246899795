import { DateTime } from 'luxon';
import { CouponMaster, DayType, CouponLayoutPattern } from '../../../../graphql/generated';
import { CouponMasterImageBodyProps, SelectedFile } from '../Image';
import { formatBytes } from '../../../../util';
import { DateTimeInput, ObjectDate } from '../../../../components/organisms/form';
import { AvailableDays } from '../../../../components/organisms/form/TimeFrameGrid';
import { CouponMasterFormInput } from '../Form';

/**
 * 2つの日付の逆転チェック
 */
export function validateStartEnd(startDateTime?: DateTime, endDateTime?: DateTime): boolean {
  // どちらかが空だったら成功
  if (!startDateTime || !endDateTime) {
    return true;
  }

  return startDateTime < endDateTime;
}

/**
 * フォーム入力値の日付をluxon.DateTimeに変換する
 * @param input
 */
export function fromInputDate(input?: DateTimeInput): DateTime | undefined {
  if (!input) {
    return undefined;
  }

  if (input instanceof DateTime) {
    return input;
  }

  return DateTime.fromMillis((input as ObjectDate).ts);
}

/**
 * フォーム日付＋時刻入力値の日付をluxon.DateTimeに変換する
 * @param inputDate 入力日付
 * @param inputTime 入力時刻
 */
export function fromInputDateAndTime(inputDate?: DateTimeInput, inputTime?: DateTimeInput): DateTime | undefined {
  const date = fromInputDate(inputDate)?.startOf('day');
  const time = fromInputDate(inputTime);
  if (!date || !time) {
    return undefined;
  }

  // 日付 + （時刻 = 今日の日付時刻 - 今日の始まり）
  return DateTime.fromMillis(date.toMillis() + (time.toMillis() - time.startOf('day').toMillis()));
}

/**
 * DateTime('2021-03-22T02:45:21.456+09:00') => '02:45'
 */
export function convertTimeFrameToDateString(input?: DateTimeInput): string | undefined {
  return fromInputDate(input)?.toFormat('HH:mm');
}

/**
 * '1,2, 31,3 => [1,2,31,3]
 */
export function convertTimeFrameToDateArray(input?: string): string[] {
  if (!input || input.length === 0) {
    return [];
  }

  return input.split(',').map((day) => day.trim());
}

/**
 * {
      mon: true,
      tue: true,
      wed: false,
      thu: false,
      fri: false,
      sat: false,
      sun: false,
    } => ['mon', 'tue']
 */
export function convertTimeFrameToDaysArray(input?: AvailableDays): DayType[] {
  if (!input) {
    return [];
  }

  return Object.entries(input).reduce<DayType[]>(
    (acc, [day, available]) => (available ? acc.concat([day as DayType]) : acc),
    []
  );
}

/**
 * 画像が選択されたときに走る共通処理
 */
export function validateImageFile(file: File, maxSizeBytes: number, setFile: (file: SelectedFile) => void): void {
  // 拡張子を抽出
  console.log('file', file);
  const extName = file.name.split('.').pop()?.split('?')[0];
  const extNameLowerCase = extName?.toLowerCase();
  const ext = {
    message: extNameLowerCase || '',
    valid:
      extNameLowerCase === 'jpg' ||
      extNameLowerCase === 'jpeg' ||
      extNameLowerCase === 'png' ||
      extNameLowerCase === 'gif',
  };

  // ファイルサイズを計測
  const length = {
    message: formatBytes(file.size),
    valid: file.size < maxSizeBytes,
  };

  setFile({
    ext,
    length,
    file,
    deleteFlag: false,
    toUpdate: true,
  });
}

/**
 * CSVが選択されたときに走る共通処理
 */
export function validateCsvFile(file: File, maxSizeBytes: number, setFile: (file: SelectedFile) => void): void {
  // 拡張子を抽出
  const extName = file.name.split('.').pop()?.split('?')[0];
  const extNameLowerCase = extName?.toLowerCase();
  const ext = {
    message: extNameLowerCase || '',
    valid: extNameLowerCase === 'csv',
  };

  // ファイルサイズを計測
  const length = {
    message: formatBytes(file.size),
    valid: file.size < maxSizeBytes,
  };

  setFile({
    ext,
    length,
    file,
    deleteFlag: false,
    toUpdate: false,
  });
}

export function generateImageProps(input: {
  fileTypeText: string;
  currentImageUrl?: string;
  selectedFile: SelectedFile;
  validateAndSetFile: (f: File) => void;
  setDeleteFile: () => void;
  widthText: string;
  deletable: boolean;
  maxSizeBytesText: string;
}): CouponMasterImageBodyProps {
  return {
    fileTypeText: input.fileTypeText,
    currentImageUrl: input.currentImageUrl,
    selectedFile: input.selectedFile,
    validateAndSetFile: input.validateAndSetFile,
    setDeleteFile: input.setDeleteFile,
    widthText: input.widthText,
    deletable: input.deletable,
    maxSizeBytesText: input.maxSizeBytesText,
  };
}

export function productImageProps(input: {
  currentImageUrl?: string;
  selectedFile: SelectedFile;
  validateAndSetFile: (f: File) => void;
  setDeleteFile: () => void;
}): CouponMasterImageBodyProps {
  return {
    fileTypeText: '商品画像',
    widthText: '横幅600px以上',
    currentImageUrl: input.currentImageUrl,
    selectedFile: input.selectedFile,
    validateAndSetFile: input.validateAndSetFile,
    setDeleteFile: input.setDeleteFile,
    deletable: true,
    maxSizeBytesText: '1MB',
  };
}

export function companyImageProps(input: {
  currentImageUrl?: string;
  selectedFile: SelectedFile;
  validateAndSetFile: (f: File) => void;
  setDeleteFile: () => void;
}): CouponMasterImageBodyProps {
  return {
    fileTypeText: 'ヘッダ画像',
    widthText: '横幅600px以上',
    currentImageUrl: input.currentImageUrl,
    selectedFile: input.selectedFile,
    validateAndSetFile: input.validateAndSetFile,
    setDeleteFile: input.setDeleteFile,
    deletable: true,
    maxSizeBytesText: '1MB',
  };
}

export function smartCheckHeaderImageProps(input: {
  currentImageUrl?: string;
  selectedFile: SelectedFile;
  validateAndSetFile: (f: File) => void;
  setDeleteFile: () => void;
}): CouponMasterImageBodyProps {
  return {
    fileTypeText: 'SmartCheckヘッダ画像',
    widthText: '横幅600px以上',
    currentImageUrl: input.currentImageUrl,
    selectedFile: input.selectedFile,
    validateAndSetFile: input.validateAndSetFile,
    setDeleteFile: input.setDeleteFile,
    deletable: true,
    maxSizeBytesText: '1MB',
  };
}

export function smartCheckUseButtonImageProps(input: {
  currentImageUrl?: string;
  selectedFile: SelectedFile;
  validateAndSetFile: (f: File) => void;
  setDeleteFile: () => void;
}): CouponMasterImageBodyProps {
  return {
    fileTypeText: '確認ボタン画像',
    widthText: '横幅350px',
    currentImageUrl: input.currentImageUrl,
    selectedFile: input.selectedFile,
    validateAndSetFile: input.validateAndSetFile,
    setDeleteFile: input.setDeleteFile,
    deletable: true,
    maxSizeBytesText: '500KB',
  };
}

export function smartCheckConfirmButtonImageProps(input: {
  currentImageUrl?: string;
  selectedFile: SelectedFile;
  validateAndSetFile: (f: File) => void;
  setDeleteFile: () => void;
}): CouponMasterImageBodyProps {
  return {
    fileTypeText: '使用ボタン画像',
    widthText: '横幅350px',
    currentImageUrl: input.currentImageUrl,
    selectedFile: input.selectedFile,
    validateAndSetFile: input.validateAndSetFile,
    setDeleteFile: input.setDeleteFile,
    deletable: true,
    maxSizeBytesText: '500KB',
  };
}

export function smartCheckConfirmCancelButtonImageProps(input: {
  currentImageUrl?: string;
  selectedFile: SelectedFile;
  validateAndSetFile: (f: File) => void;
  setDeleteFile: () => void;
}): CouponMasterImageBodyProps {
  return {
    fileTypeText: '確認キャンセルボタン画像',
    widthText: '横幅350px',
    currentImageUrl: input.currentImageUrl,
    selectedFile: input.selectedFile,
    validateAndSetFile: input.validateAndSetFile,
    setDeleteFile: input.setDeleteFile,
    deletable: true,
    maxSizeBytesText: '500KB',
  };
}

export function smartCheckForceButtonImageProps(input: {
  currentImageUrl?: string;
  selectedFile: SelectedFile;
  validateAndSetFile: (f: File) => void;
  setDeleteFile: () => void;
}): CouponMasterImageBodyProps {
  return {
    fileTypeText: 'カウントダウン強制終了ボタン画像',
    widthText: '横幅350px',
    currentImageUrl: input.currentImageUrl,
    selectedFile: input.selectedFile,
    validateAndSetFile: input.validateAndSetFile,
    setDeleteFile: input.setDeleteFile,
    deletable: true,
    maxSizeBytesText: '500KB',
  };
}

export function smartCheckUsedImageProps(input: {
  currentImageUrl?: string;
  selectedFile: SelectedFile;
  validateAndSetFile: (f: File) => void;
  setDeleteFile: () => void;
}): CouponMasterImageBodyProps {
  return {
    fileTypeText: '使用済み画像',
    widthText: '横幅600px以上',
    currentImageUrl: input.currentImageUrl,
    selectedFile: input.selectedFile,
    validateAndSetFile: input.validateAndSetFile,
    setDeleteFile: input.setDeleteFile,
    deletable: true,
    maxSizeBytesText: '1MB',
  };
}

export function reproduceInputFromCouponMaster(m: CouponMaster): CouponMasterFormInput {
  return {
    organizationId: '',
    unAvailable: !m.available,
    applyWith: m.applyWith,
    applyBarcodeSourceType: m.applyBarcode?.sourceType || undefined,
    applyBarcodeDisplayType: m.applyBarcode?.displayType || undefined,
    couponName: m.couponName,
    couponCode: m.couponCode,
    note: m.note || undefined,
    displayStartDate: m.displayStartDate ? DateTime.fromISO(m.displayStartDate) : null,
    displayEndDate: m.displayEndDate ? DateTime.fromISO(m.displayEndDate) : null,
    validityStartDate: m.validityStartDateTime ? DateTime.fromISO(m.validityStartDateTime) : null,
    validityStartTime: m.validityStartDateTime ? DateTime.fromISO(m.validityStartDateTime) : null,
    validityEndDate: m.validityEndDateTime ? DateTime.fromISO(m.validityEndDateTime) : null,
    validityEndTime: m.validityEndDateTime ? DateTime.fromISO(m.validityEndDateTime) : null,
    realTimeIssuedGetStartDate: m.realTimeIssuedGetStartDateTime
      ? DateTime.fromISO(m.realTimeIssuedGetStartDateTime)
      : null,
    realTimeIssuedGetStartTime: m.realTimeIssuedGetStartDateTime
      ? DateTime.fromISO(m.realTimeIssuedGetStartDateTime)
      : null,
    realTimeIssuedGetEndDate: m.realTimeIssuedGetEndDateTime ? DateTime.fromISO(m.realTimeIssuedGetEndDateTime) : null,
    realTimeIssuedGetEndTime: m.realTimeIssuedGetEndDateTime ? DateTime.fromISO(m.realTimeIssuedGetEndDateTime) : null,
    couponIssuedMaximumNumber: String(m.couponIssuedMaximumNumber),
    timeFrame: m.timeFrame,
    availableDate: m.availableDate?.join(','),
    availableDays: {
      mon: m.availableDays?.includes('mon') || false,
      tue: m.availableDays?.includes('tue') || false,
      wed: m.availableDays?.includes('wed') || false,
      thu: m.availableDays?.includes('thu') || false,
      fri: m.availableDays?.includes('fri') || false,
      sat: m.availableDays?.includes('sat') || false,
      sun: m.availableDays?.includes('sun') || false,
    },
    description: m.description || undefined,
    information: m.information || undefined,
    meta: m.meta || undefined,
    mapUrl: m.mapUrl || undefined,
    telNumber: m.telNumber || undefined,
    couponNameHtml: m.couponNameHtml || undefined,
    couponNameBandColor: m.couponNameBandColor,
    couponNameTextColor: m.couponNameTextColor,
    couponIssuerBandColor: m.couponIssuerBandColor,
    couponIssuerTextColor: m.couponIssuerTextColor,
    smartCheckSettingsInput: {
      smartCheckConfirmText: m.smartCheckSetting?.smartCheckConfirmText || undefined,
      smartCheckCountDownText: m.smartCheckSetting?.smartCheckCountDownText || undefined,
      smartCheckUseButtonBottomText: m.smartCheckSetting?.smartCheckUseButtonBottomText || undefined,
      countDownMinute: m.smartCheckSetting?.countDownMinute || undefined,
      staticBarcodes: m.smartCheckSetting?.staticBarcodes
        ? m.smartCheckSetting?.staticBarcodes.map((b) => ({
            barcodeType: b.barcodeType,
            barcode: b.barcode,
          }))
        : [
            { barcodeType: 'none', barcode: '' },
            { barcodeType: 'none', barcode: '' },
            { barcodeType: 'none', barcode: '' },
            { barcodeType: 'none', barcode: '' },
          ],
    },
    layoutPattern: m.layoutPattern || ('normal' as CouponLayoutPattern),
  };
}
