import { createContext } from 'react';
import { User } from '@auth0/auth0-react';

// 表示権限
export type Permission =
  | 'show:coupon-masters'
  | 'show:coupon-usage'
  | 'show:coupon-usage-list'
  | 'show:coupon-displayed'
  | 'show:coupon-displayed-list'
  | 'show:manage-organizations'
  | 'show:bulk-issue-coupon'
  | 'show:switch-organization'
  | 'show:coupons'
  | 'show:coupon-detail'
  | 'show:users'
  | 'write:admin-user'
  | 'write:shop'
  | 'write:shop-group'
  | 'write:organization-suite';

export type Role = string;

// ログインユーザーの情報と権限
export type UserContext = {
  user?: User;
  permissions: Permission[];
  roles: Role[];
};

export type UserContextProps = {
  userInfo?: UserContext;
};

export const UserContext = createContext<UserContextProps>({});
