import Bugsnag from '@bugsnag/js';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { OrganizationContext } from '../../../components/contexts/organization';
import { useInvokeShopCsvUrlLazyQuery, usePollShopCsvUrlLazyQuery } from '../../../graphql/generated';
import { downloadCsvFileWithBom } from '../../../util/download';
import { UserContext } from '../../../components/contexts/user/user-context';

export type OutputShopCsvUseCase = {
  organization?: OrganizationContext;
  organizationLoading: boolean;
  userInfo?: UserContext;
  loading: boolean;
  onCsvOut: (parentId: string) => void;
};

export const useOutputShopCsvUseCase = (): OutputShopCsvUseCase => {
  const { organization, loading: organizationLoading } = useContext(OrganizationContext);
  const { userInfo } = useContext(UserContext);
  const [loading, setLoading] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();

  // 利用状況一覧 CSV URL ポーリング
  const [pollCsvUrlQuery, statusCsvUrlContext] = usePollShopCsvUrlLazyQuery({
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    pollInterval: 1000,
  });

  // 利用状況CSV取得 invoke
  const [invokeCsvQuery] = useInvokeShopCsvUrlLazyQuery({
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    onCompleted: (data) => {
      // invokeが終わり次第、ポーリングをスタートする。
      // pollIntervalによって以降は自動的に1秒間隔でAPIが実行されるため、useEffectでポーリングの完了を待つ
      console.log('data', data);
      pollCsvUrlQuery({
        variables: {
          queryExecutionId: data.invokeShopCsvUrl.queryExecutionId,
        },
      });
    },
    onError: (e) => {
      console.error(e);
      Bugsnag.notify(e);
      enqueueSnackbar('店舗CSV出力に失敗しました', {
        variant: 'error',
      });
    },
  });

  // CSV URL クエリ出力をポーリングする
  // ポーリングクエリの onComplete は呼ばれないので useEffect でやるしかない
  useEffect(() => {
    const status = statusCsvUrlContext?.data?.pollShopCsvUrl?.status;

    // 終了系ステータスの場合、ポーリングを止める
    if (status === 'CANCELLED' || status === 'FAILED' || status === 'SUCCEEDED') {
      if (statusCsvUrlContext && statusCsvUrlContext.stopPolling) {
        statusCsvUrlContext.stopPolling();
      }
    }

    // 正常に終了した場合は、CSVファイルをダウンロード
    if (
      status === 'SUCCEEDED' &&
      statusCsvUrlContext &&
      statusCsvUrlContext.data &&
      statusCsvUrlContext.data.pollShopCsvUrl.signedUrl
    ) {
      enqueueSnackbar('店舗CSV出力処理が正常に終了しました。CSVファイルをダウンロードします。', { variant: 'success' });
      setLoading(false);
      downloadCsvFileWithBom(statusCsvUrlContext.data.pollShopCsvUrl.signedUrl);
    } else if (status === 'FAILED' || status === 'CANCELLED') {
      setLoading(false);
      enqueueSnackbar('店舗CSV出力処理でエラーが発生しました', {
        variant: 'error',
      });
      Bugsnag.notify(statusCsvUrlContext.error || new Error('polling error'));
    }
  }, [statusCsvUrlContext.data?.pollShopCsvUrl.status, statusCsvUrlContext.data?.pollShopCsvUrl.signedUrl]);

  // CSV出力
  const onCsvOutSubmit = useCallback(
    (parentId: string) => {
      if (!organization) return;
      if (!parentId) return;
      setLoading(true);
      invokeCsvQuery({ variables: { parentId } });
    },
    [organization, invokeCsvQuery]
  );

  return {
    organization,
    organizationLoading,
    userInfo,
    onCsvOut: onCsvOutSubmit,
    loading,
  };
};
