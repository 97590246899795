import { Box, Popover, Theme, Typography } from '@material-ui/core';
import HelpIcon from '@material-ui/icons/Help';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

export const useStyles = makeStyles((theme: Theme) => ({
  popover: {
    padding: theme.spacing(1),
    maxWidth: theme.spacing(50),
  },
}));

const availableTags: string[] = [
  'strong',
  'em',
  'b',
  'i',
  'p',
  'code',
  'pre',
  'tt',
  'samp',
  'kbd',
  'var',
  'sub',
  'sup',
  'dfn',
  'cite',
  'big',
  'small',
  'address',
  'hr',
  'br',
  'div',
  'span',
  'h1',
  'h2',
  'h3',
  'h4',
  'h5',
  'h6',
  'ul',
  'ol',
  'ul',
  'li',
  'dt',
  'dd',
  'abbr',
  'acronym',
  'a',
  'img',
  'blockquote',
  'del',
  'ins',
  'font',
];

const availableAttributes: string[] = [
  'href',
  'src',
  'width',
  'height',
  'alt',
  'cite',
  'datetime',
  'title',
  'class',
  'name',
  'xml:lang',
  'abbr',
  'color',
];

export const AllowedTagsHelpPopOver: React.FC = () => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const open = !!anchorEl;
  const styles = useStyles();

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement, MouseEvent>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = (): void => {
    setAnchorEl(null);
  };
  return (
    <>
      <Box onMouseEnter={handlePopoverOpen}>
        <HelpIcon fontSize="small" />
      </Box>
      <Popover
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
        classes={{
          paper: styles.popover,
        }}
      >
        <Typography variant="caption" component="p">
          利用できるタグ
        </Typography>
        <Typography variant="caption" component="p">
          {availableTags.join(', ')}
        </Typography>
        <Typography variant="caption" component="p">
          利用できる属性
        </Typography>
        <Typography variant="caption" component="p">
          {availableAttributes.join(', ')}
        </Typography>
      </Popover>
    </>
  );
};
