import { useSnackbar } from 'notistack';
import { useCallback } from 'react';
import Bugsnag from '@bugsnag/js';
import { Role, useRolesQuery, useUpdateUserRoleMutation } from '../../../graphql/generated';
import { UpdateUserRoleFormValues } from '../dialogs/UpdateUserRole';

export type UpdateUserRoleUseCase = {
  loading: boolean;
  onUpdate: (input: UpdateUserRoleFormValues) => void;
  roles?: Role[];
};

export const useUpdateUserRoleUseCase = (setOpen: (open: boolean) => void): UpdateUserRoleUseCase => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [updateUserRoleMutation, { loading, error }] = useUpdateUserRoleMutation();

  // ロールの一覧を取得する
  const { data, error: rolesError } = useRolesQuery({
    fetchPolicy: 'cache-first',
  });

  if (rolesError) {
    enqueueSnackbar(`ロール情報の取得に失敗しました: ${rolesError}`, {
      variant: 'error',
      persist: true,
    });
    throw rolesError;
  }

  const onUpdate = useCallback(
    async (input: UpdateUserRoleFormValues) => {
      closeSnackbar();

      console.log('input', input);

      await updateUserRoleMutation({
        variables: {
          id: input.id,
          roleId: input.role,
          isAdministrator: input.isAdministrator,
        },
      });

      if (error) {
        Bugsnag.notify(error);
        console.error(error);
        enqueueSnackbar(`ユーザーロールの更新に失敗しました: ${error}`, {
          variant: 'error',
          persist: true,
        });
        return;
      }

      setOpen(false);

      enqueueSnackbar(`ユーザーロールを更新しました`, {
        variant: 'success',
      });
    },
    [updateUserRoleMutation, enqueueSnackbar]
  );

  return {
    loading,
    onUpdate,
    roles: data?.roles,
  };
};
