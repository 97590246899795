import React from 'react';
import { Box, Breadcrumbs, CircularProgress, Container, Link, Typography } from '@material-ui/core';
import { CouponMasterForm, CouponMasterFormInput, CouponMasterFormProps } from './Form';
import { MenuHeaderTemplate } from '../../../components/templates/MenuHeaderTemplate';
import { useCreateCouponMaster } from './hooks/create-coupon';
import { useHistory } from 'react-router-dom';
import { CouponMasterImageBodyProps } from './Image';
import { useLocation } from 'react-router';
import { CouponFeature, OrganizationContext } from '../../../components/contexts/organization';

export type CreateCouponMasterPageProps = {
  organization?: OrganizationContext;
  couponFeature?: CouponFeature;
  organizationLoading: boolean;
  previousValues?: CouponMasterFormInput;
  onSubmit: (input: CouponMasterFormInput) => void;
  loading: boolean;
  submitStepMessage: string;
  productImageProps: CouponMasterImageBodyProps;
  companyImageProps: CouponMasterImageBodyProps;
  smartCheckHeaderImageProps: CouponMasterImageBodyProps;
  smartCheckUseButtonImageProps: CouponMasterImageBodyProps;
  smartCheckConfirmButtonImageProps: CouponMasterImageBodyProps;
  smartCheckConfirmCancelButtonImageProps: CouponMasterImageBodyProps;
  smartCheckForceButtonImageProps: CouponMasterImageBodyProps;
  smartCheckUsedImageProps: CouponMasterImageBodyProps;
};

export const CreateCouponMaster: React.FC<CreateCouponMasterPageProps> = (props) => {
  // 情報が未確定のタイミングでは、ローディングを出す
  if (props.organizationLoading || !props.organization || !props.previousValues || !props.couponFeature) {
    return (
      <Box display="flex" alignItems="center" justifyContent="center">
        <CircularProgress />
      </Box>
    );
  }

  const formProps: CouponMasterFormProps = {
    organizationContext: props.organization,
    couponFeature: props.couponFeature,
    displaySettings: {
      apply: false,
      timeFrame: props.couponFeature.timeFrame.available,
    },
    loading: props.loading,
    onSubmit: props.onSubmit,
    submitStepMessage: props.submitStepMessage,
    previousValues: props.previousValues,
    productImageProps: props.productImageProps,
    companyImageProps: props.companyImageProps,
    smartCheckHeaderImageProps: props.smartCheckHeaderImageProps,
    smartCheckUseButtonImageProps: props.smartCheckUseButtonImageProps,
    smartCheckConfirmButtonImageProps: props.smartCheckConfirmButtonImageProps,
    smartCheckConfirmCancelButtonImageProps: props.smartCheckConfirmCancelButtonImageProps,
    smartCheckUsedImageProps: props.smartCheckUsedImageProps,
    smartCheckForceButtonImageProps: props.smartCheckForceButtonImageProps,
    submitLabel: '作成する',
  };

  return <CouponMasterForm {...formProps} />;
};

export const CreateCouponMasterPage: React.FC = () => {
  const history = useHistory();
  const { state } = useLocation<string>();
  const createCouponMaster = useCreateCouponMaster(state);

  return (
    <MenuHeaderTemplate header={{ title: 'クーポンマスタ新規作成' }}>
      <Container maxWidth="xl">
        <Breadcrumbs>
          <Link
            href="#"
            onClick={() =>
              history.push({
                pathname: '/coupon-masters',
                search: history.location.search,
              })
            }
          >
            クーポンマスタ一覧
          </Link>
          <Typography>クーポンマスタ新規作成</Typography>
        </Breadcrumbs>
        <Box>
          <CreateCouponMaster {...createCouponMaster} />
        </Box>
      </Container>
    </MenuHeaderTemplate>
  );
};
