import { useSnackbar } from 'notistack';
import { useCallback } from 'react';
import { useHistory } from 'react-router';
import { OrganizationType, useDeleteShopGroupMutation } from '../../../graphql/generated';
import { ApolloError } from '@apollo/client';
import { useUrlSearchParamOrganization } from '../../../components/contexts/hooks/url';

export type DeleteShopGroupUseCase = {
  loading: boolean;
  onDelete: () => void;
};

export const useDeleteShopGroupUseCase = (
  organizationId: string,
  parentOrganizationId: string,
  type: string,
  setOpen: (open: boolean) => void
): DeleteShopGroupUseCase => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const history = useHistory();
  const organizationParams = useUrlSearchParamOrganization();

  const [deleteShopGroupMutation, { loading }] = useDeleteShopGroupMutation();

  const onDelete = useCallback(async () => {
    closeSnackbar();

    try {
      await deleteShopGroupMutation({
        variables: {
          id: organizationId,
          type: type as OrganizationType,
        },
      });

      enqueueSnackbar(`店舗グループを削除しました`, {
        variant: 'success',
      });

      history.push({ pathname: `/manage-organizations/${parentOrganizationId}`, search: organizationParams });
    } catch (e: unknown) {
      if (e instanceof ApolloError) {
        console.error('graphQLErrors', e.graphQLErrors);
        if (e.message === 'BigShopGroupConstraintError') {
          enqueueSnackbar(`子の店舗グループが存在するため削除できません`, {
            variant: 'error',
          });
        } else if (e.message === 'ChildrenShopExistsError') {
          enqueueSnackbar(`子の店舗が存在するため削除できません`, {
            variant: 'error',
          });
        }
        setOpen(false);
      } else {
        throw e;
      }
    }
  }, [deleteShopGroupMutation, enqueueSnackbar]);

  return {
    loading,
    onDelete,
  };
};
