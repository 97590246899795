import { useSnackbar } from 'notistack';
import { useCallback } from 'react';
import { useCreateShopGroupMutation } from '../../../graphql/generated';
import { useHistory } from 'react-router';
import Bugsnag from '@bugsnag/js';
import { CreateShopGroupFormValues } from '../pages/CreateShopGroupPage';

export type CreateShopGroupUseCase = {
  loading: boolean;
  onCreate: (input: CreateShopGroupFormValues) => void;
};

export const useCreateShopGroupUseCase = (parentId: string): CreateShopGroupUseCase => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const history = useHistory();

  const [createShopGroupMutation, { loading, error }] = useCreateShopGroupMutation();

  const onCreate = useCallback(
    async (input: CreateShopGroupFormValues) => {
      closeSnackbar();
      console.log(input);

      const { data } = await createShopGroupMutation({
        variables: {
          input: {
            name: input.name,
            type: input.type,
            parentId,
          },
        },
      });

      if (error) {
        Bugsnag.notify(error);
        console.error(error);
        enqueueSnackbar(`店舗グループの作成に失敗しました: ${error}`, {
          variant: 'error',
          persist: true,
        });
        return;
      }

      enqueueSnackbar(`店舗グループを作成しました`, {
        variant: 'success',
      });

      history.push({
        pathname: `/manage-organizations/${data?.createShopGroup.id}`,
        hash: '#about',
        search: history.location.search,
      });
    },
    [createShopGroupMutation, enqueueSnackbar]
  );

  return {
    loading,
    onCreate,
  };
};
