import { OrganizationContext } from '../../../../components/contexts/organization';
import { CouponMasterListConditionFormValues } from '../List';
import { CouponMastersQuery, useCouponMastersLazyQuery } from '../../../../graphql/generated';
import { useCallback, useContext, useEffect, useState } from 'react';

const PageItemsCount = 25;

export type ListCouponMasters = {
  organization?: OrganizationContext;
  organizationLoading: boolean;
  loading: boolean;
  onSearchCouponMasters: (condition: CouponMasterListConditionFormValues) => void;
  data?: CouponMastersQuery;
  onFetchMore: () => void;
  initialValues: CouponMasterListConditionFormValues;
  open: boolean;
  setOpen: (open: boolean) => void;
  realTimeCouponIssueUrl: string;
  setRealTimeCouponIssueUrl: (url: string) => void;
};

export const useListCouponMasters = (): ListCouponMasters => {
  const orgContext = useContext(OrganizationContext);
  const organization = orgContext.organization;
  const organizationLoading = orgContext.loading;
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [realTimeCouponIssueUrl, setRealTimeCouponIssueUrl] = useState('');

  const initialValues: CouponMasterListConditionFormValues = {
    value: '',
    searchCondition: 'none',
  };

  const [listQuery, queryContext] = useCouponMastersLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: () => {
      setLoading(false);
    },
    onError: (error) => {
      console.log(error); // TODO: 後日統括的にエラーハンドリングする
    },
  });

  // 初回、検索条件なしで表示する
  useEffect(() => {
    if (organization) {
      setLoading(true);
      listQuery({
        variables: {
          organizationId: organization.id,
          limit: PageItemsCount,
        },
      });
    }
  }, [listQuery, organization]);

  // 動的な検索条件をセットしてクーポンマスタを検索する
  const onSearchCouponMasters = useCallback(
    (data: CouponMasterListConditionFormValues) => {
      if (organization) {
        setLoading(true);
        listQuery({
          variables: {
            organizationId: organization.id,
            [data.searchCondition]: data.value,
            limit: PageItemsCount,
          },
        });
      }
    },
    [listQuery, organization]
  );

  // さらに読み込む
  const onFetchMore = useCallback(async () => {
    if (organization && queryContext?.fetchMore && queryContext.variables) {
      setLoading(true);
      await queryContext.fetchMore({
        variables: {
          organizationId: queryContext.variables.organizationId,
          couponName: queryContext.variables.couponName,
          couponCode: queryContext.variables.couponCode,
          nextToken: queryContext.data?.couponMasters.pageInfo.nextToken,
          limit: PageItemsCount,
        },
        updateQuery: (prevQuery, { fetchMoreResult }) => {
          setLoading(false);
          if (!fetchMoreResult) {
            return prevQuery;
          }
          return {
            couponMasters: {
              __typename: fetchMoreResult.couponMasters.__typename,
              nodes: [...prevQuery.couponMasters.nodes, ...fetchMoreResult.couponMasters.nodes],
              pageInfo: fetchMoreResult.couponMasters.pageInfo,
            },
          };
        },
      });
    }
  }, [queryContext, organization]);

  return {
    organization,
    organizationLoading,
    loading,
    onSearchCouponMasters,
    data: queryContext.data,
    onFetchMore,
    initialValues,
    open,
    setOpen,
    realTimeCouponIssueUrl,
    setRealTimeCouponIssueUrl,
  };
};
