// A custom hook that builds on useLocation to parse
// the query string for you.
import { useLocation } from 'react-router';

export function useUrlSearchParam(): URLSearchParams {
  return new URLSearchParams(useLocation().search);
}

export function useUrlSearchParamOrganization(): string {
  return `organization=${new URLSearchParams(useLocation().search).get('organization') || ''}`;
}
