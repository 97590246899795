import {
  Button,
  Dialog,
  DialogTitle,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Theme,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { CouponMaster } from '../../../graphql/generated';
import { lightGreen } from '@material-ui/core/colors';
import { ArrowRightIcon } from '@material-ui/pickers/_shared/icons/ArrowRightIcon';
import { Create } from '@material-ui/icons';
import { formatJstDateJapanese, formatJstDateTimeJapanese } from '../../../util';
import { OrganizationContext } from '../../../components/contexts/organization';
import { SwitchOrganizationDialog } from '../../switch-organization/Organization';

const useStyles = makeStyles((theme: Theme) => ({
  infoArea: {
    borderColor: theme.palette.primary.light,
    backgroundColor: lightGreen['50'],
  },
}));

export type AboutProps = CouponMaster & { organization: OrganizationContext };

export const About: React.FC<AboutProps> = (props) => {
  const classes = useStyles();
  const { pathname } = useLocation();
  const history = useHistory();
  const [openCopyDialog, setOpenCopyDialog] = useState(false);
  const [openOrganizationDialog, setOpenOrganizationDialog] = useState(false);

  const formatDisplayDays = (data: CouponMaster): string => {
    return `${formatJstDateJapanese(data.displayStartDate)}から${formatJstDateJapanese(data.displayEndDate)}まで`;
  };

  const formatValidityDays = (data: CouponMaster): string => {
    let start = '';
    if (data.validityStartDateTime) {
      start = `${formatJstDateTimeJapanese(data.validityStartDateTime)} から `;
    }
    return `${start}${formatJstDateTimeJapanese(data.validityEndDateTime)} まで`;
  };

  return (
    <>
      <Grid container spacing={2} justifyContent="flex-end">
        <Grid item xs={1} />
        <Grid item xs={4}>
          <Button
            fullWidth
            type="button"
            size="large"
            color="secondary"
            variant="outlined"
            onClick={() => setOpenCopyDialog(true)}
          >
            コピーして新規作成
          </Button>
          <Dialog maxWidth="xl" fullWidth open={openCopyDialog} onClose={() => setOpenCopyDialog(false)}>
            <DialogTitle id="select-dialog-title">コピー方法を選んでください</DialogTitle>
            <List>
              <ListItem
                button
                onClick={() => {
                  return history.push({
                    pathname: `/coupon-masters/new`,
                    search: history.location.search,
                    state: props.id,
                  });
                }}
              >
                <ListItemText primary={`現在の組織にコピーする（${props.organization?.name}）`} />
              </ListItem>
              <ListItem
                button
                onClick={() => {
                  setOpenCopyDialog(false);
                  setOpenOrganizationDialog(true);
                }}
              >
                <ListItemText primary={`別の組織に変更してコピーする`} />
              </ListItem>
            </List>
          </Dialog>
          {SwitchOrganizationDialog<string>({
            open: openOrganizationDialog,
            setOpen: setOpenOrganizationDialog,
            historyPush: { pathname: '/coupon-masters/new', state: props.id },
          })}
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Paper variant="outlined" square className={classes.infoArea}>
            <List dense>
              <ListItem>
                <ListItemAvatar>
                  <ArrowRightIcon />
                </ListItemAvatar>
                <ListItemText primary="利用可否" secondary={props.available ? '利用可能' : '利用不可'} />
              </ListItem>
              <ListItem>
                <ListItemAvatar>
                  <ArrowRightIcon />
                </ListItemAvatar>
                <ListItemText primary="クーポンマスタID" secondary={props.id} />
              </ListItem>
              <ListItem>
                <ListItemAvatar>
                  <ArrowRightIcon />
                </ListItemAvatar>
                <ListItemText primary="クーポン名" secondary={props.couponName} />
              </ListItem>
              <ListItem>
                <ListItemAvatar>
                  <ArrowRightIcon />
                </ListItemAvatar>
                <ListItemText primary="クーポンコード" secondary={props.couponCode} />
              </ListItem>
              <ListItem>
                <ListItemAvatar>
                  <ArrowRightIcon />
                </ListItemAvatar>
                <ListItemText primary="メモ" secondary={props.note} />
              </ListItem>
              <ListItem>
                <ListItemAvatar>
                  <ArrowRightIcon />
                </ListItemAvatar>
                <ListItemText primary="表示期間（日本時間）" secondary={formatDisplayDays(props)} />
              </ListItem>
              <ListItem>
                <ListItemAvatar>
                  <ArrowRightIcon />
                </ListItemAvatar>
                <ListItemText primary="有効期間（日本時間）" secondary={formatValidityDays(props)} />
              </ListItem>
              <ListItem>
                <ListItemAvatar>
                  <ArrowRightIcon />
                </ListItemAvatar>
                <ListItemText primary="作成日時" secondary={formatJstDateTimeJapanese(props.createAtDateTime)} />
              </ListItem>
              <ListItem>
                <ListItemAvatar>
                  <ArrowRightIcon />
                </ListItemAvatar>
                <ListItemText primary="最終更新日時" secondary={formatJstDateTimeJapanese(props.updateAtDateTime)} />
              </ListItem>
            </List>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Button
            fullWidth
            type="button"
            size="large"
            color="secondary"
            variant="contained"
            onClick={() => {
              history.push({
                pathname: `${pathname}/edit`,
                search: history.location.search,
              });
            }}
            startIcon={<Create />}
          >
            このクーポンマスタを編集する
          </Button>
        </Grid>
      </Grid>
    </>
  );
};
