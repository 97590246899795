import React, { useEffect } from 'react';
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { useForm, FormProvider } from 'react-hook-form';
import { useDeleteShopUseCase } from '../hooks/delete-shop-use-case';

type DeleteShopProps = {
  id: string;
  parentId: string;
  name: string;
  open: boolean;
  setOpen: (open: boolean) => void;
};

export type DeleteShopFormValues = {
  id: string;
};

export const DeleteShopAlertDialog: React.FC<DeleteShopProps> = (props) => {
  const { loading, onDelete } = useDeleteShopUseCase(props.id, props.parentId, props.setOpen);
  const deleteShopForm = useForm<DeleteShopFormValues>();

  useEffect(() => {
    deleteShopForm.reset({ id: props.id });
  }, [props]);

  const { handleSubmit } = deleteShopForm;

  return (
    <Dialog fullWidth maxWidth="md" open={props.open} onClose={() => props.setOpen(false)}>
      <FormProvider {...deleteShopForm}>
        <form onSubmit={handleSubmit(onDelete)} autoComplete="off" noValidate>
          <DialogTitle>店舗削除</DialogTitle>
          <DialogContent>
            店舗 <strong>{props.name}</strong> を削除します。よろしいですか？
          </DialogContent>
          <DialogActions>
            <Button onClick={() => props.setOpen(false)} color="secondary" disabled={loading}>
              キャンセル
            </Button>
            <Button type="submit" color="secondary" disabled={loading}>
              {loading ? <CircularProgress size="1rem" /> : '削除する'}
            </Button>
          </DialogActions>
        </form>
      </FormProvider>
    </Dialog>
  );
};
