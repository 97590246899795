import { Box, Container, Typography } from '@material-ui/core';
import React from 'react';

export const NotFound: React.FC = () => {
  return (
    <Container>
      <Box display="flex" justifyContent="center">
        <Typography variant="body1">指定されたURLが見つかりませんでした</Typography>
      </Box>
    </Container>
  );
};
