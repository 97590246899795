import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import React from 'react';
import { AppState, Auth0Provider } from '@auth0/auth0-react';
import * as luxon from 'luxon';
import LuxonUtils from '@date-io/luxon';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { ThemeProvider } from '@material-ui/core/styles';
import { createBrowserHistory } from 'history';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { AuthorizedApolloProvider } from './AuthorizedApolloProvider';
import { CouponMasterDashboardPage } from './ecosystems/coupon-master/dashboard/CouponMasterDashboard';
import { CouponDetailPage } from './ecosystems/coupon-master/dashboard/CouponDetail';
import { CreateCouponMasterPage } from './ecosystems/coupon-master/form/Create';
import { UpdateCouponMasterPage } from './ecosystems/coupon-master/form/Update';
import { ListCouponMastersPage } from './ecosystems/coupon-master/list/List';
import { NotFound } from './ecosystems/error/NotFound';
import { HomePage } from './ecosystems/home/Home';
import { theme } from './theme';
import { getRequiredEnvironmentVariable } from './util';
import { SwitchOrganizationPage } from './ecosystems/switch-organization/Organization';
import { SnackbarProvider } from 'notistack';
import { OrganizationProvider } from './components/contexts/organization';
import { CouponUsage } from './ecosystems/coupon-usage/CouponUsage';
import { CouponUsageLog } from './ecosystems/coupon-usage-log/CouponUsageLog';
import { AvailableRoute, ProtectedRoute } from './PrivateRoute';
import { CouponDisplayed } from './ecosystems/coupon-display/CouponDisplayed';
import { CouponDisplayedLog } from './ecosystems/coupon-display-log/CouponDisplayedLog';
import { CreateShopGroupPage } from './ecosystems/manage-organization/pages/CreateShopGroupPage';
import { OrganizationDashboardPage } from './ecosystems/manage-organization/pages/OrganizationDashboardPage';
import { UserProvider } from './components/contexts/user/user-provider';
import { CreateShopPage } from './ecosystems/manage-organization/pages/CreateShopPage';
import { UpdateShopPage } from './ecosystems/manage-organization/pages/UpdateShopPage';
import { UpdateShopGroupPage } from './ecosystems/manage-organization/pages/UpdateShopGroupPage';
import { ListUsersPage } from './ecosystems/user/UserList';
import { UserDashboardPage } from './ecosystems/user/UserDashboard';
import { UpsertOrganizationSuitePage } from './ecosystems/manage-organization/pages/UpsertOrgnizationSuitePage';
import { ListCouponsPage } from './ecosystems/coupon/CouponListPage';

// Auth0などの環境変数の読込
const domain = getRequiredEnvironmentVariable('REACT_APP_AUTH0_DOMAIN');
const clientId = getRequiredEnvironmentVariable('REACT_APP_AUTH0_CLIENT_ID');
const audience = getRequiredEnvironmentVariable('REACT_APP_AUTH0_AUDIENCE');
const appVersion = getRequiredEnvironmentVariable('REACT_APP_VERSION');
const bugsnagApiKey = getRequiredEnvironmentVariable('REACT_APP_BUGSNAG_API_KEY');
const stage = getRequiredEnvironmentVariable('REACT_APP_STAGE');

const history = createBrowserHistory();
const onRedirectCallback = (appState: AppState): void => {
  history.replace(appState?.returnTo || window.location.pathname);
};

// useParams で値を取得できる、単純に型を定義する。ドキュメンテーション。
export type PathParams = {
  couponMasterId: string;
  couponId: string;
  organizationId: string;
  shopGroupId: string;
  shopId: string;
  userId: string;
};

// Bugsnag クライアントをスタート
Bugsnag.start({
  apiKey: bugsnagApiKey,
  appVersion,
  releaseStage: stage,
  plugins: [new BugsnagPluginReact()],
});
const ErrorBoundary = Bugsnag.getPlugin('react')!.createErrorBoundary(React);

// luxonを日本時間に設定
luxon.Settings.defaultZoneName = 'Asia/Tokyo';
luxon.Settings.defaultLocale = 'ja-JP';

function App(): React.ReactElement {
  return (
    <ErrorBoundary>
      <BrowserRouter>
        <Auth0Provider
          domain={domain}
          clientId={clientId}
          audience={audience}
          redirectUri={window.location.origin}
          onRedirectCallback={onRedirectCallback}
        >
          <AuthorizedApolloProvider>
            <ThemeProvider theme={theme}>
              <MuiPickersUtilsProvider utils={LuxonUtils}>
                <OrganizationProvider>
                  <UserProvider>
                    <SnackbarProvider>
                      <Switch>
                        <AvailableRoute path="/coupon-masters" exact component={ListCouponMastersPage} />
                        <AvailableRoute path="/coupon-masters/new" exact component={CreateCouponMasterPage} />
                        <AvailableRoute
                          path="/coupon-masters/:couponMasterId"
                          exact
                          component={CouponMasterDashboardPage}
                        />
                        <AvailableRoute
                          path="/coupon-masters/:couponMasterId/edit"
                          exact
                          component={UpdateCouponMasterPage}
                        />
                        <AvailableRoute
                          path="/coupon-masters/:couponMasterId/coupons/:couponId"
                          exact
                          component={CouponDetailPage}
                        />
                        <AvailableRoute path="/coupons" exact component={ListCouponsPage} />
                        <AvailableRoute path="/coupon-usage" exact component={CouponUsage} />
                        <AvailableRoute path="/coupon-usage-list" exact component={CouponUsageLog} />
                        <AvailableRoute path="/coupon-displayed" exact component={CouponDisplayed} />
                        <AvailableRoute path="/coupon-displayed-list" exact component={CouponDisplayedLog} />
                        <AvailableRoute path="/switch-organization" exact component={SwitchOrganizationPage} />
                        <AvailableRoute
                          path="/manage-organizations/:organizationId"
                          exact
                          component={OrganizationDashboardPage}
                        />
                        <AvailableRoute
                          path="/manage-organizations/:organizationId/suite"
                          exact
                          component={UpsertOrganizationSuitePage}
                        />
                        <AvailableRoute
                          path="/manage-organizations/:organizationId/shop-groups/new"
                          exact
                          component={CreateShopGroupPage}
                        />
                        <AvailableRoute
                          path="/manage-organizations/:organizationId/shops/new"
                          exact
                          component={CreateShopPage}
                        />
                        <AvailableRoute
                          path="/manage-organizations/:organizationId/shop-group/edit"
                          exact
                          component={UpdateShopGroupPage}
                        />
                        <AvailableRoute
                          path="/manage-organizations/:organizationId/shop/edit"
                          exact
                          component={UpdateShopPage}
                        />
                        <AvailableRoute path="/users" exact component={ListUsersPage} />
                        <AvailableRoute path="/users/:userId" exact component={UserDashboardPage} />
                        <ProtectedRoute path="/" exact component={HomePage} />
                        <ProtectedRoute component={NotFound} />
                      </Switch>
                    </SnackbarProvider>
                  </UserProvider>
                </OrganizationProvider>
              </MuiPickersUtilsProvider>
            </ThemeProvider>
          </AuthorizedApolloProvider>
        </Auth0Provider>
      </BrowserRouter>
    </ErrorBoundary>
  );
}

export default App;
