import { Box, Button, CircularProgress, Container, Grid, InputLabel, Theme } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import { MenuHeaderTemplate } from '../../components/templates/MenuHeaderTemplate';
import { FormProvider, useForm } from 'react-hook-form';
import { Search } from '@material-ui/icons';
import { useUsageUseCase } from './hooks/usage-use-case';
import { makeStyles } from '@material-ui/core/styles';
import { InvokeCouponUsageAggregateJsonQueryVariables } from '../../graphql/generated';
import { ReadMoreButton } from '../../components/organisms/general/ReadMoreButton';
import { useUrlSearchParamOrganization } from '../../components/contexts/hooks/url';
import { TextFieldController } from '../../components/organisms/form/controllers/TextFieldController';
import { SelectController } from '../../components/organisms/form/controllers/SelectController';
import { CouponUsageInfo } from './CouponUsageInfo';
import { CouponUsageByCouponMasterTable } from './CouponUsageByCouponMasterTable';
import { CouponUsageByOrganizationTable } from './CouponUsageByOrganizationTable';

const useStyles = makeStyles((theme: Theme) => ({
  area: {
    marginTop: theme.spacing(3),
  },
  searchForm: {
    width: '100%',
  },
  section: {
    marginTop: theme.spacing(1),
  },
}));

export type CouponUsageFormValues = InvokeCouponUsageAggregateJsonQueryVariables;

export const CouponUsage: React.FC = () => {
  const classes = useStyles();
  const organizationParam = useUrlSearchParamOrganization();
  const {
    data,
    loading,
    onSearch,
    onFetchMore,
    onCsvOut,
    csvLoading,
    organizationLoading,
    isFetchMore,
    organization,
    initialValues,
    userInfo,
    allAcquired,
  } = useUsageUseCase();

  const form = useForm<CouponUsageFormValues>({
    defaultValues: initialValues,
  });
  const { handleSubmit, watch } = form;
  const searchGroupBy = watch('groupBy');
  const [groupByCategory, setGroupByCategory] = useState<'couponMaster' | 'organization'>();

  const couponUsageByTable = useCallback(() => {
    if (!searchGroupBy || searchGroupBy === 'COUPON_MASTER') {
      return (
        <CouponUsageByCouponMasterTable
          loading={loading}
          permissions={userInfo?.permissions}
          organizationParam={organizationParam}
          nodes={data?.nodes}
        />
      );
    } else {
      return <CouponUsageByOrganizationTable loading={loading} searchGroupBy={searchGroupBy} query={data} />;
    }
  }, [data, loading]);

  // 検索条件表示切り替えのため、グルーピングカテゴリを状態として保持する
  useEffect(() => {
    if (searchGroupBy === 'COUPON_MASTER') {
      setGroupByCategory('couponMaster');
    } else if (searchGroupBy === 'BIG_SHOP_GROUP' || searchGroupBy === 'SMALL_SHOP_GROUP' || searchGroupBy === 'SHOP') {
      setGroupByCategory('organization');
    }
  }, [searchGroupBy]);

  // 組織情報が取得できていないタイミングでは、ローディングを出す
  if (organizationLoading || !organization) {
    return (
      <Box display="flex" alignItems="center" justifyContent="center" className={classes.area}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <MenuHeaderTemplate header={{ title: 'クーポン利用状況集計' }}>
        <Container maxWidth="xl">
          <CouponUsageInfo searchGroupBy={searchGroupBy} />
          <Box className={classes.area}>
            <FormProvider {...form}>
              <form onSubmit={handleSubmit(onSearch)} autoComplete="off" noValidate className={classes.searchForm}>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <InputLabel shrink id="groupBy">
                      集計単位の選択
                    </InputLabel>
                    <SelectController
                      name="groupBy"
                      defaultValue={'COUPON_MASTER'}
                      variant="standard"
                      menuItems={[
                        { value: 'COUPON_MASTER', label: 'クーポンマスタ別' },
                        { value: 'SHOP', label: '店舗別' },
                        { value: 'SMALL_SHOP_GROUP', label: '小店鋪グループ別' },
                        { value: 'BIG_SHOP_GROUP', label: '大店鋪グループ別' },
                      ]}
                      disabled={loading || csvLoading}
                    />
                  </Grid>
                  <Grid item xs={8} />
                  <Grid item xs={4}>
                    <TextFieldController
                      name="organizationName"
                      label={groupByCategory === 'couponMaster' ? '発行した組織名' : '表示する組織名'}
                      size="small"
                      disabled={loading || csvLoading}
                      rule={{
                        maxLength: {
                          value: 30,
                          message: '30文字以内で入力してください',
                        },
                      }}
                    />
                  </Grid>
                  {groupByCategory === 'couponMaster' ? (
                    <>
                      <Grid item xs={4}>
                        <TextFieldController
                          name="couponCode"
                          label="クーポンコード"
                          size="small"
                          disabled={loading || csvLoading}
                          rule={{
                            maxLength: {
                              value: 30,
                              message: '30文字以内で入力してください',
                            },
                          }}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <TextFieldController
                          name="couponName"
                          label="クーポン名"
                          size="small"
                          disabled={loading || csvLoading}
                          rule={{
                            maxLength: {
                              value: 30,
                              message: '30文字以内で入力してください',
                            },
                          }}
                        />
                      </Grid>
                    </>
                  ) : (
                    <Grid item xs={8} />
                  )}
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={2}>
                        <Button
                          type="submit"
                          variant={'outlined'}
                          size={'small'}
                          color={'default'}
                          disabled={loading || csvLoading}
                          endIcon={<Search />}
                          fullWidth
                        >
                          を含む
                        </Button>
                      </Grid>
                      <Grid item xs={2}>
                        <Button
                          type="submit"
                          variant="contained"
                          size="small"
                          color={'secondary'}
                          disabled={loading || csvLoading}
                          onClick={handleSubmit(onCsvOut)}
                          fullWidth
                        >
                          {csvLoading ? <CircularProgress size={30} /> : 'CSV出力'}
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            </FormProvider>
          </Box>
          {couponUsageByTable()}
          {data?.nodes && !allAcquired ? (
            <ReadMoreButton fetchMore={handleSubmit(onFetchMore)} loading={loading} />
          ) : (
            <></>
          )}
        </Container>
      </MenuHeaderTemplate>
    </>
  );
};
