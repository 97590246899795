import {
  Box,
  Button,
  Grid,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Theme,
  Tooltip,
} from '@material-ui/core';
import React from 'react';
import { BasicTableHead, BasicTableHeadProps } from '../../../components/molecules';
import { Organization, OrganizationType, PaginatedOrganizations } from '../../../graphql/generated';
import { ReadMoreButton } from '../../../components/organisms/general/ReadMoreButton';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useHistory } from 'react-router';
import { Search } from '@material-ui/icons';
import { useForm, FormProvider } from 'react-hook-form';
import { ChildrenOrganizationFormValues } from '../hooks/search-chldren-organization-use-case';
import { TextFieldController } from '../../../components/organisms/form/controllers/TextFieldController';
import { formatOrganizationTypeString } from '../../../components/contexts/organization';

export type ChildrenShopGroupListProps = {
  onSearch: (data: ChildrenOrganizationFormValues) => void;
  loading: boolean;
  parentType?: OrganizationType;
  data?: PaginatedOrganizations;
  fetchMore: () => void;
};

const useStyles = makeStyles((theme: Theme) => ({
  list: {
    marginTop: theme.spacing(3),
  },
  searchButton: {
    textAlign: 'center',
  },
}));

export const ChildrenOrganizationListBody: React.FC<ChildrenShopGroupListProps> = ({
  data,
  loading,
  parentType,
  onSearch,
  fetchMore,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const form = useForm<ChildrenOrganizationFormValues>();
  const { handleSubmit } = form;

  const childrenTable: React.FC<{ items?: Organization[]; parentType?: OrganizationType }> = (props) => {
    const groupTableHead: BasicTableHeadProps = {
      columns: [
        {
          name: 'organizationName',
          label: '組織名',
          minWidth: theme.spacing(20),
          align: 'left',
        },
        {
          name: 'type',
          label: '種別',
          minWidth: theme.spacing(20),
          align: 'left',
        },
        {
          name: 'detailButton',
          label: '',
          minWidth: theme.spacing(20),
          align: 'center',
        },
      ],
    };

    const shopTableHead: BasicTableHeadProps = {
      columns: [
        {
          name: 'organizationName',
          label: '店舗名',
          minWidth: theme.spacing(20),
          align: 'left',
        },
        {
          name: 'type',
          label: '種別',
          minWidth: theme.spacing(20),
          align: 'left',
        },
        {
          name: 'customerShopCode',
          label: '顧客店舗コード',
          minWidth: theme.spacing(20),
          align: 'left',
        },
        {
          name: 'clearingCode',
          label: '店舗消込コード',
          minWidth: theme.spacing(20),
          align: 'left',
        },
        {
          name: 'detailButton',
          label: '',
          minWidth: theme.spacing(20),
          align: 'center',
        },
      ],
    };

    if (!props.items || !props.parentType) {
      return (
        <>
          <LinearProgress />
          <Table>
            <BasicTableHead columns={groupTableHead.columns} />
            <TableBody />
          </Table>
        </>
      );
    }

    if (props.items.length === 0) {
      return (
        <Table>
          <BasicTableHead columns={groupTableHead.columns} />
          <TableBody>
            <TableRow>
              <TableCell>データがありません</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      );
    }

    if (props.parentType === 'SMALL_SHOP_GROUP') {
      return (
        <Table>
          <BasicTableHead columns={shopTableHead.columns} />
          <TableBody>
            {props.items.map((row: Organization) => (
              <TableRow key={row.id}>
                <TableCell component="th" scope="row">
                  {row.name}
                </TableCell>
                <TableCell component="th" scope="row">
                  {formatOrganizationTypeString(row.type)}
                </TableCell>
                <TableCell component="th" scope="row">
                  {row.customerShopCode}
                </TableCell>
                <TableCell component="th" scope="row">
                  {row.simpleClearingCode}
                </TableCell>
                <TableCell align="center">
                  <Button
                    type="button"
                    size="large"
                    color="primary"
                    fullWidth
                    onClick={() => {
                      history.push({
                        pathname: `/manage-organizations/${row.id}`,
                        hash: '#about',
                        search: history.location.search,
                      });
                    }}
                  >
                    詳細
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      );
    }

    return (
      <Table>
        <BasicTableHead columns={groupTableHead.columns} />
        <TableBody>
          {props.items.map((row: Organization) => (
            <TableRow key={row.id}>
              <TableCell component="th" scope="row">
                {row.name}
              </TableCell>
              <TableCell component="th" scope="row">
                {formatOrganizationTypeString(row.type)}
              </TableCell>
              <TableCell align="center">
                <Button
                  type="button"
                  size="large"
                  color="primary"
                  fullWidth
                  onClick={() => {
                    history.push({
                      pathname: `/manage-organizations/${row.id}`,
                      search: history.location.search,
                    });
                  }}
                >
                  詳細
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    );
  };

  return (
    <>
      <Box>
        <FormProvider {...form}>
          <form onSubmit={handleSubmit(onSearch)} autoComplete="off" noValidate>
            <Grid container spacing={2} alignItems={'center'}>
              <Grid item xs={6}>
                <TextFieldController
                  name="organizationName"
                  label="組織名が"
                  rule={{
                    maxLength: {
                      value: 30,
                      message: '30文字以内で入力してください',
                    },
                  }}
                />
              </Grid>
              <Tooltip title="空で検索すると、全データのうち数件だけ返します" placement="right-start">
                <Grid item xs={6}>
                  <Button
                    type="submit"
                    size="large"
                    variant="outlined"
                    color={'default'}
                    disabled={loading}
                    endIcon={<Search />}
                    fullWidth
                  >
                    から始まる
                  </Button>
                </Grid>
              </Tooltip>
            </Grid>
          </form>
        </FormProvider>
      </Box>
      <Box className={classes.list}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TableContainer component={Paper}>
              {childrenTable({
                items: data?.items || undefined,
                parentType,
              })}
            </TableContainer>
          </Grid>
          {data?.nextToken ? <ReadMoreButton fetchMore={fetchMore} loading={loading} /> : <React.Fragment />}
        </Grid>
      </Box>
    </>
  );
};
