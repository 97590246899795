import React from 'react';

import { Controller, useFormContext } from 'react-hook-form';
import { Switch, FormControlLabel } from '@material-ui/core';

type SwitchControllerProps = {
  name: string;
  label: string;
  disabled?: boolean;
};

/**
 * React Hook Form チェックボックスコントローラー
 */
export const SwitchController: React.FC<SwitchControllerProps> = ({ name, label, disabled }) => {
  const { control, getValues } = useFormContext();
  return (
    <FormControlLabel
      control={
        <Controller
          name={name}
          control={control}
          defaultValue={getValues(name)}
          render={({ field }) => (
            <Switch onChange={(e) => field.onChange(e.target.checked)} checked={field.value} disabled={disabled} />
          )}
        />
      }
      key={name}
      label={label}
    />
  );
};
