import { useSnackbar } from 'notistack';
import { useCallback } from 'react';
import Bugsnag from '@bugsnag/js';
import { useUpdateUserPasswordMutation } from '../../../graphql/generated';
import { UpdateUserPasswordFormValues } from '../dialogs/UpdateUserPassword';

export type UpdateUserPasswordUseCase = {
  loading: boolean;
  onUpdate: (input: UpdateUserPasswordFormValues) => void;
};

export const useUpdateUserPasswordUseCase = (setOpen: (open: boolean) => void): UpdateUserPasswordUseCase => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [updateUserPasswordMutation, { loading, error }] = useUpdateUserPasswordMutation();

  const onUpdate = useCallback(
    async (input: UpdateUserPasswordFormValues) => {
      closeSnackbar();

      console.log('input', input);

      await updateUserPasswordMutation({
        variables: {
          id: input.id,
          password: input.password,
        },
      });

      if (error) {
        Bugsnag.notify(error);
        console.error(error);
        enqueueSnackbar(`ユーザーパスワードの更新に失敗しました: ${error}`, {
          variant: 'error',
          persist: true,
        });
        return;
      }

      setOpen(false);

      enqueueSnackbar(`ユーザーパスワードを更新しました`, {
        variant: 'success',
      });
    },
    [updateUserPasswordMutation, enqueueSnackbar]
  );

  return {
    loading,
    onUpdate,
  };
};
