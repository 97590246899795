import React from 'react';
import { MenuHeaderTemplate } from '../../components/templates/MenuHeaderTemplate';
import { HeaderProps } from '../../components/organisms/general/MenuHeader';
import {
  OrganizationSearchFormBody,
  OrganizationSearchFormBodyProps,
} from '../organization-util/OrganizationSerachFormBody';
import { HistoryPush, useOrganizationSearch } from '../organization-util/hooks';
import { Box, Container, Dialog, DialogTitle } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import {
  OrganizationBreadCrumbsBody,
  OrganizationBreadCrumbsBodyProps,
} from '../organization-util/OrganizationBreadcrumbs';

const useStyles = makeStyles((theme: Theme) => ({
  dialogContent: {
    padding: theme.spacing(3),
  },
  organizationSearchForm: {
    marginTop: theme.spacing(1),
  },
}));

type SwitchOrganizationPageProps = {
  header: HeaderProps;
  breadcrumb: OrganizationBreadCrumbsBodyProps;
  searchForm: OrganizationSearchFormBodyProps;
};

const useSwitchOrganizationPage = (): SwitchOrganizationPageProps => {
  const searchForm = useOrganizationSearch();
  return {
    header: { title: '組織の変更' },
    breadcrumb: {
      originalOrganizationId: searchForm.originalOrganizationId,
      organization: searchForm.targetOrganization,
      setOrganizationTarget: searchForm.onDrillDown,
    },
    searchForm,
  };
};

export const SwitchOrganizationPage: React.FC = () => {
  const classes = useStyles();
  const switchOrganizationPage = useSwitchOrganizationPage();
  return (
    <MenuHeaderTemplate header={switchOrganizationPage.header}>
      <Container maxWidth="xl">
        <Box>
          <OrganizationBreadCrumbsBody {...switchOrganizationPage.breadcrumb} />
        </Box>
        <Box className={classes.organizationSearchForm}>
          <OrganizationSearchFormBody {...switchOrganizationPage.searchForm} />
        </Box>
      </Container>
    </MenuHeaderTemplate>
  );
};

export function SwitchOrganizationDialog<T>(props: {
  historyPush: HistoryPush<T>;
  open: boolean;
  setOpen: (open: boolean) => void;
}): React.ReactElement {
  const classes = useStyles();
  const searchForm = useOrganizationSearch<T>(props.historyPush);
  const breadcrumb: OrganizationBreadCrumbsBodyProps = {
    organization: searchForm.targetOrganization,
    setOrganizationTarget: searchForm.onDrillDown,
    originalOrganizationId: searchForm.originalOrganizationId,
  };
  return (
    <Dialog maxWidth="xl" fullWidth open={props.open} onClose={() => props.setOpen(false)}>
      <DialogTitle id="select-dialog-title">組織を変更して新規作成</DialogTitle>
      <Box className={classes.dialogContent}>
        <Box>
          <OrganizationBreadCrumbsBody {...breadcrumb} />
        </Box>
        <Box className={classes.organizationSearchForm}>
          <OrganizationSearchFormBody {...searchForm} />
        </Box>
      </Box>
    </Dialog>
  );
}
