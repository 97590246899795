import { CouponsQuery, useCouponsLazyQuery } from '../../../../graphql/generated';
import { useCallback, useEffect, useState } from 'react';
import { CouponListConditionFormValues } from '../CouponList';
import Bugsnag from '@bugsnag/js';

const PageItemsCount = 25;

type ListCouponMasters = {
  loading: boolean;
  onSearchCoupons: (condition: CouponListConditionFormValues) => void;
  data?: CouponsQuery;
  onFetchMore: () => void;
  initialValues: CouponListConditionFormValues;
};

export const useListCoupons = (couponMasterId: string): ListCouponMasters => {
  const [loading, setLoading] = useState(false);

  const [listQuery, queryContext] = useCouponsLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: () => {
      setLoading(false);
    },
    onError: (error) => {
      console.log(error);
      Bugsnag.notify(error);
    },
  });

  const initialValues: CouponListConditionFormValues = {
    value: '',
    searchCondition: 'none',
  };

  // 初回、検索条件なしで表示する
  useEffect(() => {
    setLoading(true);
    listQuery({
      variables: {
        couponMasterId,
        limit: PageItemsCount,
      },
    });
  }, [listQuery]);

  // 動的な検索条件をセットしてクーポンマスタを検索する
  const onSearchCoupons = useCallback(
    (data: CouponListConditionFormValues) => {
      setLoading(true);
      listQuery({
        variables: {
          couponMasterId,
          [data.searchCondition]: data.value,
          limit: PageItemsCount,
        },
      });
    },
    [listQuery]
  );

  // さらに読み込む
  const onFetchMore = useCallback(async () => {
    if (queryContext?.fetchMore && queryContext.variables) {
      setLoading(true);
      await queryContext.fetchMore({
        variables: {
          couponMasterId,
          nextToken: queryContext.data?.coupons.pageInfo.nextToken,
          limit: PageItemsCount,
        },
        updateQuery: (prevQuery, { fetchMoreResult }) => {
          setLoading(false);
          if (!fetchMoreResult) {
            return prevQuery;
          }
          return {
            coupons: {
              __typename: fetchMoreResult.coupons.__typename,
              nodes: [...prevQuery.coupons.nodes, ...fetchMoreResult.coupons.nodes],
              pageInfo: fetchMoreResult.coupons.pageInfo,
            },
          };
        },
      });
    }
  }, [queryContext]);

  return {
    loading,
    onSearchCoupons,
    data: queryContext.data,
    onFetchMore,
    initialValues,
  };
};
