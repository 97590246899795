import { OrganizationType } from '../../../graphql/generated';

type OrganizationTypeString = string;

export const DisplayShopType = {
  BIG_SHOP_GROUP: '大店舗グループ',
  SMALL_SHOP_GROUP: '小店舗グループ',
  SHOP: '店舗',
} as { [key in OrganizationType]: string };

export function formatOrganizationTypeString(shopTypeCode: OrganizationType): OrganizationTypeString {
  return DisplayShopType[shopTypeCode];
}
