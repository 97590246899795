import { Box, Button, Container, Typography } from '@material-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';
import { useUrlSearchParamOrganization } from '../../components/contexts/hooks/url';

export const NotAuthorized: React.FC = () => {
  const organizationParam = useUrlSearchParamOrganization();
  return (
    <Container>
      <Box display="flex" justifyContent="center">
        <Typography variant="body1">権限がありません</Typography>
      </Box>
      <Box display="flex" justifyContent="center">
        <Button component={Link} to={{ pathname: '/', search: organizationParam }}>
          ホームに戻る
        </Button>
      </Box>
    </Container>
  );
};
