import { useCallback, useEffect, useState } from 'react';
import {
  CouponMaster,
  CreateCouponMasterInput,
  useCouponMasterLazyQuery,
  useCreateCouponMasterMutation,
} from '../../../../graphql/generated';
import { CouponMasterFormInput } from '../Form';
import { CreateCouponMasterPageProps } from '../Create';
import { useSnackbar } from 'notistack';
import {
  companyImageProps,
  productImageProps,
  reproduceInputFromCouponMaster,
  smartCheckConfirmButtonImageProps,
  smartCheckConfirmCancelButtonImageProps,
  smartCheckForceButtonImageProps,
  smartCheckHeaderImageProps,
  smartCheckUseButtonImageProps,
  smartCheckUsedImageProps,
} from './utils';
import { useHistory } from 'react-router-dom';
import Bugsnag from '@bugsnag/js';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { useFormCouponMaster } from './form-coupon-master';
import { useUploadImage } from './upload-image';
import { CouponFeature } from '../../../../components/contexts/organization';
import { DateTime } from 'luxon';

function createInitialValue(couponFeature: CouponFeature): CouponMasterFormInput {
  return {
    organizationId: '',
    couponName: '',
    couponNameHtml: '',
    couponCode: '',
    unAvailable: false,
    applyWith: couponFeature.apply.with,
    applyBarcodeSourceType: couponFeature.apply.barcode.sourceType,
    applyBarcodeDisplayType: couponFeature.apply.barcode.displayType,
    displayStartDate: null,
    displayEndDate: null,
    validityStartDate: null,
    validityStartTime: null,
    validityEndDate: null,
    validityEndTime: DateTime.local().set({ hour: 23, minute: 59 }),
    realTimeIssuedGetStartDate: null,
    realTimeIssuedGetEndDate: null,
    realTimeIssuedGetStartTime: null,
    realTimeIssuedGetEndTime: null,
    couponIssuedMaximumNumber: '',
    availableStartTimePeriod: null,
    availableEndTimePeriod: null,
    timeFrame: 'none',
    availableDays: {
      mon: true,
      tue: true,
      wed: true,
      thu: true,
      fri: true,
      sat: true,
      sun: true,
    },
    couponNameBandColor: '#ffffff',
    couponNameTextColor: '#000000',
    couponIssuerBandColor: '#ffffff',
    couponIssuerTextColor: '#000000',
    layoutPattern: 'normal',
    smartCheckSettingsInput: {
      smartCheckConfirmText: '',
      smartCheckUseButtonBottomText: '',
      smartCheckCountDownText: '',
      countDownMinute: '',

      // クーポンマスタで静的バーコードが4つまで設定できるので、初期値を4つにしています。
      // React Hook Form のuseFieldArray フック を使っているので、このような感じになっています。
      staticBarcodes: [
        { barcodeType: 'none', barcode: '' },
        { barcodeType: 'none', barcode: '' },
        { barcodeType: 'none', barcode: '' },
        { barcodeType: 'none', barcode: '' },
      ],
    },
  };
}

export const useCreateCouponMaster = (copyOfCouponMasterId?: string): CreateCouponMasterPageProps => {
  const history = useHistory();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [submitStepMessage, setSubmitStepMessage] = useState('読み込み中...'); // 登録中...みたいなメッセージ

  // フォーム共通処理
  const {
    organization,
    couponFeature,
    organizationLoading,
    validateDateAndGetDateTime,
    validateAndConvertAvailableSetting,
    loading,
    setLoading,
  } = useFormCouponMaster();

  // 画像アップロード＆クーポンマスタ更新
  const useUpload = useUploadImage();
  const {
    loading: imageLoading,
    uploadImages,
    shouldUploadImage,
    validateImageFiles,
    validateAndSetSmartCheckUsedFile,
    validateAndSetProductFile,
    validateAndSetSmartCheckForceButtonFile,
    validateAndSetSmartCheckConfirmCancelButtonFile,
    validateAndSetSmartCheckConfirmButtonFile,
    validateAndSetSmartCheckUseButtonFile,
    validateAndSetSmartCheckHeaderFile,
    validateAndSetCompanyFile,
    setDeleteProductFile,
    setDeleteHeaderFile,
    setDeleteSmartCheckHeaderFile,
    setDeleteSmartCheckUseButtonFile,
    setDeleteSmartCheckConfirmButtonFile,
    setDeleteSmartCheckConfirmCancelButtonFile,
    setDeleteSmartCheckForceButtonFile,
    setDeleteSmartCheckUsedFile,
    selectedFiles,
  } = useUpload;

  // データを作成したあとにマスターに対して画像をアップロードする順序のため、Create も Update も用意する必要がある
  const [createCouponMasterMutation] = useCreateCouponMasterMutation();

  // コピーして新規作成 or まっさらで新規作成
  const [previousValues, setPreviousValues] = useState<CouponMasterFormInput>();
  const [freshCouponMasterQuery] = useCouponMasterLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: async (data) => {
      const m = data.couponMaster;
      // 消込方法が異なる場合、警告ダイアログを出して未入力状態にします
      if (couponFeature?.apply.with && m.applyWith !== couponFeature.apply.with) {
        enqueueSnackbar('利用方法が異なるクーポンのコピーを検出しました。現状禁止しています。', {
          variant: 'warning',
        });
        setPreviousValues(createInitialValue(couponFeature));
        setLoading(false);
        return;
      }

      setPreviousValues(reproduceInputFromCouponMaster(m));
      // アップデートと違い、コピーして新規作成の場合はそのまアップロードできるように画像をローカルファイルとして再生成する必要がある
      const setImages = async (): Promise<void> => {
        const config: AxiosRequestConfig = {
          responseType: 'blob',
          headers: {
            Accept: 'image/*;q=0.8',
            'Access-Control-Allow-Origin': '*',
          },
        };
        const getContentType = (res: AxiosResponse): FilePropertyBag => ({
          type: res.headers['content-type'],
        });
        if (m.productImageUrl) {
          const productImageResponse = await axios.get(m.productImageUrl, config);
          console.log('productImageResponse.headers', productImageResponse.headers);
          const productImageFileCopy = new File(
            [productImageResponse.data],
            m.productImageUrl,
            getContentType(productImageResponse)
          );
          validateAndSetProductFile(productImageFileCopy);
        }
        if (m.companyImageUrl) {
          const companyImageResponse = await axios.get(m.companyImageUrl, config);
          const companyImageFileCopy = new File(
            [companyImageResponse.data],
            m.companyImageUrl,
            getContentType(companyImageResponse)
          );
          validateAndSetCompanyFile(companyImageFileCopy);
        }
        if (m.smartCheckImageUrl?.smartCheckHeaderImageUrl) {
          const imageResponse = await axios.get(m.smartCheckImageUrl.smartCheckHeaderImageUrl, config);
          const imageFileCopy = new File(
            [imageResponse.data],
            m.smartCheckImageUrl.smartCheckHeaderImageUrl,
            getContentType(imageResponse)
          );
          validateAndSetSmartCheckHeaderFile(imageFileCopy);
        }
        if (m.smartCheckImageUrl?.smartCheckUseButtonImageUrl) {
          const imageResponse = await axios.get(m.smartCheckImageUrl.smartCheckUseButtonImageUrl, config);
          const imageFileCopy = new File(
            [imageResponse.data],
            m.smartCheckImageUrl.smartCheckUseButtonImageUrl,
            getContentType(imageResponse)
          );
          validateAndSetSmartCheckUseButtonFile(imageFileCopy);
        }
        if (m.smartCheckImageUrl?.smartCheckConfirmButtonImageUrl) {
          const imageResponse = await axios.get(m.smartCheckImageUrl.smartCheckConfirmButtonImageUrl, config);
          const imageFileCopy = new File(
            [imageResponse.data],
            m.smartCheckImageUrl.smartCheckConfirmButtonImageUrl,
            getContentType(imageResponse)
          );
          validateAndSetSmartCheckConfirmButtonFile(imageFileCopy);
        }
        if (m.smartCheckImageUrl?.smartCheckConfirmCancelButtonImageUrl) {
          const imageResponse = await axios.get(m.smartCheckImageUrl.smartCheckConfirmCancelButtonImageUrl, config);
          const imageFileCopy = new File(
            [imageResponse.data],
            m.smartCheckImageUrl.smartCheckConfirmCancelButtonImageUrl,
            getContentType(imageResponse)
          );
          validateAndSetSmartCheckConfirmCancelButtonFile(imageFileCopy);
        }
        if (m.smartCheckImageUrl?.smartCheckForceButtonImageUrl) {
          const imageResponse = await axios.get(m.smartCheckImageUrl.smartCheckForceButtonImageUrl, config);
          const imageFileCopy = new File(
            [imageResponse.data],
            m.smartCheckImageUrl.smartCheckForceButtonImageUrl,
            getContentType(imageResponse)
          );
          validateAndSetSmartCheckForceButtonFile(imageFileCopy);
        }
        if (m.smartCheckImageUrl?.smartCheckUsedImageUrl) {
          const imageResponse = await axios.get(m.smartCheckImageUrl.smartCheckUsedImageUrl, config);
          const imageFileCopy = new File(
            [imageResponse.data],
            m.smartCheckImageUrl.smartCheckUsedImageUrl,
            getContentType(imageResponse)
          );
          validateAndSetSmartCheckUsedFile(imageFileCopy);
        }
      };
      await setImages().catch(console.error);
      setLoading(false);
    },
  });
  useEffect(() => {
    if (copyOfCouponMasterId) {
      // コピーして新規作成
      setLoading(true);
      freshCouponMasterQuery({
        variables: {
          couponMasterId: copyOfCouponMasterId,
        },
      });
    } else if (couponFeature) {
      setPreviousValues(createInitialValue(couponFeature));
      setLoading(false);
    }
  }, [
    copyOfCouponMasterId,
    validateAndSetProductFile,
    validateAndSetCompanyFile,
    organization,
    couponFeature,
    enqueueSnackbar,
  ]);

  const onSubmit = useCallback(
    async (input: CouponMasterFormInput) => {
      console.log('input', input);

      closeSnackbar();

      // 組織情報が読み込み終わった後にコンテンツを表示しているので、実際には発生しないと思われる
      if (!organization || !couponFeature) {
        setLoading(false);
        enqueueSnackbar('データ読み込み中です。もう少々おまちください。', {
          variant: 'warning',
          autoHideDuration: 10000,
        });
        return;
      }

      // 画像バリデーションがダメだったらその時点で終了
      if (validateImageFiles()) return;

      // 日時期間取得＆バリデーション
      const validatedDateTime = validateDateAndGetDateTime(input);
      if (validatedDateTime.invalid) return;
      const {
        displayStartDateTime,
        displayEndDateTime,
        validityStartDateTime,
        validityEndDateTime,
        realTimeIssuedGetStartDateTime,
        realTimeIssuedGetEndDateTime,
      } = validatedDateTime;

      // 帯時間取得＆バリデーション
      const validatedAvailableSettings = validateAndConvertAvailableSetting(input);
      if (validatedAvailableSettings.invalid) return;
      const { availableDateArray, availableDaysArray, availableStartTimePeriodString, availableEndTimePeriodString } =
        validatedAvailableSettings;

      setLoading(true);
      setSubmitStepMessage('マスタデータを登録しています');

      const requestParameters: CreateCouponMasterInput = {
        organizationId: organization?.id,
        available: !input.unAvailable,
        applyWith: input.applyWith,
        applyBarcode: {
          sourceType: input.applyBarcodeSourceType,
          displayType: input.applyBarcodeDisplayType,
        },
        couponName: input.couponName,
        couponCode: input.couponCode,
        note: input.note,

        displayStartDate: displayStartDateTime.toISO(), // 逆転チェックで必須チェックは通過している
        displayEndDate: displayEndDateTime.toISO(),
        validityStartDateTime: validityStartDateTime ? validityStartDateTime.toISO() : undefined,
        validityEndDateTime: validityEndDateTime.toISO(),
        realTimeIssuedGetStartDateTime: realTimeIssuedGetStartDateTime
          ? realTimeIssuedGetStartDateTime.toISO()
          : undefined,
        realTimeIssuedGetEndDateTime: realTimeIssuedGetEndDateTime ? realTimeIssuedGetEndDateTime.toISO() : undefined,
        couponIssuedMaximumNumber: input.couponIssuedMaximumNumber
          ? Number(input.couponIssuedMaximumNumber)
          : undefined,

        timeFrame: input.timeFrame || 'none',

        availableDays: availableDaysArray,
        availableDate: availableDateArray,
        availableStartTimePeriod: availableStartTimePeriodString,
        availableEndTimePeriod: availableEndTimePeriodString,

        description: input.description,
        information: input.information,
        meta: input.meta,
        mapUrl: input.mapUrl,
        telNumber: input.telNumber,

        couponNameHtml: input.couponNameHtml,
        couponNameBandColor: input.couponNameBandColor,
        couponNameTextColor: input.couponNameTextColor,
        couponIssuerBandColor: input.couponIssuerBandColor,
        couponIssuerTextColor: input.couponIssuerTextColor,
        layoutPattern: input.layoutPattern,

        smartCheckSetting: input.smartCheckSettingsInput,
      };

      console.log('requestParameters', requestParameters);

      // 登録実行
      const { data, errors } = await createCouponMasterMutation({
        variables: {
          input: requestParameters,
        },
      });

      if (!data) {
        setLoading(false);
        enqueueSnackbar(`クーポンデータの登録に失敗しました: ${errors}`, {
          variant: 'error',
          persist: true,
        });
        return;
      }

      // データ作成後、画像をアップロードすることができるか
      if (!shouldUploadImage(data.createCouponMaster.id)) return;

      // 画像がある場合、アップロード処理に移行
      setSubmitStepMessage('画像を登録しています...');

      try {
        await uploadImages(data.createCouponMaster as CouponMaster);

        enqueueSnackbar(`クーポンデータを登録しました`, {
          variant: 'success',
        });
      } catch (err) {
        Bugsnag.notify(err);
        console.error('error', err);
        enqueueSnackbar(
          'クーポンデータは登録しましたが、画像登録に失敗しました。画像のみ再度アップロードしてください',
          {
            variant: 'warning',
            autoHideDuration: 10000,
          }
        );
        await new Promise((resolve) => setTimeout(resolve, 3000));
        history.push({
          pathname: `/coupon-masters/${data.createCouponMaster.id}`,
          search: history.location.search,
        });
      } finally {
        setLoading(false);
      }
      history.push({
        pathname: `/coupon-masters/${data.createCouponMaster.id}`,
        search: history.location.search,
      });
    },
    [organization, couponFeature, createCouponMasterMutation, enqueueSnackbar, useUpload]
  );
  return {
    onSubmit,
    loading: loading || imageLoading,
    previousValues,
    submitStepMessage,
    organization,
    couponFeature,
    organizationLoading,
    productImageProps: productImageProps({
      selectedFile: selectedFiles.productFile,
      validateAndSetFile: validateAndSetProductFile,
      setDeleteFile: setDeleteProductFile,
    }),
    companyImageProps: companyImageProps({
      selectedFile: selectedFiles.companyFile,
      validateAndSetFile: validateAndSetCompanyFile,
      setDeleteFile: setDeleteHeaderFile,
    }),
    smartCheckHeaderImageProps: smartCheckHeaderImageProps({
      selectedFile: selectedFiles.smartCheckHeaderFile,
      validateAndSetFile: validateAndSetSmartCheckHeaderFile,
      setDeleteFile: setDeleteSmartCheckHeaderFile,
    }),
    smartCheckUseButtonImageProps: smartCheckUseButtonImageProps({
      selectedFile: selectedFiles.smartCheckUseButtonFile,
      validateAndSetFile: validateAndSetSmartCheckUseButtonFile,
      setDeleteFile: setDeleteSmartCheckUseButtonFile,
    }),
    smartCheckConfirmButtonImageProps: smartCheckConfirmButtonImageProps({
      selectedFile: selectedFiles.smartCheckConfirmButtonFile,
      validateAndSetFile: validateAndSetSmartCheckConfirmButtonFile,
      setDeleteFile: setDeleteSmartCheckConfirmButtonFile,
    }),
    smartCheckConfirmCancelButtonImageProps: smartCheckConfirmCancelButtonImageProps({
      selectedFile: selectedFiles.smartCheckConfirmCancelButtonFile,
      validateAndSetFile: validateAndSetSmartCheckConfirmCancelButtonFile,
      setDeleteFile: setDeleteSmartCheckConfirmCancelButtonFile,
    }),
    smartCheckForceButtonImageProps: smartCheckForceButtonImageProps({
      selectedFile: selectedFiles.smartCheckForceButtonFile,
      validateAndSetFile: validateAndSetSmartCheckForceButtonFile,
      setDeleteFile: setDeleteSmartCheckForceButtonFile,
    }),
    smartCheckUsedImageProps: smartCheckUsedImageProps({
      selectedFile: selectedFiles.smartCheckUsedFile,
      validateAndSetFile: validateAndSetSmartCheckUsedFile,
      setDeleteFile: setDeleteSmartCheckUsedFile,
    }),
  };
};
