export const CouponStatusObject = {
  ISSUED: '発行済',
  USED: '使用済',
  CANCELED: 'キャンセル済',
} as { [key: string]: string };

export const OperateFromObject = {
  admin: '管理画面',
  user: '利用画面',
  pos: 'POSレジ',
  api: 'API',
} as { [key: string]: string };

export const ApplyWithLabelObject = {
  barcode: 'POS利用',
  smartCheck: 'SmartCheck',
  smartCheckLight: 'SmartCheckLight',
} as { [key: string]: string };
