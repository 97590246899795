import { useCallback, useContext, useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { OrganizationContext } from '../../../components/contexts/organization';
import {
  PollCouponDisplayLogJsonQuery,
  useInvokeCouponDisplayLogCsvUrlLazyQuery,
  useInvokeCouponDisplayLogJsonLazyQuery,
  usePollCouponDisplayLogCsvUrlLazyQuery,
  usePollCouponDisplayLogJsonLazyQuery,
} from '../../../graphql/generated';
import { CouponDisplayedLogFormValues } from '../CouponDisplayedLog';
import { useLocation } from 'react-router';
import Bugsnag from '@bugsnag/js';
import { downloadCsvFileWithBom } from '../../../util/download';
import { useUrlSearchParamOrganization } from '../../../components/contexts/hooks/url';
import { useHistory } from 'react-router-dom';

export type CouponDisplayedLogUseCase = {
  organization?: OrganizationContext;
  organizationLoading: boolean;
  data?: PollCouponDisplayLogJsonQuery;
  loading: boolean;
  csvLoading: boolean;
  clickedCouponId: string;
  allAcquired: boolean;
  onSearch: (condition: CouponDisplayedLogFormValues) => void;
  onCsvOut: (condition: CouponDisplayedLogFormValues) => void;
  onDetailCsvOut: (couponId: string) => void;
  onFetchMore: () => void;
};

export const Limit = 100;

type QueryParams = {
  name: string | null;
  couponMasterId: string | null;
  organizationId: string | null;
};

export const useDisplayedLogUseCase = (): CouponDisplayedLogUseCase => {
  const location = useLocation();
  const history = useHistory();
  const organizationParam = useUrlSearchParamOrganization();
  const search = new URLSearchParams(location.search);
  const queryParams: QueryParams = {
    name: search.get('name'),
    couponMasterId: search.get('couponMasterId'),
    organizationId: search.get('organizationId'),
  };
  const { organization, loading: organizationLoading } = useContext(OrganizationContext);
  const [loading, setLoading] = useState<boolean>(false);
  const [csvLoading, setCsvLoading] = useState<boolean>(false);
  const [clickedCouponId, setClickedCouponId] = useState<string>('');
  const [offset, setOffset] = useState<number>(0);
  const [fetching, setFetching] = useState<boolean>(false);
  const [allAcquired, setAllAcquired] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();

  // 利用状況ログ ポーリング
  const [pollJsonQuery, statusContext] = usePollCouponDisplayLogJsonLazyQuery({
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    pollInterval: 5000,
  });

  // 利用状況ログ 取得
  // ApolloClient で ポーリングとfetchMoreを同時に利用できないため分ける
  const [getJsonQuery, getJsonQueryContext] = usePollCouponDisplayLogJsonLazyQuery({
    fetchPolicy: 'cache-first',
    onCompleted: (_) => {
      setLoading(false);
    },
  });

  // 利用状況集計取得 invoke
  const [invokeJsonQuery, invokeQueryJsonContext] = useInvokeCouponDisplayLogJsonLazyQuery({
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    onCompleted: (data) => {
      pollJsonQuery({
        variables: {
          queryExecutionId: data.invokeCouponDisplayLogJson.queryExecutionId,
        },
      });
      setOffset(offset + Limit);
    },
    onError: (e) => {
      console.error(e);
      Bugsnag.notify(e);
      enqueueSnackbar('利用状況明細一覧の取得に失敗しました', {
        variant: 'error',
      });
    },
  });

  // JSONクエリ出力をポーリングする
  useEffect(() => {
    const status = statusContext?.data?.pollCouponDisplayLogJson?.status;

    // 終了系ステータスの場合、ポーリングを止める
    if (status === 'CANCELLED' || status === 'FAILED' || status === 'SUCCEEDED') {
      if (statusContext && statusContext.stopPolling) {
        statusContext.stopPolling();
      }
    }

    // 正常に終了した場合は、データフェッチ用のクエリを呼び出してステータスを終了にする
    if (
      status === 'SUCCEEDED' &&
      statusContext &&
      statusContext.data &&
      statusContext.data.pollCouponDisplayLogJson &&
      statusContext.data.pollCouponDisplayLogJson.nodes
    ) {
      if (
        fetching &&
        organization &&
        getJsonQueryContext &&
        getJsonQueryContext.fetchMore &&
        getJsonQueryContext.variables
      ) {
        getJsonQueryContext.fetchMore({
          variables: statusContext.variables,
          updateQuery: (prevQuery, { fetchMoreResult }) => {
            setLoading(false);
            if (!fetchMoreResult) {
              return prevQuery;
            }
            // 取得件数が0件の場合は、全件取得済みとみなす
            if (fetchMoreResult?.pollCouponDisplayLogJson?.nodes?.length === 0) {
              setAllAcquired(true);
              enqueueSnackbar('全件取得済みです', { variant: 'success' });
            } else {
              enqueueSnackbar('集計処理が正常に終了しました', { variant: 'success' });
            }
            return {
              pollCouponDisplayLogJson: {
                __typename: fetchMoreResult.pollCouponDisplayLogJson.__typename,
                queryExecutionId: fetchMoreResult.pollCouponDisplayLogJson.queryExecutionId,
                status: fetchMoreResult.pollCouponDisplayLogJson.status,
                nodes: [
                  ...(prevQuery.pollCouponDisplayLogJson.nodes || []),
                  ...(fetchMoreResult.pollCouponDisplayLogJson.nodes || []),
                ],
                pageInfo: fetchMoreResult.pollCouponDisplayLogJson.pageInfo,
              },
            };
          },
        });
        setFetching(false);
      } else {
        getJsonQuery({
          variables: statusContext.variables,
        });
        enqueueSnackbar('集計処理が正常に終了しました', { variant: 'success' });
      }
    } else if (status === 'FAILED' || status === 'CANCELLED') {
      setLoading(false);
      enqueueSnackbar('明細取得処理でエラーが発生しました', {
        variant: 'error',
      });
      Bugsnag.notify(statusContext.error || new Error('polling error'));
    }
  }, [statusContext.data?.pollCouponDisplayLogJson.status]);

  // 前画面からの遷移
  useEffect(() => {
    if (!queryParams.couponMasterId) {
      enqueueSnackbar('お手数ですがトップページからやりなおしてください', {
        variant: 'warning',
      });
      history.push({ pathname: '/', search: organizationParam });
      return;
    }
    if (organization) {
      setLoading(true);
      invokeJsonQuery({
        variables: {
          couponMasterId: queryParams.couponMasterId,
          searchPolicy: 'displayed',
          limit: Limit,
          offset: offset,
        },
      });
    }
  }, [organization, invokeJsonQuery, queryParams.couponMasterId]);

  // 検索
  const onSearchJsonSubmit = useCallback(
    (form: CouponDisplayedLogFormValues) => {
      setOffset(0);
      setLoading(true);
      invokeJsonQuery({
        variables: {
          ...form,
          couponMasterId: queryParams.couponMasterId,
          searchPolicy: 'displayed',
          limit: Limit,
          offset: offset,
        },
      });
    },
    [invokeJsonQuery]
  );

  // 更に読み込む
  const onFetchMoreJson = useCallback(async () => {
    if (statusContext && statusContext.stopPolling) {
      statusContext?.stopPolling();
    }
    if (organization && getJsonQueryContext && getJsonQueryContext.fetchMore && getJsonQueryContext.variables) {
      setLoading(true);
      setFetching(true);
      await invokeJsonQuery({
        variables: {
          couponMasterId: queryParams.couponMasterId,
          searchPolicy: 'displayed',
          limit: Limit,
          offset: offset,
        },
      });
    }
  }, [statusContext, getJsonQueryContext, organization]);

  // 参照明細 CSV URL ポーリング
  const [pollCsvUrlQuery, statusCsvUrlContext] = usePollCouponDisplayLogCsvUrlLazyQuery({
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    pollInterval: 5000,
  });

  // 参照明細CSV取得 invoke
  const [invokeCsvQuery] = useInvokeCouponDisplayLogCsvUrlLazyQuery({
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    onCompleted: (data) => {
      pollCsvUrlQuery({
        variables: {
          queryExecutionId: data.invokeCouponDisplayLogCsvUrl.queryExecutionId,
        },
      });
    },
    onError: (e) => {
      console.error(e);
      Bugsnag.notify(e);
      enqueueSnackbar('参照状況集計一覧の取得に失敗しました', {
        variant: 'error',
      });
    },
  });

  // CSV URL クエリ出力をポーリングする
  // ポーリングクエリの onComplete は呼ばれないので useEffect でやるしかない
  useEffect(() => {
    const status = statusCsvUrlContext?.data?.pollCouponDisplayLogCsvUrl?.status;

    // 終了系ステータスの場合、ポーリングを止める
    if (status === 'CANCELLED' || status === 'FAILED' || status === 'SUCCEEDED') {
      if (statusCsvUrlContext && statusCsvUrlContext.stopPolling) {
        statusCsvUrlContext.stopPolling();
      }
    }

    // 正常に終了した場合は、CSVファイルをダウンロード
    if (
      status === 'SUCCEEDED' &&
      statusCsvUrlContext &&
      statusCsvUrlContext.data &&
      statusCsvUrlContext.data.pollCouponDisplayLogCsvUrl
    ) {
      enqueueSnackbar('明細取得処理が正常に終了しました。CSVファイルをダウンロードします。', { variant: 'success' });
      setCsvLoading(false);
      setClickedCouponId('');
      downloadCsvFileWithBom(statusCsvUrlContext.data.pollCouponDisplayLogCsvUrl.preSignedUrl);
    } else if (status === 'FAILED' || status === 'CANCELLED') {
      setCsvLoading(false);
      enqueueSnackbar('明細取得処理でエラーが発生しました', {
        variant: 'error',
      });
      Bugsnag.notify(statusCsvUrlContext.error || new Error('polling error'));
    }
  }, [
    statusCsvUrlContext.data?.pollCouponDisplayLogCsvUrl.status,
    statusCsvUrlContext.data?.pollCouponDisplayLogCsvUrl.preSignedUrl,
  ]);

  // 全明細CSV出力（クーポンマスタIDを指定）
  const onCsvOutSubmit = useCallback(
    (data: CouponDisplayedLogFormValues) => {
      if (!organization) {
        return;
      }
      setCsvLoading(true);
      invokeCsvQuery({
        variables: {
          ...data,
          couponMasterId: queryParams.couponMasterId,
          searchPolicy: 'displayed',
        },
      });
    },
    [organization, invokeCsvQuery]
  );

  // 行のダウンロードボタンを押したときにPreSignedUrlを取得して再ダウンロードする
  // クーポンIDを指定
  const onDetailCsvOutSubmit = useCallback(
    (couponId: string) => {
      if (!organization) {
        return;
      }
      setClickedCouponId(couponId);
      setCsvLoading(true);
      invokeCsvQuery({
        variables: {
          couponId,
          searchPolicy: 'displayed',
        },
      });
    },
    [organization, invokeCsvQuery]
  );

  return {
    organization,
    organizationLoading,
    data: getJsonQueryContext.data,
    clickedCouponId,
    loading,
    csvLoading,
    allAcquired,
    onSearch: onSearchJsonSubmit,
    onCsvOut: onCsvOutSubmit,
    onDetailCsvOut: onDetailCsvOutSubmit,
    onFetchMore: onFetchMoreJson,
  };
};
