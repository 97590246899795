import React from 'react';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Typography,
} from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { lightBlue } from '@material-ui/core/colors';
import { ArrowRightIcon } from '@material-ui/pickers/_shared/icons/ArrowRightIcon';
import { Done, Warning } from '@material-ui/icons';
import { SelectedFile } from '../../../types/file';

type UploadShopCsvDialogProps = {
  open: boolean;
  loading: boolean;
  onUpload: (parentId: string) => void;
  onClose: () => void;
  validateAndSetFile: (f: File) => void;
  selectedCsvFile: SelectedFile;
  parentId: string;
};

const useStyles = makeStyles((theme: Theme) => ({
  uploadFileInfoArea: {
    borderColor: theme.palette.primary.light,
    backgroundColor: lightBlue['50'],
  },
  uploadButton: {
    opacity: 0,
    appearance: 'none',
    position: 'absolute',
  },
  internetUpload: {
    backgroundColor: theme.palette.warning.light,
    color: theme.palette.warning.contrastText,
    '&:hover': { backgroundColor: theme.palette.warning.dark, cursor: 'pointer' },
  },
}));

export const UploadShopCsvDialog: React.FC<UploadShopCsvDialogProps> = (props) => {
  const classes = useStyles();

  const SelectedFileValidateIcon: React.FC<{ valid?: boolean }> = (props: { valid?: boolean }) => {
    const { valid } = props;
    if (valid === undefined || valid === null) {
      return <ArrowRightIcon />;
    } else if (valid) {
      return <Done color="primary" />;
    } else {
      return <Warning color="error" />;
    }
  };

  return (
    <Dialog fullWidth maxWidth="md" open={props.open} onClose={props.onClose}>
      <DialogContent>
        <Card style={{ height: '100%' }}>
          <CardHeader
            subheaderTypographyProps={{
              variant: 'caption',
            }}
            title="店舗CSV一括登録"
          />
          <Divider variant={'fullWidth'} />
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Button
                  component="label"
                  variant={'outlined'}
                  className={classes.internetUpload}
                  fullWidth
                  disabled={props.loading}
                >
                  {`店舗一括登録CSVを選択`}
                  <input
                    type="file"
                    className={classes.uploadButton}
                    accept="text/csv"
                    onChange={({ target }) => {
                      if (target.files) {
                        props.validateAndSetFile(target.files[0]);
                        target.value = '';
                      }
                    }}
                  />
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Paper variant="outlined" square className={classes.uploadFileInfoArea}>
                  <List dense>
                    <ListItem>
                      <ListItemAvatar>
                        <SelectedFileValidateIcon valid={props.selectedCsvFile.ext?.valid} />
                      </ListItemAvatar>
                      <ListItemText primary="csv" secondary={props.selectedCsvFile.ext?.message} />
                    </ListItem>
                    <ListItem>
                      <ListItemAvatar>
                        <SelectedFileValidateIcon valid={props.selectedCsvFile.length?.valid} />
                      </ListItemAvatar>
                      <ListItemText primary={`1MB以下`} secondary={props.selectedCsvFile.length?.message} />
                    </ListItem>
                  </List>
                </Paper>
              </Grid>
              <Grid item xs={12}>
                <Typography>{props.selectedCsvFile.file?.name}</Typography>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose} color="secondary" disabled={props.loading}>
          キャンセル
        </Button>
        <Button
          onClick={() => {
            props.onUpload(props.parentId);
          }}
          color="primary"
          disabled={props.loading || !props.selectedCsvFile.length.valid || !props.selectedCsvFile.ext.valid}
        >
          {props.loading ? <CircularProgress size="1rem" /> : 'アップロード'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
