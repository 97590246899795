import { Organization, useOrganizationLazyQuery } from '../../../graphql/generated';
import Bugsnag from '@bugsnag/js';
import { useEffect } from 'react';

export type DetailOrganizationUseCase = {
  data?: Organization;
  loading: boolean;
};

export const useDetailOrganizationUseCase = (id: string): DetailOrganizationUseCase => {
  const [bigShopGroupQuery, { data, loading }] = useOrganizationLazyQuery({
    fetchPolicy: 'network-only',
    onError: (e) => {
      Bugsnag.notify(e);
      console.error(e);
    },
  });

  useEffect(() => {
    bigShopGroupQuery({
      variables: {
        id,
      },
    });
  }, [bigShopGroupQuery]);

  return {
    data: data?.organization,
    loading,
  };
};
