import {
  Avatar,
  Card,
  CardActionArea,
  Container,
  Divider,
  Grid,
  IconButton,
  ListItemAvatar,
  ListItemSecondaryAction,
  Theme,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useContext } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { MenuHeaderTemplate } from '../../components/templates/MenuHeaderTemplate';
import { HeaderProps } from '../../components/organisms/general/MenuHeader';
import { ArrowForward, InsertLink } from '@material-ui/icons';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import List from '@material-ui/core/List';
import { LatestCoupon, useLatestCouponMaster } from '../coupon-master/list/hooks/latest-use-case';
import { OrganizationContext } from '../../components/contexts/organization';
import { useUrlSearchParamOrganization } from '../../components/contexts/hooks/url';
import { UserContext } from '../../components/contexts/user/user-context';

const useStyles = makeStyles((theme: Theme) => ({
  avatar: {
    backgroundColor: theme.palette.primary.light,
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
}));

type HomeBodyProps = {
  latestCoupon: LatestCoupon;
};

type HomePageProps = {
  header: HeaderProps;
  homeBodyProps: HomeBodyProps;
};

const HomeBody: React.FC<HomeBodyProps> = (props) => {
  const styles = useStyles();
  const history = useHistory();
  const organizationParam = useUrlSearchParamOrganization();
  const user = useContext(UserContext);

  // 増えてきたらコンポーネント化を検討
  const LatestCouponComponent: React.FC<LatestCoupon> = (props) => {
    return (
      <Card>
        <List>
          <ListItem>
            <ListItemAvatar>
              <Avatar className={styles.avatar}>C</Avatar>
            </ListItemAvatar>
            <ListItemText primary={'最近のクーポン'} />
          </ListItem>
          {(() => {
            // 読込中は プログレスバーを表示する
            if (props.loading || !props.data?.couponMasters) {
              return (
                <ListItem>
                  <ListItemText inset primary={'クーポン名'} secondary="読込中..." />
                </ListItem>
              );
            } else if (props.data.couponMasters.nodes.length === 0) {
              // リストデータがない場合はデータなしとする
              return (
                <ListItem>
                  <ListItemText inset primary={'クーポン名'} secondary="データがありません" />
                </ListItem>
              );
            } else {
              const coupon = props.data.couponMasters.nodes[0];

              return (
                <ListItem>
                  <ListItemText inset primary={'クーポン名'} secondary={coupon.couponName} />
                  {user.userInfo?.permissions.includes('show:coupon-masters') ? (
                    <ListItemSecondaryAction>
                      <IconButton
                        edge="end"
                        onClick={() =>
                          history.push({
                            pathname: `/coupon-masters/${coupon.id}`,
                            search: organizationParam,
                          })
                        }
                      >
                        <InsertLink />
                      </IconButton>
                    </ListItemSecondaryAction>
                  ) : (
                    <></>
                  )}
                </ListItem>
              );
            }
          })()}
        </List>
        {user.userInfo?.permissions.includes('show:coupon-masters') ? (
          <>
            <Divider />
            <CardActionArea>
              <ListItem button component={Link} to={{ pathname: '/coupon-masters', search: organizationParam }}>
                <ListItemIcon>
                  <ArrowForward />
                </ListItemIcon>
                <ListItemText secondary={'クーポンマスタ一覧に移動'} />
              </ListItem>
            </CardActionArea>
          </>
        ) : (
          <></>
        )}
      </Card>
    );
  };

  return (
    <Container maxWidth="xl">
      <Grid container spacing={4}>
        <Grid item sm={6} md={6} lg={6}>
          <LatestCouponComponent {...props.latestCoupon} />
        </Grid>
      </Grid>
    </Container>
  );
};

const useHomePage = (): HomePageProps => {
  const orgContext = useContext(OrganizationContext);
  const latestCoupon = useLatestCouponMaster(orgContext);
  return {
    header: { title: 'クーポン管理ダッシュボード' },
    homeBodyProps: { latestCoupon },
  };
};

export const HomePage: React.FC = () => {
  const homePageProps = useHomePage();
  return (
    <MenuHeaderTemplate header={homePageProps.header}>
      <HomeBody {...homePageProps.homeBodyProps} />
    </MenuHeaderTemplate>
  );
};
