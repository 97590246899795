import React from 'react';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  createStyles,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  makeStyles,
  Paper,
} from '@material-ui/core';
import { lightBlue } from '@material-ui/core/colors';
import { Done, Warning } from '@material-ui/icons';
import { ArrowRightIcon } from '@material-ui/pickers/_shared/icons/ArrowRightIcon';
import { Extension } from '../../../graphql/generated';

const useStyles = makeStyles((theme) =>
  createStyles({
    button: {
      textAlign: 'center',
    },
    uploadFileInfoArea: {
      borderColor: theme.palette.primary.light,
      backgroundColor: lightBlue['50'],
    },
    media: {
      height: '100%',
      width: '100%',
    },
    uploadButton: {
      opacity: 0,
      appearance: 'none',
      position: 'absolute',
    },
    internetUpload: {
      backgroundColor: theme.palette.warning.light,
      color: theme.palette.warning.contrastText,
      '&:hover': { backgroundColor: theme.palette.warning.dark, cursor: 'pointer' },
    },
  })
);

export type SelectedFile = {
  ext: {
    message: Extension | string;
    valid?: boolean;
  };
  length: {
    message: string;
    valid?: boolean;
  };
  file?: File;
  deleteFlag?: boolean;
  toUpdate: boolean;
};

export type CouponMasterImageBodyProps = {
  fileTypeTextOverride?: string;
  currentImageUrl?: string;
  fileTypeText: string;
  validateAndSetFile: (f: File) => void;
  setDeleteFile: () => void;
  selectedFile: SelectedFile;
  widthText: string;
  deletable: boolean;
  maxSizeBytesText: string;
};

export const CouponMasterImageBody: React.FC<CouponMasterImageBodyProps> = (props) => {
  const classes = useStyles();

  const SelectedFileValidateIcon: React.FC<{ valid?: boolean }> = (props: { valid?: boolean }) => {
    const { valid } = props;
    if (valid === undefined || valid === null) {
      return <ArrowRightIcon />;
    } else if (valid) {
      return <Done color="primary" />;
    } else {
      return <Warning color="error" />;
    }
  };

  const fileTypeText = props.fileTypeTextOverride || props.fileTypeText;

  const cardMediaContent = (): React.ReactElement => {
    // 何も入っていない場合は何も表示しない。削除フラグが立っているときはなにも表示しない
    if ((!props.currentImageUrl && !props.selectedFile.file) || props.selectedFile.deleteFlag) {
      return <></>;
    } else if (props.selectedFile.file) {
      return <img className={classes.media} src={URL.createObjectURL(props.selectedFile.file)} alt={fileTypeText} />;
    } else {
      return <img className={classes.media} src={props.currentImageUrl} alt={fileTypeText} />;
    }
  };

  return (
    <Card style={{ height: '100%' }}>
      <CardHeader
        subheaderTypographyProps={{
          variant: 'caption',
        }}
        title={fileTypeText}
        subheader={`推奨サイズ：${props.widthText}`}
      />
      <Divider variant={'fullWidth'} />
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Button component="label" variant={'outlined'} className={classes.internetUpload} fullWidth>
              {`${fileTypeText}を選択`}
              <input
                type="file"
                className={classes.uploadButton}
                accept="image/png,image/jpeg,image/jpg,image/gif"
                onChange={({ target }) => {
                  if (target.files) {
                    props.validateAndSetFile(target.files[0]);
                    target.value = '';
                  }
                }}
              />
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Paper variant="outlined" square className={classes.uploadFileInfoArea}>
              <List dense>
                <ListItem>
                  <ListItemAvatar>
                    <SelectedFileValidateIcon valid={props.selectedFile.ext?.valid} />
                  </ListItemAvatar>
                  <ListItemText primary="png, jpg, gif" secondary={props.selectedFile.ext?.message} />
                </ListItem>
                <ListItem>
                  <ListItemAvatar>
                    <SelectedFileValidateIcon valid={props.selectedFile.length?.valid} />
                  </ListItemAvatar>
                  <ListItemText
                    primary={`${props.maxSizeBytesText}以下`}
                    secondary={props.selectedFile.length?.message}
                  />
                </ListItem>
              </List>
            </Paper>
          </Grid>
          {props.deletable ? (
            <Grid item xs={12}>
              <Button variant={'outlined'} color="secondary" fullWidth onClick={() => props.setDeleteFile()}>
                削除
              </Button>
            </Grid>
          ) : (
            <></>
          )}
          <Grid item xs={12}>
            {cardMediaContent()}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
