import {
  Box,
  Breadcrumbs,
  Button,
  CircularProgress,
  Container,
  Grid,
  IconButton,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Theme,
  Typography,
} from '@material-ui/core';
import React, { useCallback } from 'react';
import { MenuHeaderTemplate } from '../../components/templates/MenuHeaderTemplate';
import { BasicTableHead, BasicTableHeadProps } from '../../components/molecules';
import { FormProvider, useForm } from 'react-hook-form';
import { Launch, Search } from '@material-ui/icons';
import { useDisplayedUseCase } from './hooks/displayed-use-case';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useHistory } from 'react-router';
import { InvokeCouponUsageAggregateJsonQueryVariables } from '../../graphql/generated';
import { appendSearchParameter } from '../../util';
import { useUrlSearchParamOrganization } from '../../components/contexts/hooks/url';
import { ReadMoreButton } from '../../components/organisms/general/ReadMoreButton';
import { TextFieldController } from '../../components/organisms/form/controllers/TextFieldController';

const useStyles = makeStyles((theme: Theme) => ({
  area: {
    marginTop: theme.spacing(3),
  },
  section: {
    marginTop: theme.spacing(1),
  },
}));

export type CouponDisplayedFormValues = InvokeCouponUsageAggregateJsonQueryVariables;

export const CouponDisplayed: React.FC = () => {
  const history = useHistory();
  const classes = useStyles();
  const theme = useTheme();
  const organizationParam = useUrlSearchParamOrganization();
  const form = useForm<CouponDisplayedFormValues>();
  const { handleSubmit } = form;
  const {
    data,
    loading,
    onSearch,
    onFetchMore,
    onCsvOut,
    csvLoading,
    organizationLoading,
    organization,
    userInfo,
    allAcquired,
  } = useDisplayedUseCase();

  const basicTableHead: BasicTableHeadProps = {
    columns: [
      {
        name: 'couponMasterId',
        label: 'マスタ詳細を開く',
        minWidth: theme.spacing(1),
        align: 'left',
        disabled: !userInfo?.permissions.includes('show:coupon-masters'),
      },
      {
        name: 'organizationName',
        label: '発行した組織名',
        minWidth: theme.spacing(1),
        align: 'left',
      },
      {
        name: 'couponCode',
        label: 'クーポンコード',
        minWidth: theme.spacing(1),
        align: 'left',
      },
      {
        name: 'couponName',
        label: 'クーポン名',
        minWidth: theme.spacing(10),
        align: 'left',
      },
      {
        name: 'publishedCount',
        label: '発行数',
        minWidth: theme.spacing(10),
        align: 'right',
      },
      {
        name: 'displayedCount',
        label: '参照数',
        minWidth: theme.spacing(10),
        align: 'right',
      },
      {
        name: 'detailButton',
        label: '',
        minWidth: theme.spacing(10),
        align: 'center',
        disabled: !userInfo?.permissions.includes('show:coupon-displayed-list'),
      },
    ],
  };

  // テーブルボディを切り替える
  const tableBody = useCallback(() => {
    if (!data || !data.pollCouponDisplayAggregateJson) {
      return;
    } else if (!data.pollCouponDisplayAggregateJson.nodes || data.pollCouponDisplayAggregateJson.nodes.length === 0) {
      return (
        <TableRow>
          <TableCell>データがありません</TableCell>
        </TableRow>
      );
    } else {
      return data?.pollCouponDisplayAggregateJson.nodes.map((usage) => {
        return (
          <TableRow key={usage.couponMasterId}>
            {userInfo?.permissions.includes('show:coupon-masters') ? (
              <TableCell>
                <IconButton target="_blank" href={`/coupon-masters/${usage.couponMasterId}?${organizationParam}`}>
                  <Launch fontSize="small" color={'inherit'} />
                </IconButton>
              </TableCell>
            ) : (
              <></>
            )}
            <TableCell>{usage.organizationName}</TableCell>
            <TableCell>{usage.couponCode}</TableCell>
            <TableCell>{usage.couponName}</TableCell>
            <TableCell align="right">{usage.publishedCount}</TableCell>
            <TableCell align="right">{usage.displayedCount}</TableCell>
            {userInfo?.permissions.includes('show:coupon-displayed-list') ? (
              <TableCell align="right" scope="row">
                <Button
                  onClick={() => {
                    history.push({
                      pathname: `/coupon-displayed-list`,
                      search: `${appendSearchParameter(history.location.search, {
                        couponMasterId: usage.couponMasterId || '',
                      })}`,
                      state: {
                        name: usage.couponName,
                      },
                    });
                  }}
                  type="button"
                  size="small"
                  color="primary"
                >
                  明細
                </Button>
              </TableCell>
            ) : (
              <></>
            )}
          </TableRow>
        );
      });
    }
  }, [data]);

  // 組織情報が取得できていないタイミングでは、ローディングを出す
  if (organizationLoading || !organization) {
    return (
      <Box display="flex" alignItems="center" justifyContent="center">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <MenuHeaderTemplate header={{ title: 'クーポン参照状況集計' }}>
        <Container maxWidth="xl">
          <Box className={classes.section}>
            <Breadcrumbs>
              <Box>
                <Typography color="textPrimary">クーポン参照状況集計</Typography>
              </Box>
            </Breadcrumbs>
          </Box>
          <div>
            <Typography variant="caption" className={classes.section}>
              【一覧の並び順】クーポンコード,クーポン名,発行数,参照数
            </Typography>
          </div>

          <Box className={classes.area}>
            <FormProvider {...form}>
              <form onSubmit={handleSubmit(onSearch)} autoComplete="off" noValidate>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <TextFieldController
                      name="couponCode"
                      label="クーポンコード"
                      disabled={loading || csvLoading}
                      size="small"
                      rule={{
                        maxLength: {
                          value: 30,
                          message: '30文字以内で入力してください',
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextFieldController
                      name="couponName"
                      label="クーポン名"
                      size="small"
                      disabled={loading || csvLoading}
                      rule={{
                        maxLength: {
                          value: 30,
                          message: '30文字以内で入力してください',
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextFieldController
                      name="organizationName"
                      label="発行した組織名"
                      size="small"
                      disabled={loading || csvLoading}
                      rule={{
                        maxLength: {
                          value: 30,
                          message: '30文字以内で入力してください',
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={2}>
                        <Button
                          type="submit"
                          variant={'outlined'}
                          size={'small'}
                          color={'default'}
                          disabled={loading || csvLoading}
                          endIcon={<Search />}
                          fullWidth
                        >
                          を含む
                        </Button>
                      </Grid>
                      <Grid item xs={2}>
                        <Button
                          type="submit"
                          variant="contained"
                          size="small"
                          color={'secondary'}
                          disabled={loading || csvLoading}
                          onClick={handleSubmit(onCsvOut)}
                          fullWidth
                        >
                          {csvLoading ? <CircularProgress size={30} /> : 'CSV出力'}
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            </FormProvider>
          </Box>

          <Grid container spacing={2} className={classes.area}>
            <Grid item xs={12}>
              <TableContainer component={Paper}>
                {loading ? <LinearProgress /> : <React.Fragment />}
                <Table size={'small'}>
                  <BasicTableHead columns={basicTableHead.columns} />
                  <TableBody>{tableBody()}</TableBody>
                </Table>
              </TableContainer>
            </Grid>
            {data?.pollCouponDisplayAggregateJson?.nodes && !allAcquired ? (
              <ReadMoreButton fetchMore={handleSubmit(onFetchMore)} loading={loading} />
            ) : (
              <></>
            )}
          </Grid>
        </Container>
      </MenuHeaderTemplate>
    </>
  );
};
