import React from 'react';
import { Button, Grid, Typography } from '@material-ui/core';
import { RegisterOptions, useFieldArray, useFormContext } from 'react-hook-form';
import { TextFieldController } from './controllers/TextFieldController';
import { SelectController } from './controllers/SelectController';
import { TextAreaController } from './controllers/TextAreaController';
import { BarcodeType, StaticBarcodeInput } from '../../../graphql/generated';
import { ApplyWithLabelObject } from '../../../ecosystems/coupon-master/dashboard/hooks';
import { eanCheckDigit } from '../../../util';

export type SmartCheckSettingsInput = {
  countDownMinute?: string;
  smartCheckConfirmText?: string;
  smartCheckCountDownText?: string;
  smartCheckUseButtonBottomText?: string;
  staticBarcodes: StaticBarcodeInput[];
};

export type SmartCheckSettingProps = {
  applyWith: string; // 表示の制御に使う
  previousValues: SmartCheckSettingsInput;
};

export const SmartCheckSettingsGrid: React.FC<SmartCheckSettingProps> = (props) => {
  const { control, setValue, watch, clearErrors } = useFormContext();

  // 静的バーコードの情報を配列で持っているので
  const { fields } = useFieldArray({
    control,
    name: 'smartCheckSettingsInput.staticBarcodes',
  });

  const switchStaticBarcodeRule = (barcodeTypeValue: BarcodeType): RegisterOptions | undefined => {
    switch (barcodeTypeValue) {
      case 'jan8':
        return {
          minLength: {
            value: 8,
            message: 'JAN8の場合、バーコードは8桁で入力してください',
          },
          maxLength: {
            value: 8,
            message: 'JAN8の場合、バーコードは8桁で入力してください',
          },
          pattern: {
            value: /^\d+$/,
            message: 'JAN8の場合、バーコードは0-9の数値で入力してください',
          },
          validate: {
            checkDigit: (value) => eanCheckDigit(value) || 'バーコードの形式が正しくありません',
          },
        };
      case 'jan13':
        return {
          minLength: {
            value: 13,
            message: 'JAN13の場合、バーコードは13桁で入力してください',
          },
          maxLength: {
            value: 13,
            message: 'JAN13の場合、バーコードは13桁で入力してください',
          },
          pattern: {
            value: /^\d+$/,
            message: 'JAN13の場合、バーコードは0-9の数値で入力してください',
          },
          validate: {
            checkDigit: (value) => eanCheckDigit(value) || 'バーコードの形式が正しくありません',
          },
        };
      case 'code128':
        return {
          minLength: undefined,
          maxLength: {
            value: 30,
            message: 'CODE128の場合、バーコードは30桁以内で入力してください',
          },
          pattern: {
            value: /^\d+$/,
            message: 'CODE128の場合、バーコードは0-9の数値で入力してください',
          },
          validate: {
            even: (value) => value.length % 2 === 0 || 'CODE128の場合、偶数桁で入力してください',
          },
        };
      case 'nw7':
        return {
          minLength: undefined,
          maxLength: undefined,
          pattern: {
            value: /^[A-D][0-9\-$:/.+]+[A-D]$/,
            message: 'NW7の場合、フォーマットに従って入力してください',
          },
          validate: undefined,
        };
      case 'qr':
        return {
          minLength: undefined,
          pattern: undefined,
          maxLength: {
            value: 100,
            message: 'QRの場合、100文字以内で入力してください',
          },
          validate: undefined,
        };
      default:
        return {
          minLength: undefined,
          pattern: undefined,
          maxLength: undefined,
          validate: undefined,
        };
    }
  };

  if (!(props.applyWith === 'smartCheck' || props.applyWith === 'smartCheckLight')) {
    return <></>;
  }

  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h4">{ApplyWithLabelObject[props.applyWith]}情報</Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography variant="subtitle1">確認ボタン下テキスト</Typography>
        <Typography color="textSecondary" variant="caption">
          {ApplyWithLabelObject[props.applyWith]}クーポンの確認ボタンの下に表示するテキスト情報です
        </Typography>
      </Grid>
      <Grid item xs={9}>
        <TextAreaController
          name="smartCheckSettingsInput.smartCheckUseButtonBottomText"
          maxLength={200}
          label="200文字以内"
        />
      </Grid>
      <Grid item xs={3}>
        <Typography variant="subtitle1">確認テキスト</Typography>
        <Typography color="textSecondary" variant="caption">
          {ApplyWithLabelObject[props.applyWith]}クーポンの確認画面に表示するテキスト情報です。
        </Typography>
      </Grid>
      <Grid item xs={9}>
        <TextAreaController name="smartCheckSettingsInput.smartCheckConfirmText" maxLength={200} label="200文字以内" />
      </Grid>
      <Grid item xs={3}>
        <Typography variant="subtitle1">カウントダウンテキスト</Typography>
        <Typography color="textSecondary" variant="caption">
          {ApplyWithLabelObject[props.applyWith]}クーポンのカウントダウン表示の画面で表示されるテキスト情報です。
        </Typography>
      </Grid>
      <Grid item xs={9}>
        <TextAreaController
          name="smartCheckSettingsInput.smartCheckCountDownText"
          maxLength={200}
          label="200文字以内"
        />
      </Grid>
      <Grid item xs={3}>
        <Typography variant="subtitle1">カウントダウン時間（分）</Typography>
        <Typography color="textSecondary" variant="caption">
          {ApplyWithLabelObject[props.applyWith]}クーポンの消込までのカウントダウンの分数を入力します。
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <TextFieldController
          type="number"
          name="smartCheckSettingsInput.countDownMinute"
          label="1 〜 60分"
          rule={{
            min: {
              value: 1,
              message: '1分以上を設定してください',
            },
            max: {
              value: 60,
              message: '最大60分です',
            },
          }}
        />
      </Grid>
      <Grid item xs={3}>
        <Button
          variant={'outlined'}
          color={'default'}
          onClick={() => {
            setValue('smartCheckSettingsInput.countDownMinute', '');
          }}
        >
          カウントダウン設定をなしにする
        </Button>
      </Grid>
      <Grid item xs={3} />
      <Grid item xs={3}>
        <Typography variant="subtitle1">表示バーコード</Typography>
        <Typography color="textSecondary" variant="caption">
          {ApplyWithLabelObject[props.applyWith]}クーポンに表示されるバーコードです。
        </Typography>
      </Grid>
      <Grid item xs={9}>
        {fields.map((data, index) => (
          <Grid container alignItems="center" key={data.id} spacing={2}>
            <Grid item xs={3}>
              <SelectController
                name={`smartCheckSettingsInput.staticBarcodes.${index}.barcodeType`}
                defaultValue={(data as StaticBarcodeInput & { id: string }).barcodeType}
                onChange={() => {
                  const barcodeValueName = `smartCheckSettingsInput.staticBarcodes.${index}.barcode`;
                  clearErrors(barcodeValueName);
                }}
                menuItems={[
                  { value: 'none', label: 'なし' },
                  { value: 'jan8', label: 'JAN8' },
                  { value: 'jan13', label: 'JAN13' },
                  { value: 'nw7', label: 'NW7' },
                  { value: 'qr', label: 'QR' },
                  { value: 'code128', label: 'CODE128' },
                ]}
              />
            </Grid>
            <Grid item xs={9}>
              <TextFieldController
                name={`smartCheckSettingsInput.staticBarcodes.${index}.barcode`}
                rule={switchStaticBarcodeRule(watch(`smartCheckSettingsInput.staticBarcodes.${index}.barcodeType`))}
                defaultValue={(data as StaticBarcodeInput & { id: string }).barcode || ''}
                label={`表示バーコード`}
              />
            </Grid>
          </Grid>
        ))}
      </Grid>
    </>
  );
};
