import { useSnackbar } from 'notistack';
import { useCallback } from 'react';
import { OrganizationQuery, useOrganizationQuery, useUpdateShopGroupMutation } from '../../../graphql/generated';
import { useHistory } from 'react-router';
import Bugsnag from '@bugsnag/js';
import { UpdateShopGroupFormValues } from '../pages/UpdateShopGroupPage';

export type UpdateShopGroupUseCase = {
  queryLoading: boolean;
  updateLoading: boolean;
  data: OrganizationQuery;
  onUpdate: (input: UpdateShopGroupFormValues) => void;
};

export const useUpdateShopGroupUseCase = (organizationId: string): UpdateShopGroupUseCase => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const history = useHistory();

  const { data: queryData, loading: queryLoading } = useOrganizationQuery({
    variables: {
      id: organizationId,
    },
  });

  const [updateShopGroupMutation, { loading, error }] = useUpdateShopGroupMutation();

  const onUpdate = useCallback(
    async (input: UpdateShopGroupFormValues) => {
      closeSnackbar();

      const { data } = await updateShopGroupMutation({
        variables: {
          id: queryData?.organization.id!,
          input: {
            name: input.name,
            type: input.type,
            parentId: queryData?.organization.parentId,
          },
        },
      });

      if (error) {
        Bugsnag.notify(error);
        console.error(error);
        enqueueSnackbar(`店舗グループの更新に失敗しました: ${error}`, {
          variant: 'error',
          persist: true,
        });
        return;
      }

      enqueueSnackbar(`店舗グループを更新しました`, {
        variant: 'success',
      });

      history.push({
        pathname: `/manage-organizations/${data?.updateShopGroup.id}`,
        hash: '#about',
        search: history.location.search,
      });
    },
    [updateShopGroupMutation, enqueueSnackbar]
  );

  return {
    queryLoading,
    updateLoading: loading,
    data: queryData!,
    onUpdate,
  };
};
