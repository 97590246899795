import { ApolloClient, ApolloLink, ApolloProvider, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { useAuth0 } from '@auth0/auth0-react';
import React from 'react';
import { getRequiredEnvironmentVariable } from './util';
// import { relayStylePagination } from '@apollo/client/utilities';

const uri = getRequiredEnvironmentVariable('REACT_APP_GRAPHQL_SERVER_URL');

const inMemoryCache = new InMemoryCache({
  // typePolicies: {
  //   Query: {
  //     fields: {
  //       // usersのQueryに対して、relayStylePaginationを適応する
  //       users: relayStylePagination(['type', 'query']),
  //     },
  //   },
  // },
});

export const AuthorizedApolloProvider: React.FC = ({ children }) => {
  const [token, setToken] = React.useState('');
  const { loginWithRedirect, getAccessTokenSilently } = useAuth0();

  const httpLink = createHttpLink({
    uri,
    fetchOptions: { credentials: 'same-origin' },
  });

  const withTokenLink = setContext(async () => {
    if (token) {
      return { auth0Token: token };
    }

    try {
      const newToken = await getAccessTokenSilently();
      setToken(newToken);
      return { auth0Token: newToken };
    } catch (e) {
      console.log(e);
      await loginWithRedirect();
    }
  });

  const authLink = setContext((_, { headers, auth0Token }) => ({
    headers: {
      ...headers,
      ...(auth0Token ? { authorization: auth0Token } : {}),
    },
  }));

  const client = new ApolloClient({
    link: ApolloLink.from([withTokenLink, authLink, httpLink]),
    cache: inMemoryCache,
  });

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};
