import React from 'react';
import { Breadcrumbs, Button, CircularProgress, Container, Grid, Theme, Typography } from '@material-ui/core';
import { MenuHeaderTemplate } from '../../../components/templates/MenuHeaderTemplate';
import { useForm, FormProvider } from 'react-hook-form';
import { makeStyles } from '@material-ui/core/styles';
import { Link, useParams } from 'react-router-dom';
import { PathParams } from '../../../App';
import { CreateShopInput } from '../../../graphql/generated';
import { TextFieldController } from '../../../components/organisms/form/controllers/TextFieldController';
import { useCreateShopUseCase } from '../hooks/create-shop-use-case';
import { useUrlSearchParamOrganization } from '../../../components/contexts/hooks/url';
import { ArrowBack } from '@material-ui/icons';

export type CreateShopFormValues = CreateShopInput;

const useStyles = makeStyles((theme: Theme) => ({
  section: {
    border: '1px solid #c9cace',
    borderRadius: theme.spacing(1),
    padding: theme.spacing(3),
    marginTop: theme.spacing(4),
  },
  submitButton: {
    marginTop: theme.spacing(2),
  },
}));

export const CreateShopPage: React.FC = () => {
  const classes = useStyles();
  const { organizationId } = useParams<PathParams>();
  const form = useForm<CreateShopFormValues>();
  const { handleSubmit } = form;
  const organizationParam = useUrlSearchParamOrganization();
  const { loading, onCreate } = useCreateShopUseCase(organizationId);

  return (
    <MenuHeaderTemplate header={{ title: '店舗新規作成' }}>
      <Container maxWidth="xl">
        <Breadcrumbs>
          <Button
            size="small"
            color="inherit"
            component={Link}
            to={{
              pathname: `/manage-organizations/${organizationId}`,
              search: organizationParam,
            }}
          >
            <ArrowBack />
            親組織へ
          </Button>
          <Typography variant="body1" color="textPrimary">
            店舗作成
          </Typography>
        </Breadcrumbs>
        <FormProvider {...form}>
          <form onSubmit={handleSubmit(onCreate)} autoComplete="off" noValidate>
            <Grid container spacing={2} className={classes.section}>
              <Grid item xs={12}>
                <Typography variant="h4">基本情報</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="subtitle1">店舗名</Typography>
                <Typography color="textSecondary" variant="caption">
                  店舗名を入力します
                </Typography>
              </Grid>
              <Grid item xs={9}>
                <TextFieldController
                  required
                  name="name"
                  type="text"
                  variant="outlined"
                  label="100文字以内"
                  rule={{
                    required: '店舗名は必須です',
                    maxLength: {
                      value: 100,
                      message: '店舗名は100文字以内です',
                    },
                  }}
                  disabled={loading}
                />
              </Grid>
              <Grid item xs={3}>
                <Typography variant="subtitle1">顧客店舗コード</Typography>
                <Typography color="textSecondary" variant="caption">
                  顧客店舗コードを入力します
                </Typography>
              </Grid>
              <Grid item xs={9}>
                <TextFieldController
                  name="customerShopCode"
                  type="text"
                  variant="outlined"
                  label="半角英数字50文字以内"
                  rule={{
                    pattern: {
                      value: /^([a-zA-Z0-9-]{0,50})$/,
                      message: '顧客店舗コードは50文字以内の半角英数字です',
                    },
                  }}
                  disabled={loading}
                />
              </Grid>
              <Grid item xs={3}>
                <Typography variant="subtitle1">店舗消込コード</Typography>
                <Typography color="textSecondary" variant="caption">
                  店舗消込コードを入力します
                </Typography>
              </Grid>
              <Grid item xs={9}>
                <TextFieldController
                  name="simpleClearingCode"
                  type="text"
                  variant="outlined"
                  label="半角数字6文字以内"
                  rule={{
                    pattern: {
                      value: /^([0-9-]{0,6})$/,
                      message: '店舗消込コードは6文字以内の半角数字です',
                    },
                  }}
                  disabled={loading}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} justifyContent="flex-end" alignItems="center" className={classes.submitButton}>
              <Grid item xs={3}>
                <Button fullWidth variant="contained" color="secondary" type="submit" disabled={loading}>
                  {loading ? <CircularProgress size={30} /> : '作成する'}
                </Button>
              </Grid>
            </Grid>
          </form>
        </FormProvider>
      </Container>
    </MenuHeaderTemplate>
  );
};
