import { TextField } from '@material-ui/core';
import React from 'react';
import { Controller, RegisterOptions, useFormContext } from 'react-hook-form';

type FormTextFieldProps = {
  name: string;
  label?: string;
  rule?: RegisterOptions;
  required?: boolean;
  size?: 'small' | 'medium';
  type?: React.InputHTMLAttributes<unknown>['type'];
  disabled?: boolean;
  readOnly?: boolean;
  variant?: 'standard' | 'outlined';
  defaultValue?: string;
};

/**
 * React Hook Form V7 からTextFieldもControllerでラップしてあげないとうまく動作しなかったため
 * ラップしたコンポーネントを作成した
 */
export const TextFieldController: React.FC<FormTextFieldProps> = ({
  variant = 'outlined',
  defaultValue = '',
  ...props
}) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const error = props.name.split('.').reduce((acc, part) => acc && acc[part], errors);
  return (
    <Controller
      name={props.name}
      control={control}
      defaultValue={defaultValue}
      rules={props.rule}
      render={({ field: { ref, ...remain } }) => (
        <TextField
          fullWidth
          margin="normal"
          variant={variant}
          error={!!error}
          helperText={error?.message ?? ''}
          InputProps={{
            readOnly: props.readOnly,
            inputRef: ref,
          }}
          {...props}
          {...remain}
        />
      )}
    />
  );
};
