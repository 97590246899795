import { Button, Grid, LinearProgress } from '@material-ui/core';
import React from 'react';

type ReadMoreButtonProps = {
  fetchMore: () => void;
  loading: boolean;
};

export const ReadMoreButton: React.FC<ReadMoreButtonProps> = ({ fetchMore, loading }) => {
  return (
    <Grid item xs={12}>
      <Grid container justifyContent={'center'}>
        <Button
          type="button"
          size="large"
          color="default"
          onClick={fetchMore}
          disabled={loading}
          variant="contained"
          fullWidth
        >
          さらに読み込む
        </Button>
      </Grid>
      {loading ? <LinearProgress /> : <React.Fragment />}
    </Grid>
  );
};
