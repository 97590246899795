import React from 'react';
import { Box, Breadcrumbs, Button, CircularProgress, Container, Grid, Theme, Typography } from '@material-ui/core';
import { MenuHeaderTemplate } from '../../../components/templates/MenuHeaderTemplate';
import { useForm, FormProvider } from 'react-hook-form';
import { Link, useParams } from 'react-router-dom';
import { PathParams } from '../../../App';
import { OrganizationSuiteQuery, UpsertOrganizationSuiteInput } from '../../../graphql/generated';
import { useUrlSearchParamOrganization } from '../../../components/contexts/hooks/url';
import { ArrowBack } from '@material-ui/icons';
import { useUpsertOrganizationSuiteUseCase } from '../hooks/upsert-organization-suite-use-case';
import { makeStyles } from '@material-ui/core/styles';
import { SelectController } from '../../../components/organisms/form/controllers/SelectController';

export type UpsertOrganizationSuiteFormValues = UpsertOrganizationSuiteInput;

type UpsertOrganizationSuiteFormProps = {
  suite: OrganizationSuiteQuery['organizationSuite'];
  onUpsert: (input: UpsertOrganizationSuiteFormValues) => void;
  loading: boolean;
};

const useStyles = makeStyles((theme: Theme) => ({
  section: {
    border: '1px solid #c9cace',
    borderRadius: theme.spacing(1),
    padding: theme.spacing(3),
    marginTop: theme.spacing(4),
  },
  submitButton: {
    marginTop: theme.spacing(2),
  },
}));

const UpsertOrganizationSuiteForm: React.FC<UpsertOrganizationSuiteFormProps> = (props) => {
  const classes = useStyles();
  const form = useForm<UpsertOrganizationSuiteFormValues>({
    defaultValues: {
      applyWith: props.suite.apply.with,
      applyBarcodeDisplayType: props.suite.apply.barcode.displayType,
      applyBarcodeSourceType: props.suite.apply.barcode.sourceType,
    },
  });
  const { handleSubmit } = form;
  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(props.onUpsert)} autoComplete="off" noValidate>
        <Grid container spacing={2} className={classes.section}>
          <Grid item xs={12}>
            <Typography variant="h4">設定情報</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="subtitle1">消込手段</Typography>
            <Typography color="textSecondary" variant="caption">
              この組織のクーポン消込手段を選択します
            </Typography>
          </Grid>
          <Grid item xs={9}>
            <SelectController
              required
              disabled={props.loading}
              name="applyWith"
              menuItems={[
                {
                  value: 'barcode',
                  label: 'POS',
                },
                {
                  value: 'smartCheck',
                  label: 'SmartCheck',
                },
                {
                  value: 'smartCheckLight',
                  label: 'SmartCheckLight',
                },
              ]}
            />
          </Grid>
          <Grid item xs={3}>
            <Typography variant="subtitle1">バーコード種別</Typography>
            <Typography color="textSecondary" variant="caption">
              バーコードの種別を選択します
            </Typography>
          </Grid>
          <Grid item xs={9}>
            <SelectController
              required
              disabled={props.loading}
              name="applyBarcodeSourceType"
              menuItems={[
                {
                  value: 'CouponPos12Barcode',
                  label: 'クーポンPOS12桁バーコード',
                },
                {
                  value: 'CouponMosBarcode',
                  label: 'クーポンMOS専用バーコード',
                },
                {
                  value: 'CouponGeneral16Barcode',
                  label: 'クーポン汎用16桁バーコード',
                },
              ]}
            />
          </Grid>
          <Grid item xs={3}>
            <Typography variant="subtitle1">バーコードタイプ</Typography>
            <Typography color="textSecondary" variant="caption">
              バーコードのタイプを選択します
            </Typography>
          </Grid>
          <Grid item xs={9}>
            <SelectController
              required
              disabled={props.loading}
              name="applyBarcodeDisplayType"
              menuItems={[
                {
                  value: 'none',
                  label: '設定なし',
                },
                {
                  value: 'jan13',
                  label: 'JAN13',
                },
                {
                  value: 'jan8',
                  label: 'JAN8',
                },
                {
                  value: 'nw7',
                  label: 'NW7',
                },
                {
                  value: 'code128',
                  label: 'CODE-128',
                },
                {
                  value: 'qr',
                  label: 'QR',
                },
              ]}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} justifyContent="flex-end" alignItems="center" className={classes.submitButton}>
          <Grid item xs={3}>
            <Button fullWidth variant="contained" color="secondary" type="submit" disabled={props.loading}>
              {props.loading ? <CircularProgress size={30} /> : '更新する'}
            </Button>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};

export const UpsertOrganizationSuitePage: React.FC = () => {
  const { organizationId } = useParams<PathParams>();
  const organizationParam = useUrlSearchParamOrganization();
  const { loading, onUpsert, suite } = useUpsertOrganizationSuiteUseCase(organizationId);

  return (
    <MenuHeaderTemplate header={{ title: '組織機能設定' }}>
      <Container maxWidth="xl">
        <Breadcrumbs>
          <Button
            disabled={loading}
            size="small"
            color="inherit"
            component={Link}
            to={{
              pathname: `/manage-organizations/${organizationId}`,
              search: organizationParam,
            }}
          >
            <ArrowBack />
            戻る
          </Button>
          <Typography variant="body1" color="textPrimary">
            {organizationId}
          </Typography>
        </Breadcrumbs>
        {suite ? (
          <UpsertOrganizationSuiteForm onUpsert={onUpsert} loading={loading} suite={suite} />
        ) : (
          <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
            <CircularProgress />
          </Box>
        )}
      </Container>
    </MenuHeaderTemplate>
  );
};
