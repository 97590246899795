import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Theme,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useCouponsQuery } from '../../../graphql/generated';
import { PathParams } from '../../../App';
import { useTheme } from '@material-ui/core/styles';
import { BasicTableHead, BasicTableHeadProps } from '../../../components/molecules';
import { Search } from '@material-ui/icons';
import { useForm, FormProvider } from 'react-hook-form';
import { useListCoupons } from './hooks/coupon-list-use-case';
import { SelectController } from '../../../components/organisms/form/controllers/SelectController';
import { TextFieldController } from '../../../components/organisms/form/controllers/TextFieldController';
import { UserContext } from '../../../components/contexts/user/user-context';

const useStyles = makeStyles((theme: Theme) => ({
  conditionFormControl: {
    minWidth: 150,
    maxWidth: 300,
  },
  searchForm: {
    marginTop: theme.spacing(0),
  },
  searchButton: {
    textAlign: 'center',
  },
  couponMastersList: {
    marginTop: theme.spacing(3),
  },
}));

export type CouponListConditionFormValues = {
  value: string;
  searchCondition: string;
};

export const couponStatus = {
  ISSUED: '発行済',
  USED: '使用済',
  CANCELED: 'キャンセル済',
} as { [key: string]: string };

const PageItemsCount = 25;

export const CouponList: React.FC = () => {
  const styles = useStyles();
  const history = useHistory();
  const { couponMasterId } = useParams<PathParams>();
  const { userInfo } = useContext(UserContext);
  const props = useListCoupons(couponMasterId);
  const couponListForm = useForm<CouponListConditionFormValues>({
    defaultValues: props.initialValues,
  });
  const { handleSubmit, watch } = couponListForm;

  const watchSearchCondition = watch('searchCondition', '');

  const theme = useTheme();
  const tableHeadProps: BasicTableHeadProps = {
    columns: [
      {
        name: 'barcodeValue',
        label: '消込用バーコード値',
        minWidth: theme.spacing(10),
        align: 'left',
      },
      {
        name: 'couponUrl',
        label: 'クーポン利用画面URL',
        minWidth: theme.spacing(20),
        align: 'left',
      },
      {
        name: 'couponStatus',
        label: '状態',
        minWidth: theme.spacing(10),
        align: 'center',
      },
      {
        name: 'detailButton',
        label: '',
        minWidth: theme.spacing(10),
        align: 'center',
        disabled: !userInfo?.permissions.includes('show:coupon-detail'),
      },
    ],
  };

  // 検索フォームを条件によって変える
  const searchForm = (): React.ReactElement => {
    switch (watchSearchCondition) {
      case 'couponUrl':
        return (
          <Grid container spacing={2} alignItems="center">
            <Grid item lg={5} xl={3}>
              <TextFieldController
                name="value"
                label="クーポン利用画面URLが"
                variant="standard"
                rule={{
                  required: {
                    value: true,
                    message: '条件を指定する場合、必須です',
                  },
                  maxLength: {
                    value: 200,
                    message: '200文字以内で入力してください',
                  },
                }}
              />
            </Grid>
            <Grid item lg={5} xl={2} className={styles.searchButton}>
              <Button
                type="submit"
                variant="outlined"
                size="large"
                color={'default'}
                disabled={props.loading}
                endIcon={<Search />}
                fullWidth
              >
                から始まる
              </Button>
            </Grid>
          </Grid>
        );
      case 'barcode':
        return (
          <Grid container spacing={2} alignItems="center">
            <Grid item lg={5} xl={3}>
              <TextFieldController
                name="value"
                label="消込用バーコード値が"
                variant="standard"
                rule={{
                  required: {
                    value: true,
                    message: '条件を指定する場合、必須です',
                  },
                  maxLength: {
                    value: 50,
                    message: '50文字以内で入力してください',
                  },
                }}
              />
            </Grid>
            <Grid item lg={5} xl={2} className={styles.searchButton}>
              <Button
                type="submit"
                variant="outlined"
                size="large"
                color={'default'}
                disabled={props.loading}
                endIcon={<Search />}
                fullWidth
              >
                から始まる
              </Button>
            </Grid>
          </Grid>
        );
      default:
        return (
          <Grid container spacing={2} className={styles.searchForm} alignItems="center">
            <Grid item xs={6}>
              <Button
                type="submit"
                variant="outlined"
                size="large"
                color={'default'}
                disabled={props.loading}
                endIcon={<Search />}
                fullWidth
              >
                条件なしで検索する
              </Button>
            </Grid>
          </Grid>
        );
    }
  };

  const { data, loading } = useCouponsQuery({
    variables: {
      couponMasterId,
      limit: PageItemsCount,
    },
  });

  if (loading || !data) {
    return (
      <Box display="flex" alignItems="center" justifyContent="center">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <Box>
        <FormProvider {...couponListForm}>
          <form onSubmit={handleSubmit(props.onSearchCoupons)} autoComplete="off" noValidate>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormControl className={styles.conditionFormControl}>
                  <SelectController
                    name="searchCondition"
                    label="検索条件"
                    defaultValue={'none'}
                    disabled={props.loading}
                    variant="standard"
                    menuItems={[
                      { value: 'none', label: 'なし' },
                      { value: 'couponUrl', label: 'URL' },
                      { value: 'barcode', label: 'バーコード' },
                    ]}
                  />
                </FormControl>
              </Grid>
            </Grid>
            {searchForm()}
          </form>
        </FormProvider>
      </Box>
      <Box className={styles.couponMastersList}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TableContainer component={Paper}>
              {props.loading ? <LinearProgress /> : <React.Fragment />}
              <Table>
                <BasicTableHead columns={tableHeadProps.columns} />
                <TableBody>
                  {(() => {
                    if (!props.data || !props.data.coupons) {
                      return;
                    } else if (props.data.coupons.nodes && props.data.coupons.nodes.length === 0) {
                      return (
                        <TableRow>
                          <TableCell>データがありません</TableCell>
                        </TableRow>
                      );
                    } else {
                      return props.data.coupons.nodes.map((row) => (
                        <TableRow key={row.id}>
                          <TableCell component="th" scope="row">
                            {row.barcode}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {row.couponUrl}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {couponStatus[row.status]}
                          </TableCell>
                          {userInfo?.permissions.includes('show:coupon-detail') ? (
                            <TableCell align="right" scope="row">
                              <Button
                                type="button"
                                size="large"
                                color="primary"
                                onClick={() =>
                                  history.push({
                                    pathname: `/coupon-masters/${couponMasterId}/coupons/${row.id}`,
                                    search: history.location.search,
                                  })
                                }
                              >
                                詳細
                              </Button>
                            </TableCell>
                          ) : (
                            <></>
                          )}
                        </TableRow>
                      ));
                    }
                  })()}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          {props.data?.coupons.pageInfo.nextToken ? (
            <Grid item xs={12}>
              <Grid container justifyContent={'center'}>
                <Button
                  size="large"
                  color="default"
                  variant="contained"
                  disabled={props.loading}
                  onClick={handleSubmit(props.onFetchMore)}
                  fullWidth
                >
                  さらに読み込む
                </Button>
              </Grid>
              {props.loading ? <LinearProgress /> : <React.Fragment />}
            </Grid>
          ) : (
            <></>
          )}
        </Grid>
      </Box>
    </>
  );
};
