import { AllowedTagsHelpPopOver } from '../../../molecules';
import { TextField } from '@material-ui/core';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useFormContext, Controller } from 'react-hook-form';

const useStyles = makeStyles(() => ({
  resizeTextArea: {
    resize: 'vertical',
  },
}));

type MultiLineTextFieldProps = {
  name: string;
  label: string;
  maxLength: number;
};

/**
 * React Hook Form V7 からTextFieldもControllerでラップしてあげないとうまく動作しなかったため
 * ReactHookForm テキストエリアコンポーネント
 */
export const TextAreaController: React.FC<MultiLineTextFieldProps> = (props) => {
  const styles = useStyles();
  const {
    control,
    watch,
    formState: { errors },
  } = useFormContext();
  const watchTextField = watch(props.name);

  const error = props.name.split('.').reduce((acc, part) => acc && acc[part], errors);

  return (
    <Controller
      name={props.name}
      control={control}
      rules={{
        maxLength: {
          value: props.maxLength,
          message: '',
        },
      }}
      render={({ field: { ref, ...remain } }) => (
        <TextField
          multiline
          fullWidth
          label={props.label}
          margin="normal"
          variant="outlined"
          rows={4}
          helperText={`${watchTextField?.length ?? 0} / ${props.maxLength}`}
          error={!!error}
          InputProps={{
            endAdornment: <AllowedTagsHelpPopOver />,
            inputRef: ref,
          }}
          inputProps={{ className: styles.resizeTextArea }}
          {...remain}
        />
      )}
    />
  );
};
