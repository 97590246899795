import React from 'react';

import {
  Box,
  Breadcrumbs,
  Button,
  CircularProgress,
  Container,
  FormControl,
  Grid,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Theme,
  Typography,
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { Search } from '@material-ui/icons';
import { useForm, FormProvider } from 'react-hook-form';
import { ListCoupons, useListCoupons } from './hooks/coupon-list-use-case';
import { formatJstDateTimeJapanese } from '../../util';
import { TextFieldController } from '../../components/organisms/form/controllers/TextFieldController';
import { HeaderProps } from '../../components/organisms/general/MenuHeader';
import { BasicTableHead, BasicTableHeadProps } from '../../components/molecules';
import { SelectController } from '../../components/organisms/form/controllers/SelectController';
import { ReadMoreButton } from '../../components/organisms/general/ReadMoreButton';
import { MenuHeaderTemplate } from '../../components/templates/MenuHeaderTemplate';
import { couponStatus } from '../coupon-master/dashboard/CouponList';

const useStyles = makeStyles((theme: Theme) => ({
  conditionFormControl: {
    minWidth: 150,
    maxWidth: 300,
  },
  searchForm: {
    marginTop: theme.spacing(1),
  },
  searchButton: {
    textAlign: 'center',
  },
  couponList: {
    marginTop: theme.spacing(3),
  },
}));

export type CouponListConditionFormValues = {
  value: string;
  searchCondition: string;
};

type ListCouponsPageProps = {
  header: HeaderProps;
  listBody: ListCoupons;
};

export const ListCouponsBody: React.FC<ListCoupons> = (props) => {
  const styles = useStyles();
  const history = useHistory();

  const couponListForm = useForm<CouponListConditionFormValues>({
    defaultValues: props.initialValues,
  });
  const { handleSubmit, watch } = couponListForm;

  const watchSearchCondition = watch('searchCondition', '');

  const theme = useTheme();
  const tableHeadProps: BasicTableHeadProps = {
    columns: [
      {
        name: 'couponCode',
        label: 'クーポンコード',
        minWidth: theme.spacing(10),
        align: 'left',
      },
      {
        name: 'couponName',
        label: 'クーポン名',
        minWidth: theme.spacing(20),
        align: 'left',
      },
      {
        name: 'barcode',
        label: 'バーコード',
        minWidth: theme.spacing(10),
        align: 'left',
      },
      {
        name: 'usedAt',
        label: '利用日時',
        minWidth: theme.spacing(10),
        align: 'left',
      },
      {
        name: 'status',
        label: 'ステータス',
        minWidth: theme.spacing(10),
        align: 'left',
      },
      {
        name: 'usedShopName',
        label: '利用店舗',
        minWidth: theme.spacing(10),
        align: 'left',
      },
      {
        name: 'detailButton',
        label: '',
        minWidth: theme.spacing(10),
        align: 'center',
      },
    ],
  };

  // 検索フォームを条件によって変える
  const searchForm = (): React.ReactElement => {
    switch (watchSearchCondition) {
      case 'barcode':
        return (
          <Grid container spacing={2} alignItems="center">
            <Grid item xl={4}>
              <TextFieldController
                name="value"
                label="バーコードが"
                variant="standard"
                rule={{
                  required: {
                    value: true,
                    message: '条件を指定する場合、必須です',
                  },
                  maxLength: {
                    value: 50,
                    message: '50文字以内で入力してください',
                  },
                }}
              />
            </Grid>
            <Grid item xl={4} className={styles.searchButton}>
              <Button
                type="submit"
                variant="outlined"
                size="large"
                color={'default'}
                disabled={props.loading}
                endIcon={<Search />}
                fullWidth
              >
                から始まる
              </Button>
            </Grid>
          </Grid>
        );
      case 'couponUrl':
        return (
          <Grid container spacing={2} alignItems="center">
            <Grid item xl={3}>
              <TextFieldController
                name="value"
                label="クーポン利用画面URLが"
                variant="standard"
                rule={{
                  required: {
                    value: true,
                    message: '条件を指定する場合、必須です',
                  },
                  maxLength: {
                    value: 200,
                    message: '200文字以内で入力してください',
                  },
                }}
              />
            </Grid>
            <Grid item xl={2} className={styles.searchButton}>
              <Button
                type="submit"
                variant="outlined"
                size="large"
                color={'default'}
                disabled={props.loading}
                endIcon={<Search />}
                fullWidth
              >
                から始まる
              </Button>
            </Grid>
          </Grid>
        );
      default:
        // and none
        return (
          <Grid container spacing={2} className={styles.searchForm} alignItems="center">
            <Grid item xs={6}>
              <Button
                type="submit"
                variant="outlined"
                size="large"
                color={'default'}
                disabled={props.loading}
                endIcon={<Search />}
                fullWidth
              >
                条件なしで検索する
              </Button>
            </Grid>
          </Grid>
        );
    }
  };

  // 組織情報が取得できていないタイミングでは、ローディングを出す
  if (props.organizationLoading || !props.organization) {
    return (
      <Box display="flex" alignItems="center" justifyContent="center">
        <CircularProgress />
      </Box>
    );
  }

  // 組織情報の取得ができているならば、本体を描画する
  return (
    <>
      <Grid container direction="row" justifyContent="space-between" alignItems="center">
        <Grid item>
          <Breadcrumbs>
            <Typography>{props.organization.name}の発行したクーポン一覧</Typography>
          </Breadcrumbs>
        </Grid>
      </Grid>
      <Box className={styles.searchForm}>
        <FormProvider {...couponListForm}>
          <form onSubmit={handleSubmit(props.onSearchCoupons)} autoComplete="off" noValidate>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormControl className={styles.conditionFormControl}>
                  <SelectController
                    name="searchCondition"
                    label="検索条件"
                    disabled={props.loading}
                    variant="standard"
                    menuItems={[
                      { value: 'none', label: 'なし' },
                      { value: 'barcode', label: 'バーコード' },
                      { value: 'couponUrl', label: 'URL' },
                    ]}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid item>{searchForm()}</Grid>
          </form>
        </FormProvider>
      </Box>
      <Box className={styles.couponList}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TableContainer component={Paper}>
              {props.loading ? <LinearProgress /> : <React.Fragment />}
              <Table>
                <BasicTableHead columns={tableHeadProps.columns} />
                <TableBody>
                  {(() => {
                    if (!props.data || !props.data.coupons) {
                      return;
                    } else if (props.data.coupons.nodes && props.data.coupons.nodes.length === 0) {
                      return (
                        <TableRow>
                          <TableCell>データがありません</TableCell>
                        </TableRow>
                      );
                    } else {
                      return props.data.coupons.nodes.map((row) => (
                        <TableRow key={row.id}>
                          <TableCell component="th" scope="row">
                            {row.couponCode}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {row.couponName}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {row.barcode}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {formatJstDateTimeJapanese(row.usedDateTime)}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {couponStatus[row.status]}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {row.status === 'USED' ? row.usedOrganizationName : ''}
                          </TableCell>
                          <TableCell align="right" scope="row">
                            <Button
                              type="button"
                              size="large"
                              color="primary"
                              onClick={() =>
                                history.push({
                                  pathname: `/coupon-masters/${row.couponMasterId}/coupons/${row.id}`,
                                  search: history.location.search,
                                })
                              }
                            >
                              詳細
                            </Button>
                          </TableCell>
                        </TableRow>
                      ));
                    }
                  })()}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          {props.data?.coupons.pageInfo.nextToken ? (
            <ReadMoreButton fetchMore={props.onFetchMore} loading={props.loading} />
          ) : (
            <></>
          )}
        </Grid>
      </Box>
    </>
  );
};

const useListCouponsPage = (): ListCouponsPageProps => {
  const couponList = useListCoupons();
  return {
    header: { title: '発行クーポン一覧' },
    listBody: couponList,
  };
};

export const ListCouponsPage: React.FC = () => {
  const listCouponsPage = useListCouponsPage();
  return (
    <MenuHeaderTemplate header={listCouponsPage.header}>
      <Container maxWidth="xl">
        <Box>
          <ListCouponsBody {...listCouponsPage.listBody} />
        </Box>
      </Container>
    </MenuHeaderTemplate>
  );
};
