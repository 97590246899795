import React, { useCallback, useState } from 'react';
import * as luxon from 'luxon';
import { Dialog } from '@material-ui/core';
import {
  SmartCheckLightTemplate,
  DescriptionProps,
  DisplayBarcode,
  ProductImageProps,
  SmartCheckLightDataProps,
} from '@sbgift-coupon/use';
import { getRequiredEnvironmentVariable } from '../../../util';
import { CouponMasterFormInput } from './Form';
import { fromInputDateAndTime } from './hooks/utils';
import { makeStyles } from '@material-ui/core/styles';

const BARCODE_IMAGE_SERVER_URL = getRequiredEnvironmentVariable('REACT_APP_BARCODE_IMAGE_SERVER_URL');

const useStyles = makeStyles(() => ({
  paper: {
    height: '100%',
  },
}));

export type SmartCheckLightDisplayPreviewProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
  input: CouponMasterFormInput;
  organizationName: string;
  productImageUrl?: string;
  companyImageUrl?: string;
  smartCheckImageUrl?: SmartCheckLightDataProps['smartCheckImageUrl'];
};

/**
 * SmartCheckLight用のプレビューダイアログ
 */
export const SmartCheckLightDisplayPreview: React.FC<SmartCheckLightDisplayPreviewProps> = (props) => {
  const classes = useStyles();
  const { open, setOpen, input } = props;

  // プレビュー画面上で画面遷移を動作させるためにStateとハンドラを用意する

  // 確認画面を表示するかどうか
  const [confirm, setConfirm] = useState<boolean>(false);

  // カウントダウンが終了したかどうか
  const [countDownComplete, setCountDownComplete] = useState<boolean>(false);

  // 擬似的なステータス
  const [status, setStatus] = useState<string>('ISSUED');

  // 擬似的な消込日付
  const [usedAt, setUsedAt] = useState<string>(luxon.DateTime.now().toISO());

  // 擬似的にカウントダウンを強制終了で上書きする
  const [countDownMinuteOverride, setCountDownMinuteOverride] = useState<string>('');

  const closeAndReset = useCallback(() => {
    setOpen(false);
    setConfirm(false);
    setCountDownComplete(false);
    setStatus('ISSUED');
    setUsedAt('');
    setCountDownMinuteOverride('');
  }, [open, confirm, countDownComplete, status, usedAt, countDownMinuteOverride]);

  // 使用するボタンをクリックしたとき
  const onUseSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    event.stopPropagation(); // ダイアログからのformのsubmitの抑制
    setConfirm(true);
  };

  // 確認画面で使用するボタンをクリックしたときSmartCheckCountDownTemplate
  const onConfirmSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    event.stopPropagation();
    setConfirm(false);

    // 擬似的に消し込みする
    setStatus('USED');
    setUsedAt(luxon.DateTime.now().toISO());
  };

  // 強制終了ボタンをクリックしたとき
  const onForceSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    event.stopPropagation();

    // 強制的にカウントダウンを終える
    setCountDownMinuteOverride('0');
  };

  // キャンセルボタンをクリックしたとき
  const onCancelClick = (): void => {
    setConfirm(false);
  };

  const productImage = (): ProductImageProps | undefined => {
    if (!props.productImageUrl) {
      return undefined;
    }
    return {
      couponName: input.couponName,
      imageUrl: props.productImageUrl,
      expired: false,
      used: false,
    };
  };

  const barcode: DisplayBarcode = {
    barcodeValue: '1234567890123456',
    barcodeType: props.input.applyBarcodeDisplayType,
    imageServer: BARCODE_IMAGE_SERVER_URL,
  };

  const description: DescriptionProps = {
    description: input.description ?? '',
    information: input.information ?? '',
    mapUrl: input.mapUrl ?? '',
    telNumber: input.telNumber ?? '',
    validityStartDateTime: fromInputDateAndTime(input.validityStartDate, input.validityStartTime)?.toISO(),
    validityEndDateTime: fromInputDateAndTime(input.validityEndDate, input.validityEndTime)?.toISO() || '',
  };

  const previewData: SmartCheckLightDataProps = {
    organizationId: 'preview-organization-id',
    couponMasterId: 'preview-coupon-master-id',
    id: 'preview-coupon-id',
    applyWith: input.applyWith,
    couponName: {
      couponName: input.couponName,
      couponNameHtml: input.couponNameHtml ?? '',
      couponNameBandColor: input.couponNameBandColor || '#ffffff',
      couponNameTextColor: input.couponNameTextColor || '#000000',
    },
    couponCode: input.couponCode,
    organizationName: {
      companyName: props.organizationName,
      couponIssuerBandColor: input.couponIssuerBandColor || '#ffffff',
      couponIssuerTextColor: input.couponIssuerTextColor || '#000000',
    },
    productImage: productImage(),
    companyImageUrl: props.companyImageUrl,
    barcode,
    description,
    smartCheckImageUrl: props.smartCheckImageUrl,
    smartCheckSetting: {
      smartCheckConfirmText: input.smartCheckSettingsInput.smartCheckConfirmText,
      smartCheckCountDownText: input.smartCheckSettingsInput.smartCheckCountDownText,
      smartCheckUseButtonBottomText: input.smartCheckSettingsInput.smartCheckUseButtonBottomText,
      countDownMinute: countDownMinuteOverride || input.smartCheckSettingsInput.countDownMinute,
      staticBarcodes: input.smartCheckSettingsInput.staticBarcodes.map((x) => {
        return {
          barcode: x.barcode || '',
          barcodeType: x.barcodeType,
        };
      }),
    },
    smartCheckUsedAt: usedAt,
  };

  return (
    <Dialog classes={{ paper: classes.paper }} onClose={() => closeAndReset()} open={open}>
      <SmartCheckLightTemplate
        layoutPattern={props.input.layoutPattern}
        confirm={confirm}
        data={previewData}
        handlers={{
          onCancelClick,
          onConfirmSubmit,
          onUseSubmit,
          onForceSubmit,
        }}
        available={{
          available: true,
          displayable: 'display',
          validity: 'valid',
          status: status,
        }}
        smartCheckCountDown={{
          countDownNow: luxon.DateTime.now().toISO(),
          countDownComplete,
          setCountDownComplete,
        }}
        meta={{
          metaTags: input.meta || '',
          couponName: input.couponName,
        }}
      />
    </Dialog>
  );
};
