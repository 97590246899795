import React from 'react';
import { Breadcrumbs, Button, CircularProgress, Container, Grid, Theme, Typography } from '@material-ui/core';
import { MenuHeaderTemplate } from '../../../components/templates/MenuHeaderTemplate';
import { useForm, FormProvider } from 'react-hook-form';
import { makeStyles } from '@material-ui/core/styles';
import { useCreateShopGroupUseCase } from '../hooks/create-shop-group-use-case';
import { OrganizationType } from '../../../graphql/generated';
import { SelectController } from '../../../components/organisms/form/controllers/SelectController';
import { TextFieldController } from '../../../components/organisms/form/controllers/TextFieldController';
import { useHistory } from 'react-router';
import { Link, useParams } from 'react-router-dom';
import { PathParams } from '../../../App';
import { ArrowBack } from '@material-ui/icons';
import { useUrlSearchParamOrganization } from '../../../components/contexts/hooks/url';

export type CreateShopGroupFormValues = {
  name: string;
  parentId: string;
  type: OrganizationType;
};

const useStyles = makeStyles((theme: Theme) => ({
  section: {
    border: '1px solid #c9cace',
    borderRadius: theme.spacing(1),
    padding: theme.spacing(3),
    marginTop: theme.spacing(4),
  },
  submitButton: {
    marginTop: theme.spacing(2),
  },
}));

export const CreateShopGroupPage: React.FC = () => {
  const classes = useStyles();
  const { organizationId } = useParams<PathParams>();
  const form = useForm<CreateShopGroupFormValues>();
  const { handleSubmit } = form;
  const organizationParam = useUrlSearchParamOrganization();
  const { loading, onCreate } = useCreateShopGroupUseCase(organizationId);

  return (
    <MenuHeaderTemplate header={{ title: '店舗グループ新規作成' }}>
      <Container maxWidth="xl">
        <Breadcrumbs>
          <Button
            size="small"
            color="inherit"
            component={Link}
            to={{
              pathname: `/manage-organizations/${organizationId}`,
              search: organizationParam,
            }}
          >
            <ArrowBack />
            親組織へ
          </Button>
          <Typography variant="body1" color="textPrimary">
            店舗グループ作成
          </Typography>
        </Breadcrumbs>
        <FormProvider {...form}>
          <form onSubmit={handleSubmit(onCreate)} autoComplete="off" noValidate>
            <Grid container spacing={2} className={classes.section}>
              <Grid item xs={12}>
                <Typography variant="h4">基本情報</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="subtitle1">種別</Typography>
                <Typography color="textSecondary" variant="caption">
                  店舗グループの場合は子に店舗グループを持つことができます。小店舗グループの場合は子に店舗を持つことができます
                </Typography>
              </Grid>
              <Grid item xs={9}>
                <SelectController
                  name="type"
                  menuItems={[
                    {
                      value: 'BIG_SHOP_GROUP',
                      label: '大店舗グループ',
                    },
                    {
                      value: 'SMALL_SHOP_GROUP',
                      label: '小店舗グループ',
                    },
                  ]}
                  defaultValue="BIG_SHOP_GROUP"
                  disabled={loading}
                />
              </Grid>
              <Grid item xs={3}>
                <Typography variant="subtitle1">店舗グループ名</Typography>
                <Typography color="textSecondary" variant="caption">
                  店舗グループ名を入力します
                </Typography>
              </Grid>
              <Grid item xs={9}>
                <TextFieldController
                  required
                  name="name"
                  label="100文字以内"
                  variant="outlined"
                  rule={{
                    required: '店舗グループ名は必須です',
                    maxLength: {
                      value: 100,
                      message: '店舗グループ名は100文字以内です',
                    },
                  }}
                  disabled={loading}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} justifyContent="flex-end" alignItems="center" className={classes.submitButton}>
              <Grid item xs={3}>
                <Button fullWidth variant="contained" color="secondary" type="submit" disabled={loading}>
                  {loading ? <CircularProgress size={30} /> : '作成する'}
                </Button>
              </Grid>
            </Grid>
          </form>
        </FormProvider>
      </Container>
    </MenuHeaderTemplate>
  );
};
