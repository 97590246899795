import { KeyboardTimePicker } from '@material-ui/pickers';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import { Controller, useFormContext } from 'react-hook-form';
import React from 'react';

type KeyboardTimePickerControllerProps = {
  name: string;
  label: string;
  required: boolean;
};

export const KeyboardTimePickerController: React.FC<KeyboardTimePickerControllerProps> = ({
  name,
  label,
  required,
}) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  return (
    <Controller
      control={control}
      name={name}
      rules={{
        required: {
          value: required,
          message: '必須です',
        },
      }}
      render={({ field: { ref, ...remain } }) => (
        <KeyboardTimePicker
          required={required}
          keyboardIcon={<AccessTimeIcon />}
          placeholder="00:00"
          mask="__:__"
          label={label}
          helperText={errors[name]?.message ?? ''}
          error={!!errors[name]}
          disableToolbar
          inputVariant="standard"
          variant="dialog"
          format="HH:mm"
          margin="normal"
          ampm={false}
          {...remain}
          inputRef={ref}
        />
      )}
    />
  );
};
