import React, { useCallback, useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import {
  OrganizationQuery,
  OrganizationSuiteQuery,
  useOrganizationLazyQuery,
  useOrganizationSuiteLazyQuery,
} from '../../../graphql/generated';
import { CouponFeature, OrganizationContext, OrganizationContextProps } from './organization-context';
import { useHistory } from 'react-router-dom';
import { useUrlSearchParam } from '../hooks/url';

export const OrganizationProvider: React.FC = ({ children }) => {
  const searchParams = useUrlSearchParam();
  const { user } = useAuth0();
  const history = useHistory();
  const [original, setOriginal] = useState<OrganizationContext>();
  const [organization, setOrganization] = useState<OrganizationContext>();
  const [couponFeature, setCouponFeature] = useState<CouponFeature>();

  // LazyQueryの場合は専用のloadingを用意する
  const [loading, setLoading] = useState(false);
  const [featureLoading, setFeatureLoading] = useState(false);
  const [spoofLoading, setSpoofLoading] = useState(false);

  const [featureQuery] = useOrganizationSuiteLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: async (queryResult: OrganizationSuiteQuery) => {
      if (queryResult.organizationSuite) {
        setCouponFeature(queryResult.organizationSuite as CouponFeature);
      }
      setFeatureLoading(false);
    },
    onError: (err: Error) => {
      console.error('err', err);
      setFeatureLoading(false);
    },
  });

  const [hardResetQuery] = useOrganizationLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: (query: OrganizationQuery) => {
      setOrganization({ ...query.organization });
      setOriginal({ ...query.organization });
      history.replace({
        search: `organization=`,
      });
      setLoading(false);
    },
    onError: (err: Error) => {
      console.error('err', err);
    },
  });

  const [softResetQuery] = useOrganizationLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: (query: OrganizationQuery) => {
      setOrganization({ ...query.organization });
      setOriginal({ ...query.organization });
      setLoading(false);
    },
    onError: (err: Error) => {
      console.error('err', err);
    },
  });

  // URLにクエリパラメータが付与されているときに適用（メモリを更新するだけ）
  const [queryParameterOrganizationQuery] = useOrganizationLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: (query: OrganizationQuery) => {
      setOrganization({ ...query.organization });
      setSpoofLoading(false);
    },
    onError: (err: Error) => {
      console.error('err', err);
    },
  });

  // ユーザーが明示的になりすましを実行したときに適用（URLを置き換え）
  const [spoofingQuery] = useOrganizationLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: (query: OrganizationQuery) => {
      setOrganization({ ...query.organization });
      history.replace({
        search: `organization=${query.organization.id}`,
      });
      setSpoofLoading(false);
    },
    onError: (err: Error) => {
      console.error('err', err);
    },
  });

  const reset = useCallback(
    (level?: 'soft' | 'hard'): void => {
      if (user != null) {
        const originalOrganization = user['https://www.softbankgift.co.jp/organizationId'];
        setLoading(true);
        switch (level) {
          case 'soft':
            softResetQuery({
              variables: {
                id: originalOrganization,
              },
            });
            break;
          case 'hard':
            hardResetQuery({
              variables: {
                id: originalOrganization,
              },
            });
            break;
          default:
            hardResetQuery({
              variables: {
                id: originalOrganization,
              },
            });
            break;
        }
      }
    },
    [softResetQuery, hardResetQuery, featureQuery, user]
  );

  // 組織IDのクエリパラメータ、ユーザー情報に更新があった場合、組織コンテキストに値をセットする
  useEffect(() => {
    // クエリパラメータがセットされている場合、なりすましの候補になる
    const queryOrg = searchParams.get('organization');

    // クエリパラメータがある
    if (!loading && queryOrg) {
      // クエリパラメータがありメモリにない場合、URLダイレクトアクセス
      // この場合は一度リセットしてなりすましを実行する
      if (!organization) {
        reset('soft');

        // クエリパラメータとなりすまし中の組織が同じ場合はなにもしない
      } else if (organization && queryOrg === organization.id) {
        setSpoofLoading(false);

        // クエリパラメータとなりすまし中の組織と異なる場合、クエリパラメータを優先する
      } else if (organization && queryOrg !== organization.id) {
        setSpoofLoading(true);
        queryParameterOrganizationQuery({
          variables: { id: queryOrg },
        });
      }

      // クエリパラメータがない
    } else if (!loading && !queryOrg) {
      // メモリの組織があり、なおかつ元の組織と異なり、なおかつクエリパラメータの組織IDが空の場合、クエリパラメータを復元する
      if (organization && original && organization.id !== original.id) {
        setSpoofLoading(true);
        history.push({
          search: `organization=${organization.id}`,
        });

        // メモリもない場合はガチ初回
      } else if (!organization) {
        reset('hard');
      } else {
        featureQuery({
          variables: {
            id: organization.id,
          },
        });
      }
    }
  }, [loading, reset, queryParameterOrganizationQuery, spoofingQuery, searchParams.get('organization')]);

  useEffect(() => {
    if (organization?.id) {
      setFeatureLoading(true);
      featureQuery({
        variables: {
          id: organization.id,
        },
      });
    }
  }, [organization, featureQuery]);

  // 「最初の組織に戻す」用
  const resetOrganizationHandler = useCallback(() => {
    reset('hard');
  }, [reset]);

  // なりすまし用
  const setOrganizationHandler = useCallback(
    (newOrgId: string): void => {
      setSpoofLoading(true);
      spoofingQuery({
        variables: { id: newOrgId },
      });
    },
    [spoofingQuery]
  );

  const availableCouponMaster =
    !couponFeature?.apply.with || !organization?.type
      ? undefined
      : !(couponFeature.apply.with === 'smartCheck' && organization.type !== 'SMALL_SHOP_GROUP');

  const result: OrganizationContextProps = {
    loading: loading || spoofLoading || featureLoading,
    original,
    organization,
    couponFeature,
    availableCouponMaster,
    setOrganization: setOrganizationHandler,
    resetOrganization: resetOrganizationHandler,
    setCouponFeature,
  };

  return <OrganizationContext.Provider value={result}>{children}</OrganizationContext.Provider>;
};
