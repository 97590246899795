import { CouponMaster, CouponMastersQuery, useCouponMastersLazyQuery } from '../../../../graphql/generated';
import { OrganizationContextProps } from '../../../../components/contexts/organization';
import { useEffect, useState } from 'react';

export type LatestCoupon = {
  data?: CouponMastersQuery;
  loading: boolean;
};

export function useLatestCouponMaster(organization: OrganizationContextProps): LatestCoupon {
  const [loading, setLoading] = useState(false);
  const [couponMastersQuery, { data }] = useCouponMastersLazyQuery({
    onCompleted: () => {
      setLoading(false);
    },
  });

  useEffect(() => {
    if (organization.organization) {
      setLoading(true);
      couponMastersQuery({
        variables: {
          organizationId: organization.organization?.id as string,
          limit: 1,
        },
      });
    }
  }, [couponMastersQuery, organization.organization]);

  return {
    data,
    loading: loading || organization.loading,
  };
}
