import { useSnackbar } from 'notistack';
import { useCallback } from 'react';
import { useHistory } from 'react-router';
import { Role, useCreateUserMutation, useRolesQuery } from '../../../graphql/generated';
import { CreateUserFormValues } from '../UserList';

export type CreateUserUseCase = {
  loading: boolean;
  onCreate: (input: CreateUserFormValues) => void;
  roles?: Role[];
  rolesLoading: boolean;
};

export const useCreateUserUseCase = (): CreateUserUseCase => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const history = useHistory();

  const [createUserMutation, { loading }] = useCreateUserMutation();

  // ロールの一覧を取得する
  const { data, loading: rolesLoading } = useRolesQuery({
    fetchPolicy: 'cache-first',
  });

  const onCreate = useCallback(
    async (input: CreateUserFormValues) => {
      closeSnackbar();

      console.log('input', input);

      try {
        const { data } = await createUserMutation({
          variables: {
            input: {
              email: input.email,
              name: input.name,
              password: input.password,
              roleId: input.role,
              organizationId: input.organizationId,
              isAdministrator: input.isAdministrator,
            },
          },
        });

        enqueueSnackbar(`ユーザーを作成しました`, {
          variant: 'success',
        });

        history.push({
          pathname: `/users/${data?.createUser.id}`,
          hash: '#about',
          search: history.location.search,
        });
      } catch (e) {
        enqueueSnackbar(e.message, {
          variant: 'error',
        });
      }
    },
    [createUserMutation, enqueueSnackbar]
  );

  return {
    loading,
    onCreate,
    roles: data?.roles,
    rolesLoading,
  };
};
