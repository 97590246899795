import React, { useEffect } from 'react';
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { TextFieldController } from '../../../components/organisms/form/controllers/TextFieldController';
import { useForm, FormProvider } from 'react-hook-form';
import { useUpdateUserNameUseCase } from '../hooks/update-user-name-use-case';

type UpdateUserNameProps = {
  id: string;
  name: string;
  open: boolean;
  setOpen: (open: boolean) => void;
};

export type UpdateUserNameFormValues = {
  id: string;
  name: string;
};

export const UpdateUserNameDialog: React.FC<UpdateUserNameProps> = (props) => {
  const { loading, onUpdate } = useUpdateUserNameUseCase(props.setOpen);

  // Hook Form
  const updateUserNameForm = useForm<UpdateUserNameFormValues>();

  useEffect(() => {
    updateUserNameForm.reset({ id: props.id, name: props.name });
  }, [props]);

  const { handleSubmit } = updateUserNameForm;

  return (
    <Dialog fullWidth maxWidth="md" open={props.open} onClose={() => props.setOpen(false)}>
      <FormProvider {...updateUserNameForm}>
        <form onSubmit={handleSubmit(onUpdate)} autoComplete="off" noValidate>
          <DialogTitle>ユーザー名更新</DialogTitle>
          <DialogContent>
            <TextFieldController
              required
              name="name"
              type="text"
              size="small"
              variant="standard"
              label="ユーザー名"
              rule={{
                required: 'ユーザー名は必須です',
                maxLength: {
                  value: 100,
                  message: 'ユーザー名は100文字以内です',
                },
              }}
              disabled={loading}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => props.setOpen(false)} color="secondary" disabled={loading}>
              キャンセル
            </Button>
            <Button type="submit" color="primary" disabled={loading}>
              {loading ? <CircularProgress size="1rem" /> : '更新する'}
            </Button>
          </DialogActions>
        </form>
      </FormProvider>
    </Dialog>
  );
};
