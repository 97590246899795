import {
  Box,
  Breadcrumbs,
  Button,
  CircularProgress,
  Container,
  Dialog,
  DialogTitle,
  Grid,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  ListSubheader,
  Paper,
  Theme,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useContext, useState } from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { CouponMaster, CouponQuery, CouponStatus } from '../../../graphql/generated';
import { MenuHeaderTemplate } from '../../../components/templates/MenuHeaderTemplate';
import { useHistory } from 'react-router';
import { PathParams } from '../../../App';
import { ArrowRightIcon } from '@material-ui/pickers/_shared/icons/ArrowRightIcon';
import { lightBlue, lightGreen, pink } from '@material-ui/core/colors';
import { Timeline } from '@material-ui/icons';
import { formatJstDateTimeSecondJapanese } from '../../../util';
import { CouponStatusObject, OperateFromObject } from './hooks';
import { useCouponDetail } from './hooks/coupon-detail-use-case';
import { IssuedCouponUsageLog } from './IssuedCouponUsageLog';
import { UserContext } from '../../../components/contexts/user/user-context';
import { NotAuthorized } from '../../error/NotAuthorized';

const useStyles = makeStyles((theme: Theme) => ({
  infoArea: {
    marginTop: theme.spacing(2),
    borderColor: theme.palette.primary.light,
    backgroundColor: lightBlue['50'],
  },
  lastUsedArea: {
    borderColor: theme.palette.primary.light,
    backgroundColor: lightGreen['50'],
  },
  lastUsedShopArea: {
    borderColor: theme.palette.secondary.light,
    backgroundColor: pink['50'],
  },
}));

export type CouponDetailProps = {
  loading: boolean;
  loadingStatus: boolean;
  editingStatus: boolean;
  setEditingStatus: (set: boolean) => void;
  couponMaster?: CouponMaster;
  data?: CouponQuery;
  onSubmitStatus: (status: CouponStatus) => void;
};

const couponStatusArray = Object.keys(CouponStatusObject);

export const CouponDetail: React.FC<CouponDetailProps> = (props) => {
  const { userInfo } = useContext(UserContext);
  if (!userInfo?.permissions.includes('show:coupon-detail')) return <NotAuthorized />;

  const classes = useStyles();
  const history = useHistory();
  const { couponMasterId } = useParams<PathParams>();

  const [displayHistories, setDisplayHistories] = useState(false);

  if (props.loading || !props.data || !props.couponMaster) {
    return (
      <Box display="flex" alignItems="center" justifyContent="center">
        <CircularProgress />
      </Box>
    );
  }

  const c = props.data.coupon;
  const m = props.couponMaster;
  const changeTargetStatus = couponStatusArray.filter((status) => status !== c.status);

  const statusElement = (): React.ReactElement => {
    if (props.loadingStatus) {
      return <CircularProgress size={20} />;
    }
    return <ListItemText primary="ステータス" secondary={c.status ? CouponStatusObject[c.status] : '-'} />;
  };

  const lastUsedInfoElement = (): React.ReactElement => {
    // if unused
    if (!c.usedDateTime) {
      return (
        <Paper variant="outlined" square className={classes.lastUsedArea}>
          <List dense subheader={<ListSubheader component="div">最終利用情報</ListSubheader>}>
            <ListItem>
              <ListItemText primary="未使用のクーポンです" />
            </ListItem>
          </List>
        </Paper>
      );
    }
    return (
      <Paper variant="outlined" square className={classes.lastUsedArea}>
        <List dense subheader={<ListSubheader component="div">最終利用情報</ListSubheader>}>
          <ListItem>
            <ListItemAvatar>
              <ArrowRightIcon />
            </ListItemAvatar>
            <ListItemText primary="更新元" secondary={OperateFromObject[c.usedFrom]} />
          </ListItem>
          <ListItem>
            <ListItemAvatar>
              <ArrowRightIcon />
            </ListItemAvatar>
            <ListItemText primary="更新元組織名" secondary={c.usedOrganizationName} />
          </ListItem>
          <ListItem>
            <ListItemAvatar>
              <ArrowRightIcon />
            </ListItemAvatar>
            <ListItemText primary="情報更新日時" secondary={formatJstDateTimeSecondJapanese(c.usedDateTime)} />
          </ListItem>
        </List>
      </Paper>
    );
  };

  const lastUsedShopInfoElement = (): React.ReactElement => {
    // if unused
    if (!c.shopId) {
      return (
        <Paper variant="outlined" square className={classes.lastUsedShopArea}>
          <List dense subheader={<ListSubheader component="div">最終店舗連携</ListSubheader>}>
            <ListItem>
              <ListItemText primary="店舗連携での利用はありません" />
            </ListItem>
          </List>
        </Paper>
      );
    }
    return (
      <Paper variant="outlined" square className={classes.lastUsedShopArea}>
        <List dense subheader={<ListSubheader component="div">最終店舗連携</ListSubheader>}>
          {c.customerShopCode ? (
            <ListItem>
              <ListItemAvatar>
                <ArrowRightIcon />
              </ListItemAvatar>
              <ListItemText primary="店舗コード" secondary={c.customerShopCode} />
            </ListItem>
          ) : (
            <></>
          )}
          {c.shopName ? (
            <ListItem>
              <ListItemAvatar>
                <ArrowRightIcon />
              </ListItemAvatar>
              <ListItemText primary="店舗名" secondary={c.shopName} />
            </ListItem>
          ) : (
            <></>
          )}
          {c.terminalCode ? (
            <ListItem>
              <ListItemAvatar>
                <ArrowRightIcon />
              </ListItemAvatar>
              <ListItemText primary="端末コード" secondary={c.terminalCode} />
            </ListItem>
          ) : (
            <></>
          )}
          {c.shopUsedDateTime ? (
            <ListItem>
              <ListItemAvatar>
                <ArrowRightIcon />
              </ListItemAvatar>
              <ListItemText primary="情報更新日時" secondary={formatJstDateTimeSecondJapanese(c.shopUsedDateTime)} />
            </ListItem>
          ) : (
            <></>
          )}
        </List>
      </Paper>
    );
  };

  const lastUsedSmartCheckInfoElement = (): React.ReactElement => {
    // if unused
    if (!c.smartCheckUsedDateTime) {
      return (
        <Paper variant="outlined" square className={classes.lastUsedShopArea}>
          <List dense subheader={<ListSubheader component="div">SmartCheck</ListSubheader>}>
            <ListItem>
              <ListItemText primary="SmartCheckでの利用はありません" />
            </ListItem>
          </List>
        </Paper>
      );
    }
    return (
      <Paper variant="outlined" square className={classes.lastUsedShopArea}>
        <List dense subheader={<ListSubheader component="div">SmartCheck</ListSubheader>}>
          {c.smartCheckUsedOrganizationName ? (
            <ListItem>
              <ListItemAvatar>
                <ArrowRightIcon />
              </ListItemAvatar>
              <ListItemText primary="利用した店舗名" secondary={c.smartCheckUsedOrganizationName} />
            </ListItem>
          ) : (
            <></>
          )}
          {c.smartCheckMemberShopCode ? (
            <ListItem>
              <ListItemAvatar>
                <ArrowRightIcon />
              </ListItemAvatar>
              <ListItemText primary="利用された加盟店コード" secondary={c.smartCheckMemberShopCode} />
            </ListItem>
          ) : (
            <></>
          )}
          {c.smartCheckClearingCode ? (
            <ListItem>
              <ListItemAvatar>
                <ArrowRightIcon />
              </ListItemAvatar>
              <ListItemText primary="入力された消込コード" secondary={c.smartCheckClearingCode} />
            </ListItem>
          ) : (
            <></>
          )}
          {c.smartCheckUsedDateTime ? (
            <ListItem>
              <ListItemAvatar>
                <ArrowRightIcon />
              </ListItemAvatar>
              <ListItemText primary="利用日時" secondary={formatJstDateTimeSecondJapanese(c.smartCheckUsedDateTime)} />
            </ListItem>
          ) : (
            <></>
          )}
        </List>
      </Paper>
    );
  };

  return (
    <>
      {userInfo?.permissions.includes('show:coupon-masters') ? (
        <Breadcrumbs>
          <Link
            component={RouterLink}
            to={{
              pathname: `/coupon-masters`,
              search: history.location.search,
            }}
          >
            クーポンマスタ一覧
          </Link>
          <Link
            component={RouterLink}
            to={{
              pathname: `/coupon-masters/${couponMasterId}`,
              search: history.location.search,
              hash: 'about',
            }}
          >
            【{m.couponName}】詳細
          </Link>{' '}
          <Link
            component={RouterLink}
            to={{
              pathname: `/coupon-masters/${couponMasterId}`,
              search: history.location.search,
              hash: 'coupons',
            }}
          >
            発行済クーポン一覧
          </Link>
          <Typography color="textPrimary">クーポン詳細</Typography>
        </Breadcrumbs>
      ) : (
        <></>
      )}
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Paper variant="outlined" square className={classes.infoArea}>
            <List dense>
              <ListItem>
                <ListItemAvatar>
                  <ArrowRightIcon />
                </ListItemAvatar>
                <ListItemText primary="クーポンマスタID" secondary={c.couponMasterId} />
              </ListItem>
              <ListItem>
                <ListItemAvatar>
                  <ArrowRightIcon />
                </ListItemAvatar>
                <ListItemText primary="クーポンID" secondary={c.id} />
              </ListItem>
              <ListItem>
                <ListItemAvatar>
                  <ArrowRightIcon />
                </ListItemAvatar>
                <ListItemText primary="クーポン名" secondary={m.couponName} />
              </ListItem>
              <ListItem>
                <ListItemAvatar>
                  <ArrowRightIcon />
                </ListItemAvatar>
                <ListItemText primary="クーポンコード" secondary={m.couponCode} />
              </ListItem>

              <ListItem>
                <ListItemAvatar>
                  <ArrowRightIcon />
                </ListItemAvatar>
                <ListItemText primary="消込用バーコード値" secondary={c.barcode} />
              </ListItem>
              <ListItem>
                <ListItemAvatar>
                  <ArrowRightIcon />
                </ListItemAvatar>
                <ListItemText primary="クーポンURL" secondary={c.couponUrl} />
              </ListItem>
              <ListItem>
                <ListItemAvatar>
                  <ArrowRightIcon />
                </ListItemAvatar>
                <ListItemText
                  primary="発行日時（日本時間）"
                  secondary={formatJstDateTimeSecondJapanese(c.publishedDateTime)}
                />
              </ListItem>
              <ListItem>
                <ListItemAvatar>
                  <ArrowRightIcon />
                </ListItemAvatar>
                {statusElement()}
                <ListItemSecondaryAction>
                  <Button
                    fullWidth
                    variant="contained"
                    color="secondary"
                    disabled={props.loading}
                    onClick={() => props.setEditingStatus(true)}
                  >
                    ステータスを更新する
                  </Button>
                  <Dialog open={props.editingStatus} onClose={() => props.setEditingStatus(false)}>
                    <DialogTitle id="select-dialog-title">次のクーポンステータスに変更する</DialogTitle>
                    <List>
                      {changeTargetStatus.map((s) => (
                        <ListItem button onClick={() => props.onSubmitStatus(s as CouponStatus)} key={s}>
                          <ListItemText primary={CouponStatusObject[s]} />
                        </ListItem>
                      ))}
                    </List>
                  </Dialog>
                </ListItemSecondaryAction>
              </ListItem>
            </List>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          {lastUsedInfoElement()}
        </Grid>
        {(() => {
          if (props.couponMaster.applyWith === 'barcode') {
            return (
              <Grid item xs={12}>
                {lastUsedShopInfoElement()}
              </Grid>
            );
          } else if (
            props.couponMaster.applyWith === 'smartCheck' ||
            props.couponMaster.applyWith === 'smartCheckLight'
          ) {
            return (
              <Grid item xs={12}>
                {lastUsedSmartCheckInfoElement()}
              </Grid>
            );
          }
        })()}
        {userInfo?.permissions.includes('show:coupon-usage-list') ? (
          <Grid item xs={12}>
            {displayHistories ? (
              <IssuedCouponUsageLog />
            ) : (
              <Button
                fullWidth
                type="button"
                size="large"
                color="primary"
                variant="outlined"
                onClick={() => setDisplayHistories(true)}
                startIcon={<Timeline />}
              >
                このクーポンの利用履歴を表示する
              </Button>
            )}
          </Grid>
        ) : (
          <></>
        )}
      </Grid>
    </>
  );
};

export const CouponDetailPage: React.FC = () => {
  const { couponMasterId, couponId } = useParams<PathParams>();
  const couponDetail = useCouponDetail(couponMasterId, couponId);
  return (
    <MenuHeaderTemplate header={{ title: 'クーポン詳細' }}>
      <Container maxWidth="xl">
        <Box>
          <CouponDetail {...couponDetail} />
        </Box>
      </Container>
    </MenuHeaderTemplate>
  );
};
