import { Controller, useFormContext } from 'react-hook-form';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import React from 'react';

type CheckBoxControllerProps = {
  name: string;
  label: string;
  disabled?: boolean;
};

/**
 * React Hook Form チェックボックスコントローラー
 */
export const CheckBoxController: React.FC<CheckBoxControllerProps> = ({ name, label, disabled }) => {
  const { control, getValues } = useFormContext();
  return (
    <FormControlLabel
      control={
        <Controller
          name={name}
          control={control}
          defaultValue={getValues(name)}
          render={({ field }) => (
            <Checkbox onChange={(e) => field.onChange(e.target.checked)} checked={field.value} disabled={disabled} />
          )}
        />
      }
      key={name}
      label={label}
    />
  );
};
