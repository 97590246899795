import React, { useEffect } from 'react';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@material-ui/core';
import { useForm, FormProvider } from 'react-hook-form';
import { useDeleteUserUseCase } from '../hooks/delete-user-use-case';

type DeleteUserProps = {
  id: string;
  name: string;
  open: boolean;
  setOpen: (open: boolean) => void;
  refetch: () => void;
};

export type DeleteUserFormValues = {
  id: string;
};

export const DeleteUserAlertDialog: React.FC<DeleteUserProps> = (props) => {
  const { loading, onDelete } = useDeleteUserUseCase(props.setOpen, props.refetch);

  // Hook Form
  const deleteUserForm = useForm<DeleteUserFormValues>();

  useEffect(() => {
    deleteUserForm.reset({ id: props.id });
  }, [props]);

  const { handleSubmit } = deleteUserForm;

  return (
    <Dialog fullWidth maxWidth="md" open={props.open} onClose={() => props.setOpen(false)}>
      <FormProvider {...deleteUserForm}>
        <form onSubmit={handleSubmit(onDelete)} autoComplete="off" noValidate>
          <DialogTitle>ユーザー削除</DialogTitle>
          <DialogContent>
            ユーザー <strong>{props.name}</strong> を削除します。よろしいですか？
          </DialogContent>
          <DialogActions>
            <Button onClick={() => props.setOpen(false)} color="secondary" disabled={loading}>
              キャンセル
            </Button>
            <Button type="submit" color="secondary" disabled={loading}>
              {loading ? <CircularProgress size="1rem" /> : '削除する'}
            </Button>
          </DialogActions>
        </form>
      </FormProvider>
    </Dialog>
  );
};
