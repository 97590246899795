import {
  Button,
  Grid,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Theme,
} from '@material-ui/core';
import React, { useCallback } from 'react';
import { BasicTableHead, BasicTableHeadProps } from '../../components/molecules';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useHistory } from 'react-router';
import { appendSearchParameter } from '../../util';
import { PollCouponUsageAggregateJsonQuery } from '../../graphql/generated';

type CouponUsageByOrganizationTableProps = {
  loading: boolean;
  query?: PollCouponUsageAggregateJsonQuery['pollCouponUsageAggregateJson'];
  searchGroupBy: string;
};

const useStyles = makeStyles((theme: Theme) => ({
  area: {
    marginTop: theme.spacing(3),
  },
}));

export const CouponUsageByOrganizationTable: React.FC<CouponUsageByOrganizationTableProps> = React.memo(
  function CouponUsageByOrganizationTable({ loading, query, searchGroupBy }) {
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const organizationHead: BasicTableHeadProps = {
      columns: [
        {
          name: 'organizationName',
          label: '組織名',
          minWidth: theme.spacing(10),
          align: 'left',
        },
        {
          name: 'publishedCount',
          label: '発行数',
          minWidth: theme.spacing(10),
          align: 'right',
        },
        {
          name: 'usedCount',
          label: '消込数',
          minWidth: theme.spacing(10),
          align: 'right',
        },
        {
          name: 'canceledCount',
          label: 'キャンセル',
          minWidth: theme.spacing(10),
          align: 'right',
        },
        {
          name: 'detailButton',
          label: '',
          minWidth: theme.spacing(10),
          align: 'center',
        },
      ],
    };
    // テーブルボディを切り替える
    const tableBody = useCallback(() => {
      if (!query || !query.nodes) {
        return;
      } else if (query.nodes.length === 0) {
        return (
          <TableRow>
            <TableCell>データがありません</TableCell>
          </TableRow>
        );
      } else {
        return query?.nodes.map((usage) => {
          if (searchGroupBy === 'SHOP') {
            return (
              <TableRow key={usage.organizationId}>
                <TableCell>{usage.organizationName}</TableCell>
                <TableCell align="right">{usage.ownPublishedCount}</TableCell>
                <TableCell align="right">
                  {usage.ownUsedCount} / {usage.usedCount}
                </TableCell>
                <TableCell align="right">{usage.canceledCount}</TableCell>
                <TableCell align="right" scope="row">
                  <Button
                    onClick={() => {
                      history.push({
                        pathname: `/coupon-usage-list`,
                        search: `${appendSearchParameter(history.location.search, {
                          organizationId: usage.organizationId || '',
                        })}`,
                        // パンくずリストの表示に利用する
                        state: {
                          name: usage.organizationName,
                        },
                      });
                    }}
                    type="button"
                    size="small"
                    color="primary"
                  >
                    明細
                  </Button>
                </TableCell>
              </TableRow>
            );
          } else {
            return (
              <TableRow key={usage.organizationId}>
                <TableCell>{usage.organizationName}</TableCell>
                <TableCell align="right">
                  {usage.ownPublishedCount} / {usage.publishedCount}
                </TableCell>
                <TableCell align="right">{usage.usedCount}</TableCell>
                <TableCell align="right">{usage.canceledCount}</TableCell>
                <TableCell align="right" scope="row">
                  <Button
                    onClick={() => {
                      history.push({
                        pathname: `/coupon-usage-list`,
                        search: `${appendSearchParameter(history.location.search, {
                          organizationId: usage.organizationId || '',
                        })}`,
                        // パンくずリストの表示に利用する
                        state: {
                          name: usage.organizationName,
                        },
                      });
                    }}
                    type="button"
                    size="small"
                    color="primary"
                  >
                    明細
                  </Button>
                </TableCell>
              </TableRow>
            );
          }
        });
      }
    }, [query]);

    return (
      <Grid container spacing={2} className={classes.area}>
        <Grid item xs={12}>
          <TableContainer component={Paper}>
            {loading ? <LinearProgress /> : <React.Fragment />}
            <Table size={'small'}>
              <BasicTableHead columns={organizationHead.columns} />
              <TableBody>{tableBody()}</TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    );
  }
);
