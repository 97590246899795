import { useCallback, useContext, useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { OrganizationContext } from '../../../components/contexts/organization';
import {
  PollCouponUsageAggregateJsonQuery,
  useInvokeCouponUsageAggregateCsvUrlLazyQuery,
  useInvokeCouponUsageAggregateJsonLazyQuery,
  usePollCouponUsageAggregateCsvUrlLazyQuery,
  usePollCouponUsageAggregateJsonLazyQuery,
} from '../../../graphql/generated';
import { CouponUsageFormValues } from '../CouponUsage';
import Bugsnag from '@bugsnag/js';
import { downloadCsvFileWithBom } from '../../../util/download';
import { UserContext } from '../../../components/contexts/user/user-context';

export type CouponUsageUseCase = {
  organization?: OrganizationContext;
  userInfo?: UserContext;
  organizationLoading: boolean;
  onSearch: (condition: CouponUsageFormValues) => void;
  onCsvOut: (condition: CouponUsageFormValues) => void;
  initialValues?: CouponUsageFormValues;
  data?: PollCouponUsageAggregateJsonQuery['pollCouponUsageAggregateJson'];
  loading: boolean;
  csvLoading: boolean;
  onFetchMore: (condition: CouponUsageFormValues) => void;
  isFetchMore: boolean;
  allAcquired: boolean;
};

const Limit = 100;

export const useUsageUseCase = (): CouponUsageUseCase => {
  const { organization, loading: organizationLoading } = useContext(OrganizationContext);
  const { userInfo } = useContext(UserContext);
  const [loading, setLoading] = useState<boolean>(false);
  const [csvLoading, setCsvLoading] = useState<boolean>(false);
  const [initialValues, setInitialValues] = useState<CouponUsageFormValues>();
  const [offset, setOffset] = useState<number>(0);
  const [fetching, setFetching] = useState<boolean>(false);
  const [allAcquired, setAllAcquired] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (organization?.id) {
      setInitialValues({
        groupBy: 'COUPON_MASTER',
        organizationId: organization.id,
      });
    }
  }, [organization?.id]);

  // 利用状況集計 ポーリング
  const [pollJsonQuery, statusContext] = usePollCouponUsageAggregateJsonLazyQuery({
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first', // これをつけないと fetchMore 時に nextTokenなしで取得しにいってしまう
    pollInterval: 5000,
  });

  // 利用状況集計 取得
  // ApolloClient で ポーリングとfetchMoreを同時に利用できないため分ける
  const [getJsonQuery, getJsonQueryContext] = usePollCouponUsageAggregateJsonLazyQuery({
    fetchPolicy: 'cache-first',
    onCompleted: (_) => {
      setLoading(false);
    },
  });

  // 利用状況集計取得 invoke
  const [invokeJsonQuery, invokeQueryJsonContext] = useInvokeCouponUsageAggregateJsonLazyQuery({
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    onCompleted: (data) => {
      const p = invokeQueryJsonContext.variables;
      if (!p?.groupBy) {
        // 必須パラメータがない場合はなにもせずに終了
        enqueueSnackbar('利用状況集計一覧のパラメータ設定に失敗しました', {
          variant: 'error',
        });
        setLoading(false);
        return;
      }
      pollJsonQuery({
        variables: {
          queryExecutionId: data.invokeCouponUsageAggregateJson.queryExecutionId,
          groupBy: p.groupBy,
        },
      });
      setOffset(offset + Limit);
    },
    onError: (e) => {
      console.error(e);
      Bugsnag.notify(e);
      enqueueSnackbar('利用状況集計一覧の取得に失敗しました', {
        variant: 'error',
      });
    },
  });

  // 利用状況一覧 CSV URL ポーリング
  const [pollCsvUrlQuery, statusCsvUrlContext] = usePollCouponUsageAggregateCsvUrlLazyQuery({
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    pollInterval: 5000,
  });

  // 利用状況CSV取得 invoke
  const [invokeCsvQuery, invokeCsvQueryContext] = useInvokeCouponUsageAggregateCsvUrlLazyQuery({
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    onCompleted: (data) => {
      const p = invokeCsvQueryContext.variables;
      if (!p?.groupBy) {
        // 必須パラメータがない場合はなにもせずに終了
        enqueueSnackbar('利用状況集計一覧のパラメータ設定に失敗しました', {
          variant: 'error',
        });
        setCsvLoading(false);
        return;
      }
      pollCsvUrlQuery({
        variables: {
          groupBy: p.groupBy,
          queryExecutionId: data.invokeCouponUsageAggregateCsvUrl.queryExecutionId,
        },
      });
    },
    onError: (e) => {
      console.error(e);
      Bugsnag.notify(e);
      enqueueSnackbar('利用状況集計一覧の取得に失敗しました', {
        variant: 'error',
      });
    },
  });

  // 検索実行
  const onSearchJsonSubmit = useCallback(
    (data: CouponUsageFormValues) => {
      if (!organization) {
        return;
      }
      setOffset(0);
      setLoading(true);
      invokeJsonQuery({
        variables: {
          organizationId: organization.id,
          organizationName: data.organizationName ?? undefined,
          couponCode: data.couponCode ?? undefined,
          couponName: data.couponName ?? undefined,
          groupBy: data.groupBy,
          limit: Limit,
          offset: offset,
        },
      });
    },
    [organization, invokeJsonQuery]
  );

  // CSV出力
  const onCsvOutSubmit = useCallback(
    (data: CouponUsageFormValues) => {
      if (!organization) {
        return;
      }
      setCsvLoading(true);
      invokeCsvQuery({
        variables: {
          organizationId: organization.id,
          organizationName: data.organizationName ?? undefined,
          couponCode: data.couponCode ?? undefined,
          couponName: data.couponName ?? undefined,
          groupBy: data.groupBy,
          searchPolicy: 'usage',
        },
      });
    },
    [organization, invokeCsvQuery]
  );

  // JSONクエリ出力をポーリングする
  // ポーリングクエリの onComplete は呼ばれないので useEffect でやるしかない
  useEffect(() => {
    const status = statusContext?.data?.pollCouponUsageAggregateJson?.status;

    // 終了系ステータスの場合、ポーリングを止める
    if (status === 'CANCELLED' || status === 'FAILED' || status === 'SUCCEEDED') {
      if (statusContext && statusContext.stopPolling) {
        statusContext.stopPolling();
      }
    }

    // 正常に終了した場合は、データフェッチ用のクエリを呼び出してステータスを終了にする
    if (
      status === 'SUCCEEDED' &&
      statusContext &&
      statusContext.data &&
      statusContext.data.pollCouponUsageAggregateJson &&
      statusContext.data.pollCouponUsageAggregateJson.nodes
    ) {
      if (
        fetching &&
        organization &&
        getJsonQueryContext &&
        getJsonQueryContext.fetchMore &&
        getJsonQueryContext.variables
      ) {
        setLoading(true);
        getJsonQueryContext.fetchMore({
          variables: statusContext.variables,
          updateQuery: (prevQuery, { fetchMoreResult }) => {
            setLoading(false);
            if (!fetchMoreResult) {
              return prevQuery;
            }
            // 取得件数が0件の場合は、全件取得済みとみなす
            if (fetchMoreResult?.pollCouponUsageAggregateJson?.nodes?.length === 0) {
              setAllAcquired(true);
              enqueueSnackbar('全件取得済みです', { variant: 'success' });
            } else {
              enqueueSnackbar('集計処理が正常に終了しました', { variant: 'success' });
            }
            return {
              pollCouponUsageAggregateJson: {
                __typename: fetchMoreResult.pollCouponUsageAggregateJson.__typename,
                queryExecutionId: fetchMoreResult.pollCouponUsageAggregateJson.queryExecutionId,
                status: fetchMoreResult.pollCouponUsageAggregateJson.status,
                nodes: [
                  ...(prevQuery.pollCouponUsageAggregateJson.nodes || []),
                  ...(fetchMoreResult.pollCouponUsageAggregateJson.nodes || []),
                ],
                pageInfo: fetchMoreResult.pollCouponUsageAggregateJson.pageInfo,
              },
            };
          },
        });
        setFetching(false);
      } else {
        getJsonQuery({
          variables: statusContext.variables,
        });
        enqueueSnackbar('集計処理が正常に終了しました', { variant: 'success' });
      }
    } else if (status === 'FAILED' || status === 'CANCELLED') {
      setLoading(false);
      enqueueSnackbar('集計処理でエラーが発生しました', {
        variant: 'error',
      });
      Bugsnag.notify(statusContext.error || new Error('polling error'));
    }
  }, [statusContext.data?.pollCouponUsageAggregateJson.status]);

  // CSV URL クエリ出力をポーリングする
  // ポーリングクエリの onComplete は呼ばれないので useEffect でやるしかない
  useEffect(() => {
    const status = statusCsvUrlContext?.data?.pollCouponUsageAggregateCsvUrl?.status;

    // 終了系ステータスの場合、ポーリングを止める
    if (status === 'CANCELLED' || status === 'FAILED' || status === 'SUCCEEDED') {
      if (statusCsvUrlContext && statusCsvUrlContext.stopPolling) {
        statusCsvUrlContext.stopPolling();
      }
    }

    // 正常に終了した場合は、CSVファイルをダウンロード
    if (
      status === 'SUCCEEDED' &&
      statusCsvUrlContext &&
      statusCsvUrlContext.data &&
      statusCsvUrlContext.data.pollCouponUsageAggregateCsvUrl
    ) {
      enqueueSnackbar('集計処理が正常に終了しました。CSVファイルをダウンロードします。', { variant: 'success' });
      setCsvLoading(false);
      downloadCsvFileWithBom(statusCsvUrlContext.data.pollCouponUsageAggregateCsvUrl.preSignedUrl);
    } else if (status === 'FAILED' || status === 'CANCELLED') {
      setCsvLoading(false);
      enqueueSnackbar('集計処理でエラーが発生しました', {
        variant: 'error',
      });
      Bugsnag.notify(statusCsvUrlContext.error || new Error('polling error'));
    }
  }, [
    statusCsvUrlContext.data?.pollCouponUsageAggregateCsvUrl.status,
    statusCsvUrlContext.data?.pollCouponUsageAggregateCsvUrl.preSignedUrl,
  ]);

  // 更に読み込む
  const onFetchMoreJson = useCallback(
    async (data: CouponUsageFormValues) => {
      if (statusContext && statusContext.stopPolling) {
        statusContext?.stopPolling();
      }
      if (organization && statusContext && statusContext.fetchMore && statusContext.variables) {
        setLoading(true);
        setFetching(true);
        await invokeJsonQuery({
          variables: {
            organizationId: organization.id,
            organizationName: data.organizationName ?? undefined,
            couponCode: data.couponCode ?? undefined,
            couponName: data.couponName ?? undefined,
            groupBy: data.groupBy,
            limit: Limit,
            offset: offset,
          },
        });
      }
    },
    [statusContext, getJsonQueryContext, organization]
  );

  return {
    organization,
    organizationLoading,
    onSearch: onSearchJsonSubmit,
    onCsvOut: onCsvOutSubmit,
    data: getJsonQueryContext.data?.pollCouponUsageAggregateJson,
    isFetchMore: true,
    userInfo,
    loading,
    csvLoading,
    onFetchMore: onFetchMoreJson,
    initialValues,
    allAcquired,
  };
};
