import { Box, Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { BulkCoupon } from './Bulk';
import { BulkCouponHistory } from './BulkHistory';
import { PathParams } from '../../../App';
import { useListCouponBulkHistories } from './hooks/coupon-bulk-histories-use-case';
import { useListCouponBulkStart } from './hooks/coupon-bulk-start-use-case';
import { UserContext } from '../../../components/contexts/user/user-context';
import { NotAuthorized } from '../../error/NotAuthorized';

const useStyles = makeStyles((theme: Theme) => ({
  histories: {
    marginTop: theme.spacing(3),
  },
}));

export const BulkAndHistories: React.FC = () => {
  const { userInfo } = useContext(UserContext);
  if (!userInfo?.permissions.includes('show:bulk-issue-coupon')) return <NotAuthorized />;

  const styles = useStyles();
  const { couponMasterId } = useParams<PathParams>();
  const bulkHistoryProps = useListCouponBulkHistories(couponMasterId);
  const bulkStartProps = useListCouponBulkStart(couponMasterId, bulkHistoryProps.refetch);

  return (
    <>
      <Box>
        <BulkCoupon {...bulkStartProps} />
      </Box>
      <Box className={styles.histories}>
        <BulkCouponHistory {...bulkHistoryProps} />
      </Box>
    </>
  );
};
