import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useState } from 'react';
import {
  useBulkShopCsvMutation,
  useBulkShopCsvStatusLazyQuery,
  useGetBulkShopCsvPutPreSignedUrlLazyQuery,
} from '../../../graphql/generated';
import { SelectedFile } from '../../../types/file';
import axios from 'axios';
import Bugsnag from '@bugsnag/js';
import { validateCsvFile } from '../../coupon-master/form/hooks/utils';

export type UploadShopCsvUseCase = {
  loading: boolean;
  onUpload: () => void;
  onClose: () => void;
  open: boolean;
  setOpen: (open: boolean) => void;
  progressLabel: string;
  selectedCsvFile: SelectedFile;
  validateAndSetCsvFile: (file: File) => void;
};

// 画像のローカルステート
const initialSelectedFile: SelectedFile = {
  ext: {
    message: '未選択',
    valid: undefined,
  },
  length: {
    message: '未選択',
    valid: undefined,
  },
  toUpdate: false,
};

export const useUploadShopCsvUseCase = (parentId?: string | null): UploadShopCsvUseCase => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [progressLabel, setProgressLabel] = useState('');
  const [selectedCsvFile, setSelectedCsvFile] = useState<SelectedFile>(initialSelectedFile);

  const validateAndSetCsvFile = useCallback(
    (file: File) => validateCsvFile(file, 1000000, setSelectedCsvFile),
    [setSelectedCsvFile]
  );

  // CSV処理の結果をポーリングするためのクエリ
  const [bulkShopCsvStatus, bulkShopCsvStatusContext] = useBulkShopCsvStatusLazyQuery({
    fetchPolicy: 'network-only',
    pollInterval: 1000,
  });

  // CSV一括処理
  const [bulkShopCsvMutation] = useBulkShopCsvMutation({
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      console.log(data);
      bulkShopCsvStatus({
        variables: {
          executionArn: data.bulkShopCsv.executionArn,
        },
      });
    },
  });

  // CSVアップロード用のPreSignedUrlを取得
  const [getBulkShopCsvPutPreSignedUrl] = useGetBulkShopCsvPutPreSignedUrlLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: async (data) => {
      console.log('getPreSingedUrl', data);
      if (!data?.getBulkShopCsvPutPreSignedUrl || !selectedCsvFile || !selectedCsvFile.file || !parentId) {
        enqueueSnackbar(`CSVのアップロードに失敗しました`, {
          variant: 'error',
          persist: true,
        });
        return;
      }

      // まずは、S3にCSVファイルをアップロード
      await axios.put(data.getBulkShopCsvPutPreSignedUrl.preSignedUrl, selectedCsvFile.file, {
        headers: {
          'Content-Type': selectedCsvFile.file.type,
        },
      });

      // アップロードが終わったら、CSV一括処理を実行
      await bulkShopCsvMutation({
        variables: {
          input: {
            parentId,
            key: data.getBulkShopCsvPutPreSignedUrl.key!,
          },
        },
      });

      enqueueSnackbar(`店舗の一括登録処理を開始しました。しばらくお待ち下さい。`, {
        variant: 'success',
      });

      setProgressLabel('店舗の一括登録処理中です。しばらくお待ち下さい。');
      setOpen(false);
    },
  });

  // CSVファイルをアップロードして、一括処理機能を呼び出す
  const onUpload = useCallback(async () => {
    try {
      setLoading(true);
      closeSnackbar();
      getBulkShopCsvPutPreSignedUrl();
    } catch (e) {
      console.error(e);
      Bugsnag.notify(e);
      enqueueSnackbar('店舗の一括登録処理でエラーが発生しました', {
        variant: 'error',
      });
    }
  }, [bulkShopCsvMutation, enqueueSnackbar]);

  // ダイアログを閉じたとき初期化する
  const onClose = useCallback(() => {
    setOpen(false);
    setSelectedCsvFile(initialSelectedFile);
  }, [selectedCsvFile, open]);

  // CSV一括処理ステータスをポーリングする
  useEffect(() => {
    const status = bulkShopCsvStatusContext?.data?.bulkShopCsvStatus?.status;
    console.log('status', status);

    // 終了系ステータスの場合、ポーリングを止める
    if (status === 'FAILED' || status === 'SUCCEEDED') {
      if (bulkShopCsvStatusContext && bulkShopCsvStatusContext.stopPolling) {
        bulkShopCsvStatusContext.stopPolling();
      }
    }

    if (status === 'SUCCEEDED') {
      setLoading(false);
      setProgressLabel('');
      enqueueSnackbar('店舗CSV一括登録処理が完了しました', {
        variant: 'success',
      });
    } else if (status === 'FAILED') {
      setLoading(false);
      setProgressLabel('');
      // エラーの場合は、サーバーからのエラーメッセージを画面に表示する
      if (
        bulkShopCsvStatusContext.data &&
        bulkShopCsvStatusContext.data.bulkShopCsvStatus.error &&
        bulkShopCsvStatusContext.data.bulkShopCsvStatus.error === 'ValidationError' &&
        bulkShopCsvStatusContext.data.bulkShopCsvStatus.cause
      ) {
        enqueueSnackbar(bulkShopCsvStatusContext.data?.bulkShopCsvStatus.cause, {
          variant: 'error',
        });
      } else if (
        bulkShopCsvStatusContext.data &&
        bulkShopCsvStatusContext.data.bulkShopCsvStatus.error &&
        bulkShopCsvStatusContext.data.bulkShopCsvStatus.error === 'BulkError' &&
        bulkShopCsvStatusContext.data.bulkShopCsvStatus.cause
      ) {
        enqueueSnackbar(bulkShopCsvStatusContext.data?.bulkShopCsvStatus.cause, {
          variant: 'warning',
        });
      } else {
        // キャッチされていない処理は、クリティカルなエラー
        enqueueSnackbar('店舗CSV一括登録処理で予期せぬエラーが発生しました。管理者に連絡してください', {
          variant: 'error',
        });
        Bugsnag.notify(bulkShopCsvStatusContext.error || new Error('bulk shop polling error'));
      }
    }
  }, [bulkShopCsvStatusContext]);

  return {
    loading,
    onUpload,
    onClose,
    open,
    setOpen,
    progressLabel,
    selectedCsvFile,
    validateAndSetCsvFile,
  };
};
