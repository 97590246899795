import { useCallback, useContext, useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { OrganizationContext } from '../../../components/contexts/organization';
import {
  PollCouponDisplayAggregateJsonQuery,
  useInvokeCouponDisplayAggregateCsvUrlLazyQuery,
  useInvokeCouponDisplayAggregateJsonLazyQuery,
  usePollCouponDisplayAggregateCsvUrlLazyQuery,
  usePollCouponDisplayAggregateJsonLazyQuery,
} from '../../../graphql/generated';
import { CouponDisplayedFormValues } from '../CouponDisplayed';
import Bugsnag from '@bugsnag/js';
import { downloadCsvFileWithBom } from '../../../util/download';
import { UserContext } from '../../../components/contexts/user/user-context';

export type CouponDisplayedUseCase = {
  organization?: OrganizationContext;
  organizationLoading: boolean;
  userInfo?: UserContext;
  data?: PollCouponDisplayAggregateJsonQuery;
  loading: boolean;
  csvLoading: boolean;
  allAcquired: boolean;
  onSearch: (condition: CouponDisplayedFormValues) => void;
  onCsvOut: (condition: CouponDisplayedFormValues) => void;
  onFetchMore: (condition: CouponDisplayedFormValues) => void;
};

const Limit = 100;

export const useDisplayedUseCase = (): CouponDisplayedUseCase => {
  const { organization, loading: organizationLoading } = useContext(OrganizationContext);
  const { userInfo } = useContext(UserContext);
  const [loading, setLoading] = useState<boolean>(false);
  const [csvLoading, setCsvLoading] = useState<boolean>(false);
  const [offset, setOffset] = useState<number>(0);
  const [fetching, setFetching] = useState<boolean>(false);
  const [allAcquired, setAllAcquired] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();

  // 参照状況一覧 ポーリング
  const [pollJsonQuery, statusContext] = usePollCouponDisplayAggregateJsonLazyQuery({
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    pollInterval: 5000,
  });

  // 参照状況一覧 取得
  // ApolloClient で ポーリングとfetchMoreを同時に参照できないため分ける
  const [getJsonQuery, getJsonQueryContext] = usePollCouponDisplayAggregateJsonLazyQuery({
    fetchPolicy: 'cache-first',
    onCompleted: (_) => {
      setLoading(false);
    },
  });

  // 利用状況集計取得 invoke
  const [invokeJsonQuery] = useInvokeCouponDisplayAggregateJsonLazyQuery({
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    onCompleted: (data) => {
      pollJsonQuery({
        variables: {
          queryExecutionId: data.invokeCouponDisplayAggregateJson.queryExecutionId,
        },
      });
      setOffset(offset + Limit);
    },
    onError: (e) => {
      console.error(e);
      Bugsnag.notify(e);
      enqueueSnackbar('参照状況集計一覧の取得に失敗しました', {
        variant: 'error',
      });
    },
  });

  // 検索実行
  const onSearchJsonSubmit = useCallback(
    (data: CouponDisplayedFormValues) => {
      if (!organization) {
        return;
      }
      setOffset(0);
      setLoading(true);
      invokeJsonQuery({
        variables: {
          organizationId: organization.id,
          organizationName: data.organizationName ?? undefined,
          couponCode: data.couponCode ?? undefined,
          couponName: data.couponName ?? undefined,
          limit: Limit,
          offset: offset,
        },
      });
    },
    [organization, invokeJsonQuery]
  );

  // JSONクエリ出力をポーリングする
  // ポーリングクエリの onComplete は呼ばれないので useEffect でやるしかない
  useEffect(() => {
    const status = statusContext?.data?.pollCouponDisplayAggregateJson?.status;

    // 終了系ステータスの場合、ポーリングを止める
    if (status === 'CANCELLED' || status === 'FAILED' || status === 'SUCCEEDED') {
      if (statusContext && statusContext.stopPolling) {
        statusContext.stopPolling();
      }
    }

    // 正常に終了した場合は、データフェッチ用のクエリを呼び出してステータスを終了にする
    if (
      status === 'SUCCEEDED' &&
      statusContext &&
      statusContext.data &&
      statusContext.data.pollCouponDisplayAggregateJson &&
      statusContext.data.pollCouponDisplayAggregateJson.nodes
    ) {
      if (
        fetching &&
        organization &&
        getJsonQueryContext &&
        getJsonQueryContext.fetchMore &&
        getJsonQueryContext.variables
      ) {
        getJsonQueryContext.fetchMore({
          variables: statusContext.variables,
          updateQuery: (prevQuery, { fetchMoreResult }) => {
            setLoading(false);
            if (!fetchMoreResult) {
              return prevQuery;
            }
            // 取得件数が0件の場合は、全件取得済みとみなす
            if (fetchMoreResult?.pollCouponDisplayAggregateJson?.nodes?.length === 0) {
              setAllAcquired(true);
              enqueueSnackbar('全件取得済みです', { variant: 'success' });
            } else {
              enqueueSnackbar('集計処理が正常に終了しました', { variant: 'success' });
            }
            return {
              pollCouponDisplayAggregateJson: {
                __typename: fetchMoreResult.pollCouponDisplayAggregateJson.__typename,
                queryExecutionId: fetchMoreResult.pollCouponDisplayAggregateJson.queryExecutionId,
                status: fetchMoreResult.pollCouponDisplayAggregateJson.status,
                nodes: [
                  ...(prevQuery.pollCouponDisplayAggregateJson.nodes || []),
                  ...(fetchMoreResult.pollCouponDisplayAggregateJson.nodes || []),
                ],
                pageInfo: fetchMoreResult.pollCouponDisplayAggregateJson.pageInfo,
              },
            };
          },
        });
        setFetching(false);
      } else {
        getJsonQuery({
          variables: statusContext.variables,
        });
        enqueueSnackbar('集計処理が正常に終了しました', { variant: 'success' });
      }
    } else if (status === 'FAILED' || status === 'CANCELLED') {
      setLoading(false);
      enqueueSnackbar('集計処理でエラーが発生しました', {
        variant: 'error',
      });
      Bugsnag.notify(statusContext.error || new Error('polling error'));
    }
  }, [statusContext.data?.pollCouponDisplayAggregateJson.status]);

  // 更に読み込む
  const onFetchMoreJson = useCallback(
    async (data: CouponDisplayedFormValues) => {
      if (statusContext && statusContext.stopPolling) {
        statusContext?.stopPolling();
      }
      if (organization && statusContext && statusContext.fetchMore && statusContext.variables) {
        setLoading(true);
        setFetching(true);
        await invokeJsonQuery({
          variables: {
            organizationId: organization.id,
            organizationName: data.organizationName ?? undefined,
            couponCode: data.couponCode ?? undefined,
            couponName: data.couponName ?? undefined,
            limit: Limit,
            offset: offset,
          },
        });
      }
    },
    [statusContext, getJsonQueryContext, organization]
  );

  // 利用状況一覧 CSV URL ポーリング
  const [pollCsvUrlQuery, statusCsvUrlContext] = usePollCouponDisplayAggregateCsvUrlLazyQuery({
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    pollInterval: 5000,
  });

  // 利用状況CSV取得 invoke
  const [invokeCsvQuery, invokeCsvQueryContext] = useInvokeCouponDisplayAggregateCsvUrlLazyQuery({
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    onCompleted: (data) => {
      const p = invokeCsvQueryContext.variables;
      if (!p?.organizationId) {
        // 必須パラメータがない場合はなにもせずに終了
        enqueueSnackbar('利用状況集計一覧のパラメータ設定に失敗しました', {
          variant: 'error',
        });
        setCsvLoading(false);
        return;
      }
      pollCsvUrlQuery({
        variables: {
          queryExecutionId: data.invokeCouponDisplayAggregateCsvUrl.queryExecutionId,
        },
      });
    },
    onError: (e) => {
      console.error(e);
      Bugsnag.notify(e);
      enqueueSnackbar('利用状況集計一覧の取得に失敗しました', {
        variant: 'error',
      });
    },
  });

  // CSV出力
  const onCsvOutSubmit = useCallback(
    (data: CouponDisplayedFormValues) => {
      if (!organization?.id) {
        return;
      }
      setCsvLoading(true);
      invokeCsvQuery({
        variables: {
          organizationId: organization.id,
          organizationName: data.organizationName ?? undefined,
          couponCode: data.couponCode ?? undefined,
          couponName: data.couponName ?? undefined,
          searchPolicy: 'displayed',
        },
      });
    },
    [organization, invokeCsvQuery]
  );

  // CSV URL クエリ出力をポーリングする
  // ポーリングクエリの onComplete は呼ばれないので useEffect でやるしかない
  useEffect(() => {
    const status = statusCsvUrlContext?.data?.pollCouponDisplayAggregateCsvUrl?.status;

    // 終了系ステータスの場合、ポーリングを止める
    if (status === 'CANCELLED' || status === 'FAILED' || status === 'SUCCEEDED') {
      if (statusCsvUrlContext && statusCsvUrlContext.stopPolling) {
        statusCsvUrlContext.stopPolling();
      }
    }

    // 正常に終了した場合は、CSVファイルをダウンロード
    if (
      status === 'SUCCEEDED' &&
      statusCsvUrlContext &&
      statusCsvUrlContext.data &&
      statusCsvUrlContext.data.pollCouponDisplayAggregateCsvUrl
    ) {
      enqueueSnackbar('集計処理が正常に終了しました。CSVファイルをダウンロードします。', { variant: 'success' });
      setCsvLoading(false);
      downloadCsvFileWithBom(statusCsvUrlContext.data.pollCouponDisplayAggregateCsvUrl.preSignedUrl);
    } else if (status === 'FAILED' || status === 'CANCELLED') {
      setCsvLoading(false);
      enqueueSnackbar('集計処理でエラーが発生しました', {
        variant: 'error',
      });
      Bugsnag.notify(statusCsvUrlContext.error || new Error('polling error'));
    }
  }, [
    statusCsvUrlContext.data?.pollCouponDisplayAggregateCsvUrl.status,
    statusCsvUrlContext.data?.pollCouponDisplayAggregateCsvUrl.preSignedUrl,
  ]);

  return {
    organization,
    organizationLoading,
    userInfo,
    onSearch: onSearchJsonSubmit,
    onCsvOut: onCsvOutSubmit,
    data: getJsonQueryContext.data,
    loading,
    csvLoading,
    allAcquired,
    onFetchMore: onFetchMoreJson,
  };
};
