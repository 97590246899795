import { useSnackbar } from 'notistack';
import { useCallback } from 'react';
import { useHistory } from 'react-router';
import { useDeleteShopMutation } from '../../../graphql/generated';
import { ApolloError } from '@apollo/client';
import { useUrlSearchParamOrganization } from '../../../components/contexts/hooks/url';

export type DeleteShopUseCase = {
  loading: boolean;
  onDelete: () => void;
};

export const useDeleteShopUseCase = (
  organizationId: string,
  parentOrganizationId: string,
  setOpen: (open: boolean) => void
): DeleteShopUseCase => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const history = useHistory();
  const organizationParam = useUrlSearchParamOrganization();

  const [deleteShopMutation, { loading }] = useDeleteShopMutation();

  const onDelete = useCallback(async () => {
    closeSnackbar();

    try {
      await deleteShopMutation({
        variables: {
          id: organizationId,
        },
      });

      enqueueSnackbar(`店舗を削除しました`, {
        variant: 'success',
      });

      // もとに戻る
      history.push({ pathname: `/manage-organizations/${parentOrganizationId}`, search: organizationParam });
    } catch (e: unknown) {
      if (e instanceof ApolloError) {
        console.error('graphQLErrors', e.graphQLErrors);
        if (e.message === 'ChildrenShopExistsError') {
          enqueueSnackbar(`子の店舗が存在するため削除できません`, {
            variant: 'error',
          });
        }
        setOpen(false);
      } else {
        throw e;
      }
    }
  }, [deleteShopMutation, enqueueSnackbar]);

  return {
    loading,
    onDelete,
  };
};
