import { useCallback, useEffect, useState } from 'react';
import {
  CouponMaster,
  UpdateCouponMasterMutationVariables,
  useCouponMasterLazyQuery,
  useUpdateCouponMasterMutation,
} from '../../../../graphql/generated';
import { CouponMasterFormInput } from '../Form';
import { useSnackbar } from 'notistack';
import {
  companyImageProps,
  productImageProps,
  reproduceInputFromCouponMaster,
  smartCheckConfirmButtonImageProps,
  smartCheckConfirmCancelButtonImageProps,
  smartCheckForceButtonImageProps,
  smartCheckHeaderImageProps,
  smartCheckUseButtonImageProps,
  smartCheckUsedImageProps,
} from './utils';
import { useHistory } from 'react-router-dom';
import { UpdateCouponMasterPageProps } from '../Update';
import { DateTime } from 'luxon';
import { useFormCouponMaster } from './form-coupon-master';
import { useUploadImage } from './upload-image';

export const useUpdateCouponMaster = (couponMasterId: string): UpdateCouponMasterPageProps => {
  const history = useHistory();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [initialLoading, setInitialLoading] = useState(true);
  const [productImageUrl, setProductImageUrl] = useState<string>();
  const [companyImageUrl, setCompanyImageUrl] = useState<string>();
  const [smartCheckHeaderImageUrl, setSmartCheckHeaderImageUrl] = useState<string>();
  const [smartCheckUseButtonImageUrl, setSmartCheckUseButtonImageUrl] = useState<string>();
  const [smartCheckConfirmButtonImageUrl, setSmartCheckConfirmButtonImageUrl] = useState<string>();
  const [smartCheckConfirmCancelButtonImageUrl, setSmartCheckConfirmCancelButtonImageUrl] = useState<string>();
  const [smartCheckForceButtonImageUrl, setSmartCheckForceButtonImageUrl] = useState<string>();
  const [smartCheckUsedImageUrl, setSmartCheckUsedImageUrl] = useState<string>();
  const [submitStepMessage, setSubmitStepMessage] = useState('読込中'); // 登録中...みたいなメッセージ

  // フォーム共通処理
  const {
    organization,
    couponFeature,
    organizationLoading,
    loading,
    setLoading,
    validateAndConvertAvailableSetting,
    validateDateAndGetDateTime,
  } = useFormCouponMaster();

  // 画像アップロード＆クーポンマスタ更新
  const useUpload = useUploadImage();
  const {
    loading: imageLoading,
    uploadImages,
    shouldUploadImage,
    validateImageFiles,
    validateAndSetSmartCheckUsedFile,
    validateAndSetProductFile,
    validateAndSetSmartCheckForceButtonFile,
    validateAndSetSmartCheckConfirmCancelButtonFile,
    validateAndSetSmartCheckConfirmButtonFile,
    validateAndSetSmartCheckUseButtonFile,
    validateAndSetSmartCheckHeaderFile,
    validateAndSetCompanyFile,
    setDeleteProductFile,
    setDeleteHeaderFile,
    setDeleteSmartCheckHeaderFile,
    setDeleteSmartCheckUseButtonFile,
    setDeleteSmartCheckConfirmButtonFile,
    setDeleteSmartCheckConfirmCancelButtonFile,
    setDeleteSmartCheckForceButtonFile,
    setDeleteSmartCheckUsedFile,
    selectedFiles,
  } = useUpload;

  // 更新の場合、初期値が手に入る
  const [updateInitialValues, setInitialValues] = useState<CouponMasterFormInput>();
  const [couponMasterQuery] = useCouponMasterLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: (query) => {
      const m = query.couponMaster;
      const initialValues = reproduceInputFromCouponMaster(m);
      if (m.availableStartTimePeriod) {
        const [startHH, startMM] = m.availableStartTimePeriod.split(':');
        initialValues.availableStartTimePeriod = DateTime.local().set({
          hour: parseInt(startHH),
          minute: parseInt(startMM),
        });
      } else {
        initialValues.availableStartTimePeriod = null;
      }
      if (m.availableEndTimePeriod) {
        const [endHH, endMM] = m.availableEndTimePeriod.split(':');
        initialValues.availableEndTimePeriod = DateTime.local().set({
          hour: parseInt(endHH),
          minute: parseInt(endMM),
        });
      } else {
        initialValues.availableEndTimePeriod = null;
      }
      setInitialValues(initialValues);

      setProductImageUrl(m.productImageUrl || undefined);
      setCompanyImageUrl(m.companyImageUrl || undefined);
      setSmartCheckHeaderImageUrl(m.smartCheckImageUrl?.smartCheckHeaderImageUrl || undefined);
      setSmartCheckUseButtonImageUrl(m.smartCheckImageUrl?.smartCheckUseButtonImageUrl || undefined);
      setSmartCheckConfirmButtonImageUrl(m.smartCheckImageUrl?.smartCheckConfirmButtonImageUrl || undefined);
      setSmartCheckConfirmCancelButtonImageUrl(
        m.smartCheckImageUrl?.smartCheckConfirmCancelButtonImageUrl || undefined
      );
      setSmartCheckForceButtonImageUrl(m.smartCheckImageUrl?.smartCheckForceButtonImageUrl || undefined);
      setSmartCheckUsedImageUrl(m.smartCheckImageUrl?.smartCheckUsedImageUrl || undefined);

      // これを false にするとフォームの描画が走る = 初期値がセットされてしまう ので初期値をサーバーのもので更新した後に読み込み完了とする
      setInitialLoading(false);
    },
    onError: console.error,
  });

  useEffect(() => {
    couponMasterQuery({
      variables: {
        couponMasterId,
      },
    });
  }, [couponMasterQuery]);

  const [updateCouponMasterMutation] = useUpdateCouponMasterMutation();

  const onSubmit = useCallback(
    async (input: CouponMasterFormInput) => {
      console.log(input);

      closeSnackbar();

      // 組織情報が読み込み終わった後にコンテンツを表示しているので、実際には発生しないと思われる
      if (!organization || !couponFeature) {
        setLoading(false);
        enqueueSnackbar('データ読み込み中です。もう少々おまちください。', {
          variant: 'warning',
          autoHideDuration: 10000,
        });
        return;
      }

      // 画像バリデーションがダメだったらその時点で終了
      if (validateImageFiles()) return;

      // 日時期間取得＆バリデーション
      const validatedDateTime = validateDateAndGetDateTime(input);
      if (validatedDateTime.invalid) return;
      const {
        displayStartDateTime,
        displayEndDateTime,
        validityStartDateTime,
        validityEndDateTime,
        realTimeIssuedGetStartDateTime,
        realTimeIssuedGetEndDateTime,
      } = validatedDateTime;

      // 帯時間取得＆バリデーション
      const validatedAvailableSettings = validateAndConvertAvailableSetting(input);
      if (validatedAvailableSettings.invalid) return;
      const { availableDateArray, availableDaysArray, availableStartTimePeriodString, availableEndTimePeriodString } =
        validatedAvailableSettings;

      setLoading(true);
      setSubmitStepMessage('マスタデータを登録しています');

      const requestParameters: UpdateCouponMasterMutationVariables = {
        id: couponMasterId,
        input: {
          available: !input.unAvailable,
          applyWith: input.applyWith,
          applyBarcode: {
            sourceType: input.applyBarcodeSourceType,
            displayType: input.applyBarcodeDisplayType,
          },

          couponName: input.couponName,
          couponCode: input.couponCode,
          note: input.note || '',

          displayStartDate: displayStartDateTime.toISO(), // 逆転チェックで必須チェックは通過している
          displayEndDate: displayEndDateTime.toISO(),
          validityStartDateTime: validityStartDateTime ? validityStartDateTime.toISO() : '',
          validityEndDateTime: validityEndDateTime.toISO(),
          realTimeIssuedGetStartDateTime: realTimeIssuedGetStartDateTime ? realTimeIssuedGetStartDateTime.toISO() : '',
          realTimeIssuedGetEndDateTime: realTimeIssuedGetEndDateTime ? realTimeIssuedGetEndDateTime.toISO() : '',
          couponIssuedMaximumNumber: input.couponIssuedMaximumNumber ? Number(input.couponIssuedMaximumNumber) : null,

          timeFrame: input.timeFrame,

          availableDays: availableDaysArray,
          availableDate: availableDateArray,
          availableStartTimePeriod: availableStartTimePeriodString || '',
          availableEndTimePeriod: availableEndTimePeriodString || '',

          description: input.description || '',
          information: input.information || '',
          meta: input.meta || '',
          mapUrl: input.mapUrl || '',
          telNumber: input.telNumber || '',

          couponNameHtml: input.couponNameHtml || '',
          couponNameBandColor: input.couponNameBandColor,
          couponNameTextColor: input.couponNameTextColor,
          couponIssuerBandColor: input.couponIssuerBandColor,
          couponIssuerTextColor: input.couponIssuerTextColor,
          layoutPattern: input.layoutPattern,

          smartCheckSetting: input.smartCheckSettingsInput,
        },
      };

      console.log(requestParameters);

      // 登録実行
      const { data, errors } = await updateCouponMasterMutation({
        variables: requestParameters,
      });

      if (!data) {
        setLoading(false);
        enqueueSnackbar(`クーポンデータの登録に失敗しました: ${errors}`, {
          variant: 'error',
          persist: true,
        });
        return;
      }

      // データ作成後、アップロードしようとしている画像があるか
      if (!shouldUploadImage(data.updateCouponMaster.id)) return;

      // 画像がある場合、アップロード処理に移行
      setSubmitStepMessage('画像を登録しています...');

      try {
        await uploadImages(data.updateCouponMaster as CouponMaster);
        enqueueSnackbar(`クーポンデータを登録しました`, {
          variant: 'success',
        });
        history.push({
          pathname: `/coupon-masters/${couponMasterId}`,
          search: history.location.search,
        });
      } catch (err) {
        console.error('error', err);
        enqueueSnackbar(
          'クーポンデータは登録しましたが、画像登録に失敗しました。画像のみ再度アップロードしてください',
          {
            variant: 'warning',
            autoHideDuration: 10000,
          }
        );
        await new Promise((resolve) => setTimeout(resolve, 3000));
        history.push({
          pathname: `/coupon-masters/${couponMasterId}`,
          search: history.location.search,
        });
      } finally {
        setLoading(false);
      }
    },
    [organization, couponFeature, updateCouponMasterMutation, enqueueSnackbar, useUpload]
  );

  return {
    previousValues: updateInitialValues,
    onSubmit,
    loading: loading || imageLoading,
    submitStepMessage,
    organization,
    couponFeature,
    organizationLoading,
    initialLoading,
    productImageProps: productImageProps({
      currentImageUrl: productImageUrl,
      selectedFile: selectedFiles.productFile,
      validateAndSetFile: validateAndSetProductFile,
      setDeleteFile: setDeleteProductFile,
    }),
    companyImageProps: companyImageProps({
      currentImageUrl: companyImageUrl,
      selectedFile: selectedFiles.companyFile,
      validateAndSetFile: validateAndSetCompanyFile,
      setDeleteFile: setDeleteHeaderFile,
    }),
    smartCheckHeaderImageProps: smartCheckHeaderImageProps({
      currentImageUrl: smartCheckHeaderImageUrl,
      selectedFile: selectedFiles.smartCheckHeaderFile,
      validateAndSetFile: validateAndSetSmartCheckHeaderFile,
      setDeleteFile: setDeleteSmartCheckHeaderFile,
    }),
    smartCheckUseButtonImageProps: smartCheckUseButtonImageProps({
      currentImageUrl: smartCheckUseButtonImageUrl,
      selectedFile: selectedFiles.smartCheckUseButtonFile,
      validateAndSetFile: validateAndSetSmartCheckUseButtonFile,
      setDeleteFile: setDeleteSmartCheckUseButtonFile,
    }),
    smartCheckConfirmButtonImageProps: smartCheckConfirmButtonImageProps({
      currentImageUrl: smartCheckConfirmButtonImageUrl,
      selectedFile: selectedFiles.smartCheckConfirmButtonFile,
      validateAndSetFile: validateAndSetSmartCheckConfirmButtonFile,
      setDeleteFile: setDeleteSmartCheckConfirmButtonFile,
    }),
    smartCheckConfirmCancelButtonImageProps: smartCheckConfirmCancelButtonImageProps({
      currentImageUrl: smartCheckConfirmCancelButtonImageUrl,
      selectedFile: selectedFiles.smartCheckConfirmCancelButtonFile,
      validateAndSetFile: validateAndSetSmartCheckConfirmCancelButtonFile,
      setDeleteFile: setDeleteSmartCheckConfirmCancelButtonFile,
    }),
    smartCheckForceButtonImageProps: smartCheckForceButtonImageProps({
      currentImageUrl: smartCheckForceButtonImageUrl,
      selectedFile: selectedFiles.smartCheckForceButtonFile,
      validateAndSetFile: validateAndSetSmartCheckForceButtonFile,
      setDeleteFile: setDeleteSmartCheckForceButtonFile,
    }),
    smartCheckUsedImageProps: smartCheckUsedImageProps({
      currentImageUrl: smartCheckUsedImageUrl,
      selectedFile: selectedFiles.smartCheckUsedFile,
      validateAndSetFile: validateAndSetSmartCheckUsedFile,
      setDeleteFile: setDeleteSmartCheckUsedFile,
    }),
  };
};
