import {
  Button,
  Grid,
  IconButton,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Theme,
} from '@material-ui/core';
import React, { useCallback } from 'react';
import { BasicTableHead, BasicTableHeadProps } from '../../components/molecules';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Launch } from '@material-ui/icons';
import { appendSearchParameter } from '../../util';
import { PollCouponUsageAggregateJsonQuery } from '../../graphql/generated';
import { Permission } from '../../components/contexts/user/user-context';
import { useHistory } from 'react-router';

type CouponUsageByCouponMasterTableProps = {
  loading: boolean;
  nodes?: PollCouponUsageAggregateJsonQuery['pollCouponUsageAggregateJson']['nodes'];
  permissions?: Permission[];
  organizationParam: string;
};

const useStyles = makeStyles((theme: Theme) => ({
  area: {
    marginTop: theme.spacing(3),
  },
}));

export const CouponUsageByCouponMasterTable: React.FC<CouponUsageByCouponMasterTableProps> = ({
  loading,
  nodes,
  permissions,
  organizationParam,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const couponMasterHead: BasicTableHeadProps = {
    columns: [
      {
        name: 'couponMasterId',
        label: 'マスタ詳細を開く',
        minWidth: theme.spacing(1),
        align: 'left',
        disabled: !permissions?.includes('show:coupon-masters'),
      },
      {
        name: 'organizationName',
        label: '発行した組織名',
        minWidth: theme.spacing(1),
        align: 'left',
      },
      {
        name: 'couponCode',
        label: 'クーポンコード',
        minWidth: theme.spacing(1),
        align: 'left',
      },
      {
        name: 'couponName',
        label: 'クーポン名',
        minWidth: theme.spacing(10),
        align: 'left',
      },
      {
        name: 'publishedCount',
        label: '発行数',
        minWidth: theme.spacing(10),
        align: 'right',
      },
      {
        name: 'usedCount',
        label: '消込数',
        minWidth: theme.spacing(10),
        align: 'right',
      },
      {
        name: 'canceledCount',
        label: 'キャンセル',
        minWidth: theme.spacing(10),
        align: 'right',
      },
      {
        name: 'detailButton',
        label: '',
        minWidth: theme.spacing(10),
        align: 'center',
        disabled: !permissions?.includes('show:coupon-usage-list'),
      },
    ],
  };
  const tableBody = useCallback(() => {
    if (!nodes) {
      return;
    } else if (nodes?.length === 0) {
      return (
        <TableRow>
          <TableCell>データがありません</TableCell>
        </TableRow>
      );
    } else {
      return nodes.map((usage) => {
        return (
          <TableRow key={usage.couponMasterId}>
            {permissions?.includes('show:coupon-masters') ? (
              <TableCell>
                <IconButton target="_blank" href={`/coupon-masters/${usage.couponMasterId}?${organizationParam}`}>
                  <Launch fontSize="small" color={'inherit'} />
                </IconButton>
              </TableCell>
            ) : (
              <></>
            )}
            <TableCell>{usage.organizationName}</TableCell>
            <TableCell>{usage.couponCode}</TableCell>
            <TableCell>{usage.couponName}</TableCell>
            <TableCell align="right">{usage.publishedCount}</TableCell>
            <TableCell align="right">{usage.usedCount}</TableCell>
            <TableCell align="right">{usage.canceledCount}</TableCell>
            {permissions?.includes('show:coupon-usage-list') ? (
              <TableCell align="right" scope="row">
                <Button
                  onClick={() => {
                    history.push({
                      pathname: `/coupon-usage-list`,
                      search: `${appendSearchParameter(history.location.search, {
                        couponMasterId: usage.couponMasterId || '',
                        searchPolicy: 'latest',
                      })}`,
                      // パンくずリストの表示に利用する
                      state: {
                        name: usage.couponName,
                      },
                    });
                  }}
                  type="button"
                  size="small"
                  color="primary"
                >
                  明細
                </Button>
              </TableCell>
            ) : (
              <></>
            )}
          </TableRow>
        );
      });
    }
  }, [nodes]);

  return (
    <Grid container spacing={2} className={classes.area}>
      <Grid item xs={12}>
        <TableContainer component={Paper}>
          {loading ? <LinearProgress /> : <React.Fragment />}
          <Table size={'small'}>
            <BasicTableHead columns={couponMasterHead.columns} />
            <TableBody>{tableBody()}</TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
};
