import React, { useEffect } from 'react';
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { TextFieldController } from '../../../components/organisms/form/controllers/TextFieldController';
import { useForm, FormProvider } from 'react-hook-form';
import { useUpdateUserPasswordUseCase } from '../hooks/update-user-password-use-case';

type UpdateUserPasswordProps = {
  id: string;
  open: boolean;
  setOpen: (open: boolean) => void;
};

export type UpdateUserPasswordFormValues = {
  id: string;
  password: string;
};

export const UpdateUserPasswordDialog: React.FC<UpdateUserPasswordProps> = (props) => {
  const { loading, onUpdate } = useUpdateUserPasswordUseCase(props.setOpen);

  // Hook Form
  const updateUserPasswordForm = useForm<UpdateUserPasswordFormValues>();

  useEffect(() => {
    updateUserPasswordForm.reset({ id: props.id, password: '' });
  }, [props]);

  const { handleSubmit } = updateUserPasswordForm;

  return (
    <Dialog fullWidth maxWidth="md" open={props.open} onClose={() => props.setOpen(false)}>
      <FormProvider {...updateUserPasswordForm}>
        <form onSubmit={handleSubmit(onUpdate)} autoComplete="off" noValidate>
          <DialogTitle>ユーザーパスワード更新</DialogTitle>
          <DialogContent>
            <TextFieldController
              required
              name="password"
              type="password"
              size="small"
              variant="standard"
              label="新しいパスワード"
              rule={{
                required: 'パスワードは必須です',
                pattern: {
                  value: /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[@$!%*?&])([a-zA-Z0-9@$!%*?&]{8,})$/,
                  message: 'パスワードは半角英小文字、半角英大文字、数字、記号（!@#$%^&*）を含めて入力してください',
                },
                minLength: {
                  value: 8,
                  message: 'パスワードは8文字以上で入力してください',
                },
              }}
              disabled={loading}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => props.setOpen(false)} color="secondary" disabled={loading}>
              キャンセル
            </Button>
            <Button type="submit" color="primary" disabled={loading}>
              {loading ? <CircularProgress size="1rem" /> : '更新する'}
            </Button>
          </DialogActions>
        </form>
      </FormProvider>
    </Dialog>
  );
};
