import { useCallback, useContext, useEffect, useState } from 'react';
import { CouponsQuery, useCouponsLazyQuery } from '../../../graphql/generated';
import { OrganizationContext } from '../../../components/contexts/organization';
import { CouponListConditionFormValues } from '../CouponListPage';

const PageItemsCount = 25;

export type ListCoupons = {
  organization?: OrganizationContext;
  organizationLoading: boolean;
  loading: boolean;
  onSearchCoupons: (condition: CouponListConditionFormValues) => void;
  data?: CouponsQuery;
  onFetchMore: () => void;
  initialValues: CouponListConditionFormValues;
};

export const useListCoupons = (): ListCoupons => {
  const orgContext = useContext(OrganizationContext);
  const organization = orgContext.organization;
  const organizationLoading = orgContext.loading;
  const [loading, setLoading] = useState(false);

  const initialValues: CouponListConditionFormValues = {
    value: '',
    searchCondition: 'none',
  };

  const [listQuery, { data, fetchMore, variables }] = useCouponsLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: () => {
      setLoading(false);
    },
  });

  // 初回、検索条件なしで表示する
  useEffect(() => {
    if (organization) {
      setLoading(true);
      listQuery({
        variables: {
          organizationId: organization.id,
          limit: PageItemsCount,
        },
      });
    }
  }, [listQuery, organization]);

  // 動的な検索条件をセットしてクーポンを検索する
  const onSearchCoupons = useCallback(
    (data: CouponListConditionFormValues) => {
      if (organization) {
        setLoading(true);
        listQuery({
          variables: {
            organizationId: organization.id,
            [data.searchCondition]: data.value,
            limit: PageItemsCount,
          },
        });
      }
    },
    [listQuery, organization]
  );

  // さらに読み込む
  const onFetchMore = useCallback(async () => {
    if (organization && fetchMore && variables) {
      setLoading(true);
      await fetchMore({
        variables: {
          organizationId: variables.organizationId,
          barcode: variables.barcode,
          couponUrl: variables.couponUrl,
          nextToken: data?.coupons.pageInfo.nextToken,
          limit: PageItemsCount,
        },
        updateQuery: (prevQuery, { fetchMoreResult }) => {
          setLoading(false);
          if (!fetchMoreResult) {
            return prevQuery;
          }
          return {
            coupons: {
              __typename: fetchMoreResult.coupons.__typename,
              nodes: [...prevQuery.coupons.nodes, ...fetchMoreResult.coupons.nodes],
              pageInfo: fetchMoreResult.coupons.pageInfo,
            },
          };
        },
      });
    }
  }, [data, fetchMore, variables, organization]);

  return {
    organization,
    organizationLoading,
    loading,
    onSearchCoupons,
    data: data,
    onFetchMore,
    initialValues,
  };
};
