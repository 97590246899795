import { useSnackbar } from 'notistack';
import { useCallback } from 'react';
import Bugsnag from '@bugsnag/js';
import { useDeleteUserMutation } from '../../../graphql/generated';
import { DeleteUserFormValues } from '../dialogs/DeleteUserAleat';

export type DeleteUserUseCase = {
  loading: boolean;
  onDelete: (input: DeleteUserFormValues) => void;
};

export const useDeleteUserUseCase = (setOpen: (open: boolean) => void, refetch: () => void): DeleteUserUseCase => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [deleteUserMutation, { loading, error }] = useDeleteUserMutation();

  const onDelete = useCallback(
    async (input: DeleteUserFormValues) => {
      closeSnackbar();

      await deleteUserMutation({
        variables: {
          id: input.id,
        },
      });

      if (error) {
        Bugsnag.notify(error);
        console.error(error);
        enqueueSnackbar(`ユーザーの削除に失敗しました: ${error}`, {
          variant: 'error',
          persist: true,
        });
        return;
      }

      setOpen(false);

      enqueueSnackbar(`ユーザーを削除しました`, {
        variant: 'success',
      });

      // ユーザー一覧を再表示
      await refetch();
    },
    [deleteUserMutation, enqueueSnackbar]
  );

  return {
    loading,
    onDelete,
  };
};
