import { useCallback, useState } from 'react';
import {
  CouponMaster,
  Extension,
  ImageContainerInput,
  ImageType,
  SmartCheckImageInput,
  UpdateCouponMasterInput,
  useCouponMasterUploadImageUrlMutation,
  useUpdateCouponMasterMutation,
} from '../../../../graphql/generated';
import { SelectedFile } from '../Image';
import axios from 'axios';
import Bugsnag from '@bugsnag/js';
import { validateImageFile } from './utils';
import { useHistory } from 'react-router';
import { useSnackbar } from 'notistack';

export const MB_1 = 1000000;
export const KB_500 = 500000;

export type UploadImageProps = {
  loading: boolean;
  validateImageFiles: () => boolean;
  shouldUploadImage: (couponMasterId: string) => boolean;
  validateAndSetProductFile: (file: File) => void;
  validateAndSetCompanyFile: (file: File) => void;
  validateAndSetSmartCheckHeaderFile: (file: File) => void;
  validateAndSetSmartCheckUseButtonFile: (file: File) => void;
  validateAndSetSmartCheckConfirmButtonFile: (file: File) => void;
  validateAndSetSmartCheckConfirmCancelButtonFile: (file: File) => void;
  validateAndSetSmartCheckForceButtonFile: (file: File) => void;
  validateAndSetSmartCheckUsedFile: (file: File) => void;
  setDeleteProductFile: () => void;
  setDeleteHeaderFile: () => void;
  setDeleteSmartCheckHeaderFile: () => void;
  setDeleteSmartCheckUseButtonFile: () => void;
  setDeleteSmartCheckConfirmButtonFile: () => void;
  setDeleteSmartCheckConfirmCancelButtonFile: () => void;
  setDeleteSmartCheckForceButtonFile: () => void;
  setDeleteSmartCheckUsedFile: () => void;
  selectedFiles: {
    productFile: SelectedFile;
    companyFile: SelectedFile;
    smartCheckHeaderFile: SelectedFile;
    smartCheckUseButtonFile: SelectedFile;
    smartCheckConfirmButtonFile: SelectedFile;
    smartCheckConfirmCancelButtonFile: SelectedFile;
    smartCheckForceButtonFile: SelectedFile;
    smartCheckUsedFile: SelectedFile;
  };
  uploadImages: (couponMaster: CouponMaster) => Promise<void>;
};

type UploadImage = { type: string; key: string };

export const useUploadImage = (): UploadImageProps => {
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  // pre-signed-url 取得
  const [imageFileMutation] = useCouponMasterUploadImageUrlMutation();

  // クーポンマスタの更新
  const [updateCouponMaster] = useUpdateCouponMasterMutation();

  // クーポンマスタから更新に必要な必須情報を取得する
  const getRequiredPrevious = (m: CouponMaster): UpdateCouponMasterInput => {
    return {
      applyBarcode: {
        sourceType: m.applyBarcode.sourceType,
        displayType: m.applyBarcode.displayType,
      },
      available: m.available,
      applyWith: m.applyWith,
      couponName: m.couponName,
      couponCode: m.couponCode,
      displayStartDate: m.displayStartDate,
      displayEndDate: m.displayEndDate,
      validityEndDateTime: m.validityEndDateTime,
    };
  };

  const upload = useCallback(
    async (input: {
      mutationResult: CouponMaster; // 必須パラメータは毎度入れる必要がある
      files: { file: SelectedFile; type: ImageType }[];
    }) => {
      setLoading(true);

      // 必須パラメータを抽出
      const m = input.mutationResult;
      const requiredPrevious: UpdateCouponMasterInput = getRequiredPrevious(m);

      try {
        const uploadImages: UploadImage[] = [];
        for (let i = 0; i < input.files.length; i++) {
          // 削除フラグの場合はキーを空としてcontinue
          if (input.files[i].file.deleteFlag) {
            uploadImages.push({
              type: input.files[i].type,
              key: '',
            });
            continue;
          }

          // まずは pre-signed-url を取得
          const imageUrl = await imageFileMutation({
            variables: {
              input: {
                couponMasterId: m.id,
                extension: input.files[i].file.ext.message as Extension,
                imageType: input.files[i].type,
              },
            },
          });

          if (!imageUrl.data || !input.files[i].file || !input.files[i].file.file) {
            return Promise.reject(JSON.stringify(imageUrl.errors));
          }

          let contentType = input.files[i].file.file?.type;

          // コピーして新規作成時に取得したS3オブジェクトメタデータのContent-Typeが空の場合があるため（原因は不明）
          // Content-Typeが存在しない場合は、ローカルファイルの拡張子をContent-Typeとして生成する
          if (!contentType) {
            const extension = input.files[i].file.ext.message as Extension;
            const imageExtension = extension === 'jpg' || extension === 'jpeg' ? 'jpeg' : extension;
            contentType = `image/${imageExtension}`;
          }

          // 取得した pre-signed-url を使ってアップロードする
          await axios.put(imageUrl.data.couponMasterUploadImageUrl.preSignedUrl, input.files[i].file.file, {
            headers: {
              'Content-Type': contentType,
            },
          });

          // アップロード後、キーが手に入るのでそれをセットする
          uploadImages.push({
            type: input.files[i].type,
            key: imageUrl.data.couponMasterUploadImageUrl.key,
          });
        }

        // アップロードしたSmartCheck画像がある場合、クーポンマスタを更新する
        if (uploadImages.length > 0) {
          const productImage: UploadImage | undefined = uploadImages.find((u) => u.type === 'product');
          const companyImage: UploadImage | undefined = uploadImages.find((u) => u.type === 'company');

          const productImageInput: ImageContainerInput | undefined = productImage
            ? { key: productImage.key }
            : undefined;
          const companyImageInput: ImageContainerInput | undefined = companyImage
            ? { key: companyImage.key }
            : undefined;
          const smartCheckImageInput: SmartCheckImageInput = uploadImages
            .filter((u) => u.type.includes('smartCheck'))
            .reduce(
              (result, current) => {
                return {
                  ...result,
                  [`${current.type}Image`]: {
                    key: current.key,
                  },
                };
              },
              // Reduceの初期値。上書きされたものはそれが、触れられてないものはクーポンマスタのキーがそのまま残る。
              {
                smartCheckConfirmButtonImage: {
                  key: m.smartCheckImage?.smartCheckConfirmButtonImage?.key || '',
                },
                smartCheckConfirmCancelButtonImage: {
                  key: m.smartCheckImage?.smartCheckConfirmCancelButtonImage?.key || '',
                },
                smartCheckForceButtonImage: {
                  key: m.smartCheckImage?.smartCheckForceButtonImage?.key || '',
                },
                smartCheckHeaderImage: {
                  key: m.smartCheckImage?.smartCheckHeaderImage?.key || '',
                },
                smartCheckUseButtonImage: {
                  key: m.smartCheckImage?.smartCheckUseButtonImage?.key || '',
                },
                smartCheckUsedImage: {
                  key: m.smartCheckImage?.smartCheckUsedImage?.key || '',
                },
              }
            );

          // アップロードされた画像のメタデータを更新する
          await updateCouponMaster({
            variables: {
              id: m.id,
              input: {
                ...requiredPrevious,
                productImage: productImageInput,
                companyImage: companyImageInput,
                smartCheckImage: smartCheckImageInput,
              },
            },
          });
        }
      } catch (err) {
        Bugsnag.notify(err);
        console.error('error', err);
        throw err;
      } finally {
        setLoading(false);
      }
    },
    [imageFileMutation, updateCouponMaster]
  );

  // 画像のローカルステート
  const initialSelectedFile: SelectedFile = {
    ext: {
      message: '未選択',
      valid: undefined,
    },
    length: {
      message: '未選択',
      valid: undefined,
    },
    toUpdate: false,
  };

  // 削除用ステート
  const deleteSelectedFile: SelectedFile = {
    deleteFlag: true,
    ext: {
      message: '削除されます',
      valid: undefined,
    },
    length: {
      message: '削除されます',
      valid: undefined,
    },
    file: undefined,
    toUpdate: true,
  };

  // 画像管理用State
  const [selectedProductFile, setSelectedProductFile] = useState<SelectedFile>(initialSelectedFile);
  const [selectedCompanyFile, setSelectedCompanyFile] = useState<SelectedFile>(initialSelectedFile);
  const [selectedSmartCheckHeaderFile, setSelectedSmartCheckHeaderFile] = useState<SelectedFile>(initialSelectedFile);
  const [selectedSmartCheckUseButtonFile, setSelectedSmartCheckUseButtonFile] =
    useState<SelectedFile>(initialSelectedFile);
  const [selectedSmartCheckConfirmButtonFile, setSelectedSmartCheckConfirmButtonFile] =
    useState<SelectedFile>(initialSelectedFile);
  const [selectedSmartCheckConfirmCancelButtonFile, setSelectedSmartCheckConfirmCancelButtonFile] =
    useState<SelectedFile>(initialSelectedFile);
  const [selectedSmartCheckForceButtonFile, setSelectedSmartCheckForceButtonFile] =
    useState<SelectedFile>(initialSelectedFile);
  const [selectedSmartCheckUsedFile, setSelectedSmartCheckUsedFile] = useState<SelectedFile>(initialSelectedFile);

  // 画像セットコールバック群
  const validateAndSetProductFile = useCallback(
    (file: File) => validateImageFile(file, MB_1, setSelectedProductFile),
    [setSelectedProductFile]
  );
  const validateAndSetCompanyFile = useCallback(
    (file: File) => validateImageFile(file, MB_1, setSelectedCompanyFile),
    [setSelectedCompanyFile]
  );
  const validateAndSetSmartCheckHeaderFile = useCallback(
    (file: File) => validateImageFile(file, MB_1, setSelectedSmartCheckHeaderFile),
    [setSelectedSmartCheckHeaderFile]
  );
  const validateAndSetSmartCheckUseButtonFile = useCallback(
    (file: File) => validateImageFile(file, KB_500, setSelectedSmartCheckUseButtonFile),
    [setSelectedSmartCheckUseButtonFile]
  );
  const validateAndSetSmartCheckConfirmButtonFile = useCallback(
    (file: File) => validateImageFile(file, KB_500, setSelectedSmartCheckConfirmButtonFile),
    [setSelectedSmartCheckConfirmButtonFile]
  );
  const validateAndSetSmartCheckConfirmCancelButtonFile = useCallback(
    (file: File) => validateImageFile(file, KB_500, setSelectedSmartCheckConfirmCancelButtonFile),
    [setSelectedSmartCheckConfirmCancelButtonFile]
  );
  const validateAndSetSmartCheckForceButtonFile = useCallback(
    (file: File) => validateImageFile(file, KB_500, setSelectedSmartCheckForceButtonFile),
    [setSelectedSmartCheckForceButtonFile]
  );
  const validateAndSetSmartCheckUsedFile = useCallback(
    (file: File) => validateImageFile(file, MB_1, setSelectedSmartCheckUsedFile),
    [setSelectedSmartCheckUsedFile]
  );

  // 画像削除フラグON コールバック群
  const setDeleteProductFile = useCallback(() => setSelectedProductFile(deleteSelectedFile), [setSelectedProductFile]);
  const setDeleteHeaderFile = useCallback(() => setSelectedCompanyFile(deleteSelectedFile), [setSelectedCompanyFile]);
  const setDeleteSmartCheckHeaderFile = useCallback(
    () => setSelectedSmartCheckHeaderFile(deleteSelectedFile),
    [setSelectedSmartCheckHeaderFile]
  );
  const setDeleteSmartCheckUseButtonFile = useCallback(
    () => setSelectedSmartCheckUseButtonFile(deleteSelectedFile),
    [setSelectedSmartCheckUseButtonFile]
  );
  const setDeleteSmartCheckConfirmButtonFile = useCallback(
    () => setSelectedSmartCheckConfirmButtonFile(deleteSelectedFile),
    [setSelectedSmartCheckConfirmButtonFile]
  );
  const setDeleteSmartCheckConfirmCancelButtonFile = useCallback(
    () => setSelectedSmartCheckConfirmCancelButtonFile(deleteSelectedFile),
    [setSelectedSmartCheckConfirmCancelButtonFile]
  );
  const setDeleteSmartCheckForceButtonFile = useCallback(
    () => setSelectedSmartCheckForceButtonFile(deleteSelectedFile),
    [setSelectedSmartCheckForceButtonFile]
  );
  const setDeleteSmartCheckUsedFile = useCallback(
    () => setSelectedSmartCheckUsedFile(deleteSelectedFile),
    [setSelectedSmartCheckUsedFile]
  );

  // 画像のバリデーションがダメだったらその時点で終了
  const validateImageFiles = (): boolean => {
    const invalidProductFile =
      selectedProductFile.file && (!selectedProductFile.length.valid || !selectedProductFile.ext.valid);
    const invalidCompanyFile =
      selectedCompanyFile.file && (!selectedCompanyFile.length.valid || !selectedCompanyFile.ext.valid);
    const invalidSelectedSmartCheckHeaderFile =
      selectedSmartCheckHeaderFile.file &&
      (!selectedSmartCheckHeaderFile.length.valid || !selectedSmartCheckHeaderFile.ext.valid);
    const invalidSelectedSmartCheckUseButtonFile =
      selectedSmartCheckUseButtonFile.file &&
      (!selectedSmartCheckUseButtonFile.length.valid || !selectedSmartCheckUseButtonFile.ext.valid);
    const invalidSelectedSmartCheckConfirmButtonFile =
      selectedSmartCheckConfirmButtonFile.file &&
      (!selectedSmartCheckConfirmButtonFile.length.valid || !selectedSmartCheckConfirmButtonFile.ext.valid);
    const invalidSelectedSmartCheckConfirmCancelButtonFile =
      selectedSmartCheckConfirmCancelButtonFile.file &&
      (!selectedSmartCheckConfirmCancelButtonFile.length.valid || !selectedSmartCheckConfirmCancelButtonFile.ext.valid);
    const invalidSelectedSmartCheckUsedFile =
      selectedSmartCheckUsedFile.file &&
      (!selectedSmartCheckUsedFile.length.valid || !selectedSmartCheckUsedFile.ext.valid);
    const invalidSelectedSmartCheckForceButtonFile =
      selectedSmartCheckForceButtonFile.file &&
      (!selectedSmartCheckForceButtonFile.length.valid || !selectedSmartCheckForceButtonFile.ext.valid);

    if (
      invalidProductFile ||
      invalidCompanyFile ||
      invalidSelectedSmartCheckHeaderFile ||
      invalidSelectedSmartCheckUseButtonFile ||
      invalidSelectedSmartCheckConfirmButtonFile ||
      invalidSelectedSmartCheckConfirmCancelButtonFile ||
      invalidSelectedSmartCheckUsedFile ||
      invalidSelectedSmartCheckForceButtonFile
    ) {
      const key = enqueueSnackbar('アップロード画像を確認してください', {
        variant: 'warning',
        persist: true,
        onClick: () => closeSnackbar(key),
      });
      return true;
    }

    return false;
  };

  /**
   * 画像のアップロードができるかどうか
   * @param couponMasterId
   */
  const shouldUploadImage = (couponMasterId: string): boolean => {
    if (
      !selectedProductFile.toUpdate &&
      !selectedCompanyFile.toUpdate &&
      !selectedSmartCheckHeaderFile.toUpdate &&
      !selectedSmartCheckConfirmButtonFile.toUpdate &&
      !selectedSmartCheckConfirmCancelButtonFile.toUpdate &&
      !selectedSmartCheckUsedFile.toUpdate &&
      !selectedSmartCheckUseButtonFile.toUpdate &&
      !selectedSmartCheckForceButtonFile.toUpdate
    ) {
      // なければ完了
      enqueueSnackbar(`クーポンデータを登録しました`, {
        variant: 'success',
      });
      history.push({
        pathname: `/coupon-masters/${couponMasterId}`,
        search: history.location.search,
      });
      return false;
    }

    return true;
  };

  const uploadImages = async (couponMaster: CouponMaster): Promise<void> => {
    const uploadFiles: { file: SelectedFile; type: ImageType }[] = [];

    // 商品画像をアップロード＆更新
    if (selectedProductFile.toUpdate) {
      uploadFiles.push({
        file: selectedProductFile,
        type: 'product',
      });
    }

    // 会社画像をアップロード＆更新
    if (selectedCompanyFile.toUpdate) {
      uploadFiles.push({
        file: selectedCompanyFile,
        type: 'company',
      });
    }

    // smartCheck系
    console.log(selectedSmartCheckHeaderFile);
    if (selectedSmartCheckHeaderFile.toUpdate) {
      uploadFiles.push({
        file: selectedSmartCheckHeaderFile,
        type: 'smartCheckHeader',
      });
    }
    if (selectedSmartCheckUseButtonFile.toUpdate) {
      uploadFiles.push({
        file: selectedSmartCheckUseButtonFile,
        type: 'smartCheckUseButton',
      });
    }
    if (selectedSmartCheckConfirmButtonFile.toUpdate) {
      uploadFiles.push({
        file: selectedSmartCheckConfirmButtonFile,
        type: 'smartCheckConfirmButton',
      });
    }
    if (selectedSmartCheckConfirmCancelButtonFile.toUpdate) {
      uploadFiles.push({
        file: selectedSmartCheckConfirmCancelButtonFile,
        type: 'smartCheckConfirmCancelButton',
      });
    }
    if (selectedSmartCheckUsedFile.toUpdate) {
      uploadFiles.push({
        file: selectedSmartCheckUsedFile,
        type: 'smartCheckUsed',
      });
    }
    if (selectedSmartCheckForceButtonFile.toUpdate) {
      uploadFiles.push({
        file: selectedSmartCheckForceButtonFile,
        type: 'smartCheckForceButton',
      });
    }

    // 画像系をまとめてアップロード
    await upload({
      mutationResult: couponMaster,
      files: uploadFiles,
    });
  };

  return {
    loading,
    validateImageFiles,
    validateAndSetProductFile,
    validateAndSetCompanyFile,
    validateAndSetSmartCheckHeaderFile,
    validateAndSetSmartCheckUseButtonFile,
    validateAndSetSmartCheckConfirmButtonFile,
    validateAndSetSmartCheckConfirmCancelButtonFile,
    validateAndSetSmartCheckForceButtonFile,
    validateAndSetSmartCheckUsedFile,
    setDeleteProductFile,
    setDeleteHeaderFile,
    setDeleteSmartCheckHeaderFile,
    setDeleteSmartCheckUseButtonFile,
    setDeleteSmartCheckConfirmButtonFile,
    setDeleteSmartCheckConfirmCancelButtonFile,
    setDeleteSmartCheckForceButtonFile,
    setDeleteSmartCheckUsedFile,
    selectedFiles: {
      productFile: selectedProductFile,
      companyFile: selectedCompanyFile,
      smartCheckHeaderFile: selectedSmartCheckHeaderFile,
      smartCheckUseButtonFile: selectedSmartCheckUseButtonFile,
      smartCheckConfirmButtonFile: selectedSmartCheckConfirmButtonFile,
      smartCheckConfirmCancelButtonFile: selectedSmartCheckConfirmCancelButtonFile,
      smartCheckForceButtonFile: selectedSmartCheckForceButtonFile,
      smartCheckUsedFile: selectedSmartCheckUsedFile,
    },
    shouldUploadImage,
    uploadImages,
  };
};
