import React, { useCallback } from 'react';
import {
  Box,
  Breadcrumbs,
  Button,
  CircularProgress,
  Container,
  Grid,
  LinearProgress,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Theme,
  Typography,
} from '@material-ui/core';
import { MenuHeaderTemplate } from '../../components/templates/MenuHeaderTemplate';
import { BasicTableHead, BasicTableHeadProps } from '../../components/molecules';
import { FormProvider, useForm } from 'react-hook-form';
import { Search } from '@material-ui/icons';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useLocation } from 'react-router';
import { InvokeCouponDisplayLogJsonQueryVariables } from '../../graphql/generated';
import { Link as RouterLink } from 'react-router-dom';
import { useDisplayedLogUseCase } from './hooks/displayed-log-use-case';
import { formatJstDateTimeSecondJapanese } from '../../util';
import { ReadMoreButton } from '../../components/organisms/general/ReadMoreButton';
import { useUrlSearchParamOrganization } from '../../components/contexts/hooks/url';
import { TextFieldController } from '../../components/organisms/form/controllers/TextFieldController';

const useStyles = makeStyles((theme: Theme) => ({
  area: {
    marginTop: theme.spacing(3),
  },
  section: {
    marginTop: theme.spacing(1),
  },
}));

export type CouponDisplayedLogFormValues = InvokeCouponDisplayLogJsonQueryVariables;

export const CouponDisplayedLog: React.FC = () => {
  const theme = useTheme();
  const classes = useStyles();
  const state = useLocation<{ name: string }>().state;
  const organizationParam = useUrlSearchParamOrganization();
  const form = useForm<CouponDisplayedLogFormValues>();
  const { handleSubmit, getValues } = form;
  const {
    data,
    loading,
    csvLoading,
    onSearch,
    onFetchMore,
    onCsvOut,
    onDetailCsvOut,
    organization,
    organizationLoading,
    clickedCouponId,
    allAcquired,
  } = useDisplayedLogUseCase();

  const displayedTableHeadProps: BasicTableHeadProps = {
    columns: [
      {
        name: 'couponCode',
        label: 'クーポンコード',
        minWidth: theme.spacing(10),
        align: 'left',
      },
      {
        name: 'couponName',
        label: 'クーポン名',
        minWidth: theme.spacing(10),
        align: 'left',
      },
      {
        name: 'barcode',
        label: 'バーコード',
        minWidth: theme.spacing(10),
        align: 'left',
      },
      {
        name: 'createAtMillis',
        label: '最終参照日時',
        minWidth: theme.spacing(10),
        align: 'left',
      },
      {
        name: 'displayedCount',
        label: '参照回数',
        minWidth: theme.spacing(10),
        align: 'right',
      },
      {
        name: 'downLoadButton',
        label: '',
        minWidth: theme.spacing(10),
        align: 'center',
      },
    ],
  };

  // テーブルボディを切り替える
  const tableBody = useCallback(() => {
    if (!data || !data.pollCouponDisplayLogJson) {
      return;
    } else if (!data.pollCouponDisplayLogJson.nodes || data.pollCouponDisplayLogJson.nodes.length === 0) {
      return (
        <TableRow>
          <TableCell>データがありません</TableCell>
        </TableRow>
      );
    } else {
      return data?.pollCouponDisplayLogJson.nodes.map((log) => (
        <TableRow key={log.couponId}>
          <TableCell>{log.couponCode}</TableCell>
          <TableCell>{log.couponName}</TableCell>
          <TableCell>{log.barcode}</TableCell>
          <TableCell>{formatJstDateTimeSecondJapanese(log.createAtMillis)}</TableCell>
          <TableCell align={'right'}>{log.displayedCount}</TableCell>
          <TableCell align="center">
            {csvLoading && clickedCouponId === log.couponId ? (
              <CircularProgress size={30} />
            ) : (
              <Button
                onClick={handleSubmit(() => onDetailCsvOut(log.couponId))}
                type="button"
                size="small"
                color="primary"
                disabled={csvLoading}
              >
                詳細DL
              </Button>
            )}
          </TableCell>
        </TableRow>
      ));
    }
  }, [data, csvLoading]);

  // 組織情報が取得できていないタイミングでは、ローディングを出す
  if (organizationLoading || !organization) {
    return (
      <Box display="flex" alignItems="center" justifyContent="center">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <MenuHeaderTemplate header={{ title: 'クーポン参照状況明細' }}>
        <Container maxWidth="xl">
          {state?.name ? (
            <Box className={classes.section}>
              <Breadcrumbs>
                <Link
                  color="inherit"
                  component={RouterLink}
                  to={{
                    pathname: `/coupon-displayed`,
                    search: organizationParam,
                  }}
                >
                  クーポン参照状況集計
                </Link>
                <Box>
                  <Typography color="textPrimary">{state.name}</Typography>
                </Box>
              </Breadcrumbs>
            </Box>
          ) : (
            <></>
          )}
          <Typography variant="caption" className={classes.section}>
            【一覧の並び順】
          </Typography>
          <Typography variant="caption" className={classes.section}>
            クーポンコード,クーポン名,バーコード,最終参照日時,参照回数
          </Typography>
          <Box display="flex" alignItems="flex-end" className={classes.area}>
            <FormProvider {...form}>
              <form onSubmit={handleSubmit(onSearch)} autoComplete="off" noValidate>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <TextFieldController
                      name="couponCode"
                      label="クーポンコード"
                      size="small"
                      disabled={loading || csvLoading}
                      rule={{
                        maxLength: {
                          value: 30,
                          message: '30文字以内で入力してください',
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextFieldController
                      name="couponName"
                      label="クーポン名"
                      size="small"
                      disabled={loading || csvLoading}
                      rule={{
                        maxLength: {
                          value: 30,
                          message: '30文字以内で入力してください',
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextFieldController
                      name="barcode"
                      label="バーコード"
                      size="small"
                      disabled={loading || csvLoading}
                      rule={{
                        maxLength: {
                          value: 30,
                          message: '30文字以内で入力してください',
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextFieldController
                      name="organizationName"
                      label="発行した組織名"
                      size="small"
                      disabled={loading || csvLoading}
                      rule={{
                        maxLength: {
                          value: 30,
                          message: '30文字以内で入力してください',
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={2}>
                        <Button
                          type="submit"
                          variant="outlined"
                          size="small"
                          color={'default'}
                          disabled={loading || csvLoading}
                          endIcon={<Search />}
                          fullWidth
                        >
                          を含む
                        </Button>
                      </Grid>
                      <Grid item xs={2}>
                        <Button
                          type="submit"
                          variant="contained"
                          size="small"
                          color={'secondary'}
                          disabled={loading || csvLoading}
                          onClick={handleSubmit(() => onCsvOut(getValues()))}
                          fullWidth
                        >
                          {csvLoading && !clickedCouponId ? <CircularProgress size={30} /> : '全明細CSV出力'}
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            </FormProvider>
          </Box>

          <Grid container spacing={2} className={classes.area}>
            <Grid item xs={12}>
              <TableContainer component={Paper}>
                {loading ? <LinearProgress /> : <React.Fragment />}
                <Table size={'small'}>
                  <BasicTableHead columns={displayedTableHeadProps.columns} />
                  <TableBody>{tableBody()}</TableBody>
                </Table>
              </TableContainer>
            </Grid>
            {data?.pollCouponDisplayLogJson?.nodes && !allAcquired ? (
              <ReadMoreButton fetchMore={onFetchMore} loading={loading} />
            ) : (
              <></>
            )}
          </Grid>
        </Container>
      </MenuHeaderTemplate>
    </>
  );
};
