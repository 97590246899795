import { Button, Card, CardActions, CardContent, CircularProgress } from '@material-ui/core';
import PublishIcon from '@material-ui/icons/Publish';
import React, { memo } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { TextFieldController } from '../../../components/organisms/form/controllers/TextFieldController';

export type BulkCouponStartFormValues = { issueNumber: number; description: string };
export type BulkCouponProps = {
  loading: boolean;
  onSubmit: (form: BulkCouponStartFormValues) => void;
};

export const BulkCoupon: React.FC<BulkCouponProps> = memo(function BulkCouponComponent(props) {
  const bulkCouponForm = useForm<BulkCouponStartFormValues>();
  const { handleSubmit } = bulkCouponForm;

  return (
    <FormProvider {...bulkCouponForm}>
      <form onSubmit={handleSubmit(props.onSubmit)} autoComplete="off" noValidate>
        <Card variant="outlined">
          <CardContent>
            <TextFieldController
              required
              type="number"
              size="small"
              name="issueNumber"
              label="発行枚数"
              disabled={props.loading}
              rule={{
                required: 'クーポン発行数は必須です',
                min: {
                  value: 1,
                  message: 'クーポン発行数は1以上にしてください',
                },
                max: {
                  value: 1000000,
                  message: 'クーポン発行数は最大1,000,000までです',
                },
              }}
            />
            <TextFieldController
              name="description"
              label="クーポン一括発行説明(任意)"
              disabled={props.loading}
              size="small"
            />
            <CardActions>
              <Button
                type="submit"
                startIcon={props.loading ? <></> : <PublishIcon />}
                variant="outlined"
                disabled={props.loading}
              >
                {props.loading ? <CircularProgress color="inherit" size="1rem" /> : 'URLを発行する'}
              </Button>
            </CardActions>
          </CardContent>
        </Card>
      </form>
    </FormProvider>
  );
});
