import { useSnackbar } from 'notistack';
import { useCallback } from 'react';
import { useCreateShopMutation } from '../../../graphql/generated';
import { useHistory } from 'react-router';
import { CreateShopFormValues } from '../pages/CreateShopPage';
import { ApolloError } from '@apollo/client';

export type CreateShopUseCase = {
  loading: boolean;
  onCreate: (input: CreateShopFormValues) => void;
};

export const useCreateShopUseCase = (parentId: string): CreateShopUseCase => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const history = useHistory();

  const [createShopMutation, { loading }] = useCreateShopMutation();

  const onCreate = useCallback(
    async (input: CreateShopFormValues) => {
      closeSnackbar();

      try {
        const { data } = await createShopMutation({
          variables: {
            input: {
              name: input.name,
              billing: 'enable',
              parentId,
              customerShopCode: input.customerShopCode,
              simpleClearingCode: input.simpleClearingCode,
            },
          },
        });

        enqueueSnackbar(`店舗を作成しました`, {
          variant: 'success',
        });

        history.push({
          pathname: `/manage-organizations/${data?.createShop.id}`,
          hash: '#about',
          search: history.location.search,
        });
      } catch (e: unknown) {
        if (e instanceof ApolloError) {
          console.error('graphQLErrors', e.graphQLErrors);
          if (e.message === 'ShopConstraintError') {
            enqueueSnackbar(`顧客店舗コードまたは店舗消込コードが重複しています`, {
              variant: 'error',
            });
          }
        } else {
          throw e;
        }
      }
    },
    [createShopMutation, enqueueSnackbar]
  );

  return {
    loading,
    onCreate,
  };
};
