import React, { useCallback, useEffect, useState } from 'react';
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  Divider,
  Grid,
  IconButton,
  Paper,
  Theme,
  Typography,
  withStyles,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { FieldError, FieldErrors, FormProvider, Ref, useForm, useFormState } from 'react-hook-form';
import { CouponMasterImageBody, CouponMasterImageBodyProps } from './Image';
import { DateTimeInput } from '../../../components/organisms/form';
import { TimeFrameFormProps, TimeFrameGrid, TimeFrameInput } from '../../../components/organisms/form/TimeFrameGrid';
import { CouponConsumerDisplayPreview, CouponConsumerDisplayPreviewProps } from './PreviewDialog';
import { DeleteForeverOutlined } from '@material-ui/icons';
import { BarcodeType, CreateCouponMasterInput, SourceType } from '../../../graphql/generated';
import { TextAreaController } from '../../../components/organisms/form/controllers/TextAreaController';
import { CheckBoxController } from '../../../components/organisms/form/controllers/CheckBoxController';
import { KeyboardDatePickerController } from '../../../components/organisms/form/controllers/KeyboardDatePickerController';
import { KeyboardTimePickerController } from '../../../components/organisms/form/controllers/KeyboardTimePickerController';
import { TextFieldController } from '../../../components/organisms/form/controllers/TextFieldController';
import { PopupStateChromePicker } from '../../../components/organisms/form/PopupStateChromePicker';
import { SelectController } from '../../../components/organisms/form/controllers/SelectController';
import {
  SmartCheckSettingProps,
  SmartCheckSettingsGrid,
  SmartCheckSettingsInput,
} from '../../../components/organisms/form/SmartCheckSettingsGrid';
import { SmartCheckLightDisplayPreview, SmartCheckLightDisplayPreviewProps } from './SmartCheckLightPreviewDialog';
import { SmartCheckLightDataProps } from '@sbgift-coupon/use';
import { SmartCheckDisplayPreview, SmartCheckDisplayPreviewProps } from './SmartCheckPreviewDialog';
import { CouponFeature, OrganizationContext } from '../../../components/contexts/organization';
import { ApplyWithLabelObject } from '../dashboard/hooks';

/**
 * フォーム要素を扱う型
 * Omitで除外する型は、フォームで独自に定義する必要性があるもの
 * 例えば、日付項目など
 */
export type CouponMasterFormInput = Omit<
  CreateCouponMasterInput,
  | 'displayStartDate'
  | 'displayEndDate'
  | 'validityStartDateTime'
  | 'validityEndDateTime'
  | 'realTimeIssuedGetStartDateTime'
  | 'realTimeIssuedGetEndDateTime'
  | 'available'
  | 'availableDays'
  | 'availableDate'
  | 'availableStartTimePeriod'
  | 'availableEndTimePeriod'
  | 'applyBarcode'
  | 'smartCheckSetting'
  | 'couponIssuedMaximumNumber'
> & {
  // 利用可否
  unAvailable: boolean;

  applyBarcodeSourceType: SourceType;
  applyBarcodeDisplayType: BarcodeType;

  // 日付など
  displayStartDate: DateTimeInput;
  displayEndDate: DateTimeInput;
  validityStartDate: DateTimeInput;
  validityStartTime: DateTimeInput;
  validityEndDate: DateTimeInput;
  validityEndTime: DateTimeInput;
  realTimeIssuedGetStartDate: DateTimeInput;
  realTimeIssuedGetStartTime: DateTimeInput;
  realTimeIssuedGetEndDate: DateTimeInput;
  realTimeIssuedGetEndTime: DateTimeInput;
  couponIssuedMaximumNumber: string;
} & TimeFrameInput & {
    smartCheckSettingsInput: SmartCheckSettingsInput;
  };

/**
 * keyofで使うため型を別にした。画像を扱う型
 */
type ImageProps = {
  productImageProps: CouponMasterImageBodyProps;
  companyImageProps: CouponMasterImageBodyProps;
  smartCheckHeaderImageProps: CouponMasterImageBodyProps;
  smartCheckUseButtonImageProps: CouponMasterImageBodyProps;
  smartCheckConfirmButtonImageProps: CouponMasterImageBodyProps;
  smartCheckConfirmCancelButtonImageProps: CouponMasterImageBodyProps;
  smartCheckForceButtonImageProps: CouponMasterImageBodyProps;
  smartCheckUsedImageProps: CouponMasterImageBodyProps;
};

export type CouponMasterFormProps = {
  // プレビューで必要
  organizationContext: OrganizationContext;
  couponFeature: CouponFeature;

  // 表示設定
  displaySettings: {
    apply: boolean;
    timeFrame: boolean;
  };

  onSubmit: (input: any) => void;
  loading: boolean;
  submitStepMessage: string;
  previousValues: CouponMasterFormInput;

  submitLabel: string;
} & ImageProps;

const useStyles = makeStyles((theme: Theme) => ({
  section: {
    border: '1px solid #c9cace',
    borderRadius: theme.spacing(1),
    padding: theme.spacing(3),
    marginTop: theme.spacing(4),
  },
  subSection: {
    padding: theme.spacing(2),
    margin: theme.spacing(1),
  },
  invisibleSection: {
    display: 'none',
  },
  emptyBox: {
    marginTop: theme.spacing(4),
  },
  controlPanel: {
    padding: `${theme.spacing(1)}px 0`,
    position: 'sticky',
    bottom: '0',
    zIndex: 2,
    backgroundColor: 'white',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: theme.palette.grey['900'],
  },
}));

const WhiteTextTypography = withStyles({
  root: {
    color: '#FFFFFF',
  },
})(Typography);

/**
 * 画像URLを取得する。keyofを使えば型セーフで書けた
 * @param key
 * @param props
 */
function getImageUrl(key: keyof ImageProps, props: CouponMasterFormProps): string | undefined {
  if (props[key].selectedFile.file) {
    return URL.createObjectURL(props[key].selectedFile.file);
  } else if (props[key].currentImageUrl) {
    return props[key].currentImageUrl;
  } else {
    return undefined;
  }
}

/**
 * SmartCheck用の画像を登録するためのオブジェクトを作成する
 * @param props
 */
function createSmartCheckImageUrl(props: CouponMasterFormProps): SmartCheckLightDataProps['smartCheckImageUrl'] {
  return [
    'smartCheckHeaderImage',
    'smartCheckUseButtonImage',
    'smartCheckConfirmButtonImage',
    'smartCheckConfirmCancelButtonImage',
    'smartCheckForceButtonImage',
    'smartCheckUsedImage',
  ]
    .map((key) => {
      return {
        [`${key}Url`]: getImageUrl(`${key}Props` as keyof ImageProps, props),
      };
    })
    .reduce((previous, current) => {
      const key = Object.keys(current);
      previous[key[0]] = current[key[0]];
      return previous;
    }, {});
}

/**
 * エラー項目の ref を取得する
 * @param obj
 */
function extractFirstErrorRef(obj: FieldErrors | FieldError): Ref | undefined {
  for (const o of Object.values(obj)) {
    if (o.ref) return o.ref;
    return extractFirstErrorRef(o);
  }
}

/**
 * エラー箇所にスクロールする
 * @param errors
 */
function scrollToError(errors: FieldErrors): void {
  const ref = extractFirstErrorRef(errors);
  if (ref && ref.focus) ref.focus();
}

export const CouponMasterForm: React.FC<CouponMasterFormProps> = (props) => {
  const styles = useStyles();
  const useCouponMasterForm = useForm<CouponMasterFormInput>({
    defaultValues: props.previousValues,
    mode: 'onBlur',
  });
  const { handleSubmit, setValue, getValues, trigger, clearErrors, control } = useCouponMasterForm;
  const { errors } = useFormState({ control });

  // カラーピッカーは、選択中の状態でいじってりる色が反映される必要があるので state を使う
  // このコンポーネントだけで完結する state である
  // onChangeComplete で form に setValue すればよい
  const [couponNameBandColorState, setCouponNameBandColorState] = useState(props.previousValues.couponNameBandColor);
  const [couponNameTextColorState, setCouponNameTextColorState] = useState(props.previousValues.couponNameTextColor);
  const [couponIssuerBandColorState, setCouponIssuerBandColorState] = useState(
    props.previousValues.couponIssuerBandColor
  );
  const [couponIssuerTextColorState, setCouponIssuerTextColorState] = useState(
    props.previousValues.couponIssuerTextColor
  );

  const applyWith = props.couponFeature.apply.with;

  const timeFrameInput: TimeFrameFormProps = {
    displaySettings: { ...props.displaySettings },
    previousValues: props.previousValues,
  };

  const smartCheckSettingsInput: SmartCheckSettingProps = {
    applyWith,
    previousValues: props.previousValues.smartCheckSettingsInput,
  };

  // レイアウトパターン参考画像の表示
  const [openLayoutPatternImage, setOpenLayoutPatternImage] = useState(false);

  // プレビュー時のスクロール処理
  useEffect(() => {
    if (Object.keys(errors).length <= 0) return;
    scrollToError(errors);
  }, [errors]);

  // プレビュー制御
  const [inPreview, setInPreview] = useState(false);
  const [previewInput, setPreviewInput] = useState<CouponMasterFormInput>(getValues() as CouponMasterFormInput);
  const onPreview = useCallback(async () => {
    // バリデーション実行、OKならモーダル用の値にセットしてオープン
    clearErrors();
    const result = await trigger();
    // if (Object.keys(errors).length > 0) { // なんか、同期的に実行してくれない。エラーがあってもゼロ個になる。trigger() の戻り値は廃止されてるらしいので新しい react-hook-form では解消されていることを願う。
    if (!result) {
      return;
    }
    setPreviewInput(getValues() as CouponMasterFormInput);
    setInPreview(true);
  }, [clearErrors, trigger, getValues]);

  const preViewProps: CouponConsumerDisplayPreviewProps = {
    organizationName: props.organizationContext.name,
    open: inPreview,
    setOpen: setInPreview,
    input: previewInput,

    // ファイルがセットされていればそのURLを、既存の値があればそれを、それもなければ undefined を返す
    companyImageUrl: getImageUrl('companyImageProps', props),
    productImageUrl: getImageUrl('productImageProps', props),
  };

  const smartCheckPreviewProps: SmartCheckDisplayPreviewProps = preViewProps;
  const smartCheckLightPreviewProps: SmartCheckLightDisplayPreviewProps = preViewProps;

  // SmartCheckなら、SmartCheck用のプレビュー要素を追加で定義する
  if (applyWith === 'smartCheck') {
    smartCheckPreviewProps.smartCheckImageUrl = createSmartCheckImageUrl(props);
  } // SmartCheckLightなら、SmartCheckLight用のプレビュー要素を追加で定義する
  else if (applyWith === 'smartCheckLight') {
    smartCheckLightPreviewProps.smartCheckImageUrl = createSmartCheckImageUrl(props);
  }

  return (
    <FormProvider {...useCouponMasterForm}>
      <form onSubmit={handleSubmit(props.onSubmit)} autoComplete="off" noValidate>
        <Grid container spacing={2} className={styles.section}>
          <Grid item xs={12}>
            <Typography variant="h4">クーポン利用設定</Typography>
          </Grid>
          <Grid item xs={12}>
            <CheckBoxController name="unAvailable" label={'利用不可にする'} />
          </Grid>
        </Grid>
        <Grid container spacing={2} className={styles.section}>
          <Grid item xs={12}>
            <Typography variant="h4">基本情報</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="subtitle1">クーポン名</Typography>
            <Typography color="textSecondary" variant="caption">
              クーポン名HTMLに入力がないときに表示される他、クーポンマスタ検索でも利用します。
            </Typography>
          </Grid>
          <Grid item xs={9}>
            <TextFieldController
              required
              name="couponName"
              label="100文字以内"
              rule={{
                required: 'クーポン名は必須です',
                maxLength: {
                  value: 100,
                  message: 'クーポン名は100文字以内です',
                },
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <Typography variant="subtitle1">受け取り画面用クーポン名</Typography>
            <Typography color="textSecondary" variant="caption">
              こちらに入力がある場合、プレーンテキストに優先して表示します。
            </Typography>
          </Grid>
          <Grid item xs={9}>
            <TextAreaController name={'couponNameHtml'} maxLength={200} label={'200文字以内'} />
          </Grid>
          <Grid item xs={12}>
            <TextFieldController
              required
              name="couponCode"
              label="クーポンコード（100文字以内）"
              rule={{
                required: 'クーポンコードは必須です',
                pattern: {
                  value: /^([a-zA-Z0-9-]{0,100})$/,
                  message: 'クーポンコードは100文字以内の半角英数字です',
                },
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextFieldController
              name="note"
              label="管理用メモ（表示には利用しません、200文字以内）"
              rule={{
                maxLength: {
                  value: 200,
                  message: 'メモは200文字以内です',
                },
              }}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2} alignItems="center" className={styles.section}>
          <Grid item xs={12}>
            <Typography variant="h4">日付・時刻設定</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="subtitle1">表示期間（日本時間）</Typography>
            <Typography color="textSecondary" variant="caption">
              クーポンをユーザに表示する日付を入力します
            </Typography>
          </Grid>
          <Grid item xs={9}>
            <Grid container direction="column" justifyContent="flex-start" alignItems="flex-start">
              <Grid item xs={6}>
                <KeyboardDatePickerController required name="displayStartDate" label="表示開始日" />
              </Grid>
              <Grid item xs={6}>
                <KeyboardDatePickerController required name="displayEndDate" label="表示終了日" />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="subtitle1">有効期間（日本時間）</Typography>
            <Typography color="textSecondary" variant="caption">
              クーポンを利用可能とする日時を入力します
            </Typography>
          </Grid>
          <Grid item xs={9}>
            <Grid container direction="column" justifyContent="flex-start" alignItems="flex-start">
              <Grid item xs={12}>
                <Grid container direction="row" justifyContent="flex-start" alignItems="flex-end" spacing={1}>
                  <Grid item xs={6}>
                    <KeyboardDatePickerController required={false} name="validityStartDate" label="有効開始日" />
                  </Grid>
                  <Grid item xs={4}>
                    <KeyboardTimePickerController required={false} name="validityStartTime" label="時刻" />
                  </Grid>
                  <Grid item xs={2}>
                    <IconButton
                      color={'secondary'}
                      onClick={() => {
                        setValue('validityStartDate', null);
                        setValue('validityStartTime', null);
                      }}
                    >
                      <DeleteForeverOutlined />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container direction="row" justifyContent="flex-start" alignItems="flex-end" spacing={1}>
                  <Grid item xs={6}>
                    <KeyboardDatePickerController required name="validityEndDate" label="有効終了日" />
                  </Grid>
                  <Grid item xs={4}>
                    <KeyboardTimePickerController required name="validityEndTime" label="時刻" />
                  </Grid>
                  <Grid item xs={2}>
                    <IconButton
                      color={'secondary'}
                      onClick={() => {
                        setValue('validityEndDate', null);
                        setValue('validityEndTime', null);
                      }}
                    >
                      <DeleteForeverOutlined />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="subtitle1">リアルタイム発行API取得期間</Typography>
            <Typography color="textSecondary" variant="caption">
              リアルタイム発行APIでクーポンを発行することができる期間を入力します
            </Typography>
          </Grid>
          <Grid item xs={9}>
            <Grid container direction="column" justifyContent="flex-start" alignItems="flex-start">
              <Grid item xs={12}>
                <Grid container direction="row" justifyContent="flex-start" alignItems="flex-end" spacing={1}>
                  <Grid item xs={6}>
                    <KeyboardDatePickerController
                      required={false}
                      name="realTimeIssuedGetStartDate"
                      label="取得開始日"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <KeyboardTimePickerController required={false} name="realTimeIssuedGetStartTime" label="時刻" />
                  </Grid>
                  <Grid item xs={2}>
                    <IconButton
                      color={'secondary'}
                      onClick={() => {
                        setValue('realTimeIssuedGetStartDate', null);
                        setValue('realTimeIssuedGetStartTime', null);
                      }}
                    >
                      <DeleteForeverOutlined />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container direction="row" justifyContent="flex-start" alignItems="flex-end" spacing={1}>
                  <Grid item xs={6}>
                    <KeyboardDatePickerController required={false} name="realTimeIssuedGetEndDate" label="取得終了日" />
                  </Grid>
                  <Grid item xs={4}>
                    <KeyboardTimePickerController required={false} name="realTimeIssuedGetEndTime" label="時刻" />
                  </Grid>
                  <Grid item xs={2}>
                    <IconButton
                      color={'secondary'}
                      onClick={() => {
                        setValue('realTimeIssuedGetEndDate', null);
                        setValue('realTimeIssuedGetEndTime', null);
                      }}
                    >
                      <DeleteForeverOutlined />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="subtitle1">リアルタイムAPIクーポン発行上限枚数</Typography>
            <Typography color="textSecondary" variant="caption">
              リアルタイム発行APIで発行することができるクーポンの上限枚数を指定する
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <TextFieldController
              type="number"
              name="couponIssuedMaximumNumber"
              label="クーポン発行上限枚数"
              rule={{
                min: {
                  value: 1,
                  message: '1以上を設定してください',
                },
                max: {
                  value: 1000000000,
                  message: '最大1,000,000,000です',
                },
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <Button
              variant={'outlined'}
              color={'default'}
              onClick={() => {
                setValue('couponIssuedMaximumNumber', '');
              }}
            >
              クーポン発行上限枚数設定をなしにする
            </Button>
          </Grid>
          <Grid item xs={3} />
          <TimeFrameGrid {...timeFrameInput} />
        </Grid>

        <Grid container spacing={2} className={styles.section}>
          <Grid item xs={12}>
            <Typography variant="h4">クーポン画像</Typography>
          </Grid>
          <Grid item xs={4}>
            <CouponMasterImageBody {...props.productImageProps} />
          </Grid>
          <Grid item xs={4}>
            <CouponMasterImageBody {...props.companyImageProps} />
          </Grid>
        </Grid>

        {/* SmartCheck画像 */}
        {applyWith === 'smartCheck' || applyWith === 'smartCheckLight' ? (
          <>
            <Grid container spacing={2} className={styles.section}>
              <Grid item xs={12}>
                <Typography variant="h4">{ApplyWithLabelObject[applyWith]}画像</Typography>
              </Grid>
              <Grid item xs={4}>
                <CouponMasterImageBody
                  fileTypeTextOverride={`${ApplyWithLabelObject[applyWith]}ヘッダ画像`}
                  {...props.smartCheckHeaderImageProps}
                />
              </Grid>
              <Grid item xs={4}>
                <CouponMasterImageBody {...props.smartCheckUseButtonImageProps} />
              </Grid>
              <Grid item xs={4}>
                <CouponMasterImageBody {...props.smartCheckConfirmButtonImageProps} />
              </Grid>
              <Grid item xs={4}>
                <CouponMasterImageBody {...props.smartCheckConfirmCancelButtonImageProps} />
              </Grid>
              <Grid item xs={4}>
                <CouponMasterImageBody {...props.smartCheckForceButtonImageProps} />
              </Grid>
              <Grid item xs={4}>
                <CouponMasterImageBody {...props.smartCheckUsedImageProps} />
              </Grid>
            </Grid>

            {/* SmartCheck情報 */}
            <Grid container spacing={2} className={styles.section} alignItems="center">
              <SmartCheckSettingsGrid {...smartCheckSettingsInput} />
            </Grid>
          </>
        ) : (
          <></>
        )}

        <Grid container spacing={2} className={styles.section}>
          <Grid item xs={12}>
            <Typography variant="h4">説明・注意事項</Typography>
          </Grid>
          <Grid item xs={12}>
            <TextAreaController name={'description'} maxLength={2000} label={'クーポンに表示する説明内容（任意）'} />
          </Grid>
          <Grid item xs={12}>
            <TextAreaController name={'information'} maxLength={2000} label={'注意事項 (任意)'} />
          </Grid>
          <Grid item xs={12}>
            <TextAreaController name={'meta'} maxLength={2000} label={'メタタグ (任意)'} />
          </Grid>
        </Grid>
        <Grid container spacing={2} className={styles.section}>
          <Grid item xs={12}>
            <Typography variant="h4">フッタ情報</Typography>
          </Grid>
          <Grid item xs={12}>
            <TextFieldController
              name="mapUrl"
              label="地図URL (任意)"
              rule={{
                maxLength: {
                  value: 300,
                  message: '地図URLは300文字以内です',
                },
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextAreaController name={'telNumber'} maxLength={200} label="お問い合わせ先 (任意)" />
          </Grid>
        </Grid>

        <Grid container spacing={2} justifyContent="flex-end" alignItems="center" className={styles.section}>
          <Grid item xs={12}>
            <Typography variant="h4">スタイル・レイアウト設定</Typography>
          </Grid>
          <Grid item xs={12}>
            <Paper className={styles.subSection}>
              <Grid container spacing={2} justifyContent="flex-start" alignItems="center">
                <Grid
                  item
                  xs={12}
                  style={{ backgroundColor: couponNameBandColorState, color: couponNameTextColorState }}
                >
                  <Typography variant="h5">クーポン名サンプル</Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="subtitle1">帯色</Typography>
                </Grid>
                <Grid item xs={3}>
                  <TextFieldController readOnly name="couponNameBandColor" size="small" />
                </Grid>
                <Grid item xs={3}>
                  <PopupStateChromePicker
                    popupId="couponNameBandColor-popup-popover"
                    name="couponNameBandColor"
                    colorState={couponNameBandColorState}
                    setColorState={setCouponNameBandColorState}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Button
                    variant={'outlined'}
                    color={'default'}
                    onClick={() => {
                      setValue('couponNameBandColor', '#ffffff');
                      setCouponNameBandColorState('#ffffff');
                    }}
                  >
                    元に戻す
                  </Button>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="subtitle1">文字色</Typography>
                </Grid>
                <Grid item xs={3}>
                  <TextFieldController readOnly name="couponNameTextColor" size="small" />
                </Grid>
                <Grid item xs={3}>
                  <PopupStateChromePicker
                    popupId="couponNameTextColor-popup-popover"
                    name="couponNameTextColor"
                    colorState={couponNameTextColorState}
                    setColorState={setCouponNameTextColorState}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Button
                    variant={'outlined'}
                    color={'default'}
                    onClick={() => {
                      setValue('couponNameTextColor', '#000000');
                      setCouponNameTextColorState('#000000');
                    }}
                  >
                    元に戻す
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper className={styles.subSection}>
              <Grid container spacing={2} justifyContent="flex-start" alignItems="center">
                <Grid
                  item
                  xs={12}
                  style={{
                    backgroundColor: couponIssuerBandColorState,
                    color: couponIssuerTextColorState,
                  }}
                >
                  <Typography variant="h5">発行者名サンプル</Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="subtitle1">帯色</Typography>
                </Grid>
                <Grid item xs={3}>
                  <TextFieldController readOnly name="couponIssuerBandColor" size="small" />
                </Grid>
                <Grid item xs={3}>
                  <PopupStateChromePicker
                    popupId="couponIssuerBandColor-popup-popover"
                    name="couponIssuerBandColor"
                    colorState={couponIssuerBandColorState}
                    setColorState={setCouponIssuerBandColorState}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Button
                    variant={'outlined'}
                    color={'default'}
                    onClick={() => {
                      setValue('couponIssuerBandColor', '#ffffff');
                      setCouponIssuerBandColorState('#ffffff');
                    }}
                  >
                    元に戻す
                  </Button>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="subtitle1">文字色</Typography>
                </Grid>
                <Grid item xs={3}>
                  <TextFieldController readOnly name="couponIssuerTextColor" size="small" />
                </Grid>
                <Grid item xs={3}>
                  <PopupStateChromePicker
                    popupId="couponIssuerTextColor-popup-popover"
                    name="couponIssuerTextColor"
                    colorState={couponIssuerTextColorState}
                    setColorState={setCouponIssuerTextColorState}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Button
                    variant={'outlined'}
                    color={'default'}
                    onClick={() => {
                      setValue('couponIssuerTextColor', '#000000');
                      setCouponIssuerTextColorState('#000000');
                    }}
                  >
                    元に戻す
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="subtitle1">レイアウトパターン</Typography>
            <Typography color="textSecondary" variant="caption">
              利用画面における配置パターンです
            </Typography>
          </Grid>
          <Grid item xs={5}>
            <SelectController
              name="layoutPattern"
              menuItems={[
                { value: 'normal', label: 'パターン1' },
                { value: 'normalNoIssuer', label: 'パターン2' },
                { value: 'product', label: 'パターン3' },
                { value: 'productNoIssuer', label: 'パターン4' },
                { value: 'bigShopName', label: 'パターン5（詳細はマニュアルに記載）' },
              ]}
            />
          </Grid>
          <Grid item xs={4}>
            <Button variant={'text'} color={'primary'} onClick={() => setOpenLayoutPatternImage(true)}>
              レイアウトパターンイメージ
            </Button>
            <Dialog maxWidth="xl" open={openLayoutPatternImage} onClose={() => setOpenLayoutPatternImage(false)}>
              <DialogContent>
                <img width="100%" height="auto" src="/template_type.jpeg" alt="レイアウトパターンイメージ" />
              </DialogContent>
            </Dialog>
          </Grid>
        </Grid>
        {/*下に固定しているコントロールパネルがいい感じで表示されるための余白*/}
        <Box className={styles.emptyBox} />
        <Grid container spacing={2} justifyContent="flex-end" alignItems="center" className={styles.controlPanel}>
          <Divider />
          <Grid item xs={3}>
            <Button
              fullWidth
              variant="outlined"
              color="primary"
              disabled={props.loading}
              onClick={async () => await onPreview()}
            >
              プレビュー
            </Button>
          </Grid>
          <Grid item xs={3}>
            <Button fullWidth variant="contained" color="secondary" type="submit" disabled={props.loading}>
              {props.submitLabel}
            </Button>
          </Grid>
        </Grid>
        <Backdrop className={styles.backdrop} open={props.loading}>
          <Grid container spacing={2} direction="column" justifyContent="center" alignItems="center">
            <Grid item xs={12}>
              <CircularProgress color="inherit" />
            </Grid>
            <Grid item xs-={12}>
              <WhiteTextTypography variant="h2">{props.submitStepMessage}</WhiteTextTypography>
            </Grid>
          </Grid>
        </Backdrop>
      </form>

      {/* プレビューダイアログ form配下にあるとform要素がネストされてしまい、プレビュー内のsubmitと同時にクーポンマスタの作成・更新が走ってしまう状態だったため外に出した */}
      {(() => {
        if (applyWith === 'barcode') {
          return <CouponConsumerDisplayPreview {...preViewProps} />;
        } else if (applyWith === 'smartCheck') {
          return <SmartCheckDisplayPreview {...smartCheckPreviewProps} />;
        } else if (applyWith === 'smartCheckLight') {
          return <SmartCheckLightDisplayPreview {...smartCheckLightPreviewProps} />;
        } else {
          return <></>;
        }
      })()}
    </FormProvider>
  );
};
