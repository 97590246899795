import { gql } from '@apollo/client';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

export type ApplyBarcodeContainer = {
  __typename?: 'ApplyBarcodeContainer';
  displayType: BarcodeType;
  sourceType: SourceType;
};

export type ApplyBarcodeInput = {
  displayType: BarcodeType;
  sourceType: SourceType;
};

export type ApplySuite = {
  __typename?: 'ApplySuite';
  barcode: ApplyBarcodeContainer;
  with: ApplyWith;
};

export type ApplyWith = 'barcode' | 'smartCheck' | 'smartCheckLight';

export type BarcodeType = 'code128' | 'jan8' | 'jan13' | 'none' | 'nw7' | 'qr';

export type Billing = 'disable' | 'enable';

export type BulkIssuedCouponStatus = 'ABORTED' | 'FAILED' | 'RUNNING' | 'SUCCEEDED' | 'TIMED_OUT';

export type BulkIssuedCouponsExecution = {
  __typename?: 'BulkIssuedCouponsExecution';
  executionArn: Scalars['String'];
};

export type BulkIssuedCouponsStatusResponse = {
  __typename?: 'BulkIssuedCouponsStatusResponse';
  bucket?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
  status: BulkIssuedCouponStatus;
};

export type BulkShopCsvExecution = {
  __typename?: 'BulkShopCsvExecution';
  executionArn: Scalars['String'];
};

export type BulkShopCsvInput = {
  key: Scalars['String'];
  parentId: Scalars['String'];
};

export type BulkShopCsvStatusResponse = {
  __typename?: 'BulkShopCsvStatusResponse';
  cause?: Maybe<Scalars['String']>;
  error?: Maybe<Scalars['String']>;
  status: QueryExecutionStatus;
};

export type Connection = {
  nodes: Array<Node>;
  pageInfo: PageInfo;
};

export type Coupon = Node & {
  __typename?: 'Coupon';
  barcode: Scalars['String'];
  couponCode?: Maybe<Scalars['String']>;
  couponMasterId: Scalars['String'];
  couponName?: Maybe<Scalars['String']>;
  couponUrl: Scalars['String'];
  createdDateTime: Scalars['String'];
  customerShopCode?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  memberShopCode?: Maybe<Scalars['String']>;
  publishedDateTime: Scalars['String'];
  publishedOrganizationId: Scalars['String'];
  publishedOrganizationName: Scalars['String'];
  shopId?: Maybe<Scalars['String']>;
  shopName?: Maybe<Scalars['String']>;
  shopUsedDateTime?: Maybe<Scalars['String']>;
  smartCheckClearingCode?: Maybe<Scalars['String']>;
  smartCheckMemberShopCode?: Maybe<Scalars['String']>;
  smartCheckUsedDateTime?: Maybe<Scalars['String']>;
  smartCheckUsedOrganizationId?: Maybe<Scalars['String']>;
  smartCheckUsedOrganizationName?: Maybe<Scalars['String']>;
  status: CouponStatus;
  terminalCode?: Maybe<Scalars['String']>;
  updatedDateTime: Scalars['String'];
  usedDateTime: Scalars['String'];
  usedFrom: Scalars['String'];
  usedOrganizationId: Scalars['String'];
  usedOrganizationName: Scalars['String'];
};

export type CouponBulkHistoriesConnection = Connection & {
  __typename?: 'CouponBulkHistoriesConnection';
  nodes: Array<CouponBulkHistory>;
  pageInfo: PageInfo;
};

export type CouponBulkHistory = Node & {
  __typename?: 'CouponBulkHistory';
  bucket: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  issuedNumber?: Maybe<Scalars['Int']>;
  key: Scalars['String'];
  publishDateTime: Scalars['String'];
  stateMachineExecutionArn?: Maybe<Scalars['String']>;
};

export type CouponConnection = Connection & {
  __typename?: 'CouponConnection';
  nodes: Array<Coupon>;
  pageInfo: PageInfo;
};

export type CouponLayoutPattern = 'bigShopName' | 'normal' | 'normalNoIssuer' | 'product' | 'productNoIssuer';

export type CouponMaster = Node & {
  __typename?: 'CouponMaster';
  applyBarcode: ApplyBarcodeContainer;
  applyWith: ApplyWith;
  available: Scalars['Boolean'];
  availableDate?: Maybe<Array<Scalars['String']>>;
  availableDays?: Maybe<Array<DayType>>;
  availableEndTimePeriod?: Maybe<Scalars['String']>;
  availableStartTimePeriod?: Maybe<Scalars['String']>;
  companyImage?: Maybe<ImageContainer>;
  companyImageUrl?: Maybe<Scalars['String']>;
  couponCode: Scalars['String'];
  couponIssuedMaximumNumber?: Maybe<Scalars['Int']>;
  couponIssuerBandColor: Scalars['String'];
  couponIssuerTextColor: Scalars['String'];
  couponName: Scalars['String'];
  couponNameBandColor: Scalars['String'];
  couponNameHtml?: Maybe<Scalars['String']>;
  couponNameTextColor: Scalars['String'];
  createAtDateTime: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  displayEndDate: Scalars['String'];
  displayStartDate: Scalars['String'];
  id: Scalars['ID'];
  information?: Maybe<Scalars['String']>;
  layoutPattern: CouponLayoutPattern;
  mapUrl?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  noteParagraph?: Maybe<Scalars['Boolean']>;
  organizationId: Scalars['ID'];
  productImage?: Maybe<ImageContainer>;
  productImageUrl?: Maybe<Scalars['String']>;
  realTimeIssuedGetEndDateTime?: Maybe<Scalars['String']>;
  realTimeIssuedGetStartDateTime?: Maybe<Scalars['String']>;
  smartCheckImage?: Maybe<SmartCheckImage>;
  smartCheckImageUrl?: Maybe<SmartCheckImageUrl>;
  smartCheckSetting?: Maybe<SmartCheckSetting>;
  telNumber?: Maybe<Scalars['String']>;
  timeFrame: TimeFrame;
  updateAtDateTime: Scalars['String'];
  validityEndDateTime: Scalars['String'];
  validityStartDateTime?: Maybe<Scalars['String']>;
};

export type CouponMasterConnection = Connection & {
  __typename?: 'CouponMasterConnection';
  nodes: Array<CouponMaster>;
  pageInfo: PageInfo;
};

export type CouponStatus = 'CANCELED' | 'ISSUED' | 'USED';

export type CouponUsage = {
  __typename?: 'CouponUsage';
  canceledCount: Scalars['Int'];
  couponCode?: Maybe<Scalars['String']>;
  couponMasterId?: Maybe<Scalars['String']>;
  couponName?: Maybe<Scalars['String']>;
  displayedCount?: Maybe<Scalars['Int']>;
  organizationId?: Maybe<Scalars['String']>;
  organizationName?: Maybe<Scalars['String']>;
  ownPublishedCount?: Maybe<Scalars['Int']>;
  ownUsedCount?: Maybe<Scalars['Int']>;
  publishedCount: Scalars['Int'];
  usedCount: Scalars['Int'];
};

export type CouponUsageAggregateCsvUrlResponse = {
  __typename?: 'CouponUsageAggregateCsvUrlResponse';
  preSignedUrl: Scalars['String'];
  queryExecutionId: Scalars['String'];
  status: QueryExecutionStatus;
};

export type CouponUsageAggregateJsonConnection = {
  __typename?: 'CouponUsageAggregateJsonConnection';
  nodes?: Maybe<Array<CouponUsage>>;
  pageInfo?: Maybe<PageInfo>;
  queryExecutionId: Scalars['String'];
  status: QueryExecutionStatus;
};

export type CouponUsageConnection = {
  __typename?: 'CouponUsageConnection';
  nodes: Array<CouponUsage>;
  pageInfo: PageInfo;
};

export type CouponUsageLog = {
  __typename?: 'CouponUsageLog';
  barcode?: Maybe<Scalars['String']>;
  couponCode: Scalars['String'];
  couponId: Scalars['String'];
  couponMasterId: Scalars['String'];
  couponName: Scalars['String'];
  createAtMillis: Scalars['String'];
  customerShopCode?: Maybe<Scalars['String']>;
  displayedCount?: Maybe<Scalars['Int']>;
  memberShopCode?: Maybe<Scalars['String']>;
  operateFrom: Scalars['String'];
  organizationName: Scalars['String'];
  status?: Maybe<Scalars['String']>;
  terminalNumber?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  usedShopName?: Maybe<Scalars['String']>;
  userAgent?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  userName?: Maybe<Scalars['String']>;
};

export type CouponUsageLogConnection = {
  __typename?: 'CouponUsageLogConnection';
  nodes: Array<CouponUsageLog>;
  pageInfo: PageInfo;
};

export type CouponUsageLogJsonConnection = {
  __typename?: 'CouponUsageLogJsonConnection';
  nodes?: Maybe<Array<CouponUsageLog>>;
  pageInfo?: Maybe<PageInfo>;
  queryExecutionId: Scalars['String'];
  status: QueryExecutionStatus;
};

export type CreateShopGroupInput = {
  name: Scalars['String'];
  parentId?: Maybe<Scalars['String']>;
  type: OrganizationType;
};

export type CreateShopInput = {
  billing: Billing;
  customerShopCode?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  parentId: Scalars['String'];
  simpleClearingCode?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['String']>;
};

export type CreateUserInput = {
  email: Scalars['String'];
  isAdministrator: Scalars['Boolean'];
  name: Scalars['String'];
  organizationId: Scalars['String'];
  password: Scalars['String'];
  roleId: Scalars['String'];
};

export type DayType = 'fri' | 'mon' | 'sat' | 'sun' | 'thu' | 'tue' | 'wed';

export type Extension = 'gif' | 'jpeg' | 'jpg' | 'png';

export type ImageContainer = {
  __typename?: 'ImageContainer';
  key?: Maybe<Scalars['String']>;
};

export type ImageContainerInput = {
  key: Scalars['String'];
};

export type ImageType =
  | 'company'
  | 'product'
  | 'smartCheckConfirmButton'
  | 'smartCheckConfirmCancelButton'
  | 'smartCheckForceButton'
  | 'smartCheckHeader'
  | 'smartCheckUseButton'
  | 'smartCheckUsed';

export type InvokeCouponUsageAggregateResponse = {
  __typename?: 'InvokeCouponUsageAggregateResponse';
  queryExecutionId: Scalars['String'];
};

export type InvokeShopCsvUrlResponse = {
  __typename?: 'InvokeShopCsvUrlResponse';
  queryExecutionId: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  bulkIssueCoupons: BulkIssuedCouponsExecution;
  bulkShopCsv: BulkShopCsvExecution;
  couponMasterUploadImageUrl: PreSignedImageUrlResponse;
  createCouponMaster: CouponMaster;
  createShop: Shop;
  createShopGroup: ShopGroup;
  createUser: User;
  deleteCouponMaster: CouponMaster;
  deleteShop: Scalars['String'];
  deleteShopGroup: Scalars['String'];
  deleteUser: Scalars['String'];
  updateCouponMaster: CouponMaster;
  updateCouponStatus: CouponStatus;
  updateShop: Shop;
  updateShopGroup: ShopGroup;
  updateUserName: User;
  updateUserPassword: User;
  updateUserRole: User;
  upsertOrganizationSuite: OrganizationSuite;
};

export type MutationBulkIssueCouponsArgs = {
  input?: Maybe<BulkIssueCouponsInput>;
};

export type MutationBulkShopCsvArgs = {
  input: BulkShopCsvInput;
};

export type MutationCouponMasterUploadImageUrlArgs = {
  input: UploadImageInput;
};

export type MutationCreateCouponMasterArgs = {
  input?: Maybe<CreateCouponMasterInput>;
};

export type MutationCreateShopArgs = {
  input: CreateShopInput;
};

export type MutationCreateShopGroupArgs = {
  input: CreateShopGroupInput;
};

export type MutationCreateUserArgs = {
  input: CreateUserInput;
};

export type MutationDeleteCouponMasterArgs = {
  input?: Maybe<DeleteCouponMasterInput>;
};

export type MutationDeleteShopArgs = {
  id: Scalars['String'];
};

export type MutationDeleteShopGroupArgs = {
  id: Scalars['String'];
  type: OrganizationType;
};

export type MutationDeleteUserArgs = {
  id: Scalars['String'];
};

export type MutationUpdateCouponMasterArgs = {
  id: Scalars['String'];
  input?: Maybe<UpdateCouponMasterInput>;
};

export type MutationUpdateCouponStatusArgs = {
  input?: Maybe<UpdateCouponStatusInput>;
};

export type MutationUpdateShopArgs = {
  id: Scalars['String'];
  input: UpdateShopInput;
};

export type MutationUpdateShopGroupArgs = {
  id: Scalars['String'];
  input: UpdateShopGroupInput;
};

export type MutationUpdateUserNameArgs = {
  id: Scalars['String'];
  name: Scalars['String'];
};

export type MutationUpdateUserPasswordArgs = {
  id: Scalars['String'];
  password: Scalars['String'];
};

export type MutationUpdateUserRoleArgs = {
  id: Scalars['String'];
  isAdministrator: Scalars['Boolean'];
  roleId: Scalars['String'];
};

export type MutationUpsertOrganizationSuiteArgs = {
  category: SuiteCategory;
  organizationId: Scalars['String'];
  suite: UpsertOrganizationSuiteInput;
};

export type Node = {
  id: Scalars['ID'];
};

export type Organization = Node & {
  __typename?: 'Organization';
  billing?: Maybe<Scalars['String']>;
  customerShopCode?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  memberShopCode?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  parentId?: Maybe<Scalars['String']>;
  shopCode?: Maybe<Scalars['String']>;
  shopNumber?: Maybe<Scalars['String']>;
  simpleClearingCode?: Maybe<Scalars['String']>;
  type: OrganizationType;
};

export type OrganizationSuite = {
  __typename?: 'OrganizationSuite';
  apply: ApplySuite;
  id: Scalars['String'];
  takeOver: TakeOverSuite;
  timeFrame: TimeFrameSuite;
};

export type OrganizationType = 'BIG_SHOP_GROUP' | 'SHOP' | 'SMALL_SHOP_GROUP';

export type PageInfo = {
  __typename?: 'PageInfo';
  nextToken?: Maybe<Scalars['String']>;
};

export type PaginatedOrganizations = {
  __typename?: 'PaginatedOrganizations';
  items?: Maybe<Array<Organization>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type PollShopCsvUrlResponse = {
  __typename?: 'PollShopCsvUrlResponse';
  queryExecutionId: Scalars['String'];
  signedUrl?: Maybe<Scalars['String']>;
  status: QueryExecutionStatus;
};

export type PreSignedImageUrlResponse = {
  __typename?: 'PreSignedImageUrlResponse';
  imageType: ImageType;
  key: Scalars['String'];
  preSignedUrl: Scalars['String'];
};

export type PreSignedUrlResponse = {
  __typename?: 'PreSignedUrlResponse';
  key?: Maybe<Scalars['String']>;
  preSignedUrl: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  bulkIssuedCouponsStatus: BulkIssuedCouponsStatusResponse;
  bulkShopCsvStatus: BulkShopCsvStatusResponse;
  coupon: Coupon;
  couponBulkHistories: CouponBulkHistoriesConnection;
  couponMaster: CouponMaster;
  couponMasterImageUrl: PreSignedUrlResponse;
  couponMasters: CouponMasterConnection;
  coupons: CouponConnection;
  getBulkShopCsvPutPreSignedUrl: PreSignedUrlResponse;
  invokeCouponDisplayAggregateCsvUrl: InvokeCouponUsageAggregateResponse;
  invokeCouponDisplayAggregateJson: InvokeCouponUsageAggregateResponse;
  invokeCouponDisplayLogCsvUrl: InvokeCouponUsageAggregateResponse;
  invokeCouponDisplayLogJson: InvokeCouponUsageAggregateResponse;
  invokeCouponUsageAggregateCsvUrl: InvokeCouponUsageAggregateResponse;
  invokeCouponUsageAggregateJson: InvokeCouponUsageAggregateResponse;
  invokeCouponUsageLogCsv: InvokeCouponUsageAggregateResponse;
  invokeCouponUsageLogJson: InvokeCouponUsageAggregateResponse;
  invokeShopCsvUrl: InvokeShopCsvUrlResponse;
  organization: Organization;
  organizationChildren: PaginatedOrganizations;
  organizationSuite: OrganizationSuite;
  pollCouponDisplayAggregateCsvUrl: CouponUsageAggregateCsvUrlResponse;
  pollCouponDisplayAggregateJson: CouponUsageAggregateJsonConnection;
  pollCouponDisplayLogCsvUrl: CouponUsageAggregateCsvUrlResponse;
  pollCouponDisplayLogJson: CouponUsageLogJsonConnection;
  pollCouponUsageAggregateCsvUrl: CouponUsageAggregateCsvUrlResponse;
  pollCouponUsageAggregateJson: CouponUsageAggregateJsonConnection;
  pollCouponUsageLogCsv: CouponUsageAggregateCsvUrlResponse;
  pollCouponUsageLogJson: CouponUsageLogJsonConnection;
  pollShopCsvUrl: PollShopCsvUrlResponse;
  preSignedGetUrl: PreSignedUrlResponse;
  roles: Array<Role>;
  shop: Shop;
  user: User;
  users: UsersConnection;
};

export type QueryBulkIssuedCouponsStatusArgs = {
  executionArn: Scalars['String'];
};

export type QueryBulkShopCsvStatusArgs = {
  executionArn: Scalars['String'];
};

export type QueryCouponArgs = {
  couponId: Scalars['ID'];
};

export type QueryCouponBulkHistoriesArgs = {
  couponMasterId: Scalars['ID'];
  limit: Scalars['Int'];
  nextToken?: Maybe<Scalars['String']>;
};

export type QueryCouponMasterArgs = {
  couponMasterId: Scalars['ID'];
};

export type QueryCouponMasterImageUrlArgs = {
  couponMasterId: Scalars['ID'];
};

export type QueryCouponMastersArgs = {
  couponCode?: Maybe<Scalars['String']>;
  couponName?: Maybe<Scalars['String']>;
  limit: Scalars['Int'];
  nextToken?: Maybe<Scalars['String']>;
  organizationId: Scalars['String'];
};

export type QueryCouponsArgs = {
  barcode?: Maybe<Scalars['String']>;
  couponMasterId?: Maybe<Scalars['String']>;
  couponUrl?: Maybe<Scalars['String']>;
  limit: Scalars['Int'];
  nextToken?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
};

export type QueryInvokeCouponDisplayAggregateCsvUrlArgs = {
  couponCode?: Maybe<Scalars['String']>;
  couponName?: Maybe<Scalars['String']>;
  organizationId: Scalars['String'];
  organizationName?: Maybe<Scalars['String']>;
  searchPolicy: Scalars['String'];
};

export type QueryInvokeCouponDisplayAggregateJsonArgs = {
  couponCode?: Maybe<Scalars['String']>;
  couponName?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  organizationId: Scalars['String'];
  organizationName?: Maybe<Scalars['String']>;
};

export type QueryInvokeCouponDisplayLogCsvUrlArgs = {
  barcode?: Maybe<Scalars['String']>;
  couponCode?: Maybe<Scalars['String']>;
  couponId?: Maybe<Scalars['String']>;
  couponMasterId?: Maybe<Scalars['String']>;
  couponName?: Maybe<Scalars['String']>;
  isCanceled?: Maybe<Scalars['Boolean']>;
  isIssued?: Maybe<Scalars['Boolean']>;
  isUsed?: Maybe<Scalars['Boolean']>;
  organizationId?: Maybe<Scalars['String']>;
  organizationName?: Maybe<Scalars['String']>;
  searchPolicy: Scalars['String'];
  url?: Maybe<Scalars['String']>;
};

export type QueryInvokeCouponDisplayLogJsonArgs = {
  barcode?: Maybe<Scalars['String']>;
  couponCode?: Maybe<Scalars['String']>;
  couponId?: Maybe<Scalars['String']>;
  couponMasterId?: Maybe<Scalars['String']>;
  couponName?: Maybe<Scalars['String']>;
  isCanceled?: Maybe<Scalars['Boolean']>;
  isIssued?: Maybe<Scalars['Boolean']>;
  isUsed?: Maybe<Scalars['Boolean']>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  organizationId?: Maybe<Scalars['String']>;
  organizationName?: Maybe<Scalars['String']>;
  searchPolicy: Scalars['String'];
  url?: Maybe<Scalars['String']>;
};

export type QueryInvokeCouponUsageAggregateCsvUrlArgs = {
  couponCode?: Maybe<Scalars['String']>;
  couponName?: Maybe<Scalars['String']>;
  groupBy: Scalars['String'];
  organizationId: Scalars['String'];
  organizationName?: Maybe<Scalars['String']>;
  searchPolicy: Scalars['String'];
};

export type QueryInvokeCouponUsageAggregateJsonArgs = {
  couponCode?: Maybe<Scalars['String']>;
  couponName?: Maybe<Scalars['String']>;
  groupBy: Scalars['String'];
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  organizationId: Scalars['String'];
  organizationName?: Maybe<Scalars['String']>;
};

export type QueryInvokeCouponUsageLogCsvArgs = {
  barcode?: Maybe<Scalars['String']>;
  couponCode?: Maybe<Scalars['String']>;
  couponId?: Maybe<Scalars['String']>;
  couponMasterId?: Maybe<Scalars['String']>;
  couponName?: Maybe<Scalars['String']>;
  isCanceled?: Maybe<Scalars['Boolean']>;
  isIssued?: Maybe<Scalars['Boolean']>;
  isUsed?: Maybe<Scalars['Boolean']>;
  organizationId?: Maybe<Scalars['String']>;
  organizationName?: Maybe<Scalars['String']>;
  searchPolicy: Scalars['String'];
  url?: Maybe<Scalars['String']>;
};

export type QueryInvokeCouponUsageLogJsonArgs = {
  barcode?: Maybe<Scalars['String']>;
  couponCode?: Maybe<Scalars['String']>;
  couponId?: Maybe<Scalars['String']>;
  couponMasterId?: Maybe<Scalars['String']>;
  couponName?: Maybe<Scalars['String']>;
  isCanceled?: Maybe<Scalars['Boolean']>;
  isIssued?: Maybe<Scalars['Boolean']>;
  isUsed?: Maybe<Scalars['Boolean']>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  organizationId?: Maybe<Scalars['String']>;
  organizationName?: Maybe<Scalars['String']>;
  searchPolicy: Scalars['String'];
  url?: Maybe<Scalars['String']>;
};

export type QueryInvokeShopCsvUrlArgs = {
  parentId: Scalars['String'];
};

export type QueryOrganizationArgs = {
  id: Scalars['String'];
};

export type QueryOrganizationChildrenArgs = {
  query: SearchOrganizationChildrenInput;
};

export type QueryOrganizationSuiteArgs = {
  id: Scalars['String'];
};

export type QueryPollCouponDisplayAggregateCsvUrlArgs = {
  queryExecutionId: Scalars['String'];
};

export type QueryPollCouponDisplayAggregateJsonArgs = {
  queryExecutionId: Scalars['String'];
};

export type QueryPollCouponDisplayLogCsvUrlArgs = {
  queryExecutionId: Scalars['String'];
};

export type QueryPollCouponDisplayLogJsonArgs = {
  queryExecutionId: Scalars['String'];
};

export type QueryPollCouponUsageAggregateCsvUrlArgs = {
  groupBy: Scalars['String'];
  queryExecutionId: Scalars['String'];
};

export type QueryPollCouponUsageAggregateJsonArgs = {
  groupBy: Scalars['String'];
  queryExecutionId: Scalars['String'];
};

export type QueryPollCouponUsageLogCsvArgs = {
  queryExecutionId: Scalars['String'];
};

export type QueryPollCouponUsageLogJsonArgs = {
  queryExecutionId: Scalars['String'];
};

export type QueryPollShopCsvUrlArgs = {
  queryExecutionId: Scalars['String'];
};

export type QueryPreSignedGetUrlArgs = {
  bucket: Scalars['String'];
  fileName?: Maybe<Scalars['String']>;
  key: Scalars['String'];
};

export type QueryShopArgs = {
  id: Scalars['String'];
};

export type QueryUserArgs = {
  id?: Maybe<Scalars['String']>;
};

export type QueryUsersArgs = {
  email?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  organizationId: Scalars['String'];
  page?: Maybe<Scalars['String']>;
};

export type QueryExecutionStatus = 'CANCELLED' | 'FAILED' | 'QUEUED' | 'RUNNING' | 'SUCCEEDED';

export type RelayStyleConnection = {
  edges: Array<RelayStyleEdge>;
  pageInfo: RelayStylePageInfo;
};

export type RelayStyleEdge = {
  cursor: Scalars['String'];
  node: Node;
};

export type RelayStylePageInfo = {
  __typename?: 'RelayStylePageInfo';
  endCursor: Scalars['String'];
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  length: Scalars['Int'];
  limit: Scalars['Int'];
  startCursor: Scalars['String'];
};

export type Role = Node & {
  __typename?: 'Role';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type SearchOrganizationChildrenInput = {
  limit?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  nextToken?: Maybe<Scalars['String']>;
  parentId: Scalars['String'];
  parentType?: Maybe<Scalars['String']>;
  shopCode?: Maybe<Scalars['String']>;
};

export type Shop = Node & {
  __typename?: 'Shop';
  billing: Scalars['String'];
  customerShopCode?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  parentId: Scalars['String'];
  shopCode: Scalars['String'];
  shopNumber: Scalars['String'];
  simpleClearingCode?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['String']>;
  type: OrganizationType;
};

export type ShopGroup = Node & {
  __typename?: 'ShopGroup';
  id: Scalars['ID'];
  memberShopCode: Scalars['String'];
  name: Scalars['String'];
  parentId?: Maybe<Scalars['String']>;
  type: OrganizationType;
};

export type ShopsConnection = Connection & {
  __typename?: 'ShopsConnection';
  nodes: Array<Shop>;
  pageInfo: PageInfo;
};

export type SmartCheckImage = {
  __typename?: 'SmartCheckImage';
  smartCheckConfirmButtonImage?: Maybe<ImageContainer>;
  smartCheckConfirmCancelButtonImage?: Maybe<ImageContainer>;
  smartCheckForceButtonImage?: Maybe<ImageContainer>;
  smartCheckHeaderImage?: Maybe<ImageContainer>;
  smartCheckUseButtonImage?: Maybe<ImageContainer>;
  smartCheckUsedImage?: Maybe<ImageContainer>;
};

export type SmartCheckImageInput = {
  smartCheckConfirmButtonImage?: Maybe<ImageContainerInput>;
  smartCheckConfirmCancelButtonImage?: Maybe<ImageContainerInput>;
  smartCheckForceButtonImage?: Maybe<ImageContainerInput>;
  smartCheckHeaderImage?: Maybe<ImageContainerInput>;
  smartCheckUseButtonImage?: Maybe<ImageContainerInput>;
  smartCheckUsedImage?: Maybe<ImageContainerInput>;
};

export type SmartCheckImageUrl = {
  __typename?: 'SmartCheckImageUrl';
  smartCheckConfirmButtonImageUrl?: Maybe<Scalars['String']>;
  smartCheckConfirmCancelButtonImageUrl?: Maybe<Scalars['String']>;
  smartCheckForceButtonImageUrl?: Maybe<Scalars['String']>;
  smartCheckHeaderImageUrl?: Maybe<Scalars['String']>;
  smartCheckUseButtonImageUrl?: Maybe<Scalars['String']>;
  smartCheckUsedImageUrl?: Maybe<Scalars['String']>;
};

export type SmartCheckSetting = {
  __typename?: 'SmartCheckSetting';
  countDownMinute?: Maybe<Scalars['String']>;
  smartCheckConfirmText?: Maybe<Scalars['String']>;
  smartCheckCountDownText?: Maybe<Scalars['String']>;
  smartCheckUseButtonBottomText?: Maybe<Scalars['String']>;
  staticBarcodes?: Maybe<Array<StaticBarcode>>;
};

export type SmartCheckSettingInput = {
  countDownMinute?: Maybe<Scalars['String']>;
  smartCheckConfirmText?: Maybe<Scalars['String']>;
  smartCheckCountDownText?: Maybe<Scalars['String']>;
  smartCheckUseButtonBottomText?: Maybe<Scalars['String']>;
  staticBarcodes: Array<StaticBarcodeInput>;
};

export type SourceType = 'CouponGeneral16Barcode' | 'CouponMosBarcode' | 'CouponPos12Barcode';

export type StaticBarcode = {
  __typename?: 'StaticBarcode';
  barcode?: Maybe<Scalars['String']>;
  barcodeType: BarcodeType;
};

export type StaticBarcodeInput = {
  barcode?: Maybe<Scalars['String']>;
  barcodeType: BarcodeType;
};

export type SuiteCategory = 'coupon';

export type TakeOverSuite = {
  __typename?: 'TakeOverSuite';
  apply: Scalars['String'];
  timeFrame: Scalars['String'];
};

export type TimeFrame = 'monthly' | 'none' | 'weekly';

export type TimeFrameSuite = {
  __typename?: 'TimeFrameSuite';
  available: Scalars['Boolean'];
};

export type UpdateShopGroupInput = {
  name: Scalars['String'];
  parentId?: Maybe<Scalars['String']>;
  type: OrganizationType;
};

export type UpdateShopInput = {
  billing: Billing;
  customerShopCode?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  parentId: Scalars['String'];
  simpleClearingCode?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['String']>;
};

export type UploadImageInput = {
  couponMasterId: Scalars['ID'];
  extension: Extension;
  imageType: ImageType;
};

export type UpsertOrganizationSuiteInput = {
  applyBarcodeDisplayType: BarcodeType;
  applyBarcodeSourceType: SourceType;
  applyWith: ApplyWith;
};

export type User = Node & {
  __typename?: 'User';
  createAtDateTime: Scalars['String'];
  email: Scalars['String'];
  id: Scalars['ID'];
  isAdministrator: Scalars['Boolean'];
  lastLoginDateTime?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  organizationId?: Maybe<Scalars['String']>;
  permissions: Array<Maybe<Scalars['String']>>;
  role: Scalars['String'];
  roleId: Scalars['String'];
  updateAtDateTime: Scalars['String'];
};

export type UserEdge = RelayStyleEdge & {
  __typename?: 'UserEdge';
  cursor: Scalars['String'];
  node: User;
};

export type UsersConnection = RelayStyleConnection & {
  __typename?: 'UsersConnection';
  edges: Array<UserEdge>;
  pageInfo: RelayStylePageInfo;
};

export type BulkIssueCouponsInput = {
  applyWith: ApplyWith;
  barcodeSource?: Maybe<Scalars['String']>;
  barcodeType?: Maybe<Scalars['String']>;
  batchSize?: Maybe<Scalars['Int']>;
  couponCode?: Maybe<Scalars['String']>;
  couponMasterId: Scalars['ID'];
  description: Scalars['String'];
  fifo?: Maybe<Scalars['Boolean']>;
  issuedNumber: Scalars['Int'];
  publishedFrom: Scalars['String'];
  publishedOrganizationId: Scalars['String'];
  publishedOrganizationName: Scalars['String'];
};

export type BulkIssuedCouponsUrlInput = {
  couponMasterId: Scalars['ID'];
};

export type CreateCouponMasterInput = {
  applyBarcode: ApplyBarcodeInput;
  applyWith: ApplyWith;
  available: Scalars['Boolean'];
  availableDate?: Maybe<Array<Scalars['String']>>;
  availableDays?: Maybe<Array<DayType>>;
  availableEndTimePeriod?: Maybe<Scalars['String']>;
  availableStartTimePeriod?: Maybe<Scalars['String']>;
  couponCode: Scalars['String'];
  couponIssuedMaximumNumber?: Maybe<Scalars['Int']>;
  couponIssuerBandColor: Scalars['String'];
  couponIssuerTextColor: Scalars['String'];
  couponName: Scalars['String'];
  couponNameBandColor: Scalars['String'];
  couponNameHtml?: Maybe<Scalars['String']>;
  couponNameTextColor: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  displayEndDate: Scalars['String'];
  displayStartDate: Scalars['String'];
  information?: Maybe<Scalars['String']>;
  layoutPattern: CouponLayoutPattern;
  mapUrl?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  noteParagraph?: Maybe<Scalars['Boolean']>;
  organizationId: Scalars['String'];
  realTimeIssuedGetEndDateTime?: Maybe<Scalars['String']>;
  realTimeIssuedGetStartDateTime?: Maybe<Scalars['String']>;
  smartCheckSetting?: Maybe<SmartCheckSettingInput>;
  telNumber?: Maybe<Scalars['String']>;
  timeFrame: TimeFrame;
  validityEndDateTime: Scalars['String'];
  validityStartDateTime?: Maybe<Scalars['String']>;
};

export type DeleteCouponMasterInput = {
  couponMasterId: Scalars['ID'];
};

export type UpdateCouponMasterInput = {
  applyBarcode: ApplyBarcodeInput;
  applyWith: ApplyWith;
  available?: Maybe<Scalars['Boolean']>;
  availableDate?: Maybe<Array<Scalars['String']>>;
  availableDays?: Maybe<Array<DayType>>;
  availableEndTimePeriod?: Maybe<Scalars['String']>;
  availableStartTimePeriod?: Maybe<Scalars['String']>;
  companyImage?: Maybe<ImageContainerInput>;
  couponCode?: Maybe<Scalars['String']>;
  couponIssuedMaximumNumber?: Maybe<Scalars['Int']>;
  couponIssuerBandColor?: Maybe<Scalars['String']>;
  couponIssuerTextColor?: Maybe<Scalars['String']>;
  couponName?: Maybe<Scalars['String']>;
  couponNameBandColor?: Maybe<Scalars['String']>;
  couponNameHtml?: Maybe<Scalars['String']>;
  couponNameTextColor?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  displayEndDate?: Maybe<Scalars['String']>;
  displayStartDate?: Maybe<Scalars['String']>;
  information?: Maybe<Scalars['String']>;
  layoutPattern?: Maybe<CouponLayoutPattern>;
  mapUrl?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  noteParagraph?: Maybe<Scalars['Boolean']>;
  productImage?: Maybe<ImageContainerInput>;
  realTimeIssuedGetEndDateTime?: Maybe<Scalars['String']>;
  realTimeIssuedGetStartDateTime?: Maybe<Scalars['String']>;
  smartCheckImage?: Maybe<SmartCheckImageInput>;
  smartCheckSetting?: Maybe<SmartCheckSettingInput>;
  telNumber?: Maybe<Scalars['String']>;
  timeFrame?: Maybe<TimeFrame>;
  validityEndDateTime?: Maybe<Scalars['String']>;
  validityStartDateTime?: Maybe<Scalars['String']>;
};

export type UpdateCouponStatusInput = {
  couponId: Scalars['ID'];
  status: CouponStatus;
  usedFrom: Scalars['String'];
  usedOrganizationId: Scalars['String'];
  usedOrganizationName: Scalars['String'];
};

export type BulkIssuedCouponsStatusQueryVariables = Exact<{
  executionArn: Scalars['String'];
}>;

export type BulkIssuedCouponsStatusQuery = {
  __typename?: 'Query';
  bulkIssuedCouponsStatus: {
    __typename?: 'BulkIssuedCouponsStatusResponse';
    bucket?: string | null | undefined;
    key?: string | null | undefined;
    status: BulkIssuedCouponStatus;
  };
};

export type BulkShopCsvStatusQueryVariables = Exact<{
  executionArn: Scalars['String'];
}>;

export type BulkShopCsvStatusQuery = {
  __typename?: 'Query';
  bulkShopCsvStatus: {
    __typename?: 'BulkShopCsvStatusResponse';
    cause?: string | null | undefined;
    error?: string | null | undefined;
    status: QueryExecutionStatus;
  };
};

export type CouponQueryVariables = Exact<{
  couponId: Scalars['ID'];
}>;

export type CouponQuery = {
  __typename?: 'Query';
  coupon: {
    __typename?: 'Coupon';
    barcode: string;
    couponCode?: string | null | undefined;
    couponMasterId: string;
    couponName?: string | null | undefined;
    couponUrl: string;
    createdDateTime: string;
    customerShopCode?: string | null | undefined;
    id: string;
    memberShopCode?: string | null | undefined;
    publishedDateTime: string;
    publishedOrganizationId: string;
    publishedOrganizationName: string;
    shopId?: string | null | undefined;
    shopName?: string | null | undefined;
    shopUsedDateTime?: string | null | undefined;
    smartCheckClearingCode?: string | null | undefined;
    smartCheckMemberShopCode?: string | null | undefined;
    smartCheckUsedDateTime?: string | null | undefined;
    smartCheckUsedOrganizationId?: string | null | undefined;
    smartCheckUsedOrganizationName?: string | null | undefined;
    status: CouponStatus;
    terminalCode?: string | null | undefined;
    updatedDateTime: string;
    usedDateTime: string;
    usedFrom: string;
    usedOrganizationId: string;
    usedOrganizationName: string;
  };
};

export type CouponBulkHistoriesQueryVariables = Exact<{
  couponMasterId: Scalars['ID'];
  limit: Scalars['Int'];
  nextToken?: Maybe<Scalars['String']>;
}>;

export type CouponBulkHistoriesQuery = {
  __typename?: 'Query';
  couponBulkHistories: {
    __typename?: 'CouponBulkHistoriesConnection';
    nodes: Array<{
      __typename?: 'CouponBulkHistory';
      bucket: string;
      description?: string | null | undefined;
      id: string;
      issuedNumber?: number | null | undefined;
      key: string;
      publishDateTime: string;
      stateMachineExecutionArn?: string | null | undefined;
    }>;
    pageInfo: { __typename?: 'PageInfo'; nextToken?: string | null | undefined };
  };
};

export type CouponMasterQueryVariables = Exact<{
  couponMasterId: Scalars['ID'];
}>;

export type CouponMasterQuery = {
  __typename?: 'Query';
  couponMaster: {
    __typename?: 'CouponMaster';
    applyWith: ApplyWith;
    available: boolean;
    availableDate?: Array<string> | null | undefined;
    availableDays?: Array<DayType> | null | undefined;
    availableEndTimePeriod?: string | null | undefined;
    availableStartTimePeriod?: string | null | undefined;
    companyImageUrl?: string | null | undefined;
    couponCode: string;
    couponIssuedMaximumNumber?: number | null | undefined;
    couponIssuerBandColor: string;
    couponIssuerTextColor: string;
    couponName: string;
    couponNameBandColor: string;
    couponNameHtml?: string | null | undefined;
    couponNameTextColor: string;
    createAtDateTime: string;
    description?: string | null | undefined;
    displayEndDate: string;
    displayStartDate: string;
    id: string;
    information?: string | null | undefined;
    layoutPattern: CouponLayoutPattern;
    mapUrl?: string | null | undefined;
    meta?: string | null | undefined;
    note?: string | null | undefined;
    noteParagraph?: boolean | null | undefined;
    organizationId: string;
    productImageUrl?: string | null | undefined;
    realTimeIssuedGetEndDateTime?: string | null | undefined;
    realTimeIssuedGetStartDateTime?: string | null | undefined;
    telNumber?: string | null | undefined;
    timeFrame: TimeFrame;
    updateAtDateTime: string;
    validityEndDateTime: string;
    validityStartDateTime?: string | null | undefined;
    applyBarcode: { __typename?: 'ApplyBarcodeContainer'; displayType: BarcodeType; sourceType: SourceType };
    companyImage?: { __typename?: 'ImageContainer'; key?: string | null | undefined } | null | undefined;
    productImage?: { __typename?: 'ImageContainer'; key?: string | null | undefined } | null | undefined;
    smartCheckImage?:
      | {
          __typename?: 'SmartCheckImage';
          smartCheckConfirmButtonImage?:
            | { __typename?: 'ImageContainer'; key?: string | null | undefined }
            | null
            | undefined;
          smartCheckConfirmCancelButtonImage?:
            | { __typename?: 'ImageContainer'; key?: string | null | undefined }
            | null
            | undefined;
          smartCheckForceButtonImage?:
            | { __typename?: 'ImageContainer'; key?: string | null | undefined }
            | null
            | undefined;
          smartCheckHeaderImage?: { __typename?: 'ImageContainer'; key?: string | null | undefined } | null | undefined;
          smartCheckUseButtonImage?:
            | { __typename?: 'ImageContainer'; key?: string | null | undefined }
            | null
            | undefined;
          smartCheckUsedImage?: { __typename?: 'ImageContainer'; key?: string | null | undefined } | null | undefined;
        }
      | null
      | undefined;
    smartCheckImageUrl?:
      | {
          __typename?: 'SmartCheckImageUrl';
          smartCheckConfirmButtonImageUrl?: string | null | undefined;
          smartCheckConfirmCancelButtonImageUrl?: string | null | undefined;
          smartCheckForceButtonImageUrl?: string | null | undefined;
          smartCheckHeaderImageUrl?: string | null | undefined;
          smartCheckUseButtonImageUrl?: string | null | undefined;
          smartCheckUsedImageUrl?: string | null | undefined;
        }
      | null
      | undefined;
    smartCheckSetting?:
      | {
          __typename?: 'SmartCheckSetting';
          countDownMinute?: string | null | undefined;
          smartCheckConfirmText?: string | null | undefined;
          smartCheckCountDownText?: string | null | undefined;
          smartCheckUseButtonBottomText?: string | null | undefined;
          staticBarcodes?:
            | Array<{ __typename?: 'StaticBarcode'; barcode?: string | null | undefined; barcodeType: BarcodeType }>
            | null
            | undefined;
        }
      | null
      | undefined;
  };
};

export type CouponMasterImageUrlQueryVariables = Exact<{
  couponMasterId: Scalars['ID'];
}>;

export type CouponMasterImageUrlQuery = {
  __typename?: 'Query';
  couponMasterImageUrl: { __typename?: 'PreSignedUrlResponse'; key?: string | null | undefined; preSignedUrl: string };
};

export type CouponMastersQueryVariables = Exact<{
  couponCode?: Maybe<Scalars['String']>;
  couponName?: Maybe<Scalars['String']>;
  limit: Scalars['Int'];
  nextToken?: Maybe<Scalars['String']>;
  organizationId: Scalars['String'];
}>;

export type CouponMastersQuery = {
  __typename?: 'Query';
  couponMasters: {
    __typename?: 'CouponMasterConnection';
    nodes: Array<{
      __typename?: 'CouponMaster';
      applyWith: ApplyWith;
      available: boolean;
      availableDate?: Array<string> | null | undefined;
      availableDays?: Array<DayType> | null | undefined;
      availableEndTimePeriod?: string | null | undefined;
      availableStartTimePeriod?: string | null | undefined;
      companyImageUrl?: string | null | undefined;
      couponCode: string;
      couponIssuedMaximumNumber?: number | null | undefined;
      couponIssuerBandColor: string;
      couponIssuerTextColor: string;
      couponName: string;
      couponNameBandColor: string;
      couponNameHtml?: string | null | undefined;
      couponNameTextColor: string;
      createAtDateTime: string;
      description?: string | null | undefined;
      displayEndDate: string;
      displayStartDate: string;
      id: string;
      information?: string | null | undefined;
      layoutPattern: CouponLayoutPattern;
      mapUrl?: string | null | undefined;
      meta?: string | null | undefined;
      note?: string | null | undefined;
      noteParagraph?: boolean | null | undefined;
      organizationId: string;
      productImageUrl?: string | null | undefined;
      realTimeIssuedGetEndDateTime?: string | null | undefined;
      realTimeIssuedGetStartDateTime?: string | null | undefined;
      telNumber?: string | null | undefined;
      timeFrame: TimeFrame;
      updateAtDateTime: string;
      validityEndDateTime: string;
      validityStartDateTime?: string | null | undefined;
      applyBarcode: { __typename?: 'ApplyBarcodeContainer'; displayType: BarcodeType; sourceType: SourceType };
      companyImage?: { __typename?: 'ImageContainer'; key?: string | null | undefined } | null | undefined;
      productImage?: { __typename?: 'ImageContainer'; key?: string | null | undefined } | null | undefined;
      smartCheckImage?:
        | {
            __typename?: 'SmartCheckImage';
            smartCheckConfirmButtonImage?:
              | { __typename?: 'ImageContainer'; key?: string | null | undefined }
              | null
              | undefined;
            smartCheckConfirmCancelButtonImage?:
              | { __typename?: 'ImageContainer'; key?: string | null | undefined }
              | null
              | undefined;
            smartCheckForceButtonImage?:
              | { __typename?: 'ImageContainer'; key?: string | null | undefined }
              | null
              | undefined;
            smartCheckHeaderImage?:
              | { __typename?: 'ImageContainer'; key?: string | null | undefined }
              | null
              | undefined;
            smartCheckUseButtonImage?:
              | { __typename?: 'ImageContainer'; key?: string | null | undefined }
              | null
              | undefined;
            smartCheckUsedImage?: { __typename?: 'ImageContainer'; key?: string | null | undefined } | null | undefined;
          }
        | null
        | undefined;
      smartCheckImageUrl?:
        | {
            __typename?: 'SmartCheckImageUrl';
            smartCheckConfirmButtonImageUrl?: string | null | undefined;
            smartCheckConfirmCancelButtonImageUrl?: string | null | undefined;
            smartCheckForceButtonImageUrl?: string | null | undefined;
            smartCheckHeaderImageUrl?: string | null | undefined;
            smartCheckUseButtonImageUrl?: string | null | undefined;
            smartCheckUsedImageUrl?: string | null | undefined;
          }
        | null
        | undefined;
      smartCheckSetting?:
        | {
            __typename?: 'SmartCheckSetting';
            countDownMinute?: string | null | undefined;
            smartCheckConfirmText?: string | null | undefined;
            smartCheckCountDownText?: string | null | undefined;
            smartCheckUseButtonBottomText?: string | null | undefined;
            staticBarcodes?:
              | Array<{ __typename?: 'StaticBarcode'; barcode?: string | null | undefined; barcodeType: BarcodeType }>
              | null
              | undefined;
          }
        | null
        | undefined;
    }>;
    pageInfo: { __typename?: 'PageInfo'; nextToken?: string | null | undefined };
  };
};

export type CouponsQueryVariables = Exact<{
  barcode?: Maybe<Scalars['String']>;
  couponMasterId?: Maybe<Scalars['String']>;
  couponUrl?: Maybe<Scalars['String']>;
  limit: Scalars['Int'];
  nextToken?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
}>;

export type CouponsQuery = {
  __typename?: 'Query';
  coupons: {
    __typename?: 'CouponConnection';
    nodes: Array<{
      __typename?: 'Coupon';
      barcode: string;
      couponCode?: string | null | undefined;
      couponMasterId: string;
      couponName?: string | null | undefined;
      couponUrl: string;
      createdDateTime: string;
      customerShopCode?: string | null | undefined;
      id: string;
      memberShopCode?: string | null | undefined;
      publishedDateTime: string;
      publishedOrganizationId: string;
      publishedOrganizationName: string;
      shopId?: string | null | undefined;
      shopName?: string | null | undefined;
      shopUsedDateTime?: string | null | undefined;
      smartCheckClearingCode?: string | null | undefined;
      smartCheckMemberShopCode?: string | null | undefined;
      smartCheckUsedDateTime?: string | null | undefined;
      smartCheckUsedOrganizationId?: string | null | undefined;
      smartCheckUsedOrganizationName?: string | null | undefined;
      status: CouponStatus;
      terminalCode?: string | null | undefined;
      updatedDateTime: string;
      usedDateTime: string;
      usedFrom: string;
      usedOrganizationId: string;
      usedOrganizationName: string;
    }>;
    pageInfo: { __typename?: 'PageInfo'; nextToken?: string | null | undefined };
  };
};

export type GetBulkShopCsvPutPreSignedUrlQueryVariables = Exact<{ [key: string]: never }>;

export type GetBulkShopCsvPutPreSignedUrlQuery = {
  __typename?: 'Query';
  getBulkShopCsvPutPreSignedUrl: {
    __typename?: 'PreSignedUrlResponse';
    key?: string | null | undefined;
    preSignedUrl: string;
  };
};

export type InvokeCouponDisplayAggregateCsvUrlQueryVariables = Exact<{
  couponCode?: Maybe<Scalars['String']>;
  couponName?: Maybe<Scalars['String']>;
  organizationId: Scalars['String'];
  organizationName?: Maybe<Scalars['String']>;
  searchPolicy: Scalars['String'];
}>;

export type InvokeCouponDisplayAggregateCsvUrlQuery = {
  __typename?: 'Query';
  invokeCouponDisplayAggregateCsvUrl: { __typename?: 'InvokeCouponUsageAggregateResponse'; queryExecutionId: string };
};

export type InvokeCouponDisplayAggregateJsonQueryVariables = Exact<{
  couponCode?: Maybe<Scalars['String']>;
  couponName?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  organizationId: Scalars['String'];
  organizationName?: Maybe<Scalars['String']>;
}>;

export type InvokeCouponDisplayAggregateJsonQuery = {
  __typename?: 'Query';
  invokeCouponDisplayAggregateJson: { __typename?: 'InvokeCouponUsageAggregateResponse'; queryExecutionId: string };
};

export type InvokeCouponDisplayLogCsvUrlQueryVariables = Exact<{
  barcode?: Maybe<Scalars['String']>;
  couponCode?: Maybe<Scalars['String']>;
  couponId?: Maybe<Scalars['String']>;
  couponMasterId?: Maybe<Scalars['String']>;
  couponName?: Maybe<Scalars['String']>;
  isCanceled?: Maybe<Scalars['Boolean']>;
  isIssued?: Maybe<Scalars['Boolean']>;
  isUsed?: Maybe<Scalars['Boolean']>;
  organizationId?: Maybe<Scalars['String']>;
  organizationName?: Maybe<Scalars['String']>;
  searchPolicy: Scalars['String'];
  url?: Maybe<Scalars['String']>;
}>;

export type InvokeCouponDisplayLogCsvUrlQuery = {
  __typename?: 'Query';
  invokeCouponDisplayLogCsvUrl: { __typename?: 'InvokeCouponUsageAggregateResponse'; queryExecutionId: string };
};

export type InvokeCouponDisplayLogJsonQueryVariables = Exact<{
  barcode?: Maybe<Scalars['String']>;
  couponCode?: Maybe<Scalars['String']>;
  couponId?: Maybe<Scalars['String']>;
  couponMasterId?: Maybe<Scalars['String']>;
  couponName?: Maybe<Scalars['String']>;
  isCanceled?: Maybe<Scalars['Boolean']>;
  isIssued?: Maybe<Scalars['Boolean']>;
  isUsed?: Maybe<Scalars['Boolean']>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  organizationId?: Maybe<Scalars['String']>;
  organizationName?: Maybe<Scalars['String']>;
  searchPolicy: Scalars['String'];
  url?: Maybe<Scalars['String']>;
}>;

export type InvokeCouponDisplayLogJsonQuery = {
  __typename?: 'Query';
  invokeCouponDisplayLogJson: { __typename?: 'InvokeCouponUsageAggregateResponse'; queryExecutionId: string };
};

export type InvokeCouponUsageAggregateCsvUrlQueryVariables = Exact<{
  couponCode?: Maybe<Scalars['String']>;
  couponName?: Maybe<Scalars['String']>;
  groupBy: Scalars['String'];
  organizationId: Scalars['String'];
  organizationName?: Maybe<Scalars['String']>;
  searchPolicy: Scalars['String'];
}>;

export type InvokeCouponUsageAggregateCsvUrlQuery = {
  __typename?: 'Query';
  invokeCouponUsageAggregateCsvUrl: { __typename?: 'InvokeCouponUsageAggregateResponse'; queryExecutionId: string };
};

export type InvokeCouponUsageAggregateJsonQueryVariables = Exact<{
  couponCode?: Maybe<Scalars['String']>;
  couponName?: Maybe<Scalars['String']>;
  groupBy: Scalars['String'];
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  organizationId: Scalars['String'];
  organizationName?: Maybe<Scalars['String']>;
}>;

export type InvokeCouponUsageAggregateJsonQuery = {
  __typename?: 'Query';
  invokeCouponUsageAggregateJson: { __typename?: 'InvokeCouponUsageAggregateResponse'; queryExecutionId: string };
};

export type InvokeCouponUsageLogCsvQueryVariables = Exact<{
  barcode?: Maybe<Scalars['String']>;
  couponCode?: Maybe<Scalars['String']>;
  couponId?: Maybe<Scalars['String']>;
  couponMasterId?: Maybe<Scalars['String']>;
  couponName?: Maybe<Scalars['String']>;
  isCanceled?: Maybe<Scalars['Boolean']>;
  isIssued?: Maybe<Scalars['Boolean']>;
  isUsed?: Maybe<Scalars['Boolean']>;
  organizationId?: Maybe<Scalars['String']>;
  organizationName?: Maybe<Scalars['String']>;
  searchPolicy: Scalars['String'];
  url?: Maybe<Scalars['String']>;
}>;

export type InvokeCouponUsageLogCsvQuery = {
  __typename?: 'Query';
  invokeCouponUsageLogCsv: { __typename?: 'InvokeCouponUsageAggregateResponse'; queryExecutionId: string };
};

export type InvokeCouponUsageLogJsonQueryVariables = Exact<{
  barcode?: Maybe<Scalars['String']>;
  couponCode?: Maybe<Scalars['String']>;
  couponId?: Maybe<Scalars['String']>;
  couponMasterId?: Maybe<Scalars['String']>;
  couponName?: Maybe<Scalars['String']>;
  isCanceled?: Maybe<Scalars['Boolean']>;
  isIssued?: Maybe<Scalars['Boolean']>;
  isUsed?: Maybe<Scalars['Boolean']>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  organizationId?: Maybe<Scalars['String']>;
  organizationName?: Maybe<Scalars['String']>;
  searchPolicy: Scalars['String'];
  url?: Maybe<Scalars['String']>;
}>;

export type InvokeCouponUsageLogJsonQuery = {
  __typename?: 'Query';
  invokeCouponUsageLogJson: { __typename?: 'InvokeCouponUsageAggregateResponse'; queryExecutionId: string };
};

export type InvokeShopCsvUrlQueryVariables = Exact<{
  parentId: Scalars['String'];
}>;

export type InvokeShopCsvUrlQuery = {
  __typename?: 'Query';
  invokeShopCsvUrl: { __typename?: 'InvokeShopCsvUrlResponse'; queryExecutionId: string };
};

export type OrganizationQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type OrganizationQuery = {
  __typename?: 'Query';
  organization: {
    __typename?: 'Organization';
    billing?: string | null | undefined;
    customerShopCode?: string | null | undefined;
    id: string;
    memberShopCode?: string | null | undefined;
    name: string;
    parentId?: string | null | undefined;
    shopCode?: string | null | undefined;
    shopNumber?: string | null | undefined;
    simpleClearingCode?: string | null | undefined;
    type: OrganizationType;
  };
};

export type OrganizationChildrenQueryVariables = Exact<{
  query: SearchOrganizationChildrenInput;
}>;

export type OrganizationChildrenQuery = {
  __typename?: 'Query';
  organizationChildren: {
    __typename?: 'PaginatedOrganizations';
    nextToken?: string | null | undefined;
    items?:
      | Array<{
          __typename?: 'Organization';
          billing?: string | null | undefined;
          customerShopCode?: string | null | undefined;
          id: string;
          memberShopCode?: string | null | undefined;
          name: string;
          parentId?: string | null | undefined;
          shopCode?: string | null | undefined;
          shopNumber?: string | null | undefined;
          simpleClearingCode?: string | null | undefined;
          type: OrganizationType;
        }>
      | null
      | undefined;
  };
};

export type OrganizationSuiteQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type OrganizationSuiteQuery = {
  __typename?: 'Query';
  organizationSuite: {
    __typename?: 'OrganizationSuite';
    id: string;
    apply: {
      __typename?: 'ApplySuite';
      with: ApplyWith;
      barcode: { __typename?: 'ApplyBarcodeContainer'; displayType: BarcodeType; sourceType: SourceType };
    };
    takeOver: { __typename?: 'TakeOverSuite'; apply: string; timeFrame: string };
    timeFrame: { __typename?: 'TimeFrameSuite'; available: boolean };
  };
};

export type PollCouponDisplayAggregateCsvUrlQueryVariables = Exact<{
  queryExecutionId: Scalars['String'];
}>;

export type PollCouponDisplayAggregateCsvUrlQuery = {
  __typename?: 'Query';
  pollCouponDisplayAggregateCsvUrl: {
    __typename?: 'CouponUsageAggregateCsvUrlResponse';
    preSignedUrl: string;
    queryExecutionId: string;
    status: QueryExecutionStatus;
  };
};

export type PollCouponDisplayAggregateJsonQueryVariables = Exact<{
  queryExecutionId: Scalars['String'];
}>;

export type PollCouponDisplayAggregateJsonQuery = {
  __typename?: 'Query';
  pollCouponDisplayAggregateJson: {
    __typename?: 'CouponUsageAggregateJsonConnection';
    queryExecutionId: string;
    status: QueryExecutionStatus;
    nodes?:
      | Array<{
          __typename?: 'CouponUsage';
          canceledCount: number;
          couponCode?: string | null | undefined;
          couponMasterId?: string | null | undefined;
          couponName?: string | null | undefined;
          displayedCount?: number | null | undefined;
          organizationId?: string | null | undefined;
          organizationName?: string | null | undefined;
          ownPublishedCount?: number | null | undefined;
          ownUsedCount?: number | null | undefined;
          publishedCount: number;
          usedCount: number;
        }>
      | null
      | undefined;
    pageInfo?: { __typename?: 'PageInfo'; nextToken?: string | null | undefined } | null | undefined;
  };
};

export type PollCouponDisplayLogCsvUrlQueryVariables = Exact<{
  queryExecutionId: Scalars['String'];
}>;

export type PollCouponDisplayLogCsvUrlQuery = {
  __typename?: 'Query';
  pollCouponDisplayLogCsvUrl: {
    __typename?: 'CouponUsageAggregateCsvUrlResponse';
    preSignedUrl: string;
    queryExecutionId: string;
    status: QueryExecutionStatus;
  };
};

export type PollCouponDisplayLogJsonQueryVariables = Exact<{
  queryExecutionId: Scalars['String'];
}>;

export type PollCouponDisplayLogJsonQuery = {
  __typename?: 'Query';
  pollCouponDisplayLogJson: {
    __typename?: 'CouponUsageLogJsonConnection';
    queryExecutionId: string;
    status: QueryExecutionStatus;
    nodes?:
      | Array<{
          __typename?: 'CouponUsageLog';
          barcode?: string | null | undefined;
          couponCode: string;
          couponId: string;
          couponMasterId: string;
          couponName: string;
          createAtMillis: string;
          customerShopCode?: string | null | undefined;
          displayedCount?: number | null | undefined;
          memberShopCode?: string | null | undefined;
          operateFrom: string;
          organizationName: string;
          status?: string | null | undefined;
          terminalNumber?: string | null | undefined;
          url?: string | null | undefined;
          usedShopName?: string | null | undefined;
          userAgent?: string | null | undefined;
          userId?: string | null | undefined;
          userName?: string | null | undefined;
        }>
      | null
      | undefined;
    pageInfo?: { __typename?: 'PageInfo'; nextToken?: string | null | undefined } | null | undefined;
  };
};

export type PollCouponUsageAggregateCsvUrlQueryVariables = Exact<{
  groupBy: Scalars['String'];
  queryExecutionId: Scalars['String'];
}>;

export type PollCouponUsageAggregateCsvUrlQuery = {
  __typename?: 'Query';
  pollCouponUsageAggregateCsvUrl: {
    __typename?: 'CouponUsageAggregateCsvUrlResponse';
    preSignedUrl: string;
    queryExecutionId: string;
    status: QueryExecutionStatus;
  };
};

export type PollCouponUsageAggregateJsonQueryVariables = Exact<{
  groupBy: Scalars['String'];
  queryExecutionId: Scalars['String'];
}>;

export type PollCouponUsageAggregateJsonQuery = {
  __typename?: 'Query';
  pollCouponUsageAggregateJson: {
    __typename?: 'CouponUsageAggregateJsonConnection';
    queryExecutionId: string;
    status: QueryExecutionStatus;
    nodes?:
      | Array<{
          __typename?: 'CouponUsage';
          canceledCount: number;
          couponCode?: string | null | undefined;
          couponMasterId?: string | null | undefined;
          couponName?: string | null | undefined;
          displayedCount?: number | null | undefined;
          organizationId?: string | null | undefined;
          organizationName?: string | null | undefined;
          ownPublishedCount?: number | null | undefined;
          ownUsedCount?: number | null | undefined;
          publishedCount: number;
          usedCount: number;
        }>
      | null
      | undefined;
    pageInfo?: { __typename?: 'PageInfo'; nextToken?: string | null | undefined } | null | undefined;
  };
};

export type PollCouponUsageLogCsvQueryVariables = Exact<{
  queryExecutionId: Scalars['String'];
}>;

export type PollCouponUsageLogCsvQuery = {
  __typename?: 'Query';
  pollCouponUsageLogCsv: {
    __typename?: 'CouponUsageAggregateCsvUrlResponse';
    preSignedUrl: string;
    queryExecutionId: string;
    status: QueryExecutionStatus;
  };
};

export type PollCouponUsageLogJsonQueryVariables = Exact<{
  queryExecutionId: Scalars['String'];
}>;

export type PollCouponUsageLogJsonQuery = {
  __typename?: 'Query';
  pollCouponUsageLogJson: {
    __typename?: 'CouponUsageLogJsonConnection';
    queryExecutionId: string;
    status: QueryExecutionStatus;
    nodes?:
      | Array<{
          __typename?: 'CouponUsageLog';
          barcode?: string | null | undefined;
          couponCode: string;
          couponId: string;
          couponMasterId: string;
          couponName: string;
          createAtMillis: string;
          customerShopCode?: string | null | undefined;
          displayedCount?: number | null | undefined;
          memberShopCode?: string | null | undefined;
          operateFrom: string;
          organizationName: string;
          status?: string | null | undefined;
          terminalNumber?: string | null | undefined;
          url?: string | null | undefined;
          usedShopName?: string | null | undefined;
          userAgent?: string | null | undefined;
          userId?: string | null | undefined;
          userName?: string | null | undefined;
        }>
      | null
      | undefined;
    pageInfo?: { __typename?: 'PageInfo'; nextToken?: string | null | undefined } | null | undefined;
  };
};

export type PollShopCsvUrlQueryVariables = Exact<{
  queryExecutionId: Scalars['String'];
}>;

export type PollShopCsvUrlQuery = {
  __typename?: 'Query';
  pollShopCsvUrl: {
    __typename?: 'PollShopCsvUrlResponse';
    queryExecutionId: string;
    signedUrl?: string | null | undefined;
    status: QueryExecutionStatus;
  };
};

export type PreSignedGetUrlQueryVariables = Exact<{
  bucket: Scalars['String'];
  fileName?: Maybe<Scalars['String']>;
  key: Scalars['String'];
}>;

export type PreSignedGetUrlQuery = {
  __typename?: 'Query';
  preSignedGetUrl: { __typename?: 'PreSignedUrlResponse'; key?: string | null | undefined; preSignedUrl: string };
};

export type RolesQueryVariables = Exact<{ [key: string]: never }>;

export type RolesQuery = { __typename?: 'Query'; roles: Array<{ __typename?: 'Role'; id: string; name: string }> };

export type ShopQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type ShopQuery = {
  __typename?: 'Query';
  shop: {
    __typename?: 'Shop';
    billing: string;
    customerShopCode?: string | null | undefined;
    endDate?: string | null | undefined;
    id: string;
    name: string;
    parentId: string;
    shopCode: string;
    shopNumber: string;
    simpleClearingCode?: string | null | undefined;
    startDate?: string | null | undefined;
    type: OrganizationType;
  };
};

export type UserQueryVariables = Exact<{
  id?: Maybe<Scalars['String']>;
}>;

export type UserQuery = {
  __typename?: 'Query';
  user: {
    __typename?: 'User';
    createAtDateTime: string;
    email: string;
    id: string;
    isAdministrator: boolean;
    lastLoginDateTime?: string | null | undefined;
    name: string;
    organizationId?: string | null | undefined;
    permissions: Array<string | null | undefined>;
    role: string;
    roleId: string;
    updateAtDateTime: string;
  };
};

export type UsersQueryVariables = Exact<{
  email?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  organizationId: Scalars['String'];
  page?: Maybe<Scalars['String']>;
}>;

export type UsersQuery = {
  __typename?: 'Query';
  users: {
    __typename?: 'UsersConnection';
    edges: Array<{
      __typename?: 'UserEdge';
      cursor: string;
      node: {
        __typename?: 'User';
        createAtDateTime: string;
        email: string;
        id: string;
        isAdministrator: boolean;
        lastLoginDateTime?: string | null | undefined;
        name: string;
        organizationId?: string | null | undefined;
        permissions: Array<string | null | undefined>;
        role: string;
        roleId: string;
        updateAtDateTime: string;
      };
    }>;
    pageInfo: {
      __typename?: 'RelayStylePageInfo';
      endCursor: string;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      length: number;
      limit: number;
      startCursor: string;
    };
  };
};

export type BulkIssueCouponsMutationVariables = Exact<{
  input?: Maybe<BulkIssueCouponsInput>;
}>;

export type BulkIssueCouponsMutation = {
  __typename?: 'Mutation';
  bulkIssueCoupons: { __typename?: 'BulkIssuedCouponsExecution'; executionArn: string };
};

export type BulkShopCsvMutationVariables = Exact<{
  input: BulkShopCsvInput;
}>;

export type BulkShopCsvMutation = {
  __typename?: 'Mutation';
  bulkShopCsv: { __typename?: 'BulkShopCsvExecution'; executionArn: string };
};

export type CouponMasterUploadImageUrlMutationVariables = Exact<{
  input: UploadImageInput;
}>;

export type CouponMasterUploadImageUrlMutation = {
  __typename?: 'Mutation';
  couponMasterUploadImageUrl: {
    __typename?: 'PreSignedImageUrlResponse';
    imageType: ImageType;
    key: string;
    preSignedUrl: string;
  };
};

export type CreateCouponMasterMutationVariables = Exact<{
  input?: Maybe<CreateCouponMasterInput>;
}>;

export type CreateCouponMasterMutation = {
  __typename?: 'Mutation';
  createCouponMaster: {
    __typename?: 'CouponMaster';
    applyWith: ApplyWith;
    available: boolean;
    availableDate?: Array<string> | null | undefined;
    availableDays?: Array<DayType> | null | undefined;
    availableEndTimePeriod?: string | null | undefined;
    availableStartTimePeriod?: string | null | undefined;
    companyImageUrl?: string | null | undefined;
    couponCode: string;
    couponIssuedMaximumNumber?: number | null | undefined;
    couponIssuerBandColor: string;
    couponIssuerTextColor: string;
    couponName: string;
    couponNameBandColor: string;
    couponNameHtml?: string | null | undefined;
    couponNameTextColor: string;
    createAtDateTime: string;
    description?: string | null | undefined;
    displayEndDate: string;
    displayStartDate: string;
    id: string;
    information?: string | null | undefined;
    layoutPattern: CouponLayoutPattern;
    mapUrl?: string | null | undefined;
    meta?: string | null | undefined;
    note?: string | null | undefined;
    noteParagraph?: boolean | null | undefined;
    organizationId: string;
    productImageUrl?: string | null | undefined;
    realTimeIssuedGetEndDateTime?: string | null | undefined;
    realTimeIssuedGetStartDateTime?: string | null | undefined;
    telNumber?: string | null | undefined;
    timeFrame: TimeFrame;
    updateAtDateTime: string;
    validityEndDateTime: string;
    validityStartDateTime?: string | null | undefined;
    applyBarcode: { __typename?: 'ApplyBarcodeContainer'; displayType: BarcodeType; sourceType: SourceType };
    companyImage?: { __typename?: 'ImageContainer'; key?: string | null | undefined } | null | undefined;
    productImage?: { __typename?: 'ImageContainer'; key?: string | null | undefined } | null | undefined;
    smartCheckImage?:
      | {
          __typename?: 'SmartCheckImage';
          smartCheckConfirmButtonImage?:
            | { __typename?: 'ImageContainer'; key?: string | null | undefined }
            | null
            | undefined;
          smartCheckConfirmCancelButtonImage?:
            | { __typename?: 'ImageContainer'; key?: string | null | undefined }
            | null
            | undefined;
          smartCheckForceButtonImage?:
            | { __typename?: 'ImageContainer'; key?: string | null | undefined }
            | null
            | undefined;
          smartCheckHeaderImage?: { __typename?: 'ImageContainer'; key?: string | null | undefined } | null | undefined;
          smartCheckUseButtonImage?:
            | { __typename?: 'ImageContainer'; key?: string | null | undefined }
            | null
            | undefined;
          smartCheckUsedImage?: { __typename?: 'ImageContainer'; key?: string | null | undefined } | null | undefined;
        }
      | null
      | undefined;
    smartCheckImageUrl?:
      | {
          __typename?: 'SmartCheckImageUrl';
          smartCheckConfirmButtonImageUrl?: string | null | undefined;
          smartCheckConfirmCancelButtonImageUrl?: string | null | undefined;
          smartCheckForceButtonImageUrl?: string | null | undefined;
          smartCheckHeaderImageUrl?: string | null | undefined;
          smartCheckUseButtonImageUrl?: string | null | undefined;
          smartCheckUsedImageUrl?: string | null | undefined;
        }
      | null
      | undefined;
    smartCheckSetting?:
      | {
          __typename?: 'SmartCheckSetting';
          countDownMinute?: string | null | undefined;
          smartCheckConfirmText?: string | null | undefined;
          smartCheckCountDownText?: string | null | undefined;
          smartCheckUseButtonBottomText?: string | null | undefined;
          staticBarcodes?:
            | Array<{ __typename?: 'StaticBarcode'; barcode?: string | null | undefined; barcodeType: BarcodeType }>
            | null
            | undefined;
        }
      | null
      | undefined;
  };
};

export type CreateShopMutationVariables = Exact<{
  input: CreateShopInput;
}>;

export type CreateShopMutation = {
  __typename?: 'Mutation';
  createShop: {
    __typename?: 'Shop';
    billing: string;
    customerShopCode?: string | null | undefined;
    endDate?: string | null | undefined;
    id: string;
    name: string;
    parentId: string;
    shopCode: string;
    shopNumber: string;
    simpleClearingCode?: string | null | undefined;
    startDate?: string | null | undefined;
    type: OrganizationType;
  };
};

export type CreateShopGroupMutationVariables = Exact<{
  input: CreateShopGroupInput;
}>;

export type CreateShopGroupMutation = {
  __typename?: 'Mutation';
  createShopGroup: {
    __typename?: 'ShopGroup';
    id: string;
    memberShopCode: string;
    name: string;
    parentId?: string | null | undefined;
    type: OrganizationType;
  };
};

export type CreateUserMutationVariables = Exact<{
  input: CreateUserInput;
}>;

export type CreateUserMutation = {
  __typename?: 'Mutation';
  createUser: {
    __typename?: 'User';
    createAtDateTime: string;
    email: string;
    id: string;
    isAdministrator: boolean;
    lastLoginDateTime?: string | null | undefined;
    name: string;
    organizationId?: string | null | undefined;
    permissions: Array<string | null | undefined>;
    role: string;
    roleId: string;
    updateAtDateTime: string;
  };
};

export type DeleteCouponMasterMutationVariables = Exact<{
  input?: Maybe<DeleteCouponMasterInput>;
}>;

export type DeleteCouponMasterMutation = {
  __typename?: 'Mutation';
  deleteCouponMaster: {
    __typename?: 'CouponMaster';
    applyWith: ApplyWith;
    available: boolean;
    availableDate?: Array<string> | null | undefined;
    availableDays?: Array<DayType> | null | undefined;
    availableEndTimePeriod?: string | null | undefined;
    availableStartTimePeriod?: string | null | undefined;
    companyImageUrl?: string | null | undefined;
    couponCode: string;
    couponIssuedMaximumNumber?: number | null | undefined;
    couponIssuerBandColor: string;
    couponIssuerTextColor: string;
    couponName: string;
    couponNameBandColor: string;
    couponNameHtml?: string | null | undefined;
    couponNameTextColor: string;
    createAtDateTime: string;
    description?: string | null | undefined;
    displayEndDate: string;
    displayStartDate: string;
    id: string;
    information?: string | null | undefined;
    layoutPattern: CouponLayoutPattern;
    mapUrl?: string | null | undefined;
    meta?: string | null | undefined;
    note?: string | null | undefined;
    noteParagraph?: boolean | null | undefined;
    organizationId: string;
    productImageUrl?: string | null | undefined;
    realTimeIssuedGetEndDateTime?: string | null | undefined;
    realTimeIssuedGetStartDateTime?: string | null | undefined;
    telNumber?: string | null | undefined;
    timeFrame: TimeFrame;
    updateAtDateTime: string;
    validityEndDateTime: string;
    validityStartDateTime?: string | null | undefined;
    applyBarcode: { __typename?: 'ApplyBarcodeContainer'; displayType: BarcodeType; sourceType: SourceType };
    companyImage?: { __typename?: 'ImageContainer'; key?: string | null | undefined } | null | undefined;
    productImage?: { __typename?: 'ImageContainer'; key?: string | null | undefined } | null | undefined;
    smartCheckImage?:
      | {
          __typename?: 'SmartCheckImage';
          smartCheckConfirmButtonImage?:
            | { __typename?: 'ImageContainer'; key?: string | null | undefined }
            | null
            | undefined;
          smartCheckConfirmCancelButtonImage?:
            | { __typename?: 'ImageContainer'; key?: string | null | undefined }
            | null
            | undefined;
          smartCheckForceButtonImage?:
            | { __typename?: 'ImageContainer'; key?: string | null | undefined }
            | null
            | undefined;
          smartCheckHeaderImage?: { __typename?: 'ImageContainer'; key?: string | null | undefined } | null | undefined;
          smartCheckUseButtonImage?:
            | { __typename?: 'ImageContainer'; key?: string | null | undefined }
            | null
            | undefined;
          smartCheckUsedImage?: { __typename?: 'ImageContainer'; key?: string | null | undefined } | null | undefined;
        }
      | null
      | undefined;
    smartCheckImageUrl?:
      | {
          __typename?: 'SmartCheckImageUrl';
          smartCheckConfirmButtonImageUrl?: string | null | undefined;
          smartCheckConfirmCancelButtonImageUrl?: string | null | undefined;
          smartCheckForceButtonImageUrl?: string | null | undefined;
          smartCheckHeaderImageUrl?: string | null | undefined;
          smartCheckUseButtonImageUrl?: string | null | undefined;
          smartCheckUsedImageUrl?: string | null | undefined;
        }
      | null
      | undefined;
    smartCheckSetting?:
      | {
          __typename?: 'SmartCheckSetting';
          countDownMinute?: string | null | undefined;
          smartCheckConfirmText?: string | null | undefined;
          smartCheckCountDownText?: string | null | undefined;
          smartCheckUseButtonBottomText?: string | null | undefined;
          staticBarcodes?:
            | Array<{ __typename?: 'StaticBarcode'; barcode?: string | null | undefined; barcodeType: BarcodeType }>
            | null
            | undefined;
        }
      | null
      | undefined;
  };
};

export type DeleteShopMutationVariables = Exact<{
  id: Scalars['String'];
}>;

export type DeleteShopMutation = { __typename?: 'Mutation'; deleteShop: string };

export type DeleteShopGroupMutationVariables = Exact<{
  id: Scalars['String'];
  type: OrganizationType;
}>;

export type DeleteShopGroupMutation = { __typename?: 'Mutation'; deleteShopGroup: string };

export type DeleteUserMutationVariables = Exact<{
  id: Scalars['String'];
}>;

export type DeleteUserMutation = { __typename?: 'Mutation'; deleteUser: string };

export type UpdateCouponMasterMutationVariables = Exact<{
  id: Scalars['String'];
  input?: Maybe<UpdateCouponMasterInput>;
}>;

export type UpdateCouponMasterMutation = {
  __typename?: 'Mutation';
  updateCouponMaster: {
    __typename?: 'CouponMaster';
    applyWith: ApplyWith;
    available: boolean;
    availableDate?: Array<string> | null | undefined;
    availableDays?: Array<DayType> | null | undefined;
    availableEndTimePeriod?: string | null | undefined;
    availableStartTimePeriod?: string | null | undefined;
    companyImageUrl?: string | null | undefined;
    couponCode: string;
    couponIssuedMaximumNumber?: number | null | undefined;
    couponIssuerBandColor: string;
    couponIssuerTextColor: string;
    couponName: string;
    couponNameBandColor: string;
    couponNameHtml?: string | null | undefined;
    couponNameTextColor: string;
    createAtDateTime: string;
    description?: string | null | undefined;
    displayEndDate: string;
    displayStartDate: string;
    id: string;
    information?: string | null | undefined;
    layoutPattern: CouponLayoutPattern;
    mapUrl?: string | null | undefined;
    meta?: string | null | undefined;
    note?: string | null | undefined;
    noteParagraph?: boolean | null | undefined;
    organizationId: string;
    productImageUrl?: string | null | undefined;
    realTimeIssuedGetEndDateTime?: string | null | undefined;
    realTimeIssuedGetStartDateTime?: string | null | undefined;
    telNumber?: string | null | undefined;
    timeFrame: TimeFrame;
    updateAtDateTime: string;
    validityEndDateTime: string;
    validityStartDateTime?: string | null | undefined;
    applyBarcode: { __typename?: 'ApplyBarcodeContainer'; displayType: BarcodeType; sourceType: SourceType };
    companyImage?: { __typename?: 'ImageContainer'; key?: string | null | undefined } | null | undefined;
    productImage?: { __typename?: 'ImageContainer'; key?: string | null | undefined } | null | undefined;
    smartCheckImage?:
      | {
          __typename?: 'SmartCheckImage';
          smartCheckConfirmButtonImage?:
            | { __typename?: 'ImageContainer'; key?: string | null | undefined }
            | null
            | undefined;
          smartCheckConfirmCancelButtonImage?:
            | { __typename?: 'ImageContainer'; key?: string | null | undefined }
            | null
            | undefined;
          smartCheckForceButtonImage?:
            | { __typename?: 'ImageContainer'; key?: string | null | undefined }
            | null
            | undefined;
          smartCheckHeaderImage?: { __typename?: 'ImageContainer'; key?: string | null | undefined } | null | undefined;
          smartCheckUseButtonImage?:
            | { __typename?: 'ImageContainer'; key?: string | null | undefined }
            | null
            | undefined;
          smartCheckUsedImage?: { __typename?: 'ImageContainer'; key?: string | null | undefined } | null | undefined;
        }
      | null
      | undefined;
    smartCheckImageUrl?:
      | {
          __typename?: 'SmartCheckImageUrl';
          smartCheckConfirmButtonImageUrl?: string | null | undefined;
          smartCheckConfirmCancelButtonImageUrl?: string | null | undefined;
          smartCheckForceButtonImageUrl?: string | null | undefined;
          smartCheckHeaderImageUrl?: string | null | undefined;
          smartCheckUseButtonImageUrl?: string | null | undefined;
          smartCheckUsedImageUrl?: string | null | undefined;
        }
      | null
      | undefined;
    smartCheckSetting?:
      | {
          __typename?: 'SmartCheckSetting';
          countDownMinute?: string | null | undefined;
          smartCheckConfirmText?: string | null | undefined;
          smartCheckCountDownText?: string | null | undefined;
          smartCheckUseButtonBottomText?: string | null | undefined;
          staticBarcodes?:
            | Array<{ __typename?: 'StaticBarcode'; barcode?: string | null | undefined; barcodeType: BarcodeType }>
            | null
            | undefined;
        }
      | null
      | undefined;
  };
};

export type UpdateCouponStatusMutationVariables = Exact<{
  input?: Maybe<UpdateCouponStatusInput>;
}>;

export type UpdateCouponStatusMutation = { __typename?: 'Mutation'; updateCouponStatus: CouponStatus };

export type UpdateShopMutationVariables = Exact<{
  id: Scalars['String'];
  input: UpdateShopInput;
}>;

export type UpdateShopMutation = {
  __typename?: 'Mutation';
  updateShop: {
    __typename?: 'Shop';
    billing: string;
    customerShopCode?: string | null | undefined;
    endDate?: string | null | undefined;
    id: string;
    name: string;
    parentId: string;
    shopCode: string;
    shopNumber: string;
    simpleClearingCode?: string | null | undefined;
    startDate?: string | null | undefined;
    type: OrganizationType;
  };
};

export type UpdateShopGroupMutationVariables = Exact<{
  id: Scalars['String'];
  input: UpdateShopGroupInput;
}>;

export type UpdateShopGroupMutation = {
  __typename?: 'Mutation';
  updateShopGroup: {
    __typename?: 'ShopGroup';
    id: string;
    memberShopCode: string;
    name: string;
    parentId?: string | null | undefined;
    type: OrganizationType;
  };
};

export type UpdateUserNameMutationVariables = Exact<{
  id: Scalars['String'];
  name: Scalars['String'];
}>;

export type UpdateUserNameMutation = {
  __typename?: 'Mutation';
  updateUserName: {
    __typename?: 'User';
    createAtDateTime: string;
    email: string;
    id: string;
    isAdministrator: boolean;
    lastLoginDateTime?: string | null | undefined;
    name: string;
    organizationId?: string | null | undefined;
    permissions: Array<string | null | undefined>;
    role: string;
    roleId: string;
    updateAtDateTime: string;
  };
};

export type UpdateUserPasswordMutationVariables = Exact<{
  id: Scalars['String'];
  password: Scalars['String'];
}>;

export type UpdateUserPasswordMutation = {
  __typename?: 'Mutation';
  updateUserPassword: {
    __typename?: 'User';
    createAtDateTime: string;
    email: string;
    id: string;
    isAdministrator: boolean;
    lastLoginDateTime?: string | null | undefined;
    name: string;
    organizationId?: string | null | undefined;
    permissions: Array<string | null | undefined>;
    role: string;
    roleId: string;
    updateAtDateTime: string;
  };
};

export type UpdateUserRoleMutationVariables = Exact<{
  id: Scalars['String'];
  isAdministrator: Scalars['Boolean'];
  roleId: Scalars['String'];
}>;

export type UpdateUserRoleMutation = {
  __typename?: 'Mutation';
  updateUserRole: {
    __typename?: 'User';
    createAtDateTime: string;
    email: string;
    id: string;
    isAdministrator: boolean;
    lastLoginDateTime?: string | null | undefined;
    name: string;
    organizationId?: string | null | undefined;
    permissions: Array<string | null | undefined>;
    role: string;
    roleId: string;
    updateAtDateTime: string;
  };
};

export type UpsertOrganizationSuiteMutationVariables = Exact<{
  category: SuiteCategory;
  organizationId: Scalars['String'];
  suite: UpsertOrganizationSuiteInput;
}>;

export type UpsertOrganizationSuiteMutation = {
  __typename?: 'Mutation';
  upsertOrganizationSuite: {
    __typename?: 'OrganizationSuite';
    id: string;
    apply: {
      __typename?: 'ApplySuite';
      with: ApplyWith;
      barcode: { __typename?: 'ApplyBarcodeContainer'; displayType: BarcodeType; sourceType: SourceType };
    };
    takeOver: { __typename?: 'TakeOverSuite'; apply: string; timeFrame: string };
    timeFrame: { __typename?: 'TimeFrameSuite'; available: boolean };
  };
};

export const BulkIssuedCouponsStatusDocument = gql`
  query BulkIssuedCouponsStatus($executionArn: String!) {
    bulkIssuedCouponsStatus(executionArn: $executionArn) {
      bucket
      key
      status
    }
  }
`;

/**
 * __useBulkIssuedCouponsStatusQuery__
 *
 * To run a query within a React component, call `useBulkIssuedCouponsStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useBulkIssuedCouponsStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBulkIssuedCouponsStatusQuery({
 *   variables: {
 *      executionArn: // value for 'executionArn'
 *   },
 * });
 */
export function useBulkIssuedCouponsStatusQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<BulkIssuedCouponsStatusQuery, BulkIssuedCouponsStatusQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<BulkIssuedCouponsStatusQuery, BulkIssuedCouponsStatusQueryVariables>(
    BulkIssuedCouponsStatusDocument,
    options
  );
}
export function useBulkIssuedCouponsStatusLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    BulkIssuedCouponsStatusQuery,
    BulkIssuedCouponsStatusQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<BulkIssuedCouponsStatusQuery, BulkIssuedCouponsStatusQueryVariables>(
    BulkIssuedCouponsStatusDocument,
    options
  );
}
export type BulkIssuedCouponsStatusQueryHookResult = ReturnType<typeof useBulkIssuedCouponsStatusQuery>;
export type BulkIssuedCouponsStatusLazyQueryHookResult = ReturnType<typeof useBulkIssuedCouponsStatusLazyQuery>;
export type BulkIssuedCouponsStatusQueryResult = ApolloReactCommon.QueryResult<
  BulkIssuedCouponsStatusQuery,
  BulkIssuedCouponsStatusQueryVariables
>;
export const BulkShopCsvStatusDocument = gql`
  query BulkShopCsvStatus($executionArn: String!) {
    bulkShopCsvStatus(executionArn: $executionArn) {
      cause
      error
      status
    }
  }
`;

/**
 * __useBulkShopCsvStatusQuery__
 *
 * To run a query within a React component, call `useBulkShopCsvStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useBulkShopCsvStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBulkShopCsvStatusQuery({
 *   variables: {
 *      executionArn: // value for 'executionArn'
 *   },
 * });
 */
export function useBulkShopCsvStatusQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<BulkShopCsvStatusQuery, BulkShopCsvStatusQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<BulkShopCsvStatusQuery, BulkShopCsvStatusQueryVariables>(
    BulkShopCsvStatusDocument,
    options
  );
}
export function useBulkShopCsvStatusLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BulkShopCsvStatusQuery, BulkShopCsvStatusQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<BulkShopCsvStatusQuery, BulkShopCsvStatusQueryVariables>(
    BulkShopCsvStatusDocument,
    options
  );
}
export type BulkShopCsvStatusQueryHookResult = ReturnType<typeof useBulkShopCsvStatusQuery>;
export type BulkShopCsvStatusLazyQueryHookResult = ReturnType<typeof useBulkShopCsvStatusLazyQuery>;
export type BulkShopCsvStatusQueryResult = ApolloReactCommon.QueryResult<
  BulkShopCsvStatusQuery,
  BulkShopCsvStatusQueryVariables
>;
export const CouponDocument = gql`
  query Coupon($couponId: ID!) {
    coupon(couponId: $couponId) {
      barcode
      couponCode
      couponMasterId
      couponName
      couponUrl
      createdDateTime
      customerShopCode
      id
      memberShopCode
      publishedDateTime
      publishedOrganizationId
      publishedOrganizationName
      shopId
      shopName
      shopUsedDateTime
      smartCheckClearingCode
      smartCheckMemberShopCode
      smartCheckUsedDateTime
      smartCheckUsedOrganizationId
      smartCheckUsedOrganizationName
      status
      terminalCode
      updatedDateTime
      usedDateTime
      usedFrom
      usedOrganizationId
      usedOrganizationName
    }
  }
`;

/**
 * __useCouponQuery__
 *
 * To run a query within a React component, call `useCouponQuery` and pass it any options that fit your needs.
 * When your component renders, `useCouponQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCouponQuery({
 *   variables: {
 *      couponId: // value for 'couponId'
 *   },
 * });
 */
export function useCouponQuery(baseOptions: ApolloReactHooks.QueryHookOptions<CouponQuery, CouponQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<CouponQuery, CouponQueryVariables>(CouponDocument, options);
}
export function useCouponLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CouponQuery, CouponQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<CouponQuery, CouponQueryVariables>(CouponDocument, options);
}
export type CouponQueryHookResult = ReturnType<typeof useCouponQuery>;
export type CouponLazyQueryHookResult = ReturnType<typeof useCouponLazyQuery>;
export type CouponQueryResult = ApolloReactCommon.QueryResult<CouponQuery, CouponQueryVariables>;
export const CouponBulkHistoriesDocument = gql`
  query CouponBulkHistories($couponMasterId: ID!, $limit: Int!, $nextToken: String) {
    couponBulkHistories(couponMasterId: $couponMasterId, limit: $limit, nextToken: $nextToken) {
      nodes {
        bucket
        description
        id
        issuedNumber
        key
        publishDateTime
        stateMachineExecutionArn
      }
      pageInfo {
        nextToken
      }
    }
  }
`;

/**
 * __useCouponBulkHistoriesQuery__
 *
 * To run a query within a React component, call `useCouponBulkHistoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCouponBulkHistoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCouponBulkHistoriesQuery({
 *   variables: {
 *      couponMasterId: // value for 'couponMasterId'
 *      limit: // value for 'limit'
 *      nextToken: // value for 'nextToken'
 *   },
 * });
 */
export function useCouponBulkHistoriesQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<CouponBulkHistoriesQuery, CouponBulkHistoriesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<CouponBulkHistoriesQuery, CouponBulkHistoriesQueryVariables>(
    CouponBulkHistoriesDocument,
    options
  );
}
export function useCouponBulkHistoriesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CouponBulkHistoriesQuery, CouponBulkHistoriesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<CouponBulkHistoriesQuery, CouponBulkHistoriesQueryVariables>(
    CouponBulkHistoriesDocument,
    options
  );
}
export type CouponBulkHistoriesQueryHookResult = ReturnType<typeof useCouponBulkHistoriesQuery>;
export type CouponBulkHistoriesLazyQueryHookResult = ReturnType<typeof useCouponBulkHistoriesLazyQuery>;
export type CouponBulkHistoriesQueryResult = ApolloReactCommon.QueryResult<
  CouponBulkHistoriesQuery,
  CouponBulkHistoriesQueryVariables
>;
export const CouponMasterDocument = gql`
  query CouponMaster($couponMasterId: ID!) {
    couponMaster(couponMasterId: $couponMasterId) {
      applyBarcode {
        displayType
        sourceType
      }
      applyWith
      available
      availableDate
      availableDays
      availableEndTimePeriod
      availableStartTimePeriod
      companyImage {
        key
      }
      companyImageUrl
      couponCode
      couponIssuedMaximumNumber
      couponIssuerBandColor
      couponIssuerTextColor
      couponName
      couponNameBandColor
      couponNameHtml
      couponNameTextColor
      createAtDateTime
      description
      displayEndDate
      displayStartDate
      id
      information
      layoutPattern
      mapUrl
      meta
      note
      noteParagraph
      organizationId
      productImage {
        key
      }
      productImageUrl
      realTimeIssuedGetEndDateTime
      realTimeIssuedGetStartDateTime
      smartCheckImage {
        smartCheckConfirmButtonImage {
          key
        }
        smartCheckConfirmCancelButtonImage {
          key
        }
        smartCheckForceButtonImage {
          key
        }
        smartCheckHeaderImage {
          key
        }
        smartCheckUseButtonImage {
          key
        }
        smartCheckUsedImage {
          key
        }
      }
      smartCheckImageUrl {
        smartCheckConfirmButtonImageUrl
        smartCheckConfirmCancelButtonImageUrl
        smartCheckForceButtonImageUrl
        smartCheckHeaderImageUrl
        smartCheckUseButtonImageUrl
        smartCheckUsedImageUrl
      }
      smartCheckSetting {
        countDownMinute
        smartCheckConfirmText
        smartCheckCountDownText
        smartCheckUseButtonBottomText
        staticBarcodes {
          barcode
          barcodeType
        }
      }
      telNumber
      timeFrame
      updateAtDateTime
      validityEndDateTime
      validityStartDateTime
    }
  }
`;

/**
 * __useCouponMasterQuery__
 *
 * To run a query within a React component, call `useCouponMasterQuery` and pass it any options that fit your needs.
 * When your component renders, `useCouponMasterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCouponMasterQuery({
 *   variables: {
 *      couponMasterId: // value for 'couponMasterId'
 *   },
 * });
 */
export function useCouponMasterQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<CouponMasterQuery, CouponMasterQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<CouponMasterQuery, CouponMasterQueryVariables>(CouponMasterDocument, options);
}
export function useCouponMasterLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CouponMasterQuery, CouponMasterQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<CouponMasterQuery, CouponMasterQueryVariables>(CouponMasterDocument, options);
}
export type CouponMasterQueryHookResult = ReturnType<typeof useCouponMasterQuery>;
export type CouponMasterLazyQueryHookResult = ReturnType<typeof useCouponMasterLazyQuery>;
export type CouponMasterQueryResult = ApolloReactCommon.QueryResult<CouponMasterQuery, CouponMasterQueryVariables>;
export const CouponMasterImageUrlDocument = gql`
  query CouponMasterImageUrl($couponMasterId: ID!) {
    couponMasterImageUrl(couponMasterId: $couponMasterId) {
      key
      preSignedUrl
    }
  }
`;

/**
 * __useCouponMasterImageUrlQuery__
 *
 * To run a query within a React component, call `useCouponMasterImageUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useCouponMasterImageUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCouponMasterImageUrlQuery({
 *   variables: {
 *      couponMasterId: // value for 'couponMasterId'
 *   },
 * });
 */
export function useCouponMasterImageUrlQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<CouponMasterImageUrlQuery, CouponMasterImageUrlQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<CouponMasterImageUrlQuery, CouponMasterImageUrlQueryVariables>(
    CouponMasterImageUrlDocument,
    options
  );
}
export function useCouponMasterImageUrlLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CouponMasterImageUrlQuery, CouponMasterImageUrlQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<CouponMasterImageUrlQuery, CouponMasterImageUrlQueryVariables>(
    CouponMasterImageUrlDocument,
    options
  );
}
export type CouponMasterImageUrlQueryHookResult = ReturnType<typeof useCouponMasterImageUrlQuery>;
export type CouponMasterImageUrlLazyQueryHookResult = ReturnType<typeof useCouponMasterImageUrlLazyQuery>;
export type CouponMasterImageUrlQueryResult = ApolloReactCommon.QueryResult<
  CouponMasterImageUrlQuery,
  CouponMasterImageUrlQueryVariables
>;
export const CouponMastersDocument = gql`
  query CouponMasters(
    $couponCode: String
    $couponName: String
    $limit: Int!
    $nextToken: String
    $organizationId: String!
  ) {
    couponMasters(
      couponCode: $couponCode
      couponName: $couponName
      limit: $limit
      nextToken: $nextToken
      organizationId: $organizationId
    ) {
      nodes {
        applyBarcode {
          displayType
          sourceType
        }
        applyWith
        available
        availableDate
        availableDays
        availableEndTimePeriod
        availableStartTimePeriod
        companyImage {
          key
        }
        companyImageUrl
        couponCode
        couponIssuedMaximumNumber
        couponIssuerBandColor
        couponIssuerTextColor
        couponName
        couponNameBandColor
        couponNameHtml
        couponNameTextColor
        createAtDateTime
        description
        displayEndDate
        displayStartDate
        id
        information
        layoutPattern
        mapUrl
        meta
        note
        noteParagraph
        organizationId
        productImage {
          key
        }
        productImageUrl
        realTimeIssuedGetEndDateTime
        realTimeIssuedGetStartDateTime
        smartCheckImage {
          smartCheckConfirmButtonImage {
            key
          }
          smartCheckConfirmCancelButtonImage {
            key
          }
          smartCheckForceButtonImage {
            key
          }
          smartCheckHeaderImage {
            key
          }
          smartCheckUseButtonImage {
            key
          }
          smartCheckUsedImage {
            key
          }
        }
        smartCheckImageUrl {
          smartCheckConfirmButtonImageUrl
          smartCheckConfirmCancelButtonImageUrl
          smartCheckForceButtonImageUrl
          smartCheckHeaderImageUrl
          smartCheckUseButtonImageUrl
          smartCheckUsedImageUrl
        }
        smartCheckSetting {
          countDownMinute
          smartCheckConfirmText
          smartCheckCountDownText
          smartCheckUseButtonBottomText
          staticBarcodes {
            barcode
            barcodeType
          }
        }
        telNumber
        timeFrame
        updateAtDateTime
        validityEndDateTime
        validityStartDateTime
      }
      pageInfo {
        nextToken
      }
    }
  }
`;

/**
 * __useCouponMastersQuery__
 *
 * To run a query within a React component, call `useCouponMastersQuery` and pass it any options that fit your needs.
 * When your component renders, `useCouponMastersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCouponMastersQuery({
 *   variables: {
 *      couponCode: // value for 'couponCode'
 *      couponName: // value for 'couponName'
 *      limit: // value for 'limit'
 *      nextToken: // value for 'nextToken'
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useCouponMastersQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<CouponMastersQuery, CouponMastersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<CouponMastersQuery, CouponMastersQueryVariables>(CouponMastersDocument, options);
}
export function useCouponMastersLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CouponMastersQuery, CouponMastersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<CouponMastersQuery, CouponMastersQueryVariables>(CouponMastersDocument, options);
}
export type CouponMastersQueryHookResult = ReturnType<typeof useCouponMastersQuery>;
export type CouponMastersLazyQueryHookResult = ReturnType<typeof useCouponMastersLazyQuery>;
export type CouponMastersQueryResult = ApolloReactCommon.QueryResult<CouponMastersQuery, CouponMastersQueryVariables>;
export const CouponsDocument = gql`
  query Coupons(
    $barcode: String
    $couponMasterId: String
    $couponUrl: String
    $limit: Int!
    $nextToken: String
    $organizationId: String
  ) {
    coupons(
      barcode: $barcode
      couponMasterId: $couponMasterId
      couponUrl: $couponUrl
      limit: $limit
      nextToken: $nextToken
      organizationId: $organizationId
    ) {
      nodes {
        barcode
        couponCode
        couponMasterId
        couponName
        couponUrl
        createdDateTime
        customerShopCode
        id
        memberShopCode
        publishedDateTime
        publishedOrganizationId
        publishedOrganizationName
        shopId
        shopName
        shopUsedDateTime
        smartCheckClearingCode
        smartCheckMemberShopCode
        smartCheckUsedDateTime
        smartCheckUsedOrganizationId
        smartCheckUsedOrganizationName
        status
        terminalCode
        updatedDateTime
        usedDateTime
        usedFrom
        usedOrganizationId
        usedOrganizationName
      }
      pageInfo {
        nextToken
      }
    }
  }
`;

/**
 * __useCouponsQuery__
 *
 * To run a query within a React component, call `useCouponsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCouponsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCouponsQuery({
 *   variables: {
 *      barcode: // value for 'barcode'
 *      couponMasterId: // value for 'couponMasterId'
 *      couponUrl: // value for 'couponUrl'
 *      limit: // value for 'limit'
 *      nextToken: // value for 'nextToken'
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useCouponsQuery(baseOptions: ApolloReactHooks.QueryHookOptions<CouponsQuery, CouponsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<CouponsQuery, CouponsQueryVariables>(CouponsDocument, options);
}
export function useCouponsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CouponsQuery, CouponsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<CouponsQuery, CouponsQueryVariables>(CouponsDocument, options);
}
export type CouponsQueryHookResult = ReturnType<typeof useCouponsQuery>;
export type CouponsLazyQueryHookResult = ReturnType<typeof useCouponsLazyQuery>;
export type CouponsQueryResult = ApolloReactCommon.QueryResult<CouponsQuery, CouponsQueryVariables>;
export const GetBulkShopCsvPutPreSignedUrlDocument = gql`
  query GetBulkShopCsvPutPreSignedUrl {
    getBulkShopCsvPutPreSignedUrl {
      key
      preSignedUrl
    }
  }
`;

/**
 * __useGetBulkShopCsvPutPreSignedUrlQuery__
 *
 * To run a query within a React component, call `useGetBulkShopCsvPutPreSignedUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBulkShopCsvPutPreSignedUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBulkShopCsvPutPreSignedUrlQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetBulkShopCsvPutPreSignedUrlQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetBulkShopCsvPutPreSignedUrlQuery,
    GetBulkShopCsvPutPreSignedUrlQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<GetBulkShopCsvPutPreSignedUrlQuery, GetBulkShopCsvPutPreSignedUrlQueryVariables>(
    GetBulkShopCsvPutPreSignedUrlDocument,
    options
  );
}
export function useGetBulkShopCsvPutPreSignedUrlLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetBulkShopCsvPutPreSignedUrlQuery,
    GetBulkShopCsvPutPreSignedUrlQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<GetBulkShopCsvPutPreSignedUrlQuery, GetBulkShopCsvPutPreSignedUrlQueryVariables>(
    GetBulkShopCsvPutPreSignedUrlDocument,
    options
  );
}
export type GetBulkShopCsvPutPreSignedUrlQueryHookResult = ReturnType<typeof useGetBulkShopCsvPutPreSignedUrlQuery>;
export type GetBulkShopCsvPutPreSignedUrlLazyQueryHookResult = ReturnType<
  typeof useGetBulkShopCsvPutPreSignedUrlLazyQuery
>;
export type GetBulkShopCsvPutPreSignedUrlQueryResult = ApolloReactCommon.QueryResult<
  GetBulkShopCsvPutPreSignedUrlQuery,
  GetBulkShopCsvPutPreSignedUrlQueryVariables
>;
export const InvokeCouponDisplayAggregateCsvUrlDocument = gql`
  query InvokeCouponDisplayAggregateCsvUrl(
    $couponCode: String
    $couponName: String
    $organizationId: String!
    $organizationName: String
    $searchPolicy: String!
  ) {
    invokeCouponDisplayAggregateCsvUrl(
      couponCode: $couponCode
      couponName: $couponName
      organizationId: $organizationId
      organizationName: $organizationName
      searchPolicy: $searchPolicy
    ) {
      queryExecutionId
    }
  }
`;

/**
 * __useInvokeCouponDisplayAggregateCsvUrlQuery__
 *
 * To run a query within a React component, call `useInvokeCouponDisplayAggregateCsvUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvokeCouponDisplayAggregateCsvUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvokeCouponDisplayAggregateCsvUrlQuery({
 *   variables: {
 *      couponCode: // value for 'couponCode'
 *      couponName: // value for 'couponName'
 *      organizationId: // value for 'organizationId'
 *      organizationName: // value for 'organizationName'
 *      searchPolicy: // value for 'searchPolicy'
 *   },
 * });
 */
export function useInvokeCouponDisplayAggregateCsvUrlQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    InvokeCouponDisplayAggregateCsvUrlQuery,
    InvokeCouponDisplayAggregateCsvUrlQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    InvokeCouponDisplayAggregateCsvUrlQuery,
    InvokeCouponDisplayAggregateCsvUrlQueryVariables
  >(InvokeCouponDisplayAggregateCsvUrlDocument, options);
}
export function useInvokeCouponDisplayAggregateCsvUrlLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    InvokeCouponDisplayAggregateCsvUrlQuery,
    InvokeCouponDisplayAggregateCsvUrlQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    InvokeCouponDisplayAggregateCsvUrlQuery,
    InvokeCouponDisplayAggregateCsvUrlQueryVariables
  >(InvokeCouponDisplayAggregateCsvUrlDocument, options);
}
export type InvokeCouponDisplayAggregateCsvUrlQueryHookResult = ReturnType<
  typeof useInvokeCouponDisplayAggregateCsvUrlQuery
>;
export type InvokeCouponDisplayAggregateCsvUrlLazyQueryHookResult = ReturnType<
  typeof useInvokeCouponDisplayAggregateCsvUrlLazyQuery
>;
export type InvokeCouponDisplayAggregateCsvUrlQueryResult = ApolloReactCommon.QueryResult<
  InvokeCouponDisplayAggregateCsvUrlQuery,
  InvokeCouponDisplayAggregateCsvUrlQueryVariables
>;
export const InvokeCouponDisplayAggregateJsonDocument = gql`
  query InvokeCouponDisplayAggregateJson(
    $couponCode: String
    $couponName: String
    $limit: Int
    $offset: Int
    $organizationId: String!
    $organizationName: String
  ) {
    invokeCouponDisplayAggregateJson(
      couponCode: $couponCode
      couponName: $couponName
      limit: $limit
      offset: $offset
      organizationId: $organizationId
      organizationName: $organizationName
    ) {
      queryExecutionId
    }
  }
`;

/**
 * __useInvokeCouponDisplayAggregateJsonQuery__
 *
 * To run a query within a React component, call `useInvokeCouponDisplayAggregateJsonQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvokeCouponDisplayAggregateJsonQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvokeCouponDisplayAggregateJsonQuery({
 *   variables: {
 *      couponCode: // value for 'couponCode'
 *      couponName: // value for 'couponName'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      organizationId: // value for 'organizationId'
 *      organizationName: // value for 'organizationName'
 *   },
 * });
 */
export function useInvokeCouponDisplayAggregateJsonQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    InvokeCouponDisplayAggregateJsonQuery,
    InvokeCouponDisplayAggregateJsonQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    InvokeCouponDisplayAggregateJsonQuery,
    InvokeCouponDisplayAggregateJsonQueryVariables
  >(InvokeCouponDisplayAggregateJsonDocument, options);
}
export function useInvokeCouponDisplayAggregateJsonLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    InvokeCouponDisplayAggregateJsonQuery,
    InvokeCouponDisplayAggregateJsonQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    InvokeCouponDisplayAggregateJsonQuery,
    InvokeCouponDisplayAggregateJsonQueryVariables
  >(InvokeCouponDisplayAggregateJsonDocument, options);
}
export type InvokeCouponDisplayAggregateJsonQueryHookResult = ReturnType<
  typeof useInvokeCouponDisplayAggregateJsonQuery
>;
export type InvokeCouponDisplayAggregateJsonLazyQueryHookResult = ReturnType<
  typeof useInvokeCouponDisplayAggregateJsonLazyQuery
>;
export type InvokeCouponDisplayAggregateJsonQueryResult = ApolloReactCommon.QueryResult<
  InvokeCouponDisplayAggregateJsonQuery,
  InvokeCouponDisplayAggregateJsonQueryVariables
>;
export const InvokeCouponDisplayLogCsvUrlDocument = gql`
  query InvokeCouponDisplayLogCsvUrl(
    $barcode: String
    $couponCode: String
    $couponId: String
    $couponMasterId: String
    $couponName: String
    $isCanceled: Boolean
    $isIssued: Boolean
    $isUsed: Boolean
    $organizationId: String
    $organizationName: String
    $searchPolicy: String!
    $url: String
  ) {
    invokeCouponDisplayLogCsvUrl(
      barcode: $barcode
      couponCode: $couponCode
      couponId: $couponId
      couponMasterId: $couponMasterId
      couponName: $couponName
      isCanceled: $isCanceled
      isIssued: $isIssued
      isUsed: $isUsed
      organizationId: $organizationId
      organizationName: $organizationName
      searchPolicy: $searchPolicy
      url: $url
    ) {
      queryExecutionId
    }
  }
`;

/**
 * __useInvokeCouponDisplayLogCsvUrlQuery__
 *
 * To run a query within a React component, call `useInvokeCouponDisplayLogCsvUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvokeCouponDisplayLogCsvUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvokeCouponDisplayLogCsvUrlQuery({
 *   variables: {
 *      barcode: // value for 'barcode'
 *      couponCode: // value for 'couponCode'
 *      couponId: // value for 'couponId'
 *      couponMasterId: // value for 'couponMasterId'
 *      couponName: // value for 'couponName'
 *      isCanceled: // value for 'isCanceled'
 *      isIssued: // value for 'isIssued'
 *      isUsed: // value for 'isUsed'
 *      organizationId: // value for 'organizationId'
 *      organizationName: // value for 'organizationName'
 *      searchPolicy: // value for 'searchPolicy'
 *      url: // value for 'url'
 *   },
 * });
 */
export function useInvokeCouponDisplayLogCsvUrlQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    InvokeCouponDisplayLogCsvUrlQuery,
    InvokeCouponDisplayLogCsvUrlQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<InvokeCouponDisplayLogCsvUrlQuery, InvokeCouponDisplayLogCsvUrlQueryVariables>(
    InvokeCouponDisplayLogCsvUrlDocument,
    options
  );
}
export function useInvokeCouponDisplayLogCsvUrlLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    InvokeCouponDisplayLogCsvUrlQuery,
    InvokeCouponDisplayLogCsvUrlQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<InvokeCouponDisplayLogCsvUrlQuery, InvokeCouponDisplayLogCsvUrlQueryVariables>(
    InvokeCouponDisplayLogCsvUrlDocument,
    options
  );
}
export type InvokeCouponDisplayLogCsvUrlQueryHookResult = ReturnType<typeof useInvokeCouponDisplayLogCsvUrlQuery>;
export type InvokeCouponDisplayLogCsvUrlLazyQueryHookResult = ReturnType<
  typeof useInvokeCouponDisplayLogCsvUrlLazyQuery
>;
export type InvokeCouponDisplayLogCsvUrlQueryResult = ApolloReactCommon.QueryResult<
  InvokeCouponDisplayLogCsvUrlQuery,
  InvokeCouponDisplayLogCsvUrlQueryVariables
>;
export const InvokeCouponDisplayLogJsonDocument = gql`
  query InvokeCouponDisplayLogJson(
    $barcode: String
    $couponCode: String
    $couponId: String
    $couponMasterId: String
    $couponName: String
    $isCanceled: Boolean
    $isIssued: Boolean
    $isUsed: Boolean
    $limit: Int
    $offset: Int
    $organizationId: String
    $organizationName: String
    $searchPolicy: String!
    $url: String
  ) {
    invokeCouponDisplayLogJson(
      barcode: $barcode
      couponCode: $couponCode
      couponId: $couponId
      couponMasterId: $couponMasterId
      couponName: $couponName
      isCanceled: $isCanceled
      isIssued: $isIssued
      isUsed: $isUsed
      limit: $limit
      offset: $offset
      organizationId: $organizationId
      organizationName: $organizationName
      searchPolicy: $searchPolicy
      url: $url
    ) {
      queryExecutionId
    }
  }
`;

/**
 * __useInvokeCouponDisplayLogJsonQuery__
 *
 * To run a query within a React component, call `useInvokeCouponDisplayLogJsonQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvokeCouponDisplayLogJsonQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvokeCouponDisplayLogJsonQuery({
 *   variables: {
 *      barcode: // value for 'barcode'
 *      couponCode: // value for 'couponCode'
 *      couponId: // value for 'couponId'
 *      couponMasterId: // value for 'couponMasterId'
 *      couponName: // value for 'couponName'
 *      isCanceled: // value for 'isCanceled'
 *      isIssued: // value for 'isIssued'
 *      isUsed: // value for 'isUsed'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      organizationId: // value for 'organizationId'
 *      organizationName: // value for 'organizationName'
 *      searchPolicy: // value for 'searchPolicy'
 *      url: // value for 'url'
 *   },
 * });
 */
export function useInvokeCouponDisplayLogJsonQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    InvokeCouponDisplayLogJsonQuery,
    InvokeCouponDisplayLogJsonQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<InvokeCouponDisplayLogJsonQuery, InvokeCouponDisplayLogJsonQueryVariables>(
    InvokeCouponDisplayLogJsonDocument,
    options
  );
}
export function useInvokeCouponDisplayLogJsonLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    InvokeCouponDisplayLogJsonQuery,
    InvokeCouponDisplayLogJsonQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<InvokeCouponDisplayLogJsonQuery, InvokeCouponDisplayLogJsonQueryVariables>(
    InvokeCouponDisplayLogJsonDocument,
    options
  );
}
export type InvokeCouponDisplayLogJsonQueryHookResult = ReturnType<typeof useInvokeCouponDisplayLogJsonQuery>;
export type InvokeCouponDisplayLogJsonLazyQueryHookResult = ReturnType<typeof useInvokeCouponDisplayLogJsonLazyQuery>;
export type InvokeCouponDisplayLogJsonQueryResult = ApolloReactCommon.QueryResult<
  InvokeCouponDisplayLogJsonQuery,
  InvokeCouponDisplayLogJsonQueryVariables
>;
export const InvokeCouponUsageAggregateCsvUrlDocument = gql`
  query InvokeCouponUsageAggregateCsvUrl(
    $couponCode: String
    $couponName: String
    $groupBy: String!
    $organizationId: String!
    $organizationName: String
    $searchPolicy: String!
  ) {
    invokeCouponUsageAggregateCsvUrl(
      couponCode: $couponCode
      couponName: $couponName
      groupBy: $groupBy
      organizationId: $organizationId
      organizationName: $organizationName
      searchPolicy: $searchPolicy
    ) {
      queryExecutionId
    }
  }
`;

/**
 * __useInvokeCouponUsageAggregateCsvUrlQuery__
 *
 * To run a query within a React component, call `useInvokeCouponUsageAggregateCsvUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvokeCouponUsageAggregateCsvUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvokeCouponUsageAggregateCsvUrlQuery({
 *   variables: {
 *      couponCode: // value for 'couponCode'
 *      couponName: // value for 'couponName'
 *      groupBy: // value for 'groupBy'
 *      organizationId: // value for 'organizationId'
 *      organizationName: // value for 'organizationName'
 *      searchPolicy: // value for 'searchPolicy'
 *   },
 * });
 */
export function useInvokeCouponUsageAggregateCsvUrlQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    InvokeCouponUsageAggregateCsvUrlQuery,
    InvokeCouponUsageAggregateCsvUrlQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    InvokeCouponUsageAggregateCsvUrlQuery,
    InvokeCouponUsageAggregateCsvUrlQueryVariables
  >(InvokeCouponUsageAggregateCsvUrlDocument, options);
}
export function useInvokeCouponUsageAggregateCsvUrlLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    InvokeCouponUsageAggregateCsvUrlQuery,
    InvokeCouponUsageAggregateCsvUrlQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    InvokeCouponUsageAggregateCsvUrlQuery,
    InvokeCouponUsageAggregateCsvUrlQueryVariables
  >(InvokeCouponUsageAggregateCsvUrlDocument, options);
}
export type InvokeCouponUsageAggregateCsvUrlQueryHookResult = ReturnType<
  typeof useInvokeCouponUsageAggregateCsvUrlQuery
>;
export type InvokeCouponUsageAggregateCsvUrlLazyQueryHookResult = ReturnType<
  typeof useInvokeCouponUsageAggregateCsvUrlLazyQuery
>;
export type InvokeCouponUsageAggregateCsvUrlQueryResult = ApolloReactCommon.QueryResult<
  InvokeCouponUsageAggregateCsvUrlQuery,
  InvokeCouponUsageAggregateCsvUrlQueryVariables
>;
export const InvokeCouponUsageAggregateJsonDocument = gql`
  query InvokeCouponUsageAggregateJson(
    $couponCode: String
    $couponName: String
    $groupBy: String!
    $limit: Int
    $offset: Int
    $organizationId: String!
    $organizationName: String
  ) {
    invokeCouponUsageAggregateJson(
      couponCode: $couponCode
      couponName: $couponName
      groupBy: $groupBy
      limit: $limit
      offset: $offset
      organizationId: $organizationId
      organizationName: $organizationName
    ) {
      queryExecutionId
    }
  }
`;

/**
 * __useInvokeCouponUsageAggregateJsonQuery__
 *
 * To run a query within a React component, call `useInvokeCouponUsageAggregateJsonQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvokeCouponUsageAggregateJsonQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvokeCouponUsageAggregateJsonQuery({
 *   variables: {
 *      couponCode: // value for 'couponCode'
 *      couponName: // value for 'couponName'
 *      groupBy: // value for 'groupBy'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      organizationId: // value for 'organizationId'
 *      organizationName: // value for 'organizationName'
 *   },
 * });
 */
export function useInvokeCouponUsageAggregateJsonQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    InvokeCouponUsageAggregateJsonQuery,
    InvokeCouponUsageAggregateJsonQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<InvokeCouponUsageAggregateJsonQuery, InvokeCouponUsageAggregateJsonQueryVariables>(
    InvokeCouponUsageAggregateJsonDocument,
    options
  );
}
export function useInvokeCouponUsageAggregateJsonLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    InvokeCouponUsageAggregateJsonQuery,
    InvokeCouponUsageAggregateJsonQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    InvokeCouponUsageAggregateJsonQuery,
    InvokeCouponUsageAggregateJsonQueryVariables
  >(InvokeCouponUsageAggregateJsonDocument, options);
}
export type InvokeCouponUsageAggregateJsonQueryHookResult = ReturnType<typeof useInvokeCouponUsageAggregateJsonQuery>;
export type InvokeCouponUsageAggregateJsonLazyQueryHookResult = ReturnType<
  typeof useInvokeCouponUsageAggregateJsonLazyQuery
>;
export type InvokeCouponUsageAggregateJsonQueryResult = ApolloReactCommon.QueryResult<
  InvokeCouponUsageAggregateJsonQuery,
  InvokeCouponUsageAggregateJsonQueryVariables
>;
export const InvokeCouponUsageLogCsvDocument = gql`
  query InvokeCouponUsageLogCsv(
    $barcode: String
    $couponCode: String
    $couponId: String
    $couponMasterId: String
    $couponName: String
    $isCanceled: Boolean
    $isIssued: Boolean
    $isUsed: Boolean
    $organizationId: String
    $organizationName: String
    $searchPolicy: String!
    $url: String
  ) {
    invokeCouponUsageLogCsv(
      barcode: $barcode
      couponCode: $couponCode
      couponId: $couponId
      couponMasterId: $couponMasterId
      couponName: $couponName
      isCanceled: $isCanceled
      isIssued: $isIssued
      isUsed: $isUsed
      organizationId: $organizationId
      organizationName: $organizationName
      searchPolicy: $searchPolicy
      url: $url
    ) {
      queryExecutionId
    }
  }
`;

/**
 * __useInvokeCouponUsageLogCsvQuery__
 *
 * To run a query within a React component, call `useInvokeCouponUsageLogCsvQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvokeCouponUsageLogCsvQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvokeCouponUsageLogCsvQuery({
 *   variables: {
 *      barcode: // value for 'barcode'
 *      couponCode: // value for 'couponCode'
 *      couponId: // value for 'couponId'
 *      couponMasterId: // value for 'couponMasterId'
 *      couponName: // value for 'couponName'
 *      isCanceled: // value for 'isCanceled'
 *      isIssued: // value for 'isIssued'
 *      isUsed: // value for 'isUsed'
 *      organizationId: // value for 'organizationId'
 *      organizationName: // value for 'organizationName'
 *      searchPolicy: // value for 'searchPolicy'
 *      url: // value for 'url'
 *   },
 * });
 */
export function useInvokeCouponUsageLogCsvQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<InvokeCouponUsageLogCsvQuery, InvokeCouponUsageLogCsvQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<InvokeCouponUsageLogCsvQuery, InvokeCouponUsageLogCsvQueryVariables>(
    InvokeCouponUsageLogCsvDocument,
    options
  );
}
export function useInvokeCouponUsageLogCsvLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    InvokeCouponUsageLogCsvQuery,
    InvokeCouponUsageLogCsvQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<InvokeCouponUsageLogCsvQuery, InvokeCouponUsageLogCsvQueryVariables>(
    InvokeCouponUsageLogCsvDocument,
    options
  );
}
export type InvokeCouponUsageLogCsvQueryHookResult = ReturnType<typeof useInvokeCouponUsageLogCsvQuery>;
export type InvokeCouponUsageLogCsvLazyQueryHookResult = ReturnType<typeof useInvokeCouponUsageLogCsvLazyQuery>;
export type InvokeCouponUsageLogCsvQueryResult = ApolloReactCommon.QueryResult<
  InvokeCouponUsageLogCsvQuery,
  InvokeCouponUsageLogCsvQueryVariables
>;
export const InvokeCouponUsageLogJsonDocument = gql`
  query InvokeCouponUsageLogJson(
    $barcode: String
    $couponCode: String
    $couponId: String
    $couponMasterId: String
    $couponName: String
    $isCanceled: Boolean
    $isIssued: Boolean
    $isUsed: Boolean
    $limit: Int
    $offset: Int
    $organizationId: String
    $organizationName: String
    $searchPolicy: String!
    $url: String
  ) {
    invokeCouponUsageLogJson(
      barcode: $barcode
      couponCode: $couponCode
      couponId: $couponId
      couponMasterId: $couponMasterId
      couponName: $couponName
      isCanceled: $isCanceled
      isIssued: $isIssued
      isUsed: $isUsed
      limit: $limit
      offset: $offset
      organizationId: $organizationId
      organizationName: $organizationName
      searchPolicy: $searchPolicy
      url: $url
    ) {
      queryExecutionId
    }
  }
`;

/**
 * __useInvokeCouponUsageLogJsonQuery__
 *
 * To run a query within a React component, call `useInvokeCouponUsageLogJsonQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvokeCouponUsageLogJsonQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvokeCouponUsageLogJsonQuery({
 *   variables: {
 *      barcode: // value for 'barcode'
 *      couponCode: // value for 'couponCode'
 *      couponId: // value for 'couponId'
 *      couponMasterId: // value for 'couponMasterId'
 *      couponName: // value for 'couponName'
 *      isCanceled: // value for 'isCanceled'
 *      isIssued: // value for 'isIssued'
 *      isUsed: // value for 'isUsed'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      organizationId: // value for 'organizationId'
 *      organizationName: // value for 'organizationName'
 *      searchPolicy: // value for 'searchPolicy'
 *      url: // value for 'url'
 *   },
 * });
 */
export function useInvokeCouponUsageLogJsonQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<InvokeCouponUsageLogJsonQuery, InvokeCouponUsageLogJsonQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<InvokeCouponUsageLogJsonQuery, InvokeCouponUsageLogJsonQueryVariables>(
    InvokeCouponUsageLogJsonDocument,
    options
  );
}
export function useInvokeCouponUsageLogJsonLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    InvokeCouponUsageLogJsonQuery,
    InvokeCouponUsageLogJsonQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<InvokeCouponUsageLogJsonQuery, InvokeCouponUsageLogJsonQueryVariables>(
    InvokeCouponUsageLogJsonDocument,
    options
  );
}
export type InvokeCouponUsageLogJsonQueryHookResult = ReturnType<typeof useInvokeCouponUsageLogJsonQuery>;
export type InvokeCouponUsageLogJsonLazyQueryHookResult = ReturnType<typeof useInvokeCouponUsageLogJsonLazyQuery>;
export type InvokeCouponUsageLogJsonQueryResult = ApolloReactCommon.QueryResult<
  InvokeCouponUsageLogJsonQuery,
  InvokeCouponUsageLogJsonQueryVariables
>;
export const InvokeShopCsvUrlDocument = gql`
  query InvokeShopCsvUrl($parentId: String!) {
    invokeShopCsvUrl(parentId: $parentId) {
      queryExecutionId
    }
  }
`;

/**
 * __useInvokeShopCsvUrlQuery__
 *
 * To run a query within a React component, call `useInvokeShopCsvUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvokeShopCsvUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvokeShopCsvUrlQuery({
 *   variables: {
 *      parentId: // value for 'parentId'
 *   },
 * });
 */
export function useInvokeShopCsvUrlQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<InvokeShopCsvUrlQuery, InvokeShopCsvUrlQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<InvokeShopCsvUrlQuery, InvokeShopCsvUrlQueryVariables>(
    InvokeShopCsvUrlDocument,
    options
  );
}
export function useInvokeShopCsvUrlLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<InvokeShopCsvUrlQuery, InvokeShopCsvUrlQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<InvokeShopCsvUrlQuery, InvokeShopCsvUrlQueryVariables>(
    InvokeShopCsvUrlDocument,
    options
  );
}
export type InvokeShopCsvUrlQueryHookResult = ReturnType<typeof useInvokeShopCsvUrlQuery>;
export type InvokeShopCsvUrlLazyQueryHookResult = ReturnType<typeof useInvokeShopCsvUrlLazyQuery>;
export type InvokeShopCsvUrlQueryResult = ApolloReactCommon.QueryResult<
  InvokeShopCsvUrlQuery,
  InvokeShopCsvUrlQueryVariables
>;
export const OrganizationDocument = gql`
  query Organization($id: String!) {
    organization(id: $id) {
      billing
      customerShopCode
      id
      memberShopCode
      name
      parentId
      shopCode
      shopNumber
      simpleClearingCode
      type
    }
  }
`;

/**
 * __useOrganizationQuery__
 *
 * To run a query within a React component, call `useOrganizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOrganizationQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<OrganizationQuery, OrganizationQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<OrganizationQuery, OrganizationQueryVariables>(OrganizationDocument, options);
}
export function useOrganizationLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OrganizationQuery, OrganizationQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<OrganizationQuery, OrganizationQueryVariables>(OrganizationDocument, options);
}
export type OrganizationQueryHookResult = ReturnType<typeof useOrganizationQuery>;
export type OrganizationLazyQueryHookResult = ReturnType<typeof useOrganizationLazyQuery>;
export type OrganizationQueryResult = ApolloReactCommon.QueryResult<OrganizationQuery, OrganizationQueryVariables>;
export const OrganizationChildrenDocument = gql`
  query OrganizationChildren($query: SearchOrganizationChildrenInput!) {
    organizationChildren(query: $query) {
      items {
        billing
        customerShopCode
        id
        memberShopCode
        name
        parentId
        shopCode
        shopNumber
        simpleClearingCode
        type
      }
      nextToken
    }
  }
`;

/**
 * __useOrganizationChildrenQuery__
 *
 * To run a query within a React component, call `useOrganizationChildrenQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationChildrenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationChildrenQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useOrganizationChildrenQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<OrganizationChildrenQuery, OrganizationChildrenQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<OrganizationChildrenQuery, OrganizationChildrenQueryVariables>(
    OrganizationChildrenDocument,
    options
  );
}
export function useOrganizationChildrenLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OrganizationChildrenQuery, OrganizationChildrenQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<OrganizationChildrenQuery, OrganizationChildrenQueryVariables>(
    OrganizationChildrenDocument,
    options
  );
}
export type OrganizationChildrenQueryHookResult = ReturnType<typeof useOrganizationChildrenQuery>;
export type OrganizationChildrenLazyQueryHookResult = ReturnType<typeof useOrganizationChildrenLazyQuery>;
export type OrganizationChildrenQueryResult = ApolloReactCommon.QueryResult<
  OrganizationChildrenQuery,
  OrganizationChildrenQueryVariables
>;
export const OrganizationSuiteDocument = gql`
  query OrganizationSuite($id: String!) {
    organizationSuite(id: $id) {
      apply {
        barcode {
          displayType
          sourceType
        }
        with
      }
      id
      takeOver {
        apply
        timeFrame
      }
      timeFrame {
        available
      }
    }
  }
`;

/**
 * __useOrganizationSuiteQuery__
 *
 * To run a query within a React component, call `useOrganizationSuiteQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationSuiteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationSuiteQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOrganizationSuiteQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<OrganizationSuiteQuery, OrganizationSuiteQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<OrganizationSuiteQuery, OrganizationSuiteQueryVariables>(
    OrganizationSuiteDocument,
    options
  );
}
export function useOrganizationSuiteLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OrganizationSuiteQuery, OrganizationSuiteQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<OrganizationSuiteQuery, OrganizationSuiteQueryVariables>(
    OrganizationSuiteDocument,
    options
  );
}
export type OrganizationSuiteQueryHookResult = ReturnType<typeof useOrganizationSuiteQuery>;
export type OrganizationSuiteLazyQueryHookResult = ReturnType<typeof useOrganizationSuiteLazyQuery>;
export type OrganizationSuiteQueryResult = ApolloReactCommon.QueryResult<
  OrganizationSuiteQuery,
  OrganizationSuiteQueryVariables
>;
export const PollCouponDisplayAggregateCsvUrlDocument = gql`
  query PollCouponDisplayAggregateCsvUrl($queryExecutionId: String!) {
    pollCouponDisplayAggregateCsvUrl(queryExecutionId: $queryExecutionId) {
      preSignedUrl
      queryExecutionId
      status
    }
  }
`;

/**
 * __usePollCouponDisplayAggregateCsvUrlQuery__
 *
 * To run a query within a React component, call `usePollCouponDisplayAggregateCsvUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `usePollCouponDisplayAggregateCsvUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePollCouponDisplayAggregateCsvUrlQuery({
 *   variables: {
 *      queryExecutionId: // value for 'queryExecutionId'
 *   },
 * });
 */
export function usePollCouponDisplayAggregateCsvUrlQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    PollCouponDisplayAggregateCsvUrlQuery,
    PollCouponDisplayAggregateCsvUrlQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    PollCouponDisplayAggregateCsvUrlQuery,
    PollCouponDisplayAggregateCsvUrlQueryVariables
  >(PollCouponDisplayAggregateCsvUrlDocument, options);
}
export function usePollCouponDisplayAggregateCsvUrlLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    PollCouponDisplayAggregateCsvUrlQuery,
    PollCouponDisplayAggregateCsvUrlQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    PollCouponDisplayAggregateCsvUrlQuery,
    PollCouponDisplayAggregateCsvUrlQueryVariables
  >(PollCouponDisplayAggregateCsvUrlDocument, options);
}
export type PollCouponDisplayAggregateCsvUrlQueryHookResult = ReturnType<
  typeof usePollCouponDisplayAggregateCsvUrlQuery
>;
export type PollCouponDisplayAggregateCsvUrlLazyQueryHookResult = ReturnType<
  typeof usePollCouponDisplayAggregateCsvUrlLazyQuery
>;
export type PollCouponDisplayAggregateCsvUrlQueryResult = ApolloReactCommon.QueryResult<
  PollCouponDisplayAggregateCsvUrlQuery,
  PollCouponDisplayAggregateCsvUrlQueryVariables
>;
export const PollCouponDisplayAggregateJsonDocument = gql`
  query PollCouponDisplayAggregateJson($queryExecutionId: String!) {
    pollCouponDisplayAggregateJson(queryExecutionId: $queryExecutionId) {
      nodes {
        canceledCount
        couponCode
        couponMasterId
        couponName
        displayedCount
        organizationId
        organizationName
        ownPublishedCount
        ownUsedCount
        publishedCount
        usedCount
      }
      pageInfo {
        nextToken
      }
      queryExecutionId
      status
    }
  }
`;

/**
 * __usePollCouponDisplayAggregateJsonQuery__
 *
 * To run a query within a React component, call `usePollCouponDisplayAggregateJsonQuery` and pass it any options that fit your needs.
 * When your component renders, `usePollCouponDisplayAggregateJsonQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePollCouponDisplayAggregateJsonQuery({
 *   variables: {
 *      queryExecutionId: // value for 'queryExecutionId'
 *   },
 * });
 */
export function usePollCouponDisplayAggregateJsonQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    PollCouponDisplayAggregateJsonQuery,
    PollCouponDisplayAggregateJsonQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<PollCouponDisplayAggregateJsonQuery, PollCouponDisplayAggregateJsonQueryVariables>(
    PollCouponDisplayAggregateJsonDocument,
    options
  );
}
export function usePollCouponDisplayAggregateJsonLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    PollCouponDisplayAggregateJsonQuery,
    PollCouponDisplayAggregateJsonQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    PollCouponDisplayAggregateJsonQuery,
    PollCouponDisplayAggregateJsonQueryVariables
  >(PollCouponDisplayAggregateJsonDocument, options);
}
export type PollCouponDisplayAggregateJsonQueryHookResult = ReturnType<typeof usePollCouponDisplayAggregateJsonQuery>;
export type PollCouponDisplayAggregateJsonLazyQueryHookResult = ReturnType<
  typeof usePollCouponDisplayAggregateJsonLazyQuery
>;
export type PollCouponDisplayAggregateJsonQueryResult = ApolloReactCommon.QueryResult<
  PollCouponDisplayAggregateJsonQuery,
  PollCouponDisplayAggregateJsonQueryVariables
>;
export const PollCouponDisplayLogCsvUrlDocument = gql`
  query PollCouponDisplayLogCsvUrl($queryExecutionId: String!) {
    pollCouponDisplayLogCsvUrl(queryExecutionId: $queryExecutionId) {
      preSignedUrl
      queryExecutionId
      status
    }
  }
`;

/**
 * __usePollCouponDisplayLogCsvUrlQuery__
 *
 * To run a query within a React component, call `usePollCouponDisplayLogCsvUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `usePollCouponDisplayLogCsvUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePollCouponDisplayLogCsvUrlQuery({
 *   variables: {
 *      queryExecutionId: // value for 'queryExecutionId'
 *   },
 * });
 */
export function usePollCouponDisplayLogCsvUrlQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    PollCouponDisplayLogCsvUrlQuery,
    PollCouponDisplayLogCsvUrlQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<PollCouponDisplayLogCsvUrlQuery, PollCouponDisplayLogCsvUrlQueryVariables>(
    PollCouponDisplayLogCsvUrlDocument,
    options
  );
}
export function usePollCouponDisplayLogCsvUrlLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    PollCouponDisplayLogCsvUrlQuery,
    PollCouponDisplayLogCsvUrlQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<PollCouponDisplayLogCsvUrlQuery, PollCouponDisplayLogCsvUrlQueryVariables>(
    PollCouponDisplayLogCsvUrlDocument,
    options
  );
}
export type PollCouponDisplayLogCsvUrlQueryHookResult = ReturnType<typeof usePollCouponDisplayLogCsvUrlQuery>;
export type PollCouponDisplayLogCsvUrlLazyQueryHookResult = ReturnType<typeof usePollCouponDisplayLogCsvUrlLazyQuery>;
export type PollCouponDisplayLogCsvUrlQueryResult = ApolloReactCommon.QueryResult<
  PollCouponDisplayLogCsvUrlQuery,
  PollCouponDisplayLogCsvUrlQueryVariables
>;
export const PollCouponDisplayLogJsonDocument = gql`
  query PollCouponDisplayLogJson($queryExecutionId: String!) {
    pollCouponDisplayLogJson(queryExecutionId: $queryExecutionId) {
      nodes {
        barcode
        couponCode
        couponId
        couponMasterId
        couponName
        createAtMillis
        customerShopCode
        displayedCount
        memberShopCode
        operateFrom
        organizationName
        status
        terminalNumber
        url
        usedShopName
        userAgent
        userId
        userName
      }
      pageInfo {
        nextToken
      }
      queryExecutionId
      status
    }
  }
`;

/**
 * __usePollCouponDisplayLogJsonQuery__
 *
 * To run a query within a React component, call `usePollCouponDisplayLogJsonQuery` and pass it any options that fit your needs.
 * When your component renders, `usePollCouponDisplayLogJsonQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePollCouponDisplayLogJsonQuery({
 *   variables: {
 *      queryExecutionId: // value for 'queryExecutionId'
 *   },
 * });
 */
export function usePollCouponDisplayLogJsonQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<PollCouponDisplayLogJsonQuery, PollCouponDisplayLogJsonQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<PollCouponDisplayLogJsonQuery, PollCouponDisplayLogJsonQueryVariables>(
    PollCouponDisplayLogJsonDocument,
    options
  );
}
export function usePollCouponDisplayLogJsonLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    PollCouponDisplayLogJsonQuery,
    PollCouponDisplayLogJsonQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<PollCouponDisplayLogJsonQuery, PollCouponDisplayLogJsonQueryVariables>(
    PollCouponDisplayLogJsonDocument,
    options
  );
}
export type PollCouponDisplayLogJsonQueryHookResult = ReturnType<typeof usePollCouponDisplayLogJsonQuery>;
export type PollCouponDisplayLogJsonLazyQueryHookResult = ReturnType<typeof usePollCouponDisplayLogJsonLazyQuery>;
export type PollCouponDisplayLogJsonQueryResult = ApolloReactCommon.QueryResult<
  PollCouponDisplayLogJsonQuery,
  PollCouponDisplayLogJsonQueryVariables
>;
export const PollCouponUsageAggregateCsvUrlDocument = gql`
  query PollCouponUsageAggregateCsvUrl($groupBy: String!, $queryExecutionId: String!) {
    pollCouponUsageAggregateCsvUrl(groupBy: $groupBy, queryExecutionId: $queryExecutionId) {
      preSignedUrl
      queryExecutionId
      status
    }
  }
`;

/**
 * __usePollCouponUsageAggregateCsvUrlQuery__
 *
 * To run a query within a React component, call `usePollCouponUsageAggregateCsvUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `usePollCouponUsageAggregateCsvUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePollCouponUsageAggregateCsvUrlQuery({
 *   variables: {
 *      groupBy: // value for 'groupBy'
 *      queryExecutionId: // value for 'queryExecutionId'
 *   },
 * });
 */
export function usePollCouponUsageAggregateCsvUrlQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    PollCouponUsageAggregateCsvUrlQuery,
    PollCouponUsageAggregateCsvUrlQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<PollCouponUsageAggregateCsvUrlQuery, PollCouponUsageAggregateCsvUrlQueryVariables>(
    PollCouponUsageAggregateCsvUrlDocument,
    options
  );
}
export function usePollCouponUsageAggregateCsvUrlLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    PollCouponUsageAggregateCsvUrlQuery,
    PollCouponUsageAggregateCsvUrlQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    PollCouponUsageAggregateCsvUrlQuery,
    PollCouponUsageAggregateCsvUrlQueryVariables
  >(PollCouponUsageAggregateCsvUrlDocument, options);
}
export type PollCouponUsageAggregateCsvUrlQueryHookResult = ReturnType<typeof usePollCouponUsageAggregateCsvUrlQuery>;
export type PollCouponUsageAggregateCsvUrlLazyQueryHookResult = ReturnType<
  typeof usePollCouponUsageAggregateCsvUrlLazyQuery
>;
export type PollCouponUsageAggregateCsvUrlQueryResult = ApolloReactCommon.QueryResult<
  PollCouponUsageAggregateCsvUrlQuery,
  PollCouponUsageAggregateCsvUrlQueryVariables
>;
export const PollCouponUsageAggregateJsonDocument = gql`
  query PollCouponUsageAggregateJson($groupBy: String!, $queryExecutionId: String!) {
    pollCouponUsageAggregateJson(groupBy: $groupBy, queryExecutionId: $queryExecutionId) {
      nodes {
        canceledCount
        couponCode
        couponMasterId
        couponName
        displayedCount
        organizationId
        organizationName
        ownPublishedCount
        ownUsedCount
        publishedCount
        usedCount
      }
      pageInfo {
        nextToken
      }
      queryExecutionId
      status
    }
  }
`;

/**
 * __usePollCouponUsageAggregateJsonQuery__
 *
 * To run a query within a React component, call `usePollCouponUsageAggregateJsonQuery` and pass it any options that fit your needs.
 * When your component renders, `usePollCouponUsageAggregateJsonQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePollCouponUsageAggregateJsonQuery({
 *   variables: {
 *      groupBy: // value for 'groupBy'
 *      queryExecutionId: // value for 'queryExecutionId'
 *   },
 * });
 */
export function usePollCouponUsageAggregateJsonQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    PollCouponUsageAggregateJsonQuery,
    PollCouponUsageAggregateJsonQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<PollCouponUsageAggregateJsonQuery, PollCouponUsageAggregateJsonQueryVariables>(
    PollCouponUsageAggregateJsonDocument,
    options
  );
}
export function usePollCouponUsageAggregateJsonLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    PollCouponUsageAggregateJsonQuery,
    PollCouponUsageAggregateJsonQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<PollCouponUsageAggregateJsonQuery, PollCouponUsageAggregateJsonQueryVariables>(
    PollCouponUsageAggregateJsonDocument,
    options
  );
}
export type PollCouponUsageAggregateJsonQueryHookResult = ReturnType<typeof usePollCouponUsageAggregateJsonQuery>;
export type PollCouponUsageAggregateJsonLazyQueryHookResult = ReturnType<
  typeof usePollCouponUsageAggregateJsonLazyQuery
>;
export type PollCouponUsageAggregateJsonQueryResult = ApolloReactCommon.QueryResult<
  PollCouponUsageAggregateJsonQuery,
  PollCouponUsageAggregateJsonQueryVariables
>;
export const PollCouponUsageLogCsvDocument = gql`
  query PollCouponUsageLogCsv($queryExecutionId: String!) {
    pollCouponUsageLogCsv(queryExecutionId: $queryExecutionId) {
      preSignedUrl
      queryExecutionId
      status
    }
  }
`;

/**
 * __usePollCouponUsageLogCsvQuery__
 *
 * To run a query within a React component, call `usePollCouponUsageLogCsvQuery` and pass it any options that fit your needs.
 * When your component renders, `usePollCouponUsageLogCsvQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePollCouponUsageLogCsvQuery({
 *   variables: {
 *      queryExecutionId: // value for 'queryExecutionId'
 *   },
 * });
 */
export function usePollCouponUsageLogCsvQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<PollCouponUsageLogCsvQuery, PollCouponUsageLogCsvQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<PollCouponUsageLogCsvQuery, PollCouponUsageLogCsvQueryVariables>(
    PollCouponUsageLogCsvDocument,
    options
  );
}
export function usePollCouponUsageLogCsvLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PollCouponUsageLogCsvQuery, PollCouponUsageLogCsvQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<PollCouponUsageLogCsvQuery, PollCouponUsageLogCsvQueryVariables>(
    PollCouponUsageLogCsvDocument,
    options
  );
}
export type PollCouponUsageLogCsvQueryHookResult = ReturnType<typeof usePollCouponUsageLogCsvQuery>;
export type PollCouponUsageLogCsvLazyQueryHookResult = ReturnType<typeof usePollCouponUsageLogCsvLazyQuery>;
export type PollCouponUsageLogCsvQueryResult = ApolloReactCommon.QueryResult<
  PollCouponUsageLogCsvQuery,
  PollCouponUsageLogCsvQueryVariables
>;
export const PollCouponUsageLogJsonDocument = gql`
  query PollCouponUsageLogJson($queryExecutionId: String!) {
    pollCouponUsageLogJson(queryExecutionId: $queryExecutionId) {
      nodes {
        barcode
        couponCode
        couponId
        couponMasterId
        couponName
        createAtMillis
        customerShopCode
        displayedCount
        memberShopCode
        operateFrom
        organizationName
        status
        terminalNumber
        url
        usedShopName
        userAgent
        userId
        userName
      }
      pageInfo {
        nextToken
      }
      queryExecutionId
      status
    }
  }
`;

/**
 * __usePollCouponUsageLogJsonQuery__
 *
 * To run a query within a React component, call `usePollCouponUsageLogJsonQuery` and pass it any options that fit your needs.
 * When your component renders, `usePollCouponUsageLogJsonQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePollCouponUsageLogJsonQuery({
 *   variables: {
 *      queryExecutionId: // value for 'queryExecutionId'
 *   },
 * });
 */
export function usePollCouponUsageLogJsonQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<PollCouponUsageLogJsonQuery, PollCouponUsageLogJsonQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<PollCouponUsageLogJsonQuery, PollCouponUsageLogJsonQueryVariables>(
    PollCouponUsageLogJsonDocument,
    options
  );
}
export function usePollCouponUsageLogJsonLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PollCouponUsageLogJsonQuery, PollCouponUsageLogJsonQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<PollCouponUsageLogJsonQuery, PollCouponUsageLogJsonQueryVariables>(
    PollCouponUsageLogJsonDocument,
    options
  );
}
export type PollCouponUsageLogJsonQueryHookResult = ReturnType<typeof usePollCouponUsageLogJsonQuery>;
export type PollCouponUsageLogJsonLazyQueryHookResult = ReturnType<typeof usePollCouponUsageLogJsonLazyQuery>;
export type PollCouponUsageLogJsonQueryResult = ApolloReactCommon.QueryResult<
  PollCouponUsageLogJsonQuery,
  PollCouponUsageLogJsonQueryVariables
>;
export const PollShopCsvUrlDocument = gql`
  query PollShopCsvUrl($queryExecutionId: String!) {
    pollShopCsvUrl(queryExecutionId: $queryExecutionId) {
      queryExecutionId
      signedUrl
      status
    }
  }
`;

/**
 * __usePollShopCsvUrlQuery__
 *
 * To run a query within a React component, call `usePollShopCsvUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `usePollShopCsvUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePollShopCsvUrlQuery({
 *   variables: {
 *      queryExecutionId: // value for 'queryExecutionId'
 *   },
 * });
 */
export function usePollShopCsvUrlQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<PollShopCsvUrlQuery, PollShopCsvUrlQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<PollShopCsvUrlQuery, PollShopCsvUrlQueryVariables>(PollShopCsvUrlDocument, options);
}
export function usePollShopCsvUrlLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PollShopCsvUrlQuery, PollShopCsvUrlQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<PollShopCsvUrlQuery, PollShopCsvUrlQueryVariables>(
    PollShopCsvUrlDocument,
    options
  );
}
export type PollShopCsvUrlQueryHookResult = ReturnType<typeof usePollShopCsvUrlQuery>;
export type PollShopCsvUrlLazyQueryHookResult = ReturnType<typeof usePollShopCsvUrlLazyQuery>;
export type PollShopCsvUrlQueryResult = ApolloReactCommon.QueryResult<
  PollShopCsvUrlQuery,
  PollShopCsvUrlQueryVariables
>;
export const PreSignedGetUrlDocument = gql`
  query PreSignedGetUrl($bucket: String!, $fileName: String, $key: String!) {
    preSignedGetUrl(bucket: $bucket, fileName: $fileName, key: $key) {
      key
      preSignedUrl
    }
  }
`;

/**
 * __usePreSignedGetUrlQuery__
 *
 * To run a query within a React component, call `usePreSignedGetUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `usePreSignedGetUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePreSignedGetUrlQuery({
 *   variables: {
 *      bucket: // value for 'bucket'
 *      fileName: // value for 'fileName'
 *      key: // value for 'key'
 *   },
 * });
 */
export function usePreSignedGetUrlQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<PreSignedGetUrlQuery, PreSignedGetUrlQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<PreSignedGetUrlQuery, PreSignedGetUrlQueryVariables>(
    PreSignedGetUrlDocument,
    options
  );
}
export function usePreSignedGetUrlLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PreSignedGetUrlQuery, PreSignedGetUrlQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<PreSignedGetUrlQuery, PreSignedGetUrlQueryVariables>(
    PreSignedGetUrlDocument,
    options
  );
}
export type PreSignedGetUrlQueryHookResult = ReturnType<typeof usePreSignedGetUrlQuery>;
export type PreSignedGetUrlLazyQueryHookResult = ReturnType<typeof usePreSignedGetUrlLazyQuery>;
export type PreSignedGetUrlQueryResult = ApolloReactCommon.QueryResult<
  PreSignedGetUrlQuery,
  PreSignedGetUrlQueryVariables
>;
export const RolesDocument = gql`
  query Roles {
    roles {
      id
      name
    }
  }
`;

/**
 * __useRolesQuery__
 *
 * To run a query within a React component, call `useRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRolesQuery({
 *   variables: {
 *   },
 * });
 */
export function useRolesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RolesQuery, RolesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<RolesQuery, RolesQueryVariables>(RolesDocument, options);
}
export function useRolesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RolesQuery, RolesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<RolesQuery, RolesQueryVariables>(RolesDocument, options);
}
export type RolesQueryHookResult = ReturnType<typeof useRolesQuery>;
export type RolesLazyQueryHookResult = ReturnType<typeof useRolesLazyQuery>;
export type RolesQueryResult = ApolloReactCommon.QueryResult<RolesQuery, RolesQueryVariables>;
export const ShopDocument = gql`
  query Shop($id: String!) {
    shop(id: $id) {
      billing
      customerShopCode
      endDate
      id
      name
      parentId
      shopCode
      shopNumber
      simpleClearingCode
      startDate
      type
    }
  }
`;

/**
 * __useShopQuery__
 *
 * To run a query within a React component, call `useShopQuery` and pass it any options that fit your needs.
 * When your component renders, `useShopQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShopQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useShopQuery(baseOptions: ApolloReactHooks.QueryHookOptions<ShopQuery, ShopQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<ShopQuery, ShopQueryVariables>(ShopDocument, options);
}
export function useShopLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ShopQuery, ShopQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<ShopQuery, ShopQueryVariables>(ShopDocument, options);
}
export type ShopQueryHookResult = ReturnType<typeof useShopQuery>;
export type ShopLazyQueryHookResult = ReturnType<typeof useShopLazyQuery>;
export type ShopQueryResult = ApolloReactCommon.QueryResult<ShopQuery, ShopQueryVariables>;
export const UserDocument = gql`
  query User($id: String) {
    user(id: $id) {
      createAtDateTime
      email
      id
      isAdministrator
      lastLoginDateTime
      name
      organizationId
      permissions
      role
      roleId
      updateAtDateTime
    }
  }
`;

/**
 * __useUserQuery__
 *
 * To run a query within a React component, call `useUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UserQuery, UserQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<UserQuery, UserQueryVariables>(UserDocument, options);
}
export function useUserLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UserQuery, UserQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<UserQuery, UserQueryVariables>(UserDocument, options);
}
export type UserQueryHookResult = ReturnType<typeof useUserQuery>;
export type UserLazyQueryHookResult = ReturnType<typeof useUserLazyQuery>;
export type UserQueryResult = ApolloReactCommon.QueryResult<UserQuery, UserQueryVariables>;
export const UsersDocument = gql`
  query Users($email: String, $name: String, $organizationId: String!, $page: String) {
    users(email: $email, name: $name, organizationId: $organizationId, page: $page) {
      edges {
        cursor
        node {
          createAtDateTime
          email
          id
          isAdministrator
          lastLoginDateTime
          name
          organizationId
          permissions
          role
          roleId
          updateAtDateTime
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        length
        limit
        startCursor
      }
    }
  }
`;

/**
 * __useUsersQuery__
 *
 * To run a query within a React component, call `useUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersQuery({
 *   variables: {
 *      email: // value for 'email'
 *      name: // value for 'name'
 *      organizationId: // value for 'organizationId'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useUsersQuery(baseOptions: ApolloReactHooks.QueryHookOptions<UsersQuery, UsersQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
}
export function useUsersLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UsersQuery, UsersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
}
export type UsersQueryHookResult = ReturnType<typeof useUsersQuery>;
export type UsersLazyQueryHookResult = ReturnType<typeof useUsersLazyQuery>;
export type UsersQueryResult = ApolloReactCommon.QueryResult<UsersQuery, UsersQueryVariables>;
export const BulkIssueCouponsDocument = gql`
  mutation BulkIssueCoupons($input: bulkIssueCouponsInput) {
    bulkIssueCoupons(input: $input) {
      executionArn
    }
  }
`;
export type BulkIssueCouponsMutationFn = ApolloReactCommon.MutationFunction<
  BulkIssueCouponsMutation,
  BulkIssueCouponsMutationVariables
>;

/**
 * __useBulkIssueCouponsMutation__
 *
 * To run a mutation, you first call `useBulkIssueCouponsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkIssueCouponsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkIssueCouponsMutation, { data, loading, error }] = useBulkIssueCouponsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBulkIssueCouponsMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<BulkIssueCouponsMutation, BulkIssueCouponsMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<BulkIssueCouponsMutation, BulkIssueCouponsMutationVariables>(
    BulkIssueCouponsDocument,
    options
  );
}
export type BulkIssueCouponsMutationHookResult = ReturnType<typeof useBulkIssueCouponsMutation>;
export type BulkIssueCouponsMutationResult = ApolloReactCommon.MutationResult<BulkIssueCouponsMutation>;
export type BulkIssueCouponsMutationOptions = ApolloReactCommon.BaseMutationOptions<
  BulkIssueCouponsMutation,
  BulkIssueCouponsMutationVariables
>;
export const BulkShopCsvDocument = gql`
  mutation BulkShopCsv($input: BulkShopCsvInput!) {
    bulkShopCsv(input: $input) {
      executionArn
    }
  }
`;
export type BulkShopCsvMutationFn = ApolloReactCommon.MutationFunction<
  BulkShopCsvMutation,
  BulkShopCsvMutationVariables
>;

/**
 * __useBulkShopCsvMutation__
 *
 * To run a mutation, you first call `useBulkShopCsvMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkShopCsvMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkShopCsvMutation, { data, loading, error }] = useBulkShopCsvMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBulkShopCsvMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<BulkShopCsvMutation, BulkShopCsvMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<BulkShopCsvMutation, BulkShopCsvMutationVariables>(BulkShopCsvDocument, options);
}
export type BulkShopCsvMutationHookResult = ReturnType<typeof useBulkShopCsvMutation>;
export type BulkShopCsvMutationResult = ApolloReactCommon.MutationResult<BulkShopCsvMutation>;
export type BulkShopCsvMutationOptions = ApolloReactCommon.BaseMutationOptions<
  BulkShopCsvMutation,
  BulkShopCsvMutationVariables
>;
export const CouponMasterUploadImageUrlDocument = gql`
  mutation CouponMasterUploadImageUrl($input: UploadImageInput!) {
    couponMasterUploadImageUrl(input: $input) {
      imageType
      key
      preSignedUrl
    }
  }
`;
export type CouponMasterUploadImageUrlMutationFn = ApolloReactCommon.MutationFunction<
  CouponMasterUploadImageUrlMutation,
  CouponMasterUploadImageUrlMutationVariables
>;

/**
 * __useCouponMasterUploadImageUrlMutation__
 *
 * To run a mutation, you first call `useCouponMasterUploadImageUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCouponMasterUploadImageUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [couponMasterUploadImageUrlMutation, { data, loading, error }] = useCouponMasterUploadImageUrlMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCouponMasterUploadImageUrlMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CouponMasterUploadImageUrlMutation,
    CouponMasterUploadImageUrlMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<CouponMasterUploadImageUrlMutation, CouponMasterUploadImageUrlMutationVariables>(
    CouponMasterUploadImageUrlDocument,
    options
  );
}
export type CouponMasterUploadImageUrlMutationHookResult = ReturnType<typeof useCouponMasterUploadImageUrlMutation>;
export type CouponMasterUploadImageUrlMutationResult =
  ApolloReactCommon.MutationResult<CouponMasterUploadImageUrlMutation>;
export type CouponMasterUploadImageUrlMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CouponMasterUploadImageUrlMutation,
  CouponMasterUploadImageUrlMutationVariables
>;
export const CreateCouponMasterDocument = gql`
  mutation CreateCouponMaster($input: createCouponMasterInput) {
    createCouponMaster(input: $input) {
      applyBarcode {
        displayType
        sourceType
      }
      applyWith
      available
      availableDate
      availableDays
      availableEndTimePeriod
      availableStartTimePeriod
      companyImage {
        key
      }
      companyImageUrl
      couponCode
      couponIssuedMaximumNumber
      couponIssuerBandColor
      couponIssuerTextColor
      couponName
      couponNameBandColor
      couponNameHtml
      couponNameTextColor
      createAtDateTime
      description
      displayEndDate
      displayStartDate
      id
      information
      layoutPattern
      mapUrl
      meta
      note
      noteParagraph
      organizationId
      productImage {
        key
      }
      productImageUrl
      realTimeIssuedGetEndDateTime
      realTimeIssuedGetStartDateTime
      smartCheckImage {
        smartCheckConfirmButtonImage {
          key
        }
        smartCheckConfirmCancelButtonImage {
          key
        }
        smartCheckForceButtonImage {
          key
        }
        smartCheckHeaderImage {
          key
        }
        smartCheckUseButtonImage {
          key
        }
        smartCheckUsedImage {
          key
        }
      }
      smartCheckImageUrl {
        smartCheckConfirmButtonImageUrl
        smartCheckConfirmCancelButtonImageUrl
        smartCheckForceButtonImageUrl
        smartCheckHeaderImageUrl
        smartCheckUseButtonImageUrl
        smartCheckUsedImageUrl
      }
      smartCheckSetting {
        countDownMinute
        smartCheckConfirmText
        smartCheckCountDownText
        smartCheckUseButtonBottomText
        staticBarcodes {
          barcode
          barcodeType
        }
      }
      telNumber
      timeFrame
      updateAtDateTime
      validityEndDateTime
      validityStartDateTime
    }
  }
`;
export type CreateCouponMasterMutationFn = ApolloReactCommon.MutationFunction<
  CreateCouponMasterMutation,
  CreateCouponMasterMutationVariables
>;

/**
 * __useCreateCouponMasterMutation__
 *
 * To run a mutation, you first call `useCreateCouponMasterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCouponMasterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCouponMasterMutation, { data, loading, error }] = useCreateCouponMasterMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCouponMasterMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<CreateCouponMasterMutation, CreateCouponMasterMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<CreateCouponMasterMutation, CreateCouponMasterMutationVariables>(
    CreateCouponMasterDocument,
    options
  );
}
export type CreateCouponMasterMutationHookResult = ReturnType<typeof useCreateCouponMasterMutation>;
export type CreateCouponMasterMutationResult = ApolloReactCommon.MutationResult<CreateCouponMasterMutation>;
export type CreateCouponMasterMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateCouponMasterMutation,
  CreateCouponMasterMutationVariables
>;
export const CreateShopDocument = gql`
  mutation CreateShop($input: CreateShopInput!) {
    createShop(input: $input) {
      billing
      customerShopCode
      endDate
      id
      name
      parentId
      shopCode
      shopNumber
      simpleClearingCode
      startDate
      type
    }
  }
`;
export type CreateShopMutationFn = ApolloReactCommon.MutationFunction<CreateShopMutation, CreateShopMutationVariables>;

/**
 * __useCreateShopMutation__
 *
 * To run a mutation, you first call `useCreateShopMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateShopMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createShopMutation, { data, loading, error }] = useCreateShopMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateShopMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<CreateShopMutation, CreateShopMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<CreateShopMutation, CreateShopMutationVariables>(CreateShopDocument, options);
}
export type CreateShopMutationHookResult = ReturnType<typeof useCreateShopMutation>;
export type CreateShopMutationResult = ApolloReactCommon.MutationResult<CreateShopMutation>;
export type CreateShopMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateShopMutation,
  CreateShopMutationVariables
>;
export const CreateShopGroupDocument = gql`
  mutation CreateShopGroup($input: CreateShopGroupInput!) {
    createShopGroup(input: $input) {
      id
      memberShopCode
      name
      parentId
      type
    }
  }
`;
export type CreateShopGroupMutationFn = ApolloReactCommon.MutationFunction<
  CreateShopGroupMutation,
  CreateShopGroupMutationVariables
>;

/**
 * __useCreateShopGroupMutation__
 *
 * To run a mutation, you first call `useCreateShopGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateShopGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createShopGroupMutation, { data, loading, error }] = useCreateShopGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateShopGroupMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<CreateShopGroupMutation, CreateShopGroupMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<CreateShopGroupMutation, CreateShopGroupMutationVariables>(
    CreateShopGroupDocument,
    options
  );
}
export type CreateShopGroupMutationHookResult = ReturnType<typeof useCreateShopGroupMutation>;
export type CreateShopGroupMutationResult = ApolloReactCommon.MutationResult<CreateShopGroupMutation>;
export type CreateShopGroupMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateShopGroupMutation,
  CreateShopGroupMutationVariables
>;
export const CreateUserDocument = gql`
  mutation CreateUser($input: CreateUserInput!) {
    createUser(input: $input) {
      createAtDateTime
      email
      id
      isAdministrator
      lastLoginDateTime
      name
      organizationId
      permissions
      role
      roleId
      updateAtDateTime
    }
  }
`;
export type CreateUserMutationFn = ApolloReactCommon.MutationFunction<CreateUserMutation, CreateUserMutationVariables>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUserMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<CreateUserMutation, CreateUserMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<CreateUserMutation, CreateUserMutationVariables>(CreateUserDocument, options);
}
export type CreateUserMutationHookResult = ReturnType<typeof useCreateUserMutation>;
export type CreateUserMutationResult = ApolloReactCommon.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateUserMutation,
  CreateUserMutationVariables
>;
export const DeleteCouponMasterDocument = gql`
  mutation DeleteCouponMaster($input: deleteCouponMasterInput) {
    deleteCouponMaster(input: $input) {
      applyBarcode {
        displayType
        sourceType
      }
      applyWith
      available
      availableDate
      availableDays
      availableEndTimePeriod
      availableStartTimePeriod
      companyImage {
        key
      }
      companyImageUrl
      couponCode
      couponIssuedMaximumNumber
      couponIssuerBandColor
      couponIssuerTextColor
      couponName
      couponNameBandColor
      couponNameHtml
      couponNameTextColor
      createAtDateTime
      description
      displayEndDate
      displayStartDate
      id
      information
      layoutPattern
      mapUrl
      meta
      note
      noteParagraph
      organizationId
      productImage {
        key
      }
      productImageUrl
      realTimeIssuedGetEndDateTime
      realTimeIssuedGetStartDateTime
      smartCheckImage {
        smartCheckConfirmButtonImage {
          key
        }
        smartCheckConfirmCancelButtonImage {
          key
        }
        smartCheckForceButtonImage {
          key
        }
        smartCheckHeaderImage {
          key
        }
        smartCheckUseButtonImage {
          key
        }
        smartCheckUsedImage {
          key
        }
      }
      smartCheckImageUrl {
        smartCheckConfirmButtonImageUrl
        smartCheckConfirmCancelButtonImageUrl
        smartCheckForceButtonImageUrl
        smartCheckHeaderImageUrl
        smartCheckUseButtonImageUrl
        smartCheckUsedImageUrl
      }
      smartCheckSetting {
        countDownMinute
        smartCheckConfirmText
        smartCheckCountDownText
        smartCheckUseButtonBottomText
        staticBarcodes {
          barcode
          barcodeType
        }
      }
      telNumber
      timeFrame
      updateAtDateTime
      validityEndDateTime
      validityStartDateTime
    }
  }
`;
export type DeleteCouponMasterMutationFn = ApolloReactCommon.MutationFunction<
  DeleteCouponMasterMutation,
  DeleteCouponMasterMutationVariables
>;

/**
 * __useDeleteCouponMasterMutation__
 *
 * To run a mutation, you first call `useDeleteCouponMasterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCouponMasterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCouponMasterMutation, { data, loading, error }] = useDeleteCouponMasterMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteCouponMasterMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteCouponMasterMutation, DeleteCouponMasterMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<DeleteCouponMasterMutation, DeleteCouponMasterMutationVariables>(
    DeleteCouponMasterDocument,
    options
  );
}
export type DeleteCouponMasterMutationHookResult = ReturnType<typeof useDeleteCouponMasterMutation>;
export type DeleteCouponMasterMutationResult = ApolloReactCommon.MutationResult<DeleteCouponMasterMutation>;
export type DeleteCouponMasterMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteCouponMasterMutation,
  DeleteCouponMasterMutationVariables
>;
export const DeleteShopDocument = gql`
  mutation DeleteShop($id: String!) {
    deleteShop(id: $id)
  }
`;
export type DeleteShopMutationFn = ApolloReactCommon.MutationFunction<DeleteShopMutation, DeleteShopMutationVariables>;

/**
 * __useDeleteShopMutation__
 *
 * To run a mutation, you first call `useDeleteShopMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteShopMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteShopMutation, { data, loading, error }] = useDeleteShopMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteShopMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteShopMutation, DeleteShopMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<DeleteShopMutation, DeleteShopMutationVariables>(DeleteShopDocument, options);
}
export type DeleteShopMutationHookResult = ReturnType<typeof useDeleteShopMutation>;
export type DeleteShopMutationResult = ApolloReactCommon.MutationResult<DeleteShopMutation>;
export type DeleteShopMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteShopMutation,
  DeleteShopMutationVariables
>;
export const DeleteShopGroupDocument = gql`
  mutation DeleteShopGroup($id: String!, $type: OrganizationType!) {
    deleteShopGroup(id: $id, type: $type)
  }
`;
export type DeleteShopGroupMutationFn = ApolloReactCommon.MutationFunction<
  DeleteShopGroupMutation,
  DeleteShopGroupMutationVariables
>;

/**
 * __useDeleteShopGroupMutation__
 *
 * To run a mutation, you first call `useDeleteShopGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteShopGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteShopGroupMutation, { data, loading, error }] = useDeleteShopGroupMutation({
 *   variables: {
 *      id: // value for 'id'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useDeleteShopGroupMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteShopGroupMutation, DeleteShopGroupMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<DeleteShopGroupMutation, DeleteShopGroupMutationVariables>(
    DeleteShopGroupDocument,
    options
  );
}
export type DeleteShopGroupMutationHookResult = ReturnType<typeof useDeleteShopGroupMutation>;
export type DeleteShopGroupMutationResult = ApolloReactCommon.MutationResult<DeleteShopGroupMutation>;
export type DeleteShopGroupMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteShopGroupMutation,
  DeleteShopGroupMutationVariables
>;
export const DeleteUserDocument = gql`
  mutation DeleteUser($id: String!) {
    deleteUser(id: $id)
  }
`;
export type DeleteUserMutationFn = ApolloReactCommon.MutationFunction<DeleteUserMutation, DeleteUserMutationVariables>;

/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMutation, { data, loading, error }] = useDeleteUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteUserMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteUserMutation, DeleteUserMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<DeleteUserMutation, DeleteUserMutationVariables>(DeleteUserDocument, options);
}
export type DeleteUserMutationHookResult = ReturnType<typeof useDeleteUserMutation>;
export type DeleteUserMutationResult = ApolloReactCommon.MutationResult<DeleteUserMutation>;
export type DeleteUserMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteUserMutation,
  DeleteUserMutationVariables
>;
export const UpdateCouponMasterDocument = gql`
  mutation UpdateCouponMaster($id: String!, $input: updateCouponMasterInput) {
    updateCouponMaster(id: $id, input: $input) {
      applyBarcode {
        displayType
        sourceType
      }
      applyWith
      available
      availableDate
      availableDays
      availableEndTimePeriod
      availableStartTimePeriod
      companyImage {
        key
      }
      companyImageUrl
      couponCode
      couponIssuedMaximumNumber
      couponIssuerBandColor
      couponIssuerTextColor
      couponName
      couponNameBandColor
      couponNameHtml
      couponNameTextColor
      createAtDateTime
      description
      displayEndDate
      displayStartDate
      id
      information
      layoutPattern
      mapUrl
      meta
      note
      noteParagraph
      organizationId
      productImage {
        key
      }
      productImageUrl
      realTimeIssuedGetEndDateTime
      realTimeIssuedGetStartDateTime
      smartCheckImage {
        smartCheckConfirmButtonImage {
          key
        }
        smartCheckConfirmCancelButtonImage {
          key
        }
        smartCheckForceButtonImage {
          key
        }
        smartCheckHeaderImage {
          key
        }
        smartCheckUseButtonImage {
          key
        }
        smartCheckUsedImage {
          key
        }
      }
      smartCheckImageUrl {
        smartCheckConfirmButtonImageUrl
        smartCheckConfirmCancelButtonImageUrl
        smartCheckForceButtonImageUrl
        smartCheckHeaderImageUrl
        smartCheckUseButtonImageUrl
        smartCheckUsedImageUrl
      }
      smartCheckSetting {
        countDownMinute
        smartCheckConfirmText
        smartCheckCountDownText
        smartCheckUseButtonBottomText
        staticBarcodes {
          barcode
          barcodeType
        }
      }
      telNumber
      timeFrame
      updateAtDateTime
      validityEndDateTime
      validityStartDateTime
    }
  }
`;
export type UpdateCouponMasterMutationFn = ApolloReactCommon.MutationFunction<
  UpdateCouponMasterMutation,
  UpdateCouponMasterMutationVariables
>;

/**
 * __useUpdateCouponMasterMutation__
 *
 * To run a mutation, you first call `useUpdateCouponMasterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCouponMasterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCouponMasterMutation, { data, loading, error }] = useUpdateCouponMasterMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCouponMasterMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateCouponMasterMutation, UpdateCouponMasterMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<UpdateCouponMasterMutation, UpdateCouponMasterMutationVariables>(
    UpdateCouponMasterDocument,
    options
  );
}
export type UpdateCouponMasterMutationHookResult = ReturnType<typeof useUpdateCouponMasterMutation>;
export type UpdateCouponMasterMutationResult = ApolloReactCommon.MutationResult<UpdateCouponMasterMutation>;
export type UpdateCouponMasterMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateCouponMasterMutation,
  UpdateCouponMasterMutationVariables
>;
export const UpdateCouponStatusDocument = gql`
  mutation UpdateCouponStatus($input: updateCouponStatusInput) {
    updateCouponStatus(input: $input)
  }
`;
export type UpdateCouponStatusMutationFn = ApolloReactCommon.MutationFunction<
  UpdateCouponStatusMutation,
  UpdateCouponStatusMutationVariables
>;

/**
 * __useUpdateCouponStatusMutation__
 *
 * To run a mutation, you first call `useUpdateCouponStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCouponStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCouponStatusMutation, { data, loading, error }] = useUpdateCouponStatusMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCouponStatusMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateCouponStatusMutation, UpdateCouponStatusMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<UpdateCouponStatusMutation, UpdateCouponStatusMutationVariables>(
    UpdateCouponStatusDocument,
    options
  );
}
export type UpdateCouponStatusMutationHookResult = ReturnType<typeof useUpdateCouponStatusMutation>;
export type UpdateCouponStatusMutationResult = ApolloReactCommon.MutationResult<UpdateCouponStatusMutation>;
export type UpdateCouponStatusMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateCouponStatusMutation,
  UpdateCouponStatusMutationVariables
>;
export const UpdateShopDocument = gql`
  mutation UpdateShop($id: String!, $input: UpdateShopInput!) {
    updateShop(id: $id, input: $input) {
      billing
      customerShopCode
      endDate
      id
      name
      parentId
      shopCode
      shopNumber
      simpleClearingCode
      startDate
      type
    }
  }
`;
export type UpdateShopMutationFn = ApolloReactCommon.MutationFunction<UpdateShopMutation, UpdateShopMutationVariables>;

/**
 * __useUpdateShopMutation__
 *
 * To run a mutation, you first call `useUpdateShopMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateShopMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateShopMutation, { data, loading, error }] = useUpdateShopMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateShopMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateShopMutation, UpdateShopMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<UpdateShopMutation, UpdateShopMutationVariables>(UpdateShopDocument, options);
}
export type UpdateShopMutationHookResult = ReturnType<typeof useUpdateShopMutation>;
export type UpdateShopMutationResult = ApolloReactCommon.MutationResult<UpdateShopMutation>;
export type UpdateShopMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateShopMutation,
  UpdateShopMutationVariables
>;
export const UpdateShopGroupDocument = gql`
  mutation UpdateShopGroup($id: String!, $input: UpdateShopGroupInput!) {
    updateShopGroup(id: $id, input: $input) {
      id
      memberShopCode
      name
      parentId
      type
    }
  }
`;
export type UpdateShopGroupMutationFn = ApolloReactCommon.MutationFunction<
  UpdateShopGroupMutation,
  UpdateShopGroupMutationVariables
>;

/**
 * __useUpdateShopGroupMutation__
 *
 * To run a mutation, you first call `useUpdateShopGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateShopGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateShopGroupMutation, { data, loading, error }] = useUpdateShopGroupMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateShopGroupMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateShopGroupMutation, UpdateShopGroupMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<UpdateShopGroupMutation, UpdateShopGroupMutationVariables>(
    UpdateShopGroupDocument,
    options
  );
}
export type UpdateShopGroupMutationHookResult = ReturnType<typeof useUpdateShopGroupMutation>;
export type UpdateShopGroupMutationResult = ApolloReactCommon.MutationResult<UpdateShopGroupMutation>;
export type UpdateShopGroupMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateShopGroupMutation,
  UpdateShopGroupMutationVariables
>;
export const UpdateUserNameDocument = gql`
  mutation UpdateUserName($id: String!, $name: String!) {
    updateUserName(id: $id, name: $name) {
      createAtDateTime
      email
      id
      isAdministrator
      lastLoginDateTime
      name
      organizationId
      permissions
      role
      roleId
      updateAtDateTime
    }
  }
`;
export type UpdateUserNameMutationFn = ApolloReactCommon.MutationFunction<
  UpdateUserNameMutation,
  UpdateUserNameMutationVariables
>;

/**
 * __useUpdateUserNameMutation__
 *
 * To run a mutation, you first call `useUpdateUserNameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserNameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserNameMutation, { data, loading, error }] = useUpdateUserNameMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useUpdateUserNameMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateUserNameMutation, UpdateUserNameMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<UpdateUserNameMutation, UpdateUserNameMutationVariables>(
    UpdateUserNameDocument,
    options
  );
}
export type UpdateUserNameMutationHookResult = ReturnType<typeof useUpdateUserNameMutation>;
export type UpdateUserNameMutationResult = ApolloReactCommon.MutationResult<UpdateUserNameMutation>;
export type UpdateUserNameMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateUserNameMutation,
  UpdateUserNameMutationVariables
>;
export const UpdateUserPasswordDocument = gql`
  mutation UpdateUserPassword($id: String!, $password: String!) {
    updateUserPassword(id: $id, password: $password) {
      createAtDateTime
      email
      id
      isAdministrator
      lastLoginDateTime
      name
      organizationId
      permissions
      role
      roleId
      updateAtDateTime
    }
  }
`;
export type UpdateUserPasswordMutationFn = ApolloReactCommon.MutationFunction<
  UpdateUserPasswordMutation,
  UpdateUserPasswordMutationVariables
>;

/**
 * __useUpdateUserPasswordMutation__
 *
 * To run a mutation, you first call `useUpdateUserPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserPasswordMutation, { data, loading, error }] = useUpdateUserPasswordMutation({
 *   variables: {
 *      id: // value for 'id'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useUpdateUserPasswordMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateUserPasswordMutation, UpdateUserPasswordMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<UpdateUserPasswordMutation, UpdateUserPasswordMutationVariables>(
    UpdateUserPasswordDocument,
    options
  );
}
export type UpdateUserPasswordMutationHookResult = ReturnType<typeof useUpdateUserPasswordMutation>;
export type UpdateUserPasswordMutationResult = ApolloReactCommon.MutationResult<UpdateUserPasswordMutation>;
export type UpdateUserPasswordMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateUserPasswordMutation,
  UpdateUserPasswordMutationVariables
>;
export const UpdateUserRoleDocument = gql`
  mutation UpdateUserRole($id: String!, $isAdministrator: Boolean!, $roleId: String!) {
    updateUserRole(id: $id, isAdministrator: $isAdministrator, roleId: $roleId) {
      createAtDateTime
      email
      id
      isAdministrator
      lastLoginDateTime
      name
      organizationId
      permissions
      role
      roleId
      updateAtDateTime
    }
  }
`;
export type UpdateUserRoleMutationFn = ApolloReactCommon.MutationFunction<
  UpdateUserRoleMutation,
  UpdateUserRoleMutationVariables
>;

/**
 * __useUpdateUserRoleMutation__
 *
 * To run a mutation, you first call `useUpdateUserRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserRoleMutation, { data, loading, error }] = useUpdateUserRoleMutation({
 *   variables: {
 *      id: // value for 'id'
 *      isAdministrator: // value for 'isAdministrator'
 *      roleId: // value for 'roleId'
 *   },
 * });
 */
export function useUpdateUserRoleMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateUserRoleMutation, UpdateUserRoleMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<UpdateUserRoleMutation, UpdateUserRoleMutationVariables>(
    UpdateUserRoleDocument,
    options
  );
}
export type UpdateUserRoleMutationHookResult = ReturnType<typeof useUpdateUserRoleMutation>;
export type UpdateUserRoleMutationResult = ApolloReactCommon.MutationResult<UpdateUserRoleMutation>;
export type UpdateUserRoleMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateUserRoleMutation,
  UpdateUserRoleMutationVariables
>;
export const UpsertOrganizationSuiteDocument = gql`
  mutation UpsertOrganizationSuite(
    $category: SuiteCategory!
    $organizationId: String!
    $suite: UpsertOrganizationSuiteInput!
  ) {
    upsertOrganizationSuite(category: $category, organizationId: $organizationId, suite: $suite) {
      apply {
        barcode {
          displayType
          sourceType
        }
        with
      }
      id
      takeOver {
        apply
        timeFrame
      }
      timeFrame {
        available
      }
    }
  }
`;
export type UpsertOrganizationSuiteMutationFn = ApolloReactCommon.MutationFunction<
  UpsertOrganizationSuiteMutation,
  UpsertOrganizationSuiteMutationVariables
>;

/**
 * __useUpsertOrganizationSuiteMutation__
 *
 * To run a mutation, you first call `useUpsertOrganizationSuiteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertOrganizationSuiteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertOrganizationSuiteMutation, { data, loading, error }] = useUpsertOrganizationSuiteMutation({
 *   variables: {
 *      category: // value for 'category'
 *      organizationId: // value for 'organizationId'
 *      suite: // value for 'suite'
 *   },
 * });
 */
export function useUpsertOrganizationSuiteMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpsertOrganizationSuiteMutation,
    UpsertOrganizationSuiteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<UpsertOrganizationSuiteMutation, UpsertOrganizationSuiteMutationVariables>(
    UpsertOrganizationSuiteDocument,
    options
  );
}
export type UpsertOrganizationSuiteMutationHookResult = ReturnType<typeof useUpsertOrganizationSuiteMutation>;
export type UpsertOrganizationSuiteMutationResult = ApolloReactCommon.MutationResult<UpsertOrganizationSuiteMutation>;
export type UpsertOrganizationSuiteMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpsertOrganizationSuiteMutation,
  UpsertOrganizationSuiteMutationVariables
>;
