import Bugsnag from '@bugsnag/js';

import { useSnackbar } from 'notistack';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { UpsertOrganizationSuiteFormValues } from '../pages/UpsertOrgnizationSuitePage';
import { CouponFeature, OrganizationContext } from '../../../components/contexts/organization';
import {
  OrganizationSuiteQuery,
  useOrganizationSuiteLazyQuery,
  useUpsertOrganizationSuiteMutation,
} from '../../../graphql/generated';

type OrganizationSuite = OrganizationSuiteQuery['organizationSuite'];

export type UpsertOrganizationSuiteUseCase = {
  loading: boolean;
  suite?: OrganizationSuite;
  onUpsert: (input: UpsertOrganizationSuiteFormValues) => void;
};

export const useUpsertOrganizationSuiteUseCase = (organizationId: string): UpsertOrganizationSuiteUseCase => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const organization = useContext(OrganizationContext);
  const history = useHistory();
  const [featureLoading, setFeatureLoading] = useState<boolean>(false);
  const [upsertOrganizationSuiteMutation, { loading, error }] = useUpsertOrganizationSuiteMutation();

  const [featureQuery, { data }] = useOrganizationSuiteLazyQuery({
    fetchPolicy: 'cache-first',
    onCompleted: async () => {
      setFeatureLoading(false);
    },
    onError: (err: Error) => {
      console.error('err', err);
      setFeatureLoading(false);
    },
  });

  useEffect(() => {
    featureQuery({
      variables: {
        id: organizationId,
      },
    });
  }, [organizationId]);

  // 組織機能を登録する
  const onUpsert = useCallback(
    async (input: UpsertOrganizationSuiteFormValues) => {
      closeSnackbar();

      console.log('input', input);

      const { data } = await upsertOrganizationSuiteMutation({
        variables: {
          organizationId,
          category: 'coupon',
          suite: {
            applyWith: input.applyWith,
            applyBarcodeSourceType: input.applyBarcodeSourceType,
            applyBarcodeDisplayType: input.applyBarcodeDisplayType,
          },
        },
      });
      console.log('suite', data);

      if (error) {
        Bugsnag.notify(error);
        console.error(error);
        enqueueSnackbar(`組織の更新に失敗しました: ${error}`, {
          variant: 'error',
          persist: true,
        });
        return;
      }

      enqueueSnackbar(`組織機能を更新しました`, {
        variant: 'success',
      });

      // コンテキストの組織機能を更新する
      if (data) {
        organization.setCouponFeature({
          timeFrame: data.upsertOrganizationSuite.timeFrame,
          apply: data.upsertOrganizationSuite.apply,
          takeOver: data.upsertOrganizationSuite.takeOver as CouponFeature['takeOver'],
        });
      }

      history.push({
        pathname: `/manage-organizations/${organizationId}`,
        hash: '#about',
        search: history.location.search,
      });
    },
    [upsertOrganizationSuiteMutation, enqueueSnackbar]
  );

  return {
    loading: featureLoading || loading,
    suite: data?.organizationSuite,
    onUpsert,
  };
};
