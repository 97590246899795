import { DateTime } from 'luxon';

export const getRequiredEnvironmentVariable = (envName: string): string => {
  const res = process.env[envName];
  if (res == null || res === '') {
    throw new Error(`environment ${envName} is not found.`);
  }

  return res;
};

export function qs(url: string): { [key: string]: string } {
  return url
    .substring(1)
    .split('&')
    .map((p) => p.split('='))
    .reduce((obj, e) => ({ ...obj, [e[0]]: e[1] }), {});
}

export function formatBytes(bytes: number, decimals = 2): string {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

// 2021年4月5日
export function formatJstDateJapanese(isoString?: string): string {
  if (!isoString) {
    return '';
  }
  return DateTime.fromISO(isoString).toFormat('yyyy年MM月dd日');
}

// 2021年4月5日 23:45
export function formatJstDateTimeJapanese(isoString?: string): string {
  if (!isoString) {
    return '';
  }
  return DateTime.fromISO(isoString).toFormat('yyyy年MM月dd日 HH:mm');
}

// 2021年4月5日 23:45:56
export function formatJstDateTimeSecondJapanese(isoString?: string): string {
  if (!isoString) {
    return '';
  }
  return DateTime.fromISO(isoString).toFormat('yyyy年MM月dd日 HH:mm:ss');
}

// 2021/4/5
export function formatJstDate(isoString?: string): string {
  if (!isoString) {
    return '';
  }
  return DateTime.fromISO(isoString).toFormat('yyyy/MM/dd');
}

// 2021/4/5 23:45
export function formatJstDateTime(isoString?: string): string {
  if (!isoString) {
    return '';
  }
  return DateTime.fromISO(isoString).toFormat('yyyy/MM/dd HH:mm');
}

// 202104052345
export function formatJstDateTimeNumeric(isoString?: string): string {
  if (!isoString) {
    return '';
  }
  return DateTime.fromISO(isoString).toFormat('yyyyMMddHHmm');
}

export function formatNumberToJaJpLocale(num: number): string {
  return num.toLocaleString('ja');
}

// "?topic=webdev" => "?topic=webdev&key=value"
export function appendSearchParameter(base: string, kvs: { [key: string]: string }): string {
  const param = new URLSearchParams(base);
  Object.entries(kvs).forEach(([k, v]) => {
    param.append(k, v);
  });
  return param.toString();
}

/**
 * チェックデジットチェック
 * @param barcodeStr
 */
export function eanCheckDigit(barcodeStr: string): boolean {
  const barcode = ('00000' + barcodeStr).slice(-13);
  let evenNum = 0;
  let oddNum = 0;
  for (let i = 0; i < barcode.length - 1; i++) {
    if (i % 2 == 0) {
      oddNum += Number(barcode[i]);
    } else {
      evenNum += Number(barcode[i]);
    }
  }

  const checkNumber = Number((evenNum * 3 + oddNum).toString().slice(-1));
  const checkDigit = Number(barcode.slice(-1));

  // 下1桁が0の場合は、チェックディジットは0
  if (checkNumber === 0) {
    return checkNumber === checkDigit;
  }

  return 10 - checkNumber === checkDigit;
}
