import {
  CouponBulkHistoriesQuery,
  CouponBulkHistory,
  PreSignedGetUrlQuery,
  useCouponBulkHistoriesLazyQuery,
  useCouponMasterLazyQuery,
  usePreSignedGetUrlLazyQuery,
} from '../../../../graphql/generated';
import { useCallback, useEffect, useState } from 'react';
import { formatJstDateTimeNumeric } from '../../../../util';
import { downloadCsvFileWithBom } from '../../../../util/download';

const PageItemsCount = 25;

type ListCouponBulkHistories = {
  loading: boolean;
  downloadInProgressId: string;
  data?: CouponBulkHistoriesQuery;
  onFetchMore: () => void;
  onDownload: (row: CouponBulkHistory) => void;
  refetch: () => void;
};

export const useListCouponBulkHistories = (couponMasterId: string): ListCouponBulkHistories => {
  const [loading, setLoading] = useState(false);
  const [downloadInProgressId, setDownloadInProgressId] = useState('');

  const [couponMasterQuery, { data, loading: masterLoading }] = useCouponMasterLazyQuery({
    fetchPolicy: 'cache-first',
  });

  // クーポンマスタを取得する
  useEffect(() => {
    couponMasterQuery({
      variables: {
        couponMasterId,
      },
    });
  }, [couponMasterId]);

  // クーポン一括発行履歴一覧を取得
  const [historiesQuery, queryContext] = useCouponBulkHistoriesLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: () => {
      setLoading(false);
    },
    onError: console.error,
  });

  // 再読み込み。新しくダウンロードを実行した後などを想定
  const refetch = useCallback(() => {
    setLoading(true);
    historiesQuery({
      variables: {
        couponMasterId,
        limit: PageItemsCount,
      },
    });
  }, [historiesQuery]);

  // 初回読み込み
  useEffect(() => {
    refetch();
  }, [historiesQuery]);

  // クリックされた行の再ダウンロードURLを取得するLazy Query
  const [getBulkIssuedCouponsUrl] = usePreSignedGetUrlLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: (query: PreSignedGetUrlQuery) => {
      downloadCsvFileWithBom(query.preSignedGetUrl.preSignedUrl, () => setDownloadInProgressId(''));
    },
  });

  // 行のダウンロードボタンを押したときにPreSignedUrlを取得して再ダウンロードする
  const onDownload = useCallback(
    (row: CouponBulkHistory): void => {
      if (!data?.couponMaster?.couponCode) {
        return;
      }
      setDownloadInProgressId(row.id);
      getBulkIssuedCouponsUrl({
        variables: {
          bucket: row.bucket,
          key: row.key,
          fileName: `${data.couponMaster.couponCode}_${formatJstDateTimeNumeric(row.publishDateTime)}.csv`,
        },
      });
    },
    [getBulkIssuedCouponsUrl, data?.couponMaster]
  );

  // さらに読み込むボタンクリック
  const onFetchMore = useCallback(async (): Promise<void> => {
    if (queryContext?.fetchMore && queryContext.variables) {
      setLoading(true);
      await queryContext.fetchMore({
        variables: {
          couponMasterId,
          nextToken: queryContext.data?.couponBulkHistories.pageInfo.nextToken,
          limit: PageItemsCount,
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          setLoading(false);
          if (!fetchMoreResult) {
            return prev;
          }
          return {
            couponBulkHistories: {
              __typename: fetchMoreResult.couponBulkHistories.__typename,
              nodes: [...prev.couponBulkHistories.nodes, ...fetchMoreResult.couponBulkHistories.nodes],
              pageInfo: fetchMoreResult.couponBulkHistories.pageInfo,
            },
          };
        },
      });
    }
  }, [queryContext]);

  return {
    loading: loading || masterLoading,
    downloadInProgressId,
    data: queryContext.data,
    onFetchMore,
    onDownload,
    refetch,
  };
};
