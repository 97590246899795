import React, { useCallback } from 'react';
import {
  Button,
  CircularProgress,
  Grid,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@material-ui/core';
import { BasicTableHead, BasicTableHeadProps } from '../../../components/molecules';
import { useTheme } from '@material-ui/core/styles';
import { CouponMaster, PollCouponUsageLogJsonQuery } from '../../../graphql/generated';
import { formatJstDateTimeJapanese } from '../../../util';
import { OperateFromObject } from './hooks';
import { useIssuedCouponUsageLog } from './hooks/issued-couponusage-log-use-case';
import { useParams } from 'react-router-dom';
import { PathParams } from '../../../App';

export type IssuedCouponUsageLogProps = {
  data?: PollCouponUsageLogJsonQuery;
  couponMaster?: CouponMaster;
  loading: boolean;
  csvLoading: boolean;
  onCsvOut: () => void;
  onFetchMore: () => void;
};

export const IssuedCouponUsageLog: React.FC = () => {
  const theme = useTheme();
  const { couponMasterId, couponId } = useParams<PathParams>();
  const usageHistories = useIssuedCouponUsageLog(couponMasterId, couponId);
  const { data, couponMaster, loading, csvLoading, onFetchMore, onCsvOut } = usageHistories;

  const posUsageTableHeadProps: BasicTableHeadProps = {
    columns: [
      {
        name: 'index',
        label: 'No',
        minWidth: theme.spacing(3),
        align: 'center',
      },
      {
        name: 'from',
        label: '操作元',
        minWidth: theme.spacing(10),
        align: 'left',
      },
      {
        name: 'status',
        label: 'ステータス',
        minWidth: theme.spacing(5),
        align: 'left',
      },
      {
        name: 'createAtMillis',
        label: '操作日時',
        minWidth: theme.spacing(10),
        align: 'left',
      },
      {
        name: 'usedShopName',
        label: '利用店舗名',
        minWidth: theme.spacing(10),
        align: 'left',
      },
      {
        name: 'customerShopCode',
        label: '店舗コード',
        minWidth: theme.spacing(10),
        align: 'left',
      },
      {
        name: 'memberShopCode',
        label: '加盟店コード',
        minWidth: theme.spacing(10),
        align: 'left',
      },
      {
        name: 'terminalNumber',
        label: '端末コード',
        minWidth: theme.spacing(3),
        align: 'left',
      },
      {
        name: 'userName',
        label: '更新ユーザー名',
        minWidth: theme.spacing(10),
        align: 'left',
      },
    ],
  };

  const smartCheckUsageTableHeadProps: BasicTableHeadProps = {
    columns: [
      {
        name: 'index',
        label: 'No',
        minWidth: theme.spacing(3),
        align: 'center',
      },
      {
        name: 'from',
        label: '操作元',
        minWidth: theme.spacing(10),
        align: 'left',
      },
      {
        name: 'status',
        label: 'ステータス',
        minWidth: theme.spacing(5),
        align: 'left',
      },
      {
        name: 'createAtMillis',
        label: '操作日時',
        minWidth: theme.spacing(10),
        align: 'left',
      },
      {
        name: 'usedShopName',
        label: '利用店舗名',
        minWidth: theme.spacing(10),
        align: 'left',
      },
      {
        name: 'memberShopCode',
        label: '加盟店コード',
        minWidth: theme.spacing(10),
        align: 'left',
      },
      {
        name: 'userName',
        label: '更新ユーザー名',
        minWidth: theme.spacing(10),
        align: 'left',
      },
    ],
  };

  // テーブルボディを切り替える
  const tableHeadBody = useCallback(() => {
    if (!data || !data.pollCouponUsageLogJson || !couponMaster) {
      return;
    } else if (!data.pollCouponUsageLogJson.nodes || data.pollCouponUsageLogJson.nodes.length === 0) {
      return (
        <Table size={'small'}>
          <TableBody>
            <TableRow>
              <TableCell>データがありません</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      );
    } else if (couponMaster.applyWith === 'barcode') {
      return (
        <Table size={'small'}>
          <BasicTableHead columns={posUsageTableHeadProps.columns} />
          <TableBody>
            {data?.pollCouponUsageLogJson.nodes.map((log, index) => (
              <TableRow key={index + 1}>
                <TableCell align={'center'}>{index + 1}</TableCell>
                <TableCell>{OperateFromObject[log.operateFrom]}</TableCell>
                <TableCell>{log.status}</TableCell>
                <TableCell>{formatJstDateTimeJapanese(log.createAtMillis)}</TableCell>
                <TableCell>{log.usedShopName}</TableCell>
                <TableCell>{log.customerShopCode}</TableCell>
                <TableCell>{log.memberShopCode}</TableCell>
                <TableCell>{log.terminalNumber}</TableCell>
                <TableCell>{log.userName}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      );
    } else if (couponMaster.applyWith === 'smartCheck' || couponMaster.applyWith === 'smartCheckLight') {
      return (
        <Table size={'small'}>
          <BasicTableHead columns={smartCheckUsageTableHeadProps.columns} />
          <TableBody>
            {data?.pollCouponUsageLogJson.nodes.map((log, index) => (
              <TableRow key={index + 1}>
                <TableCell align={'center'}>{index + 1}</TableCell>
                <TableCell>{OperateFromObject[log.operateFrom]}</TableCell>
                <TableCell>{log.status}</TableCell>
                <TableCell>{formatJstDateTimeJapanese(log.createAtMillis)}</TableCell>
                <TableCell>{log.usedShopName}</TableCell>
                <TableCell>{log.memberShopCode}</TableCell>
                <TableCell>{log.userName}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      );
    }
  }, [data, couponMaster]);

  return (
    <>
      <Grid container spacing={2} justifyContent="space-between" alignItems="flex-end">
        <Grid item xs={10}>
          <Typography variant={'caption'}>※履歴への反映まで最大5分掛かる場合がございます。</Typography>
        </Grid>
        <Grid item xs={2}>
          <Button
            type="submit"
            variant="contained"
            size="small"
            color={'secondary'}
            disabled={loading || csvLoading}
            onClick={() => onCsvOut()}
            fullWidth
          >
            {csvLoading ? <CircularProgress size={30} /> : 'CSV出力'}
          </Button>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TableContainer component={Paper}>
            {loading ? <LinearProgress /> : <React.Fragment />}
            {tableHeadBody()}
          </TableContainer>
        </Grid>
        {data?.pollCouponUsageLogJson?.pageInfo?.nextToken ? (
          <Grid item xs={12}>
            <Grid container justifyContent={'center'}>
              <Button
                size="small"
                color="default"
                variant="contained"
                disabled={loading}
                onClick={() => onFetchMore()}
                fullWidth
              >
                さらに読み込む
              </Button>
            </Grid>
            {loading ? <LinearProgress /> : <React.Fragment />}
          </Grid>
        ) : (
          <></>
        )}
      </Grid>
    </>
  );
};
