import React, { useContext, useState } from 'react';
import {
  Breadcrumbs,
  Button,
  Container,
  Grid,
  Tab,
  Link as MaterialLink,
  Theme,
  Typography,
  CircularProgress,
} from '@material-ui/core';
import { useLocation, useParams } from 'react-router-dom';
import { TabContext, TabList, TabPanel } from '@material-ui/lab';
import { MenuHeaderTemplate } from '../../../components/templates/MenuHeaderTemplate';
import { makeStyles } from '@material-ui/core/styles';
import { ChildrenOrganizationListBody } from '../component/ChildrenOrganizationListBody';
import { OrganizationInfo } from '../component/OrganizationInfo';
import { useDetailOrganizationUseCase } from '../hooks/detail-organization-use-case';
import { PathParams } from '../../../App';
import { useSearchChildrenOrganization } from '../hooks/search-chldren-organization-use-case';
import { ArrowBack, Delete, Add, CloudUpload, CloudDownload } from '@material-ui/icons';
import { useUrlSearchParamOrganization } from '../../../components/contexts/hooks/url';
import { Link } from 'react-router-dom';
import { OrganizationContext } from '../../../components/contexts/organization';
import { useHistory } from 'react-router';
import { UploadShopCsvDialog } from '../component/UploadShopCsvDialog';
import { useUploadShopCsvUseCase } from '../hooks/upload-shop-csv-use-case';
import { useOutputShopCsvUseCase } from '../hooks/output-shop-csv-use-case';
import { UserContext } from '../../../components/contexts/user/user-context';
import { DeleteShopAlertDialog } from '../component/DeleteShopAleat';
import { DeleteShopGroupAlertDialog } from '../component/DeleteShopGroupAleat';

const useStyles = makeStyles((theme: Theme) => ({
  tab: {
    margin: `${theme.spacing(3)}px 0 0`,
  },
  tabPanel: {
    padding: `${theme.spacing(2)}px 0`,
  },
  tabList: {
    backgroundColor: theme.palette.grey[50],
  },
  tabListIndicator: {
    backgroundColor: theme.palette.grey[400],
  },
}));

export const OrganizationDashboardPage: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const { hash } = useLocation();
  const orgContext = useContext(OrganizationContext);
  const { userInfo } = useContext(UserContext);
  const { organizationId } = useParams<PathParams>();
  const organizationParam = useUrlSearchParamOrganization();
  const children = useSearchChildrenOrganization(organizationId);
  const organization = useDetailOrganizationUseCase(organizationId);
  const uploadShopCsv = useUploadShopCsvUseCase(organization.data?.id);
  const outputShopCsv = useOutputShopCsvUseCase();
  const [openDeleteShopAlertDialog, setOpenShopDeleteAlertDialog] = useState(false);
  const [openDeleteShopGroupAlertDialog, setOpenDeleteShopGroupAlertDialog] = useState(false);

  // 親IDが存在し、なおかつ表示中の組織がもともとの組織と異なれば親にさかのぼってOK
  const backToParent = organization.data?.parentId && organization.data.id !== orgContext.original?.id;

  return (
    <MenuHeaderTemplate
      header={{ title: '組織管理', loading: uploadShopCsv.loading, progressLabel: uploadShopCsv.progressLabel }}
    >
      <Container maxWidth="xl">
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Breadcrumbs>
              {backToParent ? (
                <Button
                  size="small"
                  color="inherit"
                  component={Link}
                  to={{
                    pathname: `/manage-organizations/${organization.data?.parentId}`,
                    search: organizationParam,
                  }}
                >
                  <ArrowBack />
                  親組織へ
                </Button>
              ) : undefined}
              <Typography variant="body1" color="textPrimary">
                {organization.data?.name || '読み込み中...'}
              </Typography>
            </Breadcrumbs>
          </Grid>
          <Grid item>
            <Grid container spacing={2}>
              {(() => {
                if (organization.data?.type === 'BIG_SHOP_GROUP') {
                  if (!userInfo?.permissions.includes('write:shop-group')) return <></>;
                  if (organization.data.parentId && organization.data.id !== orgContext.original?.id) {
                    return (
                      <>
                        <Grid item>
                          <Button
                            color="secondary"
                            variant="contained"
                            onClick={() => {
                              setOpenDeleteShopGroupAlertDialog(true);
                            }}
                            startIcon={<Delete />}
                          >
                            店舗グループを削除
                          </Button>
                        </Grid>
                        <Grid item>
                          <Button
                            color="primary"
                            variant="contained"
                            onClick={() => {
                              history.push({
                                pathname: `/manage-organizations/${organizationId}/shop-groups/new`,
                                search: `${history.location.search}`,
                              });
                            }}
                            startIcon={<Add />}
                          >
                            店舗グループを追加
                          </Button>
                        </Grid>
                      </>
                    );
                  } else {
                    // ルート店舗グループは削除できない
                    return (
                      <Grid item>
                        <Button
                          color="primary"
                          variant="contained"
                          onClick={() => {
                            history.push({
                              pathname: `/manage-organizations/${organizationId}/shop-groups/new`,
                              search: `${history.location.search}`,
                            });
                          }}
                          startIcon={<Add />}
                        >
                          店舗グループを追加
                        </Button>
                      </Grid>
                    );
                  }
                } else if (organization.data?.type === 'SMALL_SHOP_GROUP') {
                  return (
                    <>
                      <Grid item>
                        <Button
                          color="secondary"
                          variant="contained"
                          onClick={() => {
                            setOpenDeleteShopGroupAlertDialog(true);
                          }}
                          startIcon={<Delete />}
                        >
                          店舗グループを削除
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          fullWidth
                          color="default"
                          variant="contained"
                          onClick={() => {
                            if (organization.data?.id) {
                              outputShopCsv.onCsvOut(organization.data.id);
                            }
                          }}
                          disabled={outputShopCsv.loading}
                          startIcon={<CloudDownload />}
                        >
                          {outputShopCsv.loading ? <CircularProgress size={25} /> : '店舗CSV出力'}
                        </Button>
                      </Grid>
                      {userInfo?.permissions.includes('write:shop') ? (
                        <>
                          <Grid item>
                            <Button
                              fullWidth
                              color="primary"
                              variant="contained"
                              onClick={() => {
                                uploadShopCsv.setOpen(true);
                              }}
                              startIcon={<CloudUpload />}
                            >
                              店舗CSV一括登録
                            </Button>
                          </Grid>
                          <Grid item>
                            <Button
                              color="primary"
                              fullWidth
                              variant="contained"
                              onClick={() => {
                                history.push({
                                  pathname: `/manage-organizations/${organizationId}/shops/new`,
                                  search: history.location.search,
                                });
                              }}
                              startIcon={<Add />}
                            >
                              店舗を追加
                            </Button>
                          </Grid>
                        </>
                      ) : (
                        <></>
                      )}
                    </>
                  );
                } else if (organization.data?.type === 'SHOP') {
                  return (
                    <Grid item>
                      <Button
                        color="secondary"
                        variant="contained"
                        onClick={() => {
                          setOpenShopDeleteAlertDialog(true);
                        }}
                        startIcon={<Delete />}
                      >
                        店舗を削除
                      </Button>
                    </Grid>
                  );
                }
              })()}
            </Grid>
          </Grid>
        </Grid>
        <TabContext value={hash || '#children-organization'}>
          <TabList className={classes.tab} classes={{ indicator: classes.tabListIndicator, root: classes.tabList }}>
            <Tab
              label="子組織"
              component={MaterialLink}
              href="#children-organization"
              value="#children-organization"
              disabled={organization.data?.type === 'SHOP'}
            />
            <Tab label="この組織の詳細" component={MaterialLink} href="#about" value="#about" />
          </TabList>
          <TabPanel value="#children-organization" classes={{ root: classes.tabPanel }}>
            <ChildrenOrganizationListBody {...children} parentType={organization.data?.type} />
          </TabPanel>
          <TabPanel value="#about" classes={{ root: classes.tabPanel }}>
            <OrganizationInfo {...organization} />
          </TabPanel>
        </TabContext>
        {organization.data && organization.data.parentId ? (
          <>
            <UploadShopCsvDialog
              open={uploadShopCsv.open}
              loading={uploadShopCsv.loading}
              onUpload={uploadShopCsv.onUpload}
              onClose={uploadShopCsv.onClose}
              validateAndSetFile={uploadShopCsv.validateAndSetCsvFile}
              selectedCsvFile={uploadShopCsv.selectedCsvFile}
              parentId={organization.data.parentId}
            />
            <DeleteShopAlertDialog
              id={organization.data.id}
              parentId={organization.data.parentId}
              name={organization.data.name}
              open={openDeleteShopAlertDialog}
              setOpen={setOpenShopDeleteAlertDialog}
            />
            <DeleteShopGroupAlertDialog
              id={organization.data.id}
              parentId={organization.data.parentId}
              type={organization.data.type}
              name={organization.data.name}
              open={openDeleteShopGroupAlertDialog}
              setOpen={setOpenDeleteShopGroupAlertDialog}
            />
          </>
        ) : (
          <></>
        )}
      </Container>
    </MenuHeaderTemplate>
  );
};
