import { KeyboardDatePicker } from '@material-ui/pickers';
import { Controller, useFormContext } from 'react-hook-form';
import React from 'react';

type KeyboardDatePickerControllerProps = {
  name: string;
  label: string;
  required: boolean;
};

/**
 * React Hook Form KeyboardDatePicker
 */
export const KeyboardDatePickerController: React.FC<KeyboardDatePickerControllerProps> = ({
  name,
  label,
  required,
}) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  return (
    <Controller
      control={control}
      name={name}
      rules={{
        required: {
          value: required,
          message: '必須です',
        },
      }}
      render={({ field: { ref, ...remain } }) => (
        <KeyboardDatePicker
          required={required}
          label={label}
          helperText={errors[name]?.message ?? ''}
          error={!!errors[name]}
          disableToolbar
          inputVariant="standard"
          variant="dialog"
          format="yyyy年MM月dd日"
          margin="normal"
          // render に変えたことによる副作用を考慮。Material-UI は フォームのラッパーなので ref では渡せない。
          // 一方、RHFとしては直接入力フォームにわたすことを想定しており field の中に ref という名前で Ref オブジェクトを持っていいる。
          // Material-UIでは「refを渡す必要があるときは inputRef を使ってね」というルールがあるのでこれにのっとり
          // inputRefへ個別にわたす。
          {...remain}
          inputRef={ref}
        />
      )}
    />
  );
};
