import { useCallback, useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import Bugsnag from '@bugsnag/js';
import { IssuedCouponUsageLogProps } from '../IssuedCouponUsageLog';
import {
  useCouponMasterLazyQuery,
  useInvokeCouponUsageLogCsvLazyQuery,
  useInvokeCouponUsageLogJsonLazyQuery,
  usePollCouponUsageLogCsvLazyQuery,
  usePollCouponUsageLogJsonLazyQuery,
} from '../../../../graphql/generated';
import { downloadCsvFileWithBom } from '../../../../util/download';

export type IssuedCouponUsageLogUseCase = IssuedCouponUsageLogProps;

export const Limit = 100;

export const useIssuedCouponUsageLog = (couponMasterId: string, couponId: string): IssuedCouponUsageLogUseCase => {
  const [loading, setLoading] = useState<boolean>(false);
  const [csvLoading, setCsvLoading] = useState<boolean>(false);
  const [offset, setOffset] = useState<number>(0);
  const { enqueueSnackbar } = useSnackbar();

  const [masterQuery, masterContext] = useCouponMasterLazyQuery({
    fetchPolicy: 'cache-first',
  });

  // 利用状況ログ ポーリング
  const [pollJsonQuery, statusContext] = usePollCouponUsageLogJsonLazyQuery({
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    pollInterval: 5000,
  });

  // 利用状況ログ 取得
  // ApolloClient で ポーリングとfetchMoreを同時に利用できないため分ける
  const [getJsonQuery, getJsonQueryContext] = usePollCouponUsageLogJsonLazyQuery({
    fetchPolicy: 'cache-first',
    onCompleted: (_) => {
      setLoading(false);
    },
  });

  // 利用状況ログ取得 invoke
  const [invokeJsonQuery] = useInvokeCouponUsageLogJsonLazyQuery({
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    onCompleted: (data) => {
      const queryExecutionId = data.invokeCouponUsageLogJson.queryExecutionId;
      if (!queryExecutionId) {
        // 必須パラメータがない場合はなにもせずに終了
        enqueueSnackbar('利用状況明細一覧のパラメータ設定に失敗しました', {
          variant: 'error',
        });
        setLoading(false);
        return;
      }
      pollJsonQuery({
        variables: {
          queryExecutionId,
        },
      });
      setOffset(offset + Limit);
    },
    onError: (e) => {
      console.error(e);
      Bugsnag.notify(e);
      enqueueSnackbar('利用状況明細一覧の取得に失敗しました', {
        variant: 'error',
      });
    },
  });

  // JSONクエリ出力をポーリングする
  useEffect(() => {
    const status = statusContext?.data?.pollCouponUsageLogJson?.status;

    // 終了系ステータスの場合、ポーリングを止める
    if (status === 'CANCELLED' || status === 'FAILED' || status === 'SUCCEEDED') {
      if (statusContext && statusContext.stopPolling) {
        statusContext.stopPolling();
      }
    }

    // 正常に終了した場合は、データフェッチ用のクエリを呼び出してステータスを終了にする
    if (
      status === 'SUCCEEDED' &&
      statusContext &&
      statusContext.data &&
      statusContext.data.pollCouponUsageLogJson &&
      statusContext.data.pollCouponUsageLogJson.nodes
    ) {
      enqueueSnackbar('明細取得処理が正常に終了しました', { variant: 'success' });
      getJsonQuery({
        variables: statusContext.variables,
      });
    } else if (status === 'FAILED' || status === 'CANCELLED') {
      setLoading(false);
      enqueueSnackbar('明細取得処理でエラーが発生しました', {
        variant: 'error',
      });
      Bugsnag.notify(statusContext.error || new Error('polling error'));
    }
  }, [statusContext.data?.pollCouponUsageLogJson.status]);

  // 更に読み込む
  const onFetchMoreJson = useCallback(async () => {
    if (statusContext && statusContext.stopPolling) {
      statusContext?.stopPolling();
    }
    if (getJsonQueryContext && getJsonQueryContext.fetchMore && getJsonQueryContext.variables) {
      setLoading(true);
      await getJsonQueryContext.fetchMore({
        variables: {
          ...getJsonQueryContext.variables,
        },
        updateQuery: (prevQuery, { fetchMoreResult }) => {
          setLoading(false);
          if (!fetchMoreResult) {
            return prevQuery;
          }
          return {
            pollCouponUsageLogJson: {
              __typename: fetchMoreResult.pollCouponUsageLogJson.__typename,
              queryExecutionId: fetchMoreResult.pollCouponUsageLogJson.queryExecutionId,
              status: fetchMoreResult.pollCouponUsageLogJson.status,
              nodes: [
                ...(prevQuery.pollCouponUsageLogJson.nodes || []),
                ...(fetchMoreResult.pollCouponUsageLogJson.nodes || []),
              ],
              pageInfo: fetchMoreResult.pollCouponUsageLogJson.pageInfo,
            },
          };
        },
      });
    }
  }, [statusContext, getJsonQueryContext]);

  // 履歴呼び出し時（初回）
  useEffect(() => {
    setLoading(true);
    masterQuery({
      variables: {
        couponMasterId,
      },
    });
    invokeJsonQuery({
      variables: {
        couponId,
        searchPolicy: 'history',
        limit: Limit,
        offset: offset,
      },
    });
  }, [masterQuery, invokeJsonQuery]);

  // 利用状況一覧 CSV URL ポーリング
  const [pollCsvUrlQuery, statusCsvUrlContext] = usePollCouponUsageLogCsvLazyQuery({
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    pollInterval: 5000,
  });

  // 利用状況CSV取得 invoke
  const [invokeCsvQuery] = useInvokeCouponUsageLogCsvLazyQuery({
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    onCompleted: (data) => {
      pollCsvUrlQuery({
        variables: {
          queryExecutionId: data.invokeCouponUsageLogCsv.queryExecutionId,
        },
      });
    },
    onError: (e) => {
      console.error(e);
      Bugsnag.notify(e);
      enqueueSnackbar('利用状況集計一覧の取得に失敗しました', {
        variant: 'error',
      });
    },
  });

  // CSV URL クエリ出力をポーリングする
  // ポーリングクエリの onComplete は呼ばれないので useEffect でやるしかない
  useEffect(() => {
    const status = statusCsvUrlContext?.data?.pollCouponUsageLogCsv?.status;

    // 終了系ステータスの場合、ポーリングを止める
    if (status === 'CANCELLED' || status === 'FAILED' || status === 'SUCCEEDED') {
      if (statusCsvUrlContext && statusCsvUrlContext.stopPolling) {
        statusCsvUrlContext.stopPolling();
      }
    }

    // 正常に終了した場合は、CSVファイルをダウンロード
    if (
      status === 'SUCCEEDED' &&
      statusCsvUrlContext &&
      statusCsvUrlContext.data &&
      statusCsvUrlContext.data.pollCouponUsageLogCsv
    ) {
      enqueueSnackbar('明細取得処理が正常に終了しました。CSVファイルをダウンロードします。', { variant: 'success' });
      setCsvLoading(false);
      downloadCsvFileWithBom(statusCsvUrlContext.data.pollCouponUsageLogCsv.preSignedUrl);
    } else if (status === 'FAILED' || status === 'CANCELLED') {
      setCsvLoading(false);
      enqueueSnackbar('明細取得処理でエラーが発生しました', {
        variant: 'error',
      });
      Bugsnag.notify(statusCsvUrlContext.error || new Error('polling error'));
    }
  }, [
    statusCsvUrlContext.data?.pollCouponUsageLogCsv.status,
    statusCsvUrlContext.data?.pollCouponUsageLogCsv.preSignedUrl,
  ]);

  // CSV出力
  const onCsvOutSubmit = useCallback(() => {
    setCsvLoading(true);
    invokeCsvQuery({
      variables: {
        couponId,
        searchPolicy: 'history',
      },
    });
  }, [invokeCsvQuery]);

  return {
    onCsvOut: onCsvOutSubmit,
    data: getJsonQueryContext.data,
    couponMaster: masterContext.data?.couponMaster,
    loading,
    csvLoading,
    onFetchMore: onFetchMoreJson,
  };
};
