import axios, { AxiosResponse } from 'axios';
import { formatJstDateTimeNumeric } from './index';
import { DateTime } from 'luxon';

/**
 * https://stackoverflow.com/questions/40939380/how-to-get-file-name-from-content-disposition
 */
const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;

function getFileNameFromContentDisposition(contentDisposition?: string): string {
  const matches = filenameRegex.exec(contentDisposition || '');
  if (!contentDisposition || contentDisposition.indexOf('attachment') < 0 || !matches || !matches[1]) {
    // 日付でも返しておく
    return formatJstDateTimeNumeric(DateTime.local().toISO());
  }
  return matches[1].replace(/['"]/g, '');
}

/**
 * BOMヘッダを付与してファイルをダウンロードする。なんと、hidden <a> タグを作ってそれをクリックするという
 * React らしからぬソリューションらしい。
 * refs: https://gist.github.com/jbutko/d7b992086634a94e84b6a3e526336da3
 * refs: https://stackoverflow.com/Questions/17879198/Adding-utf-8-bom-to-string-blob
 */
export function downloadCsvFileWithBom(url: string, onComplete?: () => void): void {
  // 取得したPreSignedUrlでCSVを取得する
  const getCsv: Promise<AxiosResponse> = axios.get(url, {
    responseType: 'blob',
  });
  getCsv.then((res) => {
    const contentDisposition = res.headers['content-disposition'];
    const blob = new Blob([new Uint8Array([0xef, 0xbb, 0xbf]), res.data]);
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = getFileNameFromContentDisposition(contentDisposition);
    link.click();
    if (onComplete) {
      onComplete();
    }
  });
}
