import {
  useBulkIssueCouponsMutation,
  useBulkIssuedCouponsStatusLazyQuery,
  useCouponMasterLazyQuery,
} from '../../../../graphql/generated';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import Bugsnag from '@bugsnag/js';
import { BulkCouponProps, BulkCouponStartFormValues } from '../Bulk';
import { OrganizationContext } from '../../../../components/contexts/organization';

export type StartCouponBulkHistories = BulkCouponProps;

export const useListCouponBulkStart = (couponMasterId: string, refetch?: () => void): StartCouponBulkHistories => {
  const { organization, loading: organizationLoading } = useContext(OrganizationContext);
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const [couponMasterQuery, { data, loading: masterLoading }] = useCouponMasterLazyQuery({
    fetchPolicy: 'cache-first',
  });

  // クーポンマスタを取得する
  useEffect(() => {
    couponMasterQuery({
      variables: {
        couponMasterId,
      },
    });
  }, [couponMasterId]);

  // URLを取得するための処理
  const [getBulkIssuedCouponsStatus, bulkIssuedCouponStatusContext] = useBulkIssuedCouponsStatusLazyQuery({
    fetchPolicy: 'network-only',
    pollInterval: 1000,
  });

  const [bulkIssueCouponsMutation] = useBulkIssueCouponsMutation({
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      getBulkIssuedCouponsStatus({
        variables: {
          executionArn: data.bulkIssueCoupons.executionArn,
        },
      });
    },
  });

  const onSubmit = useCallback(
    (form: BulkCouponStartFormValues) => {
      const start = async (form: BulkCouponStartFormValues): Promise<void> => {
        if (organization && data?.couponMaster) {
          setLoading(true);
          try {
            await bulkIssueCouponsMutation({
              variables: {
                input: {
                  couponMasterId,
                  issuedNumber: form.issueNumber,
                  applyWith: data.couponMaster.applyWith,
                  barcodeSource: data.couponMaster.applyBarcode.sourceType,
                  batchSize: 5000, // デフォルトでは10000だが並列のLambdaがタイムアウトすることがあるため、5000にした
                  barcodeType: data.couponMaster.applyBarcode.displayType,
                  publishedOrganizationId: organization.id,
                  publishedOrganizationName: organization.name,
                  publishedFrom: 'admin',
                  description: form.description || '',
                  couponCode: data.couponMaster.couponCode,
                },
              },
            });

            enqueueSnackbar('クーポンURL一括発行処理を受付けました。しばらくお待ち下さい', { variant: 'info' });
          } catch (err) {
            console.error(err);
            enqueueSnackbar('クーポンの一括発行でエラーが発生しました', {
              variant: 'error',
            });
            Bugsnag.notify(err);
          }
        }
      };
      start(form);
    },
    [data, bulkIssueCouponsMutation, enqueueSnackbar, getBulkIssuedCouponsStatus, organization]
  );

  // 一括発行ステータスをポーリングする
  useEffect(() => {
    const status = bulkIssuedCouponStatusContext?.data?.bulkIssuedCouponsStatus?.status;

    // 終了系ステータスの場合、ポーリングを止める
    if (status === 'ABORTED' || status === 'FAILED' || status === 'SUCCEEDED' || status === 'TIMED_OUT') {
      if (bulkIssuedCouponStatusContext && bulkIssuedCouponStatusContext.stopPolling) {
        bulkIssuedCouponStatusContext.stopPolling();
      }
    }

    // 正常に終了した場合は、リフェッチする。ファイル名を確定させるために履歴情報がほしい
    if (
      status === 'SUCCEEDED' &&
      bulkIssuedCouponStatusContext &&
      bulkIssuedCouponStatusContext.data &&
      bulkIssuedCouponStatusContext.data.bulkIssuedCouponsStatus &&
      bulkIssuedCouponStatusContext.data.bulkIssuedCouponsStatus.bucket &&
      bulkIssuedCouponStatusContext.data.bulkIssuedCouponsStatus.key
    ) {
      setLoading(false);
      enqueueSnackbar(
        'クーポンURL一括発行処理が正常に終了しました。履歴が作成されますのでCSVダウンロードボタンからダウンロードしてください',
        { variant: 'success' }
      );
      if (refetch) {
        refetch();
      }
    } else if (status === 'FAILED' || status === 'TIMED_OUT') {
      enqueueSnackbar('クーポンの一括発行でエラーが発生しました', {
        variant: 'error',
      });
      Bugsnag.notify(bulkIssuedCouponStatusContext.error || new Error('bulk coupon polling error'));
    }
  }, [bulkIssuedCouponStatusContext]);

  return {
    loading: loading || organizationLoading || masterLoading,
    onSubmit,
  };
};
