import { Box, Breadcrumbs, CircularProgress, Container, Link, Tab, Theme, Typography } from '@material-ui/core';
import { TabContext, TabList, TabPanel } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';
import React, { useContext, useEffect } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useUserLazyQuery } from '../../graphql/generated';
import { UserAbout } from './UserAbout';
import { MenuHeaderTemplate } from '../../components/templates/MenuHeaderTemplate';
import { PathParams } from '../../App';
import { OrganizationContext } from '../../components/contexts/organization';

const useStyles = makeStyles((theme: Theme) => ({
  tab: {
    margin: `${theme.spacing(3)}px 0 0`,
  },
  tabPanel: {
    padding: `${theme.spacing(2)}px 0`,
  },
  tabList: {
    backgroundColor: theme.palette.grey[50],
  },
  tabListIndicator: {
    backgroundColor: theme.palette.grey[400],
  },
}));

export const UserDashboard: React.FC = () => {
  const styles = useStyles();
  const history = useHistory();
  const { hash } = useLocation();
  const orgContext = useContext(OrganizationContext);

  const { userId } = useParams<PathParams>();

  const [userQuery, { data, error, loading }] = useUserLazyQuery({
    fetchPolicy: 'network-only',
    onError: (e) => {
      console.log('error', e);
    },
  });

  console.log('data', data);

  useEffect(() => {
    userQuery({
      variables: {
        id: userId,
      },
    });
  }, [userQuery]);

  if (orgContext.loading || !orgContext.organization || loading || !data?.user) {
    return (
      <Box display="flex" alignItems="center" justifyContent="center">
        <CircularProgress />
      </Box>
    );
  }
  if (error) {
    return (
      <Box display="flex" alignItems="center" justifyContent="center">
        <Typography variant="body1">ユーザーの表示に失敗しました</Typography>
      </Box>
    );
  }

  return (
    <>
      <Breadcrumbs>
        <Link
          href="#"
          onClick={() =>
            history.push({
              pathname: '/users',
              search: history.location.search,
            })
          }
        >
          ユーザー一覧
        </Link>
        <Typography color="textPrimary">
          {data.user.id}
          {`（${data.user.name}）`}
        </Typography>
      </Breadcrumbs>
      <Box marginTop="8px" />
      <TabContext value={hash || '#about'}>
        <TabList className={styles.tab} classes={{ indicator: styles.tabListIndicator, root: styles.tabList }}>
          <Tab label="概要" component={Link} href="#about" value="#about" />
        </TabList>
        <TabPanel value="#about" classes={{ root: styles.tabPanel }}>
          <UserAbout {...data?.user} />
        </TabPanel>
      </TabContext>
    </>
  );
};

export const UserDashboardPage: React.FC = () => {
  return (
    <MenuHeaderTemplate header={{ title: 'ユーザー詳細' }}>
      <Container maxWidth="lg">
        <Box>
          <UserDashboard />
        </Box>
      </Container>
    </MenuHeaderTemplate>
  );
};
