import React, { useContext, useState } from 'react';
import {
  Button,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
  Theme,
} from '@material-ui/core';
import { lightGreen } from '@material-ui/core/colors';
import { ArrowRight } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';

import { User } from '../../graphql/generated';
import { formatJstDateTimeJapanese } from '../../util';
import { UpdateUserNameDialog } from './dialogs/UpdateUserName';
import { UpdateUserRoleDialog } from './dialogs/UpdateUserRole';
import { UserContext } from '../../components/contexts/user/user-context';

const useStyles = makeStyles((theme: Theme) => ({
  infoArea: {
    borderColor: theme.palette.primary.light,
    backgroundColor: lightGreen['50'],
  },
}));

export type UserAboutProps = Omit<User, 'permissions'>;

export const UserAbout: React.FC<UserAboutProps> = (props) => {
  const classes = useStyles();
  const { userInfo } = useContext(UserContext);

  const [openUpdateUserNameDialog, setOpenUpdateUserNameDialog] = useState<boolean>(false);
  const [openUpdateUserRoleDialog, setOpenUpdateUserRoleDialog] = useState<boolean>(false);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Paper variant="outlined" square className={classes.infoArea}>
          <List dense>
            <ListItem>
              <ListItemAvatar>
                <ArrowRight />
              </ListItemAvatar>
              <ListItemText primary="組織ID" secondary={props.organizationId} />
            </ListItem>
            <ListItem>
              <ListItemAvatar>
                <ArrowRight />
              </ListItemAvatar>
              <ListItemText primary="ユーザーID" secondary={props.id} />
            </ListItem>
            <ListItem>
              <ListItemAvatar>
                <ArrowRight />
              </ListItemAvatar>
              <ListItemText primary="Eメール" secondary={props.email} />
            </ListItem>
            <ListItem>
              <ListItemAvatar>
                <ArrowRight />
              </ListItemAvatar>
              <ListItemText primary="ユーザー名" secondary={props.name} />
              <ListItemSecondaryAction>
                <Button
                  size="small"
                  onClick={() => setOpenUpdateUserNameDialog(true)}
                  color="primary"
                  disabled={!userInfo?.permissions.includes('write:admin-user') && props.isAdministrator}
                >
                  編集
                </Button>
                <UpdateUserNameDialog
                  id={props.id}
                  name={props.name}
                  open={openUpdateUserNameDialog}
                  setOpen={setOpenUpdateUserNameDialog}
                />
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem>
              <ListItemAvatar>
                <ArrowRight />
              </ListItemAvatar>
              <ListItemText primary="ロール" secondary={props.role} />
              <ListItemSecondaryAction>
                <Button
                  size="small"
                  onClick={() => setOpenUpdateUserRoleDialog(true)}
                  color="primary"
                  disabled={!userInfo?.permissions.includes('write:admin-user') && props.isAdministrator}
                >
                  編集
                </Button>
                <UpdateUserRoleDialog
                  id={props.id}
                  roleId={props.roleId!}
                  isAdministrator={props.isAdministrator}
                  open={openUpdateUserRoleDialog}
                  setOpen={setOpenUpdateUserRoleDialog}
                />
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem>
              <ListItemAvatar>
                <ArrowRight />
              </ListItemAvatar>
              <ListItemText
                primary="最終ログイン日時"
                secondary={formatJstDateTimeJapanese(props.lastLoginDateTime ?? '')}
              />
            </ListItem>
            <ListItem>
              <ListItemAvatar>
                <ArrowRight />
              </ListItemAvatar>
              <ListItemText primary="作成日時" secondary={formatJstDateTimeJapanese(props.createAtDateTime)} />
            </ListItem>
            <ListItem>
              <ListItemAvatar>
                <ArrowRight />
              </ListItemAvatar>
              <ListItemText primary="最終更新日時" secondary={formatJstDateTimeJapanese(props.updateAtDateTime)} />
            </ListItem>
          </List>
        </Paper>
      </Grid>
    </Grid>
  );
};
