import { Grid, Paper, Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { DateTimeInput } from './index';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import { CheckBoxController } from './controllers/CheckBoxController';
import { KeyboardTimePickerController } from './controllers/KeyboardTimePickerController';
import { TextFieldController } from './controllers/TextFieldController';
import { SelectController } from './controllers/SelectController';

export type AvailableDays = {
  mon: boolean;
  tue: boolean;
  wed: boolean;
  thu: boolean;
  fri: boolean;
  sat: boolean;
  sun: boolean;
};

export type TimeFrameInput = {
  // 月ごと・曜日ごと設定
  timeFrame: string;
  availableDate?: string;
  availableDays: AvailableDays;
  availableStartTimePeriod?: DateTimeInput;
  availableEndTimePeriod?: DateTimeInput;
};

export type TimeFrameFormProps = {
  // 表示設定
  displaySettings: {
    timeFrame: boolean;
  };
  previousValues: TimeFrameInput;
};

const availableDaysNames = [
  { name: 'availableDays.mon', label: '月曜日' },
  { name: 'availableDays.tue', label: '火曜日' },
  { name: 'availableDays.wed', label: '水曜日' },
  { name: 'availableDays.thu', label: '木曜日' },
  { name: 'availableDays.fri', label: '金曜日' },
  { name: 'availableDays.sat', label: '土曜日' },
  { name: 'availableDays.sun', label: '日曜日' },
];

const useStyles = makeStyles((theme: Theme) => ({
  subSection: {
    padding: theme.spacing(2),
    margin: theme.spacing(1),
  },
}));

export const TimeFrameGrid: React.FC<TimeFrameFormProps> = (props) => {
  const classes = useStyles();
  const { watch } = useFormContext();

  // 帯時間設定の切り替えに使う
  const watchTimeFrame = watch('timeFrame', '');

  if (!props.displaySettings.timeFrame) {
    return <></>;
  }

  // 帯時間設定項目
  const timeFrameSettingsComponent = (timeFrame: string): React.ReactElement => {
    // 表示する場合、ここは共通
    const timeRange = (
      <>
        <Grid item xs={3}>
          <KeyboardTimePickerController name="availableStartTimePeriod" label="開始時間" required={false} />
        </Grid>
        <Grid item xs={1}>
          <ArrowRightAltIcon color="disabled" />
        </Grid>
        <Grid item xs={3}>
          <KeyboardTimePickerController name="availableEndTimePeriod" label="終了時間" required={false} />
        </Grid>
      </>
    );
    if (timeFrame === 'monthly') {
      return (
        <Paper className={classes.subSection}>
          <Grid container alignItems="flex-end" spacing={2}>
            <Grid item xs={12}>
              <Typography variant="subtitle1">利用可能日付</Typography>
              <Typography variant="caption">利用可能とする日付をカンマ区切りで入力します（例：1,15,30）</Typography>
            </Grid>
            <Grid item xs={12}>
              <TextFieldController
                required
                name="availableDate"
                label="利用可能日付"
                rule={{
                  required: '月ごとの設定の場合、利用可能日は必須です',
                  maxLength: {
                    value: 100,
                    message: '空白を消してみてください',
                  },
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle1">利用可能時間（日本時間）</Typography>
            </Grid>
            {timeRange}
          </Grid>
        </Paper>
      );
    } else if (timeFrame === 'weekly') {
      return (
        <Paper className={classes.subSection}>
          <Grid container alignItems="flex-end" spacing={2}>
            <Grid item xs={12}>
              <Typography variant="subtitle1">利用可能曜日</Typography>
            </Grid>
            <Grid item xs={12}>
              {availableDaysNames.map(({ name, label }) => (
                <CheckBoxController name={name} label={label} key={name} />
              ))}
            </Grid>
            {timeRange}
          </Grid>
        </Paper>
      );
    } else {
      return <></>;
    }
  };

  return (
    <>
      <Grid item xs={3}>
        <Typography variant="subtitle1">帯時間設定</Typography>
        <Typography color="textSecondary" variant="caption">
          利用可能な帯時間を設定したい場合、有効にします
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <SelectController
          name="timeFrame"
          menuItems={[
            { value: 'none', label: '利用しない' },
            { value: 'monthly', label: '毎月' },
            { value: 'weekly', label: '毎週' },
          ]}
        />
      </Grid>
      <Grid item xs={12}>
        {timeFrameSettingsComponent(watchTimeFrame)}
      </Grid>
    </>
  );
};
