import { ApplyWith, BarcodeType, Organization, SourceType } from '../../../graphql/generated';
import { createContext } from 'react';

export type OrganizationContext = Pick<
  Organization,
  | 'id'
  | 'memberShopCode'
  | 'name'
  | 'parentId'
  | 'type'
  | 'shopCode'
  | 'shopNumber'
  | 'customerShopCode'
  | 'simpleClearingCode'
>;

type TakeOver = 'inherit' | 'override';

// 組織がもつクーポンにかかわる機能
export type CouponFeature = {
  apply: {
    with: ApplyWith;
    barcode: {
      sourceType: SourceType;
      displayType: BarcodeType;
    };
  };
  timeFrame: {
    available: boolean;
  };
  takeOver: {
    apply: TakeOver;
    timeFrame: TakeOver;
  };
};

export type OrganizationContextProps = {
  // 読込中かどうか
  loading: boolean;

  // もとの組織
  original?: OrganizationContext;

  // 利用中の組織
  organization?: OrganizationContext;

  // 利用中組織の機能
  couponFeature?: CouponFeature;

  // 組織機能の設定
  setCouponFeature: (feature: CouponFeature) => void;

  // クーポンマスタページにアクセスして良いか？
  availableCouponMaster?: boolean;

  // なりすまし用
  setOrganization: (newOrgId: string) => void;

  // もともとの組織に戻る
  resetOrganization: () => void;
};

/**
 * @ignore
 */
const stub = (): never => {
  throw new Error('You forgot to wrap your component in <BreadcrumbsProvider>.');
};

export const OrganizationContext = createContext<OrganizationContextProps>({
  loading: true,
  setOrganization: stub,
  resetOrganization: stub,
  setCouponFeature: stub,
});
