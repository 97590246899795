import {
  Box,
  Button,
  Grid,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import React from 'react';
import { formatJstDateTimeJapanese, formatNumberToJaJpLocale } from '../../../util';
import { BasicTableHead, BasicTableHeadProps } from '../../../components/molecules';
import { CouponBulkHistoriesQuery, CouponBulkHistory } from '../../../graphql/generated';

export type BulkCouponHistoryProps = {
  loading: boolean;
  downloadInProgressId: string;
  data?: CouponBulkHistoriesQuery;
  onFetchMore: () => void;
  onDownload: (row: CouponBulkHistory) => void;
};

export const BulkCouponHistory: React.FC<BulkCouponHistoryProps> = (props) => {
  const theme = useTheme();
  const tableHeadProps: BasicTableHeadProps = {
    columns: [
      {
        name: 'publishDateTime',
        label: '発行日',
        minWidth: theme.spacing(10),
        align: 'left',
      },
      {
        name: 'issuedNumber',
        label: '発行数',
        minWidth: theme.spacing(10),
        align: 'right',
      },
      {
        name: 'description',
        label: '備考',
        minWidth: theme.spacing(20),
        align: 'left',
      },
      {
        name: 'donwload',
        label: 'CSVダウンロード',
        minWidth: theme.spacing(10),
        align: 'right',
      },
    ],
  };
  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TableContainer component={Paper}>
            {props.loading ? <LinearProgress /> : <React.Fragment />}
            <Table>
              <BasicTableHead columns={tableHeadProps.columns} />
              <TableBody>
                {(() => {
                  if (!props.data || !props.data.couponBulkHistories) {
                    return;
                  } else if (
                    props.data.couponBulkHistories.nodes &&
                    props.data.couponBulkHistories.nodes.length === 0
                  ) {
                    return (
                      <TableRow>
                        <TableCell>データがありません</TableCell>
                      </TableRow>
                    );
                  } else {
                    return props.data.couponBulkHistories.nodes.map((row) => (
                      <TableRow key={row.id}>
                        <TableCell component="th" scope="row">
                          {formatJstDateTimeJapanese(row.publishDateTime)}
                        </TableCell>
                        <TableCell align="right" component="th" scope="row">
                          {formatNumberToJaJpLocale(row.issuedNumber || 0)}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row.description}
                        </TableCell>
                        <TableCell align="right" scope="row">
                          <Button
                            variant={'outlined'}
                            onClick={() => props.onDownload(row)}
                            type="button"
                            size="small"
                            color="primary"
                            disabled={!!props.downloadInProgressId}
                          >
                            {props.downloadInProgressId === row.id ? '生成中...' : 'ダウンロード'}
                          </Button>
                        </TableCell>
                      </TableRow>
                    ));
                  }
                })()}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        {props.data?.couponBulkHistories.pageInfo.nextToken ? (
          <Grid item xs={12}>
            <Grid container justifyContent={'center'}>
              <Button
                size="large"
                color="default"
                variant="contained"
                disabled={props.loading}
                onClick={() => props.onFetchMore()}
                fullWidth
              >
                さらに読み込む
              </Button>
            </Grid>
            {props.loading ? <LinearProgress /> : <React.Fragment />}
          </Grid>
        ) : (
          <></>
        )}
      </Grid>
    </Box>
  );
};
