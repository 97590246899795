import {
  Box,
  Breadcrumbs,
  Button,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Theme,
  Typography,
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { CouponMaster } from '../../../graphql/generated';
import { MenuHeaderTemplate } from '../../../components/templates/MenuHeaderTemplate';
import { BasicTableHead, BasicTableHeadProps } from '../../../components/molecules';
import { Search } from '@material-ui/icons';
import { useForm, FormProvider } from 'react-hook-form';
import { ListCouponMasters, useListCouponMasters } from './hooks/list-use-case';
import { HeaderProps } from '../../../components/organisms/general/MenuHeader';
import { formatJstDate, formatJstDateTime, getRequiredEnvironmentVariable } from '../../../util';
import { ReadMoreButton } from '../../../components/organisms/general/ReadMoreButton';
import { TextFieldController } from '../../../components/organisms/form/controllers/TextFieldController';
import { SelectController } from '../../../components/organisms/form/controllers/SelectController';

const ConsumerUrl = getRequiredEnvironmentVariable('REACT_APP_CONSUMER_SERVER_URL');

const useStyles = makeStyles((theme: Theme) => ({
  conditionFormControl: {
    minWidth: 150,
    maxWidth: 300,
  },
  searchForm: {
    marginTop: theme.spacing(1),
  },
  searchButton: {
    textAlign: 'center',
  },
  couponMastersList: {
    marginTop: theme.spacing(3),
  },
}));

export type CouponMasterListConditionFormValues = {
  value: string;
  searchCondition: string;
};

type ListCouponMastersPageProps = {
  header: HeaderProps;
  listBody: ListCouponMasters;
};

export const ListCouponMastersBody: React.FC<ListCouponMasters> = (props) => {
  const styles = useStyles();
  const history = useHistory();

  const couponMasterListForm = useForm<CouponMasterListConditionFormValues>({
    defaultValues: props.initialValues,
  });
  const { handleSubmit, watch } = couponMasterListForm;

  const watchSearchCondition = watch('searchCondition', '');

  const theme = useTheme();
  const tableHeadProps: BasicTableHeadProps = {
    columns: [
      {
        name: 'couponCode',
        label: 'コード',
        minWidth: theme.spacing(10),
        align: 'left',
      },
      {
        name: 'name',
        label: 'クーポン名',
        minWidth: theme.spacing(20),
        align: 'left',
      },
      {
        name: 'displayDateRange',
        label: '表示期間',
        minWidth: theme.spacing(10),
        align: 'left',
      },
      {
        name: 'availableRange',
        label: '有効期間',
        minWidth: theme.spacing(10),
        align: 'left',
      },
      {
        name: 'updateAt',
        label: '更新日時 / 作成日時',
        minWidth: theme.spacing(10),
        align: 'left',
      },
      {
        name: 'detailButton',
        label: '',
        minWidth: theme.spacing(10),
        align: 'center',
      },
      {
        name: 'realTimeCouponIssueUrlButton',
        label: '配布URL',
        minWidth: theme.spacing(10),
        align: 'center',
      },
    ],
  };

  const formatDisplayDays = (row: CouponMaster): React.ReactElement => {
    return (
      <>
        {formatJstDate(row.displayStartDate)}から
        <br />
        {formatJstDate(row.displayEndDate)}まで
      </>
    );
  };

  const formatValidityDays = (row: CouponMaster): React.ReactElement => {
    return (
      <>
        {row.validityStartDateTime ? `${formatJstDateTime(row.validityStartDateTime)} から` : ''}
        {row.validityStartDateTime ? <br /> : <></>}
        {`${formatJstDateTime(row.validityEndDateTime)} まで`}
      </>
    );
  };

  // 検索フォームを条件によって変える
  const searchForm = (): React.ReactElement => {
    switch (watchSearchCondition) {
      case 'couponName':
        return (
          <Grid container spacing={2} alignItems="center">
            <Grid item lg={5} xl={3}>
              <TextFieldController
                name="value"
                label="クーポン名が"
                variant="standard"
                rule={{
                  required: {
                    value: true,
                    message: '条件を指定する場合、必須です',
                  },
                  maxLength: {
                    value: 30,
                    message: '30文字以内で入力してください',
                  },
                }}
              />
            </Grid>
            <Grid item lg={5} xl={2} className={styles.searchButton}>
              <Button
                type="submit"
                variant="outlined"
                size="large"
                color={'default'}
                disabled={props.loading}
                endIcon={<Search />}
                fullWidth
              >
                から始まる
              </Button>
            </Grid>
          </Grid>
        );
      case 'couponCode':
        return (
          <Grid container spacing={2} alignItems="center">
            <Grid item lg={5} xl={3}>
              <TextFieldController
                name="value"
                label="クーポンコードが"
                variant="standard"
                rule={{
                  required: {
                    value: true,
                    message: '条件を指定する場合、必須です',
                  },
                  maxLength: {
                    value: 30,
                    message: '30文字以内で入力してください',
                  },
                }}
              />
            </Grid>
            <Grid item lg={5} xl={2} className={styles.searchButton}>
              <Button
                type="submit"
                variant="outlined"
                size="large"
                color={'default'}
                disabled={props.loading}
                endIcon={<Search />}
                fullWidth
              >
                から始まる
              </Button>
            </Grid>
          </Grid>
        );
      default:
        // and none
        return (
          <Grid container spacing={2} className={styles.searchForm} alignItems="center">
            <Grid item xs={6}>
              <Button
                type="submit"
                variant="outlined"
                size="large"
                color={'default'}
                disabled={props.loading}
                endIcon={<Search />}
                fullWidth
              >
                条件なしで検索する
              </Button>
            </Grid>
          </Grid>
        );
    }
  };

  // 組織情報が取得できていないタイミングでは、ローディングを出す
  if (props.organizationLoading || !props.organization) {
    return (
      <Box display="flex" alignItems="center" justifyContent="center">
        <CircularProgress />
      </Box>
    );
  }

  // 組織情報の取得ができているならば、本体を描画する
  return (
    <>
      <Grid container direction="row" justifyContent="space-between" alignItems="center">
        <Grid item>
          <Breadcrumbs>
            <Typography variant="body1">{props.organization.name}のクーポンマスタ一覧</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item>
          <Button
            color="secondary"
            variant="contained"
            onClick={() =>
              history.push({
                pathname: '/coupon-masters/new',
                search: history.location.search,
              })
            }
          >
            <Typography variant="body1">新規作成</Typography>
          </Button>
        </Grid>
      </Grid>
      <Box className={styles.searchForm}>
        <FormProvider {...couponMasterListForm}>
          <form onSubmit={handleSubmit(props.onSearchCouponMasters)} autoComplete="off" noValidate>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormControl className={styles.conditionFormControl}>
                  <SelectController
                    name="searchCondition"
                    label="検索条件"
                    disabled={props.loading}
                    variant="standard"
                    menuItems={[
                      { value: 'none', label: 'なし' },
                      { value: 'couponName', label: 'クーポン名' },
                      { value: 'couponCode', label: 'クーポンコード' },
                    ]}
                  />
                </FormControl>
              </Grid>
            </Grid>
            {searchForm()}
          </form>
        </FormProvider>
      </Box>
      <Box className={styles.couponMastersList}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TableContainer component={Paper}>
              {props.loading ? <LinearProgress /> : <React.Fragment />}
              <Table>
                <BasicTableHead columns={tableHeadProps.columns} />
                <TableBody>
                  {(() => {
                    if (!props.data || !props.data.couponMasters) {
                      return;
                    } else if (props.data.couponMasters.nodes && props.data.couponMasters.nodes.length === 0) {
                      return (
                        <TableRow>
                          <TableCell>データがありません</TableCell>
                        </TableRow>
                      );
                    } else {
                      return props.data.couponMasters.nodes.map((row) => (
                        <TableRow key={row.id}>
                          <TableCell component="th" scope="row">
                            {row.couponCode}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {row.couponName}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            <Typography variant={'caption'}>{formatDisplayDays(row)}</Typography>
                          </TableCell>
                          <TableCell component="th" scope="row">
                            <Typography variant={'caption'}> {formatValidityDays(row)} </Typography>
                          </TableCell>
                          <TableCell component="th" scope="row">
                            <Typography variant={'caption'}>更新：{formatJstDateTime(row.updateAtDateTime)}</Typography>
                            <br />
                            <Typography variant={'caption'}>作成：{formatJstDateTime(row.createAtDateTime)}</Typography>
                          </TableCell>
                          <TableCell align="right" scope="row">
                            <Button
                              type="button"
                              size="large"
                              color="primary"
                              onClick={() =>
                                history.push({
                                  pathname: `/coupon-masters/${row.id}`,
                                  search: history.location.search,
                                })
                              }
                            >
                              詳細
                            </Button>
                          </TableCell>
                          <TableCell align="right" scope="row">
                            <Button
                              type="button"
                              size="large"
                              color="secondary"
                              onClick={() => {
                                props.setOpen(true);
                                props.setRealTimeCouponIssueUrl(`${ConsumerUrl}/coupon_masters/${row.id}/issue_coupon`);
                              }}
                            >
                              URL
                            </Button>
                          </TableCell>
                        </TableRow>
                      ));
                    }
                  })()}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          {props.data?.couponMasters.pageInfo.nextToken ? (
            <ReadMoreButton fetchMore={props.onFetchMore} loading={props.loading} />
          ) : (
            <></>
          )}
        </Grid>
        {/* リアルタイム発行API URLダイアログ */}
        <Dialog maxWidth="xl" open={props.open}>
          <DialogTitle>
            <Typography variant="h4">クーポン発行URL</Typography>
          </DialogTitle>
          <DialogContent>
            <DialogContentText variant="subtitle1" color="primary">
              以下のURLを呼び出すことでクーポンURLを取得することができます。
            </DialogContentText>
            <DialogContentText variant="h4" color="textPrimary">
              {props.realTimeCouponIssueUrl}
            </DialogContentText>
            <DialogActions>
              <Button onClick={() => props.setOpen(false)} color="primary">
                OK
              </Button>
            </DialogActions>
          </DialogContent>
        </Dialog>
      </Box>
    </>
  );
};

const useListCouponMastersPage = (): ListCouponMastersPageProps => {
  const couponMastersList = useListCouponMasters();
  return {
    header: { title: 'クーポンマスタ一覧' },
    listBody: couponMastersList,
  };
};

export const ListCouponMastersPage: React.FC = () => {
  const listCouponMastersPage = useListCouponMastersPage();
  return (
    <MenuHeaderTemplate header={listCouponMastersPage.header}>
      <Container maxWidth="xl">
        <Box>
          <ListCouponMastersBody {...listCouponMastersPage.listBody} />
        </Box>
      </Container>
    </MenuHeaderTemplate>
  );
};
