import React, { useContext, useEffect } from 'react';
import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { useForm, FormProvider } from 'react-hook-form';
import { SelectController } from '../../../components/organisms/form/controllers/SelectController';
import { useUpdateUserRoleUseCase } from '../hooks/update-user-role-use-case';
import { SwitchController } from '../../../components/organisms/form/controllers/SwitchController';
import { UserContext } from '../../../components/contexts/user/user-context';

type UpdateUserRoleProps = {
  id: string;
  roleId: string;
  isAdministrator: boolean;
  open: boolean;
  setOpen: (open: boolean) => void;
};

export type UpdateUserRoleFormValues = {
  id: string;
  role: string;
  isAdministrator: boolean;
};

export const UpdateUserRoleDialog: React.FC<UpdateUserRoleProps> = (props) => {
  const { loading, onUpdate, roles } = useUpdateUserRoleUseCase(props.setOpen);
  const { userInfo } = useContext(UserContext);

  // Hook Form
  const updateUserRoleForm = useForm<UpdateUserRoleFormValues>();

  useEffect(() => {
    if (props.isAdministrator) {
      // 管理者の場合、ロールの一覧を初期表示する
      updateUserRoleForm.reset({ id: props.id, role: roles![0].id, isAdministrator: props.isAdministrator });
    } else {
      // 管理者以外の場合、設定されているロールを表示する
      updateUserRoleForm.reset({ id: props.id, role: props.roleId, isAdministrator: props.isAdministrator });
    }
  }, [props.roleId]);

  const { handleSubmit, watch } = updateUserRoleForm;

  const isAdministrator = watch('isAdministrator');

  return (
    <Dialog fullWidth maxWidth="md" open={props.open} onClose={() => props.setOpen(false)}>
      <FormProvider {...updateUserRoleForm}>
        <form onSubmit={handleSubmit(onUpdate)} autoComplete="off" noValidate>
          <DialogTitle>ユーザーロール更新</DialogTitle>
          <DialogContent>
            {roles ? (
              <>
                <Box
                  display={userInfo?.permissions.includes('write:admin-user') ? 'flex' : 'none'}
                  justifyContent="flex-end"
                >
                  <SwitchController name="isAdministrator" label="管理者" disabled={loading} />
                </Box>
                <SelectController
                  required
                  name="role"
                  variant="standard"
                  label="ロール"
                  menuItems={roles.map((x) => {
                    return {
                      value: x.id,
                      label: x.name,
                    };
                  })}
                  disabled={loading || isAdministrator}
                  rules={{
                    required: {
                      value: true,
                      message: 'ロールは必須です',
                    },
                  }}
                />
              </>
            ) : (
              <CircularProgress />
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => props.setOpen(false)} color="secondary" disabled={loading}>
              キャンセル
            </Button>
            <Button type="submit" color="primary" disabled={loading}>
              {loading ? <CircularProgress size="1rem" /> : '更新する'}
            </Button>
          </DialogActions>
        </form>
      </FormProvider>
    </Dialog>
  );
};
