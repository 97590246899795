import React, { useContext, useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from '@material-ui/core';
import { TextFieldController } from '../../../components/organisms/form/controllers/TextFieldController';
import { SelectController } from '../../../components/organisms/form/controllers/SelectController';
import { useForm, FormProvider } from 'react-hook-form';
import { CreateUserFormValues } from '../UserList';
import { useOrganizationSearch } from '../../organization-util/hooks';
import {
  OrganizationBreadCrumbsBody,
  OrganizationBreadCrumbsBodyProps,
} from '../../organization-util/OrganizationBreadcrumbs';
import { OrganizationSearchFormBody } from '../../organization-util/OrganizationSerachFormBody';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Role } from '../../../graphql/generated';
import { OrganizationContext } from '../../../components/contexts/organization';
import { SwitchController } from '../../../components/organisms/form/controllers/SwitchController';
import { UserContext } from '../../../components/contexts/user/user-context';

type CreateShopPageProps = {
  open: boolean;
  roles?: Role[];
  rolesLoading: boolean;
  loading: boolean;
  onCreate: (input: CreateUserFormValues) => void;
  onClose: () => void;
};

const useStyles = makeStyles((theme: Theme) => ({
  dialogContent: {
    padding: theme.spacing(3),
  },
  organizationSearchForm: {
    marginTop: theme.spacing(1),
  },
  organizationDialogButton: {
    marginTop: theme.spacing(2),
  },
}));

export const CreateUserDialog: React.FC<CreateShopPageProps> = (props) => {
  const styles = useStyles();
  const orgContext = useContext(OrganizationContext);
  const organization = orgContext.organization;
  const { userInfo } = useContext(UserContext);
  const [selectedOrganizationId, setSelectedOrganizationId] = useState<string>('');
  const [orgOpen, setOrgOpen] = useState<boolean>(false);

  // Hook Form
  const createUserForm = useForm<CreateUserFormValues>({
    defaultValues: {
      email: '',
      name: '',
      password: '',
      isAdministrator: false,
      role: props.roles ? props.roles[0].id : undefined,
      organizationId: selectedOrganizationId,
    },
  });

  const { handleSubmit, setValue, watch } = createUserForm;
  setValue('organizationId', selectedOrganizationId);

  const isAdministrator = watch('isAdministrator');

  return (
    <Dialog fullWidth maxWidth="md" open={props.open} onClose={props.onClose}>
      <FormProvider {...createUserForm}>
        <form onSubmit={handleSubmit(props.onCreate)} autoComplete="off" noValidate>
          <DialogTitle>ユーザー作成</DialogTitle>
          <DialogContent>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={6}>
                <TextFieldController
                  required
                  name="organizationId"
                  type="text"
                  size="small"
                  variant="standard"
                  label="所属する組織"
                  rule={{
                    required: '組織は必須です',
                  }}
                  disabled={true}
                />
              </Grid>
              <Grid item xs={3}>
                <Button
                  className={styles.organizationDialogButton}
                  fullWidth
                  color="primary"
                  type="button"
                  variant="contained"
                  onClick={() => {
                    setSelectedOrganizationId(organization!.id);
                  }}
                  disabled={props.loading}
                >
                  現組織と同じ
                </Button>
              </Grid>
              <Grid item xs={3}>
                <Button
                  className={styles.organizationDialogButton}
                  fullWidth
                  color="primary"
                  type="button"
                  variant="contained"
                  onClick={() => {
                    setOrgOpen(true);
                  }}
                  disabled={props.loading}
                >
                  子組織から選択
                </Button>
              </Grid>
            </Grid>
            <TextFieldController
              required
              name="email"
              type="email"
              size="small"
              variant="standard"
              label="Eメール"
              rule={{
                required: 'Eメールは必須です',
                maxLength: {
                  value: 200,
                  message: 'Eメールは200文字以内です',
                },
                pattern: {
                  value: /[a-zA-Z0-9]+[a-zA-Z0-9._-]*@[a-zA-Z0-9_-]+[a-zA-Z0-9._-]+/,
                  message: 'Eメールアドレスの形式で入力してください',
                },
              }}
              disabled={props.loading}
            />
            <TextFieldController
              required
              name="name"
              type="text"
              size="small"
              variant="standard"
              label="ユーザー名"
              rule={{
                required: 'ユーザー名は必須です',
                maxLength: {
                  value: 100,
                  message: 'ユーザー名は100文字以内です',
                },
              }}
              disabled={props.loading}
            />
            <TextFieldController
              required
              name="password"
              type="password"
              size="small"
              variant="standard"
              label="パスワード"
              rule={{
                required: 'パスワードは必須です',
                pattern: {
                  value: /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*])([a-zA-Z0-9!@#$%^&*]{8,})$/,
                  message: 'パスワードは半角英小文字、半角英大文字、数字、記号（!@#$%^&*）を含めて入力してください',
                },
                minLength: {
                  value: 8,
                  message: 'パスワードは8文字以上で入力してください',
                },
              }}
              disabled={props.loading}
            />
            <Box
              display={userInfo?.permissions.includes('write:admin-user') ? 'flex' : 'none'}
              justifyContent="flex-end"
            >
              <SwitchController name="isAdministrator" label="管理者" disabled={props.rolesLoading || props.loading} />
            </Box>
            <SelectController
              required
              name="role"
              variant="standard"
              label="ロール"
              menuItems={
                props.roles
                  ? props.roles?.map((x) => {
                      return {
                        value: x.id,
                        label: x.name,
                      };
                    })
                  : []
              }
              disabled={props.rolesLoading || props.loading || isAdministrator}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={props.onClose} color="secondary" disabled={props.loading}>
              キャンセル
            </Button>
            <Button type="submit" color="primary" disabled={props.loading}>
              {props.loading ? <CircularProgress size="1rem" /> : '作成する'}
            </Button>
          </DialogActions>
        </form>
      </FormProvider>
      <SelectOrganizationDialog open={orgOpen} setOpen={setOrgOpen} setOrganizationId={setSelectedOrganizationId} />
    </Dialog>
  );
};

/**
 * ユーザーを作成する際にどこの組織に属するかを選択するダイアログ
 * @param props
 * @constructor
 */
export function SelectOrganizationDialog<T>(props: {
  open: boolean;
  setOpen: (open: boolean) => void;
  setOrganizationId: (organizationId: string) => void;
}): React.ReactElement {
  const classes = useStyles();
  const { onSwitch, ...searchForm } = useOrganizationSearch<T>();
  const breadcrumb: OrganizationBreadCrumbsBodyProps = {
    organization: searchForm.targetOrganization,
    setOrganizationTarget: searchForm.onDrillDown,
    originalOrganizationId: searchForm.originalOrganizationId,
  };
  return (
    <Dialog maxWidth="lg" fullWidth open={props.open} onClose={() => props.setOpen(false)}>
      <DialogTitle>所属する組織を選択します</DialogTitle>
      <Box className={classes.dialogContent}>
        <Box>
          <OrganizationBreadCrumbsBody {...breadcrumb} />
        </Box>
        <Box className={classes.organizationSearchForm}>
          <OrganizationSearchFormBody
            {...searchForm}
            onSelected={(row) => {
              props.setOrganizationId(row.id);
              props.setOpen(false);
            }}
          />
        </Box>
      </Box>
    </Dialog>
  );
}
