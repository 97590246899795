import React from 'react';
import { Dialog } from '@material-ui/core';
import {
  AvailableTemplate,
  CouponDataProps,
  DescriptionProps,
  DisplayBarcode,
  ProductImageProps,
} from '@sbgift-coupon/use';
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import { getRequiredEnvironmentVariable } from '../../../util';
import { CouponMasterFormInput } from './Form';
import { fromInputDateAndTime } from './hooks/utils';

const BARCODE_IMAGE_SERVER_URL = getRequiredEnvironmentVariable('REACT_APP_BARCODE_IMAGE_SERVER_URL');

export type CouponConsumerDisplayPreviewProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
  input: CouponMasterFormInput;
  organizationName: string;
  productImageUrl?: string;
  companyImageUrl?: string;
};

const useStyles = makeStyles(() => ({
  paper: {
    height: '100%',
  },
}));

export const CouponConsumerDisplayPreview: React.FC<CouponConsumerDisplayPreviewProps> = (props) => {
  const { open, setOpen, input } = props;
  const theme = useTheme<Theme>();
  const classes = useStyles();

  const productImage = (): ProductImageProps | undefined => {
    if (!props.productImageUrl) {
      return undefined;
    }
    return {
      couponName: input.couponName,
      imageUrl: props.productImageUrl,
      expired: false,
      used: false,
    };
  };

  const barcode: DisplayBarcode = {
    barcodeValue: 'A1234567890123C',
    barcodeType: props.input.applyBarcodeDisplayType || 'nw7',
    imageServer: BARCODE_IMAGE_SERVER_URL,
  };

  const description: DescriptionProps = {
    description: input.description ?? '',
    information: input.information ?? '',
    mapUrl: input.mapUrl ?? '',
    telNumber: input.telNumber ?? '',
    validityStartDateTime: fromInputDateAndTime(input.validityStartDate, input.validityStartTime)?.toISO(),
    validityEndDateTime: fromInputDateAndTime(input.validityEndDate, input.validityEndTime)?.toISO() || '',
  };

  const previewData: CouponDataProps = {
    organizationId: 'preview-organization-id',
    couponMasterId: 'preview-coupon-master-id',
    id: 'preview-coupon-id',
    applyWith: input.applyWith,
    couponName: {
      couponName: input.couponName,
      couponNameHtml: input.couponNameHtml ?? '',
      couponNameBandColor: input.couponNameBandColor || '#ffffff',
      couponNameTextColor: input.couponNameTextColor || '#000000',
    },
    couponCode: input.couponCode,
    organizationName: {
      companyName: props.organizationName,
      couponIssuerBandColor: input.couponIssuerBandColor || '#ffffff',
      couponIssuerTextColor: input.couponIssuerTextColor || '#000000',
    },
    productImage: productImage(),
    companyImageUrl: props.companyImageUrl,
    barcode,
    description,
  };

  return (
    <Dialog
      classes={{
        paper: classes.paper,
      }}
      onClose={() => setOpen(false)}
      open={open}
    >
      <AvailableTemplate theme={theme} layoutPattern={props.input.layoutPattern} {...previewData} />
    </Dialog>
  );
};
