import { MenuItem, Select, TextField } from '@material-ui/core';
import { Controller, RegisterOptions, useFormContext } from 'react-hook-form';
import React from 'react';

type SelectControllerProps = {
  name: string;
  label?: string;
  menuItems: { value: string; label: string }[];
  variant?: 'standard' | 'outlined' | 'filled';
  disabled?: boolean;
  defaultValue?: string;
  onChange?: () => void;
  required?: boolean;
  rules?: RegisterOptions;
};

export const SelectController: React.FC<SelectControllerProps> = ({
  name,
  label,
  menuItems,
  variant = 'outlined',
  disabled,
  defaultValue = '',
  onChange,
  required,
  rules,
}) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      rules={rules}
      render={({ field: { ref, ...remain } }) => (
        <TextField
          select
          required={required}
          inputRef={ref}
          fullWidth
          label={label}
          variant={variant}
          error={!!errors[name]}
          helperText={errors[name]?.message ?? ''}
          disabled={disabled}
          inputProps={{ onChange }}
          {...remain}
        >
          {menuItems.map(({ value, label }, index) => (
            <MenuItem key={index} value={value}>
              {label}
            </MenuItem>
          ))}
        </TextField>
      )}
    />
  );
};
