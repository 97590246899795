import { Box, Breadcrumbs, CircularProgress, Container, Link, Typography } from '@material-ui/core';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { CouponMasterForm, CouponMasterFormInput, CouponMasterFormProps } from './Form';
import { MenuHeaderTemplate } from '../../../components/templates/MenuHeaderTemplate';
import { CouponMasterImageBodyProps } from './Image';
import { useUpdateCouponMaster } from './hooks/update-coupon';
import { PathParams } from '../../../App';
import { CouponFeature, OrganizationContext } from '../../../components/contexts/organization';

export type UpdateCouponMasterPageProps = {
  organization?: OrganizationContext;
  couponFeature?: CouponFeature;
  organizationLoading: boolean;
  initialLoading: boolean;
  previousValues?: CouponMasterFormInput;
  onSubmit: (input: CouponMasterFormInput) => void;
  loading: boolean;
  submitStepMessage: string;
  productImageProps: CouponMasterImageBodyProps;
  companyImageProps: CouponMasterImageBodyProps;
  smartCheckHeaderImageProps: CouponMasterImageBodyProps;
  smartCheckUseButtonImageProps: CouponMasterImageBodyProps;
  smartCheckConfirmButtonImageProps: CouponMasterImageBodyProps;
  smartCheckConfirmCancelButtonImageProps: CouponMasterImageBodyProps;
  smartCheckForceButtonImageProps: CouponMasterImageBodyProps;
  smartCheckUsedImageProps: CouponMasterImageBodyProps;
};

export const UpdateCouponMaster: React.FC<UpdateCouponMasterPageProps> = (props) => {
  // 組織情報と初期値が取得できていないタイミングでは、ローディングを出す
  if (
    props.organizationLoading ||
    !props.organization ||
    props.initialLoading ||
    !props.previousValues ||
    !props.couponFeature
  ) {
    return (
      <Box display="flex" alignItems="center" justifyContent="center">
        <CircularProgress />
      </Box>
    );
  }

  const formProps: CouponMasterFormProps = {
    organizationContext: props.organization,
    couponFeature: props.couponFeature,
    displaySettings: {
      apply: false,
      timeFrame: props.couponFeature.timeFrame.available,
    },
    loading: props.loading,
    onSubmit: props.onSubmit,
    submitStepMessage: props.submitStepMessage,
    previousValues: props.previousValues,
    productImageProps: props.productImageProps,
    companyImageProps: props.companyImageProps,
    smartCheckHeaderImageProps: props.smartCheckHeaderImageProps,
    smartCheckUseButtonImageProps: props.smartCheckUseButtonImageProps,
    smartCheckConfirmButtonImageProps: props.smartCheckConfirmButtonImageProps,
    smartCheckConfirmCancelButtonImageProps: props.smartCheckConfirmCancelButtonImageProps,
    smartCheckUsedImageProps: props.smartCheckUsedImageProps,
    smartCheckForceButtonImageProps: props.smartCheckForceButtonImageProps,
    submitLabel: '更新する',
  };

  return <CouponMasterForm {...formProps} />;
};

export const UpdateCouponMasterPage: React.FC = () => {
  const history = useHistory();
  const { couponMasterId } = useParams<PathParams>();
  const useCaseUpdate = useUpdateCouponMaster(couponMasterId);

  return (
    <MenuHeaderTemplate header={{ title: 'クーポンマスタの更新' }}>
      <Container maxWidth="xl">
        <Breadcrumbs>
          <Link
            href="#"
            onClick={() =>
              history.push({
                pathname: '/coupon-masters',
                search: history.location.search,
              })
            }
          >
            クーポンマスタ一覧
          </Link>
          <Link
            href="#"
            onClick={() =>
              history.push({
                pathname: `/coupon-masters/${couponMasterId}`,
                search: history.location.search,
              })
            }
          >
            クーポンマスタ詳細
          </Link>
          <Typography>クーポンマスタの更新</Typography>
        </Breadcrumbs>
        <Box>
          <UpdateCouponMaster {...useCaseUpdate} />
        </Box>
      </Container>
    </MenuHeaderTemplate>
  );
};
