import { useContext, useState } from 'react';
import { CouponFeature, OrganizationContext } from '../../../../components/contexts/organization';
import {
  convertTimeFrameToDateArray,
  convertTimeFrameToDateString,
  convertTimeFrameToDaysArray,
  fromInputDate,
  fromInputDateAndTime,
  validateStartEnd,
} from './utils';
import { CouponMasterFormInput } from '../Form';
import { useSnackbar } from 'notistack';
import { DateTime } from 'luxon';
import { DayType } from '../../../../graphql/generated';

type InValid = {
  invalid: true;
};

type ValidDateTime = {
  invalid: false;
  displayStartDateTime: DateTime;
  displayEndDateTime: DateTime;
  validityStartDateTime: DateTime | undefined;
  validityEndDateTime: DateTime;
  realTimeIssuedGetStartDateTime: DateTime | undefined;
  realTimeIssuedGetEndDateTime: DateTime | undefined;
};

type ValidAvailableSetting = {
  invalid: false;
  availableDateArray: Array<string>;
  availableDaysArray: Array<DayType>;
  availableStartTimePeriodString?: string;
  availableEndTimePeriodString?: string;
};

export type FormCouponMasterUseCase = {
  organization?: OrganizationContext;
  couponFeature?: CouponFeature;
  organizationLoading: boolean;
  loading: boolean;
  setLoading: (loading: boolean) => void;
  validateDateAndGetDateTime: (input: CouponMasterFormInput) => InValid | ValidDateTime;
  validateAndConvertAvailableSetting: (input: CouponMasterFormInput) => InValid | ValidAvailableSetting;
};

/**
 * Create と Update は本質的に別の業務であり、本来であればロジックも完全に別で定義するべき。
 * ただ、あまりにも共通する内容が多いのと、その分量が無視できなくなってきたため、
 * 今の時点で「たまたま」重複しているロジックを切り出して Create からも Update からも使うようにしている。
 * 今後、アップデートなどで例えば Update だけに追加される処理、Create ではなくなる処理がこのファイルで発生した場合、
 * if( create) など条件文で分岐するのではなく、迷わずロジックごと分割して、Create 用、 Update 用それぞれで書くようにしてください。
 */
export const useFormCouponMaster = (): FormCouponMasterUseCase => {
  const orgContext = useContext(OrganizationContext);
  const organization = orgContext.organization;
  const couponFeature = orgContext.couponFeature;
  const organizationLoading = orgContext.loading;
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);

  const validateDateAndGetDateTime = (input: CouponMasterFormInput): InValid | ValidDateTime => {
    // 表示期間をDateTimeに変換
    const displayStartDateTime = fromInputDate(input.displayStartDate);
    const displayEndDateTime = fromInputDate(input.displayEndDate);

    // 終了期間をDateTimeに変換
    const validityStartDateTime = fromInputDateAndTime(input.validityStartDate, input.validityStartTime);
    const validityEndDateTime = fromInputDateAndTime(input.validityEndDate, input.validityEndTime);

    // 取得期間をDateTimeに変換
    const realTimeIssuedGetStartDateTime = fromInputDateAndTime(
      input.realTimeIssuedGetStartDate,
      input.realTimeIssuedGetStartTime
    );
    const realTimeIssuedGetEndDateTime = fromInputDateAndTime(
      input.realTimeIssuedGetEndDate,
      input.realTimeIssuedGetEndTime
    );

    // 日付と時刻は両方入力して
    if (
      !!input.validityStartDate !== !!input.validityStartTime ||
      !!input.realTimeIssuedGetStartDate !== !!input.realTimeIssuedGetStartTime ||
      !!input.realTimeIssuedGetEndDate !== !!input.realTimeIssuedGetEndTime
    ) {
      const key = enqueueSnackbar('日付と時刻はどちらも入力してください。', {
        variant: 'warning',
        persist: true,
        onClick: () => closeSnackbar(key),
      });
      return { invalid: true };
    }

    //日時のバリデーション
    if (
      !displayStartDateTime ||
      !displayEndDateTime ||
      !validityEndDateTime ||
      !displayStartDateTime.isValid ||
      !displayEndDateTime.isValid ||
      (validityStartDateTime && !validityStartDateTime.isValid) ||
      !validityEndDateTime.isValid ||
      (realTimeIssuedGetStartDateTime && !realTimeIssuedGetStartDateTime.isValid) ||
      (realTimeIssuedGetEndDateTime && !realTimeIssuedGetEndDateTime.isValid)
    ) {
      const key = enqueueSnackbar('表示期間、有効期限、取得期限の日時情報を正しく入力してください', {
        variant: 'warning',
        persist: true,
        onClick: () => closeSnackbar(key),
      });
      return { invalid: true };
    }

    //日時の逆転チェック
    if (
      !displayStartDateTime ||
      !displayEndDateTime ||
      !validityEndDateTime ||
      !validateStartEnd(displayStartDateTime, displayEndDateTime) ||
      !validateStartEnd(validityStartDateTime, validityEndDateTime) ||
      !validateStartEnd(realTimeIssuedGetStartDateTime, realTimeIssuedGetEndDateTime)
    ) {
      const key = enqueueSnackbar('開始日時が前になるように修正してください', {
        variant: 'warning',
        persist: true,
        onClick: () => closeSnackbar(key),
      });
      return { invalid: true };
    }

    return {
      invalid: false,
      displayStartDateTime,
      displayEndDateTime,
      validityStartDateTime,
      validityEndDateTime,
      realTimeIssuedGetStartDateTime,
      realTimeIssuedGetEndDateTime,
    };
  };

  /**
   * 帯時間設定のバリデーションとコンバートを行う
   */
  const validateAndConvertAvailableSetting = (input: CouponMasterFormInput): InValid | ValidAvailableSetting => {
    // 帯時間設定（月ごと）の日付を配列にする
    const availableDateArray = convertTimeFrameToDateArray(input.availableDate);

    // 帯時間設定（週ごと）の曜日を配列にする
    const availableDaysArray = convertTimeFrameToDaysArray(input.availableDays);

    // 帯時間は文字列だけを抽出する
    const availableStartTimePeriodString = convertTimeFrameToDateString(input.availableStartTimePeriod);
    const availableEndTimePeriodString = convertTimeFrameToDateString(input.availableEndTimePeriod);

    // 帯時間は入力するなら両方必要
    if ([availableStartTimePeriodString, availableEndTimePeriodString].filter(Boolean).length === 1) {
      setLoading(false);
      const key = enqueueSnackbar('帯時間を設定する場合は、両方入力してください', {
        variant: 'warning',
        persist: true,
        onClick: () => closeSnackbar(key),
      });
      return { invalid: true };
    }

    return {
      invalid: false,
      availableDateArray,
      availableDaysArray,
      availableStartTimePeriodString,
      availableEndTimePeriodString,
    };
  };

  return {
    organization,
    couponFeature,
    organizationLoading,
    loading,
    setLoading,
    validateDateAndGetDateTime,
    validateAndConvertAvailableSetting,
  };
};
