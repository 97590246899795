import { useSnackbar } from 'notistack';
import { useCallback } from 'react';
import { useHistory } from 'react-router';
import { Billing, OrganizationQuery, useOrganizationQuery, useUpdateShopMutation } from '../../../graphql/generated';
import { UpdateShopFormValues } from '../pages/UpdateShopPage';
import { ApolloError } from '@apollo/client';

export type UpdateShopUseCase = {
  updateLoading: boolean;
  queryLoading: boolean;
  data: OrganizationQuery;
  onUpdate: (input: UpdateShopFormValues) => void;
};

export const useUpdateShopUseCase = (organizationId: string): UpdateShopUseCase => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const history = useHistory();

  const { data: queryData, loading: queryLoading } = useOrganizationQuery({
    variables: {
      id: organizationId,
    },
  });

  const [updateShopMutation, { loading }] = useUpdateShopMutation();

  const onUpdate = useCallback(
    async (input: UpdateShopFormValues) => {
      closeSnackbar();

      try {
        const { data } = await updateShopMutation({
          variables: {
            id: organizationId,
            input: {
              name: input.name,
              billing: queryData?.organization.billing as Billing,
              parentId: queryData?.organization.parentId!,
              customerShopCode: input.customerShopCode,
              simpleClearingCode: input.simpleClearingCode,
            },
          },
        });

        enqueueSnackbar(`店舗を更新しました`, {
          variant: 'success',
        });

        history.push({
          pathname: `/manage-organizations/${data?.updateShop.id}`,
          hash: '#about',
          search: history.location.search,
        });
      } catch (e: unknown) {
        if (e instanceof ApolloError) {
          console.error('graphQLErrors', e.graphQLErrors);
          if (e.message === 'ShopConstraintError') {
            enqueueSnackbar(`顧客店舗コードまたは店舗消込コードが重複しています`, {
              variant: 'error',
            });
          }
        } else {
          throw e;
        }
      }
    },
    [updateShopMutation, enqueueSnackbar]
  );

  return {
    updateLoading: loading,
    queryLoading,
    data: queryData!,
    onUpdate,
  };
};
