import { Box, Breadcrumbs, CircularProgress, Container, Link, Tab, Theme, Typography } from '@material-ui/core';
import { TabContext, TabList, TabPanel } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';
import React, { useContext, useEffect } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { CouponMaster, useCouponMasterLazyQuery } from '../../../graphql/generated';
import { About } from './About';
import { CouponList } from './CouponList';
import { MenuHeaderTemplate } from '../../../components/templates/MenuHeaderTemplate';
import { PathParams } from '../../../App';
import { BulkAndHistories } from './BulkAndHistories';
import { OrganizationContext } from '../../../components/contexts/organization';
import { useSnackbar } from 'notistack';
import { UserContext } from '../../../components/contexts/user/user-context';

const useStyles = makeStyles((theme: Theme) => ({
  tab: {
    margin: `${theme.spacing(3)}px 0 0`,
  },
  tabPanel: {
    padding: `${theme.spacing(2)}px 0`,
  },
  tabList: {
    backgroundColor: theme.palette.grey[50],
  },
  tabListIndicator: {
    backgroundColor: theme.palette.grey[400],
  },
}));

export const CouponMasterDashboard: React.FC = () => {
  const styles = useStyles();
  const history = useHistory();
  const { hash } = useLocation();
  const orgContext = useContext(OrganizationContext);
  const { userInfo } = useContext(UserContext);

  const { couponMasterId } = useParams<PathParams>();
  const { enqueueSnackbar } = useSnackbar();

  const [couponMasterQuery, { data, error, loading }] = useCouponMasterLazyQuery({
    fetchPolicy: 'cache-first',
    onError: (err) => {
      console.log('error', err);
    },
  });

  useEffect(() => {
    couponMasterQuery({
      variables: {
        couponMasterId,
      },
    });
  }, [couponMasterQuery]);

  // 現在の組織とクーポンマスタの組織が異なる場合、なりすます
  useEffect(() => {
    if (
      !orgContext.loading &&
      data?.couponMaster &&
      orgContext.organization?.id !== data?.couponMaster?.organizationId
    ) {
      orgContext.setOrganization(data?.couponMaster.organizationId);
      enqueueSnackbar(`このクーポンマスタを作成した組織として操作します`, {
        variant: 'success',
      });
    }
  }, [orgContext, data?.couponMaster, enqueueSnackbar, couponMasterId]);

  if (orgContext.loading || !orgContext.organization || loading || !data?.couponMaster) {
    return (
      <Box display="flex" alignItems="center" justifyContent="center">
        <CircularProgress />
      </Box>
    );
  }
  if (error) {
    return (
      <Box display="flex" alignItems="center" justifyContent="center">
        <Typography variant="body1">クーポンマスタの読み込みに失敗しました</Typography>
      </Box>
    );
  }

  return (
    <>
      <Breadcrumbs>
        <Link
          href="#"
          onClick={() =>
            history.push({
              pathname: '/coupon-masters',
              search: history.location.search,
            })
          }
        >
          クーポンマスタ一覧
        </Link>
        <Typography color="textPrimary">クーポンマスタ詳細</Typography>
      </Breadcrumbs>
      <Box marginTop="8px" />
      <TabContext value={hash || '#about'}>
        <TabList className={styles.tab} classes={{ indicator: styles.tabListIndicator, root: styles.tabList }}>
          <Tab label="概要" component={Link} href="#about" value="#about" />
          {userInfo?.permissions.includes('show:bulk-issue-coupon') ? (
            <Tab label="一括発行" component={Link} href="#bulk" value="#bulk" />
          ) : (
            <></>
          )}
          <Tab label="発行済みクーポン一覧" component={Link} href="#coupons" value="#coupons" />
        </TabList>
        <TabPanel value="#about" classes={{ root: styles.tabPanel }}>
          <About organization={orgContext.organization} {...(data?.couponMaster as CouponMaster)} />
        </TabPanel>
        <TabPanel value="#bulk" classes={{ root: styles.tabPanel }}>
          <BulkAndHistories />
        </TabPanel>
        <TabPanel value="#coupons" classes={{ root: styles.tabPanel }}>
          <CouponList />
        </TabPanel>
      </TabContext>
    </>
  );
};

export const CouponMasterDashboardPage: React.FC = () => {
  return (
    <MenuHeaderTemplate header={{ title: 'クーポンマスタ詳細' }}>
      <Container maxWidth="xl">
        <Box>
          <CouponMasterDashboard />
        </Box>
      </Container>
    </MenuHeaderTemplate>
  );
};
