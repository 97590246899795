import {
  OrganizationQuery,
  PaginatedOrganizations,
  useOrganizationChildrenLazyQuery,
  useOrganizationLazyQuery,
} from '../../../graphql/generated';
import { OrganizationContext } from '../../../components/contexts/organization';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router';

export type OrganizationSearchUseCase = {
  onSearchSubmit: (data: ChildrenFormValues) => void;
  targetOrganization?: OrganizationContext;
  originalOrganizationId?: string;
  loading: boolean;
  data?: PaginatedOrganizations;
  onSwitch: (data: OrganizationContext) => void;
  onDrillDown: (organizationId: string) => void;
  fetchMore: () => void;
};

export type ChildrenFormValues = {
  organizationName: string;
};

const PageItemsCount = '25';

// 組織を切り替えたときにオプションで別のぺージにとばせるようにする
export type HistoryPush<T> = {
  pathname: string;
  state?: T;
};

export function useOrganizationSearch<T>(historyPush?: HistoryPush<T>): OrganizationSearchUseCase {
  const context = useContext(OrganizationContext);
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [targetOrganization, setTargetOrganization] = useState<OrganizationContext>();
  const { enqueueSnackbar } = useSnackbar();

  const [organizationChildrenQuery, queryContext] = useOrganizationChildrenLazyQuery({
    // デフォルトだと同じ検索条件で再検索をかけると何も起こらない。データ更新を考慮して常に最新のデータをとってきたいため、
    // network-only とする。ただし、キャッシュしたデータは一覧表示に使うので、
    // no-chache とはしない。
    fetchPolicy: 'network-only',
    onCompleted: () => {
      setLoading(false);
    },
    onError: (error) => {
      console.log(error);
      enqueueSnackbar('子組織の取得でエラーが発生しました', {
        variant: 'error',
      });
    },
  });

  const [organizationQuery] = useOrganizationLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: (data: OrganizationQuery) => {
      setLoading(false);
      setTargetOrganization(data.organization);
    },
    onError: (error) => {
      console.log(error);
      enqueueSnackbar('組織情報の取得でエラーが発生しました', {
        variant: 'error',
      });
    },
  });

  // 検索対象の組織として最初はコンテキストを設定する
  useEffect(() => {
    if (context.organization) {
      setTargetOrganization(context.organization);
      setLoading(true);
      organizationChildrenQuery({
        variables: {
          query: {
            parentId: context.organization.id,
            limit: PageItemsCount,
          },
        },
      });
    }
  }, [context.organization, organizationChildrenQuery]); // 初回だけ実行させたいのでcontext全体ではなくoriginalを指定する

  const onSearchSubmit = useCallback(
    (data: ChildrenFormValues) => {
      // 検索条件がある => 名前を指定
      // 検索条件がない => GSIを指定せずに検索
      if (targetOrganization?.id) {
        setLoading(true);
        organizationChildrenQuery({
          variables: {
            query: {
              parentId: targetOrganization.id,
              name: data.organizationName,
              limit: PageItemsCount,
            },
          },
        });
      }
    },
    [targetOrganization, organizationChildrenQuery]
  );

  const onSwitch = useCallback(
    (data: OrganizationContext) => {
      // historyPush が存在する場合は、なりすまし先の組織を付与して遷移する（遷移先で自動でなりすましが実行される）
      if (historyPush) {
        history.push({
          ...historyPush,
          search: `organization=${data.id}`,
        });
      } else {
        // そうでない場合は普通にこの場でなりすまし実行
        context.setOrganization(data.id);
      }
      enqueueSnackbar(`${data.name}として操作します`, {
        variant: 'success',
      });
    },
    [history, context, enqueueSnackbar]
  );

  const onDrillDown = useCallback(
    (organizationId: string) => {
      // この組織を親にして、その子供を検索する
      setLoading(true);

      // パンくずのセットが終わったらドリルダウン実行
      organizationQuery({
        variables: {
          id: organizationId,
        },
      });

      organizationChildrenQuery({
        variables: {
          query: {
            parentId: organizationId,
            limit: PageItemsCount,
          },
        },
      });
    },
    [organizationChildrenQuery, organizationQuery]
  );

  const handleFetchMore = useCallback(async () => {
    if (targetOrganization && queryContext?.fetchMore && queryContext?.variables?.query) {
      setLoading(true);
      await queryContext.fetchMore({
        variables: {
          query: {
            parentId: queryContext.variables.query.parentId,
            name: queryContext.variables.query.name,
            shopCode: queryContext.variables.query.shopCode,
            limit: queryContext.variables.query.limit,
            nextToken: queryContext.data?.organizationChildren.nextToken,
          },
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          setLoading(false);
          if (!fetchMoreResult) {
            return prev;
          } else {
            return {
              __typename: fetchMoreResult.__typename,
              organizationChildren: {
                __typename: fetchMoreResult.organizationChildren.__typename,
                items: fetchMoreResult.organizationChildren.items
                  ? prev.organizationChildren.items?.concat(fetchMoreResult.organizationChildren.items)
                  : prev.organizationChildren.items,
                nextToken: fetchMoreResult.organizationChildren.nextToken,
              },
            };
          }
        },
      });
    }
  }, [queryContext, targetOrganization]);
  return {
    onSearchSubmit,
    targetOrganization,
    originalOrganizationId: context.original?.id,
    loading: loading || context.loading,
    data: queryContext.data?.organizationChildren,
    onSwitch,
    onDrillDown,
    fetchMore: handleFetchMore,
  };
}
