import { createTheme } from '@material-ui/core/styles';

export const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 340,
      md: 768,
      lg: 1024,
      xl: 1536,
    },
  },
  typography: {
    fontSize: 16,
    fontFamily: [
      'Helvetica Neue',
      'Helvetica, Arial',
      '游ゴシック体',
      'Yu Gothic',
      'YuGothic',
      'Hiragino Kaku Gothic ProN',
      'Meiryo',
      'sans-serif',
    ].join(','),
    body1: {
      fontSize: '1rem',
    },
    body2: {
      fontSize: '0.889rem',
    },
    subtitle1: {
      fontSize: '1.125rem',
    },
    subtitle2: {
      fontSize: '1rem',
    },
    caption: {
      fontSize: '0.79rem',
    },
    button: {
      fontSize: '0.889rem',
      textTransform: 'none',
    },
    h6: {
      fontSize: '1.125rem',
    },
    h5: {
      fontSize: '1.266rem',
    },
    h4: {
      fontSize: '1.424rem',
    },
    h3: {
      fontSize: '1.602rem',
    },
    h2: {
      fontSize: '1.802rem',
      fontWeight: 450,
    },
    h1: {
      fontSize: '2.027rem',
      fontWeight: 450,
    },
  },
  palette: {
    primary: {
      main: '#607d8b',
    },
    secondary: {
      main: '#f06292',
    },
    background: {
      default: '#fff',
      paper: '#fff',
    },
  },
  props: {
    MuiTextField: {
      variant: 'filled',
    },
    MuiCheckbox: {
      color: 'primary',
    },
    MuiRadio: {
      color: 'primary',
    },
    MuiSwitch: {
      color: 'primary',
    },
  },
});
