import { useAuth0 } from '@auth0/auth0-react';
import {
  AppBar,
  Box,
  Button,
  CircularProgress,
  Divider,
  IconButton,
  LinearProgress,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from '@material-ui/core';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import {
  AccountCircle,
  Create,
  Domain,
  Home,
  Pageview,
  Store,
  Timeline,
  Person,
  PhoneIphone,
} from '@material-ui/icons';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Hidden from '@material-ui/core/Hidden';
import Drawer from '@material-ui/core/Drawer';
import { Link, useHistory } from 'react-router-dom';
import { OrganizationContext } from '../../contexts/organization';
import { useUrlSearchParamOrganization } from '../../contexts/hooks/url';
import { UserContext } from '../../contexts/user/user-context';
import { ApplyWith } from '../../../graphql/generated';

export type HeaderProps = {
  title: string;
  loading?: boolean;
  progressLabel?: string;
};

type SideMenu = {
  text: string;
  to: { pathname: string; search: string };
  enabled: boolean | undefined;
  icon: any;
};

const DisplayFeatureType = {
  barcode: 'POS利用',
  smartCheck: 'SmartCheck',
  smartCheckLight: 'SmartCheckLight',
} as { [key in ApplyWith]: string };

const BarcodeFeatureType = {
  jan13: 'JAN13桁',
  jan8: 'JAN8桁',
  nw7: 'NW7',
  code128: 'CODE-128',
  qr: 'QRコード',
  none: '設定無し',
};

const SourceFeatureType = {
  CouponPos12Barcode: 'POS12桁バーコード',
  CouponGeneral16Barcode: '汎用16桁バーコード',
  CouponMosBarcode: 'MOS専用バーコード',
};

const useStyles = makeStyles((theme) => {
  const drawerWidth = theme.breakpoints.values.md / 3;
  return {
    root: {
      display: 'flex',
    },

    // サイドバー
    drawer: {
      [theme.breakpoints.up('md')]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    drawerPaper: {
      width: drawerWidth,
    },
    circular: {
      color: 'white',
      marginRight: theme.spacing(1),
    },
    circularTitle: {
      marginRight: theme.spacing(2),
    },
    // ヘッダー
    header: {
      [theme.breakpoints.up('md')]: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
      },
    },
    toolbar: theme.mixins.toolbar,
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },

    // ログインメニュー
    name: {
      marginRight: theme.spacing(1),
    },
    title: {
      marginLeft: theme.spacing(1),
    },
  };
});

export const MenuHeader: React.FC<HeaderProps> = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const { user, logout } = useAuth0();
  const organizationParam = useUrlSearchParamOrganization();
  const organization = useContext(OrganizationContext);
  const { userInfo } = useContext(UserContext);
  const [userAnchorEl, setUserAnchorEl] = useState<null | HTMLElement>(null);
  const [organizationAnchorEl, setOrganizationAnchorEl] = useState<null | HTMLElement>(null);
  const [organizationName, setOrganizationName] = useState('読込中...');
  const [organizationId, setOrganizationId] = useState('');
  const [originalOrganizationName, setOriginalOrganizationName] = useState('読込中...');
  const [userName, setUserName] = useState('読込中...');
  const [featureName, setFeatureName] = useState('読込中...');
  const [barcodeType, setBarcodeType] = useState('読込中...');
  const [sourceType, setSourceType] = useState('読込中...');
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = (): void => {
    setMobileOpen(!mobileOpen);
  };

  useEffect(() => {
    if (organization.loading || !organization.organization) {
      setOrganizationName('読込中...');
    } else {
      setOrganizationName(organization.organization.name);
    }
  }, [organization.organization, organization.loading]);

  useEffect(() => {
    if (organization.loading || !organization.original) {
      setOriginalOrganizationName('読込中...');
    } else {
      setOriginalOrganizationName(organization.original.name);
      setOrganizationId(organization.original.id);
    }
  }, [organization.original, organization.loading]);

  useEffect(() => {
    if (!user || !user.nickname) {
      setUserName('読込中...');
    } else {
      setUserName(user.nickname);
    }
  }, [user]);

  useEffect(() => {
    if (!organization || !organization.couponFeature) {
      setFeatureName('読込中...');
      setBarcodeType('読込中...');
      setSourceType('読込中...');
    } else {
      setFeatureName(DisplayFeatureType[organization.couponFeature.apply.with]);
      setBarcodeType(BarcodeFeatureType[organization.couponFeature.apply.barcode.displayType]);
      setSourceType(SourceFeatureType[organization.couponFeature.apply.barcode.sourceType]);
    }
  }, [organization.couponFeature]);

  const isOriginalOrganization = (): boolean => organization.organization?.id === organization.original?.id;

  // サイドメニューのリスト
  const sideMenuList: SideMenu[] = [
    {
      text: 'クーポンマスタ',
      to: { pathname: '/coupon-masters', search: organizationParam },
      enabled: organization.availableCouponMaster && userInfo?.permissions.includes('show:coupon-masters'),
      icon: <Create />,
    },
    {
      text: 'クーポン一覧',
      to: { pathname: '/coupons', search: organizationParam },
      enabled: userInfo?.permissions.includes('show:coupons'),
      icon: <PhoneIphone />,
    },
    {
      text: 'クーポン利用状況集計',
      to: { pathname: '/coupon-usage', search: organizationParam },
      enabled: userInfo?.permissions.includes('show:coupon-usage'),
      icon: <Timeline />,
    },
    {
      text: 'クーポン参照状況集計',
      to: { pathname: '/coupon-displayed', search: organizationParam },
      enabled: userInfo?.permissions.includes('show:coupon-displayed'),
      icon: <Pageview />,
    },
    {
      text: '組織管理',
      to: { pathname: `/manage-organizations/${organizationId}`, search: organizationParam },
      enabled: userInfo?.permissions.includes('show:manage-organizations') && organizationId != null,
      icon: <Store />,
    },
    {
      text: 'ユーザー管理',
      to: { pathname: '/users', search: organizationParam },
      enabled: userInfo?.permissions.includes('show:users'),
      icon: <Person />,
    },
  ];

  // サイドメニューリスト
  // Auth0のパーミッションによってメニューの表示非表示制御をする
  const drawer = (
    <>
      <Box className={classes.toolbar} display="flex" justifyContent="center">
        <ListItem button component={Link} to={{ pathname: '/', search: organizationParam }}>
          <ListItemIcon>
            <Home />
          </ListItemIcon>
          <ListItemText primary={'ホーム'} />
        </ListItem>
      </Box>
      {organization.availableCouponMaster === undefined ? <LinearProgress /> : <></>}
      <List>
        {sideMenuList
          .filter((x) => x.enabled)
          .map((m) => (
            <Fragment key={m.to.pathname}>
              <ListItem
                key={m.text}
                selected={history.location.pathname.includes(m.to.pathname)}
                button
                component={Link}
                to={m.to}
              >
                <ListItemIcon>{m.icon}</ListItemIcon>
                <ListItemText primary={m.text} />
              </ListItem>
            </Fragment>
          ))}
      </List>
    </>
  );

  const container = window !== undefined ? () => window.document.body : undefined;

  // 組織メニュー
  const organizationMenu = (
    <Menu
      open={!!organizationAnchorEl}
      onClose={() => setOrganizationAnchorEl(null)}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      getContentAnchorEl={null}
      anchorEl={organizationAnchorEl}
      keepMounted
      autoFocus={false}
    >
      <MenuItem disabled>
        <Box>
          <Typography variant="caption" component="div">
            現在の組織
          </Typography>
          <Typography variant="caption" component="div" color={isOriginalOrganization() ? 'inherit' : 'secondary'}>
            {organizationName}
          </Typography>
        </Box>
      </MenuItem>
      <MenuItem disabled>
        <Box>
          <Typography variant="caption" component="div">
            元の組織
          </Typography>
          <Typography variant="caption" component="div">
            {originalOrganizationName}
          </Typography>
        </Box>
      </MenuItem>
      <MenuItem disabled>
        <Box>
          <Typography variant="caption" component="div">
            クーポン種別
          </Typography>
          <Typography variant="caption" component="div">
            {featureName}
          </Typography>
        </Box>
      </MenuItem>
      <MenuItem disabled>
        <Box>
          <Typography variant="caption" component="div">
            バーコード種別
          </Typography>
          <Typography variant="caption" component="div">
            {sourceType}
          </Typography>
        </Box>
      </MenuItem>
      <MenuItem disabled>
        <Box>
          <Typography variant="caption" component="div">
            バーコードタイプ
          </Typography>
          <Typography variant="caption" component="div">
            {barcodeType}
          </Typography>
        </Box>
      </MenuItem>
      <Divider />
      <MenuItem
        disabled={!userInfo?.permissions.includes('show:switch-organization')}
        onClick={() =>
          history.push({
            pathname: '/switch-organization',
            search: organizationParam,
          })
        }
      >
        <Typography variant="caption">組織の変更</Typography>
      </MenuItem>
      <MenuItem
        onClick={() => {
          organization.resetOrganization();
          history.push({ pathname: '/', search: organizationParam });
        }}
        disabled={isOriginalOrganization() || !userInfo?.permissions.includes('show:switch-organization')}
      >
        <Typography variant="caption">もとの組織に戻る</Typography>
      </MenuItem>
      <MenuItem
        disabled={!userInfo?.permissions.includes('write:organization-suite')}
        onClick={() =>
          history.push({
            pathname: `/manage-organizations/${organization.organization?.id}/suite`,
            search: organizationParam,
          })
        }
      >
        <Typography variant="caption">組織の設定</Typography>
      </MenuItem>
    </Menu>
  );

  // ユーザーメニュー
  const userMenu = (
    <Menu
      open={!!userAnchorEl}
      onClose={() => setUserAnchorEl(null)}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      getContentAnchorEl={null}
      anchorEl={userAnchorEl}
      keepMounted
      autoFocus={false}
    >
      <MenuItem disabled>
        <Box>
          <Typography variant="caption" component="div">
            ログインアカウント
          </Typography>
          <Typography variant="caption" component="div">
            {userName}
          </Typography>
        </Box>
      </MenuItem>
      <MenuItem disabled>
        <Box>
          <Typography variant="caption" component="div">
            ロール
          </Typography>
          <Typography variant="caption" component="div">
            {userInfo?.roles[0]}
          </Typography>
        </Box>
      </MenuItem>
      <Divider />
      <MenuItem onClick={() => logout({ returnTo: window.location.origin })}>
        <Typography variant="caption">ログアウト</Typography>
      </MenuItem>
    </Menu>
  );

  return (
    <>
      <AppBar className={classes.header}>
        <Toolbar>
          <IconButton color="inherit" edge="start" onClick={handleDrawerToggle} className={classes.menuButton}>
            <MenuIcon />
          </IconButton>
          <Typography variant="h5">{props.title}</Typography>
          <Box
            display="flex"
            alignItems="center"
            marginLeft="auto"
            height="100%"
            onClick={(event) => setOrganizationAnchorEl(event.currentTarget)}
          >
            {props.loading && props.progressLabel ? (
              <>
                <CircularProgress className={classes.circular} size={30} />
                <Box className={classes.circularTitle}>{props.progressLabel}</Box>
              </>
            ) : (
              <></>
            )}

            <Button
              variant="text"
              color="inherit"
              size={'large'}
              startIcon={<Domain />}
              endIcon={<ExpandMoreIcon fontSize={'large'} />}
            >
              <Box display={{ xs: 'none', sm: 'none', md: 'block' }}>
                <Typography>{organizationName}</Typography>
              </Box>
            </Button>
          </Box>
          <IconButton edge="end" color="inherit" onClick={(event) => setUserAnchorEl(event.currentTarget)}>
            <AccountCircle />
          </IconButton>
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer}>
        {/*ボタンクリックに反応するタイプのポップアップメニュー*/}
        <Hidden mdUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        {/*常時表示のメニュー*/}
        <Hidden smDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      {organizationMenu}
      {userMenu}
      <Toolbar />
    </>
  );
};
