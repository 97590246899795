import React from 'react';
import { Breadcrumbs, Button, Typography } from '@material-ui/core';
import { OrganizationContext } from '../../components/contexts/organization';
import { ArrowBack, Cached } from '@material-ui/icons';

export type OrganizationBreadCrumbsBodyProps = {
  organization?: OrganizationContext;
  originalOrganizationId?: string;
  setOrganizationTarget: (organizationId: string) => void;
};

export const OrganizationBreadCrumbsBody: React.FC<OrganizationBreadCrumbsBodyProps> = (props) => {
  // 親IDが存在し、なおかつ表示中の組織がもともとの組織と異なれば親にさかのぼってOK
  const parentId = props.organization?.parentId || '';
  const backToParent = props.organization?.parentId && props.organization.id !== props.originalOrganizationId;

  return (
    <Breadcrumbs>
      {backToParent ? (
        <Button size="small" color="inherit" onClick={() => props.setOrganizationTarget(parentId)}>
          <ArrowBack />
          親組織へ
        </Button>
      ) : undefined}
      <Typography variant="body1" color="textPrimary">
        {props.organization?.name || '読み込み中...'}
      </Typography>
    </Breadcrumbs>
  );
};
