import { Box, Breadcrumbs, Theme, Typography } from '@material-ui/core';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

type CouponUsageInfoProps = {
  searchGroupBy: string;
};

const useStyles = makeStyles((theme: Theme) => ({
  section: {
    marginTop: theme.spacing(1),
  },
}));

export const CouponUsageInfo: React.FC<CouponUsageInfoProps> = React.memo(function CouponUsageInfo({ searchGroupBy }) {
  const classes = useStyles();
  return (
    <>
      <Box className={classes.section}>
        <Breadcrumbs>
          <Box>
            <Typography color="textPrimary">クーポン利用状況集計</Typography>
          </Box>
        </Breadcrumbs>
      </Box>
      <div>
        <Typography variant="caption">【一覧の並び順】</Typography>
        {searchGroupBy === 'COUPON_MASTER' ? (
          <Typography variant="caption">クーポンコード,クーポン名,発行数,消込数,キャンセル</Typography>
        ) : (
          <Typography variant="caption">組織名,発行数,消込数,キャンセル</Typography>
        )}
        {searchGroupBy === 'COUPON_MASTER' ? (
          <></>
        ) : searchGroupBy === 'SHOP' ? (
          <>
            <Typography variant="caption">【使用済数の表記】</Typography>
            <Typography variant="caption">自店舗発行のクーポン使用数 / 全てのクーポン使用数</Typography>
          </>
        ) : (
          <>
            <Typography variant="caption">【発行数表記】</Typography>
            <Typography variant="caption">自グループ発行分の発行数 / 全ての発行数</Typography>
          </>
        )}
      </div>
    </>
  );
});
