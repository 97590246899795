import { Box, Button, Popover } from '@material-ui/core';
import PopupState, { bindPopover, bindTrigger } from 'material-ui-popup-state';
import { ChromePicker } from 'react-color';
import React from 'react';
import { useFormContext } from 'react-hook-form';

type PopupStateChromePickerProps = {
  popupId: string;
  name: string;
  colorState: string;
  setColorState: (value: string) => void;
};

export const PopupStateChromePicker: React.FC<PopupStateChromePickerProps> = (props) => {
  const { setValue } = useFormContext();
  return (
    <PopupState variant="popover" popupId={props.popupId}>
      {(popupState) => (
        <>
          <Button fullWidth variant="outlined" color="primary" {...bindTrigger(popupState)}>
            選ぶ
          </Button>
          <Popover
            {...bindPopover(popupState)}
            anchorOrigin={{
              vertical: 'center',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <Box p={2}>
              <ChromePicker
                disableAlpha
                color={props.colorState}
                onChange={(v) => props.setColorState(v.hex)}
                onChangeComplete={(v) => setValue(props.name, v.hex)}
              />
            </Box>
          </Popover>
        </>
      )}
    </PopupState>
  );
};
