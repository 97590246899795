import {
  Box,
  Breadcrumbs,
  Button,
  CircularProgress,
  Container,
  Grid,
  IconButton,
  LinearProgress,
  ListItemIcon,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Theme,
  Typography,
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Search, MoreHoriz, Delete, VpnKey, Person, PersonAdd } from '@material-ui/icons';
import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm, FormProvider } from 'react-hook-form';
import { MenuHeaderTemplate } from '../../components/templates/MenuHeaderTemplate';
import { BasicTableHead, BasicTableHeadProps } from '../../components/molecules';
import { HeaderProps } from '../../components/organisms/general/MenuHeader';
import { formatJstDateTime } from '../../util';
import { ListUsers, useListUsers } from './hooks/user-list-use-case';
import { TextFieldController } from '../../components/organisms/form/controllers/TextFieldController';
import { CreateUserDialog } from './dialogs/CreateUser';
import { useCreateUserUseCase } from './hooks/create-user-use-case';
import { User } from '../../graphql/generated';
import { UpdateUserNameDialog } from './dialogs/UpdateUserName';
import { UpdateUserRoleDialog } from './dialogs/UpdateUserRole';
import { UpdateUserPasswordDialog } from './dialogs/UpdateUserPassword';
import { DeleteUserAlertDialog } from './dialogs/DeleteUserAleat';
import { UserContext } from '../../components/contexts/user/user-context';

const useStyles = makeStyles((theme: Theme) => ({
  searchForm: {
    marginTop: theme.spacing(1),
  },
  searchButton: {
    textAlign: 'center',
  },
  userList: {
    marginTop: theme.spacing(3),
  },
}));

export type UserListConditionFormValues = {
  email: string;
  name: string;
};

type ListUsersPageProps = {
  header: HeaderProps;
  listBody: ListUsers;
};

export type CreateUserFormValues = {
  email: string;
  name: string;
  password: string;
  repeatPassword: string;
  role: string;
  isAdministrator: boolean;
  organizationId: string;
};

export const ListUsersBody: React.FC<ListUsers> = (props) => {
  const theme = useTheme();
  const styles = useStyles();
  const history = useHistory();
  const { userInfo } = useContext(UserContext);

  const [openCreateUserDialog, setOpenCreateUserDialog] = useState(false);
  const [openUpdateUserNameDialog, setOpenUpdateUserNameDialog] = useState<boolean>(false);
  const [openUpdateUserRoleDialog, setOpenUpdateUserRoleDialog] = useState<boolean>(false);
  const [openUpdateUserPasswordDialog, setOpenUpdateUserPasswordDialog] = useState<boolean>(false);
  const [openDeleteUserDialog, setOpenDeleteUserDialog] = useState<boolean>(false);
  const [detailMenuAnchorEl, setDetailMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [selectRowUser, setSelectRowUser] = useState<Omit<User, 'permissions'>>();
  const { onCreate, loading, roles, rolesLoading } = useCreateUserUseCase();

  const userListForm = useForm<UserListConditionFormValues>({
    defaultValues: props.initialValues,
  });
  const { handleSubmit } = userListForm;

  const tableHeadProps: BasicTableHeadProps = {
    columns: [
      {
        name: 'email',
        label: 'Eメール',
        minWidth: theme.spacing(1),
        align: 'left',
      },
      {
        name: 'name',
        label: 'ユーザー名',
        minWidth: theme.spacing(1),
        align: 'left',
      },
      {
        name: 'latestLoginAt',
        label: '最終ログイン日時',
        minWidth: theme.spacing(1),
        align: 'left',
      },
      {
        name: 'createAt',
        label: '作成日時',
        minWidth: theme.spacing(1),
        align: 'left',
      },
      {
        name: 'updateAt',
        label: '更新日時',
        minWidth: theme.spacing(1),
        align: 'left',
      },
      {
        name: 'detailButton',
        label: '',
        minWidth: theme.spacing(1),
        align: 'center',
      },
    ],
  };

  // 組織情報が取得できていないタイミングでは、ローディングを出す
  if (props.organizationLoading || !props.organization) {
    return (
      <Container maxWidth="xl">
        <Box display="flex" alignItems="center" justifyContent="center">
          <CircularProgress />
        </Box>
      </Container>
    );
  }

  return (
    <>
      <Grid container direction="row" justifyContent="space-between" alignItems="center">
        <Grid item>
          <Breadcrumbs>
            <Typography variant="body1">ユーザー一覧</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item>
          <Button
            color="primary"
            variant="contained"
            disabled={!roles}
            onClick={() => {
              setOpenCreateUserDialog(true);
            }}
          >
            <Typography variant="body1">新規作成</Typography>
          </Button>
        </Grid>
      </Grid>
      <Box className={styles.searchForm}>
        <FormProvider {...userListForm}>
          <form onSubmit={handleSubmit(props.onSearchUsers)} autoComplete="off" noValidate>
            <Grid
              container
              spacing={2}
              className={styles.searchForm}
              alignItems="center"
              justifyContent="space-between"
            >
              <Grid item xs={5}>
                <TextFieldController
                  name="email"
                  label="Eメール"
                  size="small"
                  variant="outlined"
                  rule={{
                    minLength: {
                      value: 3,
                      message: '3文字以上で入力してください',
                    },
                    maxLength: {
                      value: 100,
                      message: '100文字以内で入力してください',
                    },
                  }}
                />
              </Grid>
              <Grid item xs={5}>
                <TextFieldController
                  name="name"
                  label="ユーザー名"
                  size="small"
                  variant="outlined"
                  rule={{
                    minLength: {
                      value: 3,
                      message: '3文字以上で入力してください',
                    },
                    maxLength: {
                      value: 100,
                      message: '100文字以内で入力してください',
                    },
                  }}
                />
              </Grid>
              <Grid item xs={2}>
                <Button
                  type="submit"
                  variant="contained"
                  color={'default'}
                  disabled={props.loading}
                  startIcon={<Search />}
                >
                  検索
                </Button>
              </Grid>
            </Grid>
          </form>
        </FormProvider>
      </Box>
      <Box className={styles.userList}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TableContainer component={Paper}>
              {props.loading ? <LinearProgress /> : <React.Fragment />}
              <Table size="small">
                <BasicTableHead columns={tableHeadProps.columns} />
                <TableBody>
                  {(() => {
                    if (!props.data || !props.data.users.edges) {
                      return;
                    } else if (props.data.users.edges && props.data.users.edges.length === 0) {
                      return (
                        <TableRow>
                          <TableCell>データがありません</TableCell>
                        </TableRow>
                      );
                    } else {
                      return props.data.users.edges.map((row) => (
                        <TableRow key={row.node.id}>
                          <TableCell component="th" scope="row">
                            {row.node.email}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {row.node.name}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {formatJstDateTime(row.node.lastLoginDateTime ?? '')}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {formatJstDateTime(row.node.createAtDateTime)}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {formatJstDateTime(row.node.updateAtDateTime)}
                          </TableCell>
                          <TableCell align="right" scope="row">
                            <IconButton
                              type="button"
                              size="small"
                              color="primary"
                              onClick={(event) => {
                                setSelectRowUser(row.node);
                                console.log(row.node);
                                setDetailMenuAnchorEl(event.currentTarget);
                              }}
                            >
                              <MoreHoriz />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ));
                    }
                  })()}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              component="div"
              rowsPerPageOptions={props.data ? [props.data.users.pageInfo.limit] : []}
              count={-1}
              rowsPerPage={props.data ? props.data.users.pageInfo.limit : 0}
              backIconButtonProps={{ disabled: loading || !props.data?.users.pageInfo.hasPreviousPage }}
              nextIconButtonProps={{ disabled: loading || !props.data?.users.pageInfo.hasNextPage }}
              page={props.page}
              onPageChange={(event, newPage) => {
                props.setPage(newPage);
                props.onFetchMore(newPage);
              }}
            />
          </Grid>
        </Grid>
        <Menu
          open={!!detailMenuAnchorEl}
          onClose={() => setDetailMenuAnchorEl(null)}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          getContentAnchorEl={null}
          anchorEl={detailMenuAnchorEl}
        >
          <MenuItem
            onClick={() =>
              history.push({
                pathname: `/users/${selectRowUser?.id}`,
                search: history.location.search,
              })
            }
          >
            詳細を表示する
          </MenuItem>
          <MenuItem
            onClick={() => {
              setDetailMenuAnchorEl(null);
              setOpenUpdateUserNameDialog(true);
            }}
            disabled={!userInfo?.permissions.includes('write:admin-user') && selectRowUser?.isAdministrator}
          >
            <ListItemIcon>
              <Person />
            </ListItemIcon>
            ユーザー名を変更する
          </MenuItem>
          <MenuItem
            onClick={() => {
              setDetailMenuAnchorEl(null);
              setOpenUpdateUserRoleDialog(true);
            }}
            disabled={!userInfo?.permissions.includes('write:admin-user') && selectRowUser?.isAdministrator}
          >
            <ListItemIcon>
              <PersonAdd />
            </ListItemIcon>
            ロールを変更する
          </MenuItem>
          <MenuItem
            onClick={() => {
              setDetailMenuAnchorEl(null);
              setOpenUpdateUserPasswordDialog(true);
            }}
            disabled={!userInfo?.permissions.includes('write:admin-user') && selectRowUser?.isAdministrator}
          >
            <ListItemIcon>
              <VpnKey />
            </ListItemIcon>
            パスワードを変更する
          </MenuItem>
          <MenuItem
            onClick={() => {
              setDetailMenuAnchorEl(null);
              setOpenDeleteUserDialog(true);
            }}
            disabled={
              selectRowUser?.email === userInfo!.user?.email ||
              (!userInfo?.permissions.includes('write:admin-user') && selectRowUser?.isAdministrator)
            }
          >
            <ListItemIcon>
              <Delete color="error" />
            </ListItemIcon>
            <Typography variant="body1" color="error">
              ユーザーを削除する
            </Typography>
          </MenuItem>
        </Menu>
      </Box>
      <CreateUserDialog
        open={openCreateUserDialog}
        onClose={() => {
          setOpenCreateUserDialog(false);
        }}
        loading={loading}
        roles={roles}
        rolesLoading={rolesLoading}
        onCreate={onCreate}
      />
      {selectRowUser ? (
        <>
          <UpdateUserRoleDialog
            id={selectRowUser.id}
            roleId={selectRowUser.roleId}
            isAdministrator={selectRowUser.isAdministrator}
            open={openUpdateUserRoleDialog}
            setOpen={setOpenUpdateUserRoleDialog}
          />
          <UpdateUserNameDialog
            id={selectRowUser.id}
            name={selectRowUser.name}
            open={openUpdateUserNameDialog}
            setOpen={setOpenUpdateUserNameDialog}
          />
          <UpdateUserPasswordDialog
            id={selectRowUser.id}
            open={openUpdateUserPasswordDialog}
            setOpen={setOpenUpdateUserPasswordDialog}
          />
          <DeleteUserAlertDialog
            id={selectRowUser.id}
            name={selectRowUser.name}
            open={openDeleteUserDialog}
            setOpen={setOpenDeleteUserDialog}
            refetch={props.refetch}
          />
        </>
      ) : (
        <></>
      )}
    </>
  );
};

const useListUsersPage = (): ListUsersPageProps => {
  const usersList = useListUsers();
  return {
    header: { title: 'ユーザー一覧' },
    listBody: usersList,
  };
};

export const ListUsersPage: React.FC = () => {
  const listUsersPage = useListUsersPage();
  return (
    <MenuHeaderTemplate header={listUsersPage.header}>
      <Container maxWidth="xl">
        <ListUsersBody {...listUsersPage.listBody} />
      </Container>
    </MenuHeaderTemplate>
  );
};
