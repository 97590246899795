import { PaginatedOrganizations, useOrganizationChildrenLazyQuery } from '../../../graphql/generated';
import { OrganizationContext } from '../../../components/contexts/organization';
import { useCallback, useContext, useEffect, useState } from 'react';

export type SearchChildrenOrganizationUseCase = {
  onSearch: (data: ChildrenOrganizationFormValues) => void;
  loading: boolean;
  data?: PaginatedOrganizations;
  fetchMore: () => void;
};

export type ChildrenOrganizationFormValues = {
  organizationName: string;
};

const PageItemsCount = '25';

export function useSearchChildrenOrganization(parentId: string): SearchChildrenOrganizationUseCase {
  const context = useContext(OrganizationContext);
  const [loading, setLoading] = useState(false);

  const [organizationChildrenQuery, queryContext] = useOrganizationChildrenLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: () => {
      setLoading(false);
    },
    onError: (error) => {
      console.log(error);
    },
  });

  // 検索対象の組織として最初はコンテキストを設定する
  useEffect(() => {
    setLoading(true);
    organizationChildrenQuery({
      variables: {
        query: {
          parentId,
          limit: PageItemsCount,
        },
      },
    });
  }, [organizationChildrenQuery]);

  const onSearch = useCallback(
    (data: ChildrenOrganizationFormValues) => {
      setLoading(true);
      organizationChildrenQuery({
        variables: {
          query: {
            parentId,
            name: data.organizationName,
            limit: PageItemsCount,
          },
        },
      });
    },
    [organizationChildrenQuery]
  );

  const handleFetchMore = useCallback(async () => {
    if (queryContext?.fetchMore && queryContext?.variables?.query) {
      setLoading(true);
      await queryContext.fetchMore({
        variables: {
          query: {
            parentId: queryContext.variables.query.parentId,
            name: queryContext.variables.query.name,
            limit: queryContext.variables.query.limit,
            nextToken: queryContext.data?.organizationChildren.nextToken,
          },
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          setLoading(false);
          if (!fetchMoreResult) {
            return prev;
          } else {
            return {
              __typename: fetchMoreResult.__typename,
              organizationChildren: {
                __typename: fetchMoreResult.organizationChildren.__typename,
                items: fetchMoreResult.organizationChildren.items
                  ? prev.organizationChildren.items?.concat(fetchMoreResult.organizationChildren.items)
                  : prev.organizationChildren.items,
                nextToken: fetchMoreResult.organizationChildren.nextToken,
              },
            };
          }
        },
      });
    }
  }, [queryContext]);
  return {
    loading: loading || context.loading,
    data: queryContext.data?.organizationChildren,
    fetchMore: handleFetchMore,
    onSearch,
  };
}
