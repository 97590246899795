import React, { useCallback, useContext } from 'react';
import { Route, RouteProps } from 'react-router-dom';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { Box, CircularProgress } from '@material-ui/core';
import { OrganizationContext } from './components/contexts/organization';
import { SmartCheckUnavailablePage } from './ecosystems/error/SmartCheckUnavailablePage';
import { Permission, UserContext } from './components/contexts/user/user-context';
import { NotAuthorized } from './ecosystems/error/NotAuthorized';

export const ProtectedRoute: React.FC<Required<Pick<RouteProps, 'component'>> & RouteProps> = ({
  component,
  location,
  ...rest
}) => {
  const onRedirecting = useCallback(
    () => (
      <Box display="flex" alignItems="center" justifyContent="center">
        <CircularProgress />
      </Box>
    ),
    []
  );
  return (
    <Route
      component={withAuthenticationRequired(component, {
        onRedirecting,
      })}
      {...rest}
    />
  );
};

// ログインユーザーの権限と組織の権限によって、画面遷移できるかを判断する
export const AvailableRoute: React.FC<Required<Pick<RouteProps, 'component'>> & RouteProps> = ({
  component,
  ...rest
}) => {
  const organization = useContext(OrganizationContext);
  const { userInfo } = useContext(UserContext);

  // SmartCheckの場合、小店舗グループのみ画面遷移できるようにするため
  if (organization.availableCouponMaster === false) {
    return <SmartCheckUnavailablePage />;
  }

  // ユーザー権限が読み込まれていない場合、ローディングする
  if (userInfo?.permissions.length === 0) {
    return <CircularProgress />;
  }

  // クーポン詳細への直接アクセスはコンポーネント側で権限制御する
  if (location?.pathname.split('/')[3] === 'coupons') {
    return <ProtectedRoute component={component} {...rest} />;
  }

  // トップレベルでのURL直アクセスを防止する（ex: https://example.com/coupon-masters など）
  return userInfo?.permissions.includes(`show:${location?.pathname.split('/')[1]}` as Permission) ? (
    <ProtectedRoute component={component} {...rest} />
  ) : (
    <NotAuthorized />
  );
};
