import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Button,
  Grid,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Theme,
  Tooltip,
} from '@material-ui/core';
import { useForm, FormProvider } from 'react-hook-form';
import { ArrowForward, Cached, Search } from '@material-ui/icons';
import { Organization, PaginatedOrganizations } from '../../graphql/generated';
import { BasicTableHead, BasicTableHeadProps } from '../../components/molecules';
import { formatOrganizationTypeString, OrganizationContext } from '../../components/contexts/organization';
import { useTheme } from '@material-ui/core/styles';
import { ChildrenFormValues } from './hooks';
import { ReadMoreButton } from '../../components/organisms/general/ReadMoreButton';
import { TextFieldController } from '../../components/organisms/form/controllers/TextFieldController';

export type OrganizationSearchFormBodyProps = {
  onSearchSubmit: (data: ChildrenFormValues) => void;
  loading: boolean;
  data?: PaginatedOrganizations;
  onDrillDown: (organizationId: string) => void;
  fetchMore: () => void;
  onSwitch?: (data: OrganizationContext) => void;
  onSelected?: (row: Organization) => void;
};

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    textAlign: 'center',
  },
  organizationChildren: {
    marginTop: theme.spacing(3),
  },
}));

export const OrganizationSearchFormBody: React.FC<OrganizationSearchFormBodyProps> = (props) => {
  const classes = useStyles();
  const organizationSearchForm = useForm();
  const { handleSubmit } = organizationSearchForm;

  const theme = useTheme();
  const tableHeadProps: BasicTableHeadProps = {
    columns: [
      {
        name: 'name',
        label: '組織名',
        minWidth: theme.spacing(20),
        align: 'left',
      },
      {
        name: 'type',
        label: '種別',
        minWidth: theme.spacing(10),
        align: 'left',
      },
      {
        name: 'spoof',
        label: '選択',
        minWidth: theme.spacing(3),
        align: 'center',
      },
      {
        name: 'toChildren',
        label: 'さらに探す',
        minWidth: theme.spacing(3),
        align: 'center',
      },
    ],
  };

  const isShop = (data: OrganizationContext): boolean => {
    return data.type === 'SHOP';
  };

  return (
    <>
      <Box>
        <FormProvider {...organizationSearchForm}>
          <form onSubmit={handleSubmit(props.onSearchSubmit)} autoComplete="off" noValidate>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={6}>
                <TextFieldController
                  name="organizationName"
                  label="子の組織名が"
                  variant="outlined"
                  rule={{
                    maxLength: {
                      value: 30,
                      message: '30文字以内で入力してください',
                    },
                  }}
                />
              </Grid>
              <Tooltip title="空で検索すると、全データのうち数件だけ返します" placement="right-start">
                <Grid item xs={6}>
                  <Button
                    type="submit"
                    variant="outlined"
                    size="large"
                    color={'default'}
                    disabled={props.loading}
                    endIcon={<Search />}
                    fullWidth
                  >
                    から始まる
                  </Button>
                </Grid>
              </Tooltip>
            </Grid>
          </form>
        </FormProvider>
      </Box>
      <Box className={classes.organizationChildren}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TableContainer component={Paper}>
              {props.loading ? <LinearProgress /> : <React.Fragment />}
              <Table>
                <BasicTableHead columns={tableHeadProps.columns} />
                <TableBody>
                  {(() => {
                    if (!props.data || !props.data.items) {
                      return;
                    } else if (props.data.items && props.data.items.length === 0) {
                      return (
                        <TableRow>
                          <TableCell>データがありません</TableCell>
                        </TableRow>
                      );
                    } else {
                      return props.data.items.map((row: Organization) => (
                        <TableRow key={row.name}>
                          <TableCell component="th" scope="row">
                            {row.name}
                          </TableCell>
                          <TableCell align="left">{formatOrganizationTypeString(row.type)}</TableCell>
                          <TableCell align="center">
                            <Button
                              type="button"
                              size="large"
                              color="secondary"
                              fullWidth
                              onClick={() => {
                                if (props.onSwitch) {
                                  props.onSwitch(row);
                                } else if (props.onSelected) {
                                  props.onSelected(row);
                                } else {
                                  return;
                                }
                              }}
                            >
                              <Cached />
                            </Button>
                          </TableCell>
                          <TableCell align="center">
                            <Button
                              type="button"
                              size="large"
                              color="primary"
                              fullWidth
                              onClick={() => props.onDrillDown(row.id)}
                              disabled={isShop(row)}
                            >
                              <ArrowForward />
                            </Button>
                          </TableCell>
                        </TableRow>
                      ));
                    }
                  })()}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          {props.data?.nextToken ? (
            <ReadMoreButton fetchMore={props.fetchMore} loading={props.loading} />
          ) : (
            <React.Fragment />
          )}
        </Grid>
      </Box>
    </>
  );
};
