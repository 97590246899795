import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Permission, UserContext, UserContextProps } from './user-context';
import jwtDecode, { JwtPayload } from 'jwt-decode';

export const UserProvider: React.FC = ({ children }) => {
  const { user, getAccessTokenSilently } = useAuth0();
  const [permissions, setPermissions] = useState<Permission[]>([]);

  // ユーザーの設定とJWTデコードして権限を取得する
  useEffect(() => {
    if (user != null) {
      const token = getAccessTokenSilently();
      token.then((value) => {
        const decoded = jwtDecode<JwtPayload & { permissions: Permission[] }>(value);
        setPermissions(decoded.permissions);
      });
    }
  }, [user]);

  const result: UserContextProps = {
    userInfo: {
      user: user,
      permissions,
      roles: user ? user['https://www.softbankgift.co.jp/roles'] : [],
    },
  };

  return <UserContext.Provider value={result}>{children}</UserContext.Provider>;
};
