import { useCallback, useContext, useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { OrganizationContext } from '../../../components/contexts/organization';
import {
  PollCouponUsageLogJsonQuery,
  useInvokeCouponUsageLogCsvLazyQuery,
  useInvokeCouponUsageLogJsonLazyQuery,
  usePollCouponUsageLogCsvLazyQuery,
  usePollCouponUsageLogJsonLazyQuery,
} from '../../../graphql/generated';
import { CouponUsageLogFormValues } from '../CouponUsageLog';
import { useLocation } from 'react-router';
import Bugsnag from '@bugsnag/js';
import { downloadCsvFileWithBom } from '../../../util/download';
import { UserContext } from '../../../components/contexts/user/user-context';
import { useUrlSearchParamOrganization } from '../../../components/contexts/hooks/url';
import { useHistory } from 'react-router-dom';

export type CouponUsageLogUseCase = {
  organization?: OrganizationContext;
  organizationLoading: boolean;
  userInfo?: UserContext;
  data?: PollCouponUsageLogJsonQuery;
  loading: boolean;
  csvLoading: boolean;
  onSearch: (condition: CouponUsageLogFormValues) => void;
  onCsvOut: (condition: CouponUsageLogFormValues) => void;
  onFetchMore: (condition: CouponUsageLogFormValues) => void;
  queryParams: QueryParams;
  allAcquired: boolean;
};

export const Limit = 100;

type QueryParams = {
  name: string | null;
  couponMasterId: string | null;
  organizationId: string | null;
};

export const useUsageLogUseCase = (): CouponUsageLogUseCase => {
  const history = useHistory();
  const location = useLocation();
  const search = new URLSearchParams(location.search);
  const queryParams: QueryParams = {
    name: search.get('name'),
    couponMasterId: search.get('couponMasterId'),
    organizationId: search.get('organizationId'),
  };
  const organizationParam = useUrlSearchParamOrganization();
  const { organization, loading: organizationLoading } = useContext(OrganizationContext);
  const { userInfo } = useContext(UserContext);
  const [offset, setOffset] = useState<number>(0);
  const [fetching, setFetching] = useState<boolean>(false);
  const [allAcquired, setAllAcquired] = useState<boolean>(false);

  const [loading, setLoading] = useState<boolean>(false);
  const [csvLoading, setCsvLoading] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();

  // 利用状況ログ ポーリング
  const [pollJsonQuery, statusContext] = usePollCouponUsageLogJsonLazyQuery({
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    pollInterval: 5000,
  });

  // 利用状況ログ 取得
  // ApolloClient で ポーリングとfetchMoreを同時に利用できないため分ける
  const [getJsonQuery, getJsonQueryContext] = usePollCouponUsageLogJsonLazyQuery({
    fetchPolicy: 'cache-first',
    onCompleted: (_) => {
      setLoading(false);
    },
  });

  // 利用状況集計取得 invoke
  const [invokeJsonQuery, invokeQueryJsonContext] = useInvokeCouponUsageLogJsonLazyQuery({
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    onCompleted: (data) => {
      pollJsonQuery({
        variables: {
          queryExecutionId: data.invokeCouponUsageLogJson.queryExecutionId,
        },
      });
      setOffset(offset + Limit);
    },
    onError: (e) => {
      console.error(e);
      Bugsnag.notify(e);
      enqueueSnackbar('利用状況明細一覧の取得に失敗しました', {
        variant: 'error',
      });
    },
  });

  // 利用状況一覧 CSV URL ポーリング
  const [pollCsvUrlQuery, statusCsvUrlContext] = usePollCouponUsageLogCsvLazyQuery({
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    pollInterval: 5000,
  });

  // 利用状況CSV取得 invoke
  const [invokeCsvQuery] = useInvokeCouponUsageLogCsvLazyQuery({
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    onCompleted: (data) => {
      pollCsvUrlQuery({
        variables: {
          queryExecutionId: data.invokeCouponUsageLogCsv.queryExecutionId,
        },
      });
    },
    onError: (e) => {
      console.error(e);
      Bugsnag.notify(e);
      enqueueSnackbar('利用状況集計一覧の取得に失敗しました', {
        variant: 'error',
      });
    },
  });

  // 前画面からの遷移
  useEffect(() => {
    if (!queryParams.couponMasterId && !queryParams.organizationId) {
      enqueueSnackbar('お手数ですがトップページからやりなおしてください', {
        variant: 'warning',
      });
      history.push({ pathname: '/', search: organizationParam });
      return;
    }
    if (organization) {
      setLoading(true);
      invokeJsonQuery({
        variables: {
          couponMasterId: queryParams.couponMasterId,
          organizationId: queryParams.organizationId,
          searchPolicy: 'latest',
          limit: Limit,
          offset: offset,
        },
      });
    }
  }, [invokeJsonQuery, organization, queryParams.couponMasterId, queryParams.organizationId]);

  // JSONクエリ出力をポーリングする
  useEffect(() => {
    const status = statusContext?.data?.pollCouponUsageLogJson?.status;

    // 終了系ステータスの場合、ポーリングを止める
    if (status === 'CANCELLED' || status === 'FAILED' || status === 'SUCCEEDED') {
      if (statusContext && statusContext.stopPolling) {
        statusContext.stopPolling();
      }
    }

    // 正常に終了した場合は、データフェッチ用のクエリを呼び出してステータスを終了にする
    if (
      status === 'SUCCEEDED' &&
      statusContext &&
      statusContext.data &&
      statusContext.data.pollCouponUsageLogJson &&
      statusContext.data.pollCouponUsageLogJson.nodes
    ) {
      if (
        fetching &&
        organization &&
        getJsonQueryContext &&
        getJsonQueryContext.fetchMore &&
        getJsonQueryContext.variables
      ) {
        getJsonQueryContext.fetchMore({
          variables: statusContext.variables,
          updateQuery: (prevQuery, { fetchMoreResult }) => {
            setLoading(false);
            if (!fetchMoreResult) {
              return prevQuery;
            }
            // 取得件数が0件の場合は、全件取得済みとみなす
            if (fetchMoreResult?.pollCouponUsageLogJson?.nodes?.length === 0) {
              setAllAcquired(true);
              enqueueSnackbar('全件取得済みです', { variant: 'success' });
            } else {
              enqueueSnackbar('明細取得処理が正常に終了しました', { variant: 'success' });
            }
            return {
              pollCouponUsageLogJson: {
                __typename: fetchMoreResult.pollCouponUsageLogJson.__typename,
                queryExecutionId: fetchMoreResult.pollCouponUsageLogJson.queryExecutionId,
                status: fetchMoreResult.pollCouponUsageLogJson.status,
                nodes: [
                  ...(prevQuery.pollCouponUsageLogJson.nodes || []),
                  ...(fetchMoreResult.pollCouponUsageLogJson.nodes || []),
                ],
                pageInfo: fetchMoreResult.pollCouponUsageLogJson.pageInfo,
              },
            };
          },
        });
        setFetching(false);
      } else {
        getJsonQuery({
          variables: statusContext.variables,
        });
        enqueueSnackbar('明細取得処理が正常に終了しました', { variant: 'success' });
      }
    } else if (status === 'FAILED' || status === 'CANCELLED') {
      setLoading(false);
      enqueueSnackbar('明細取得処理でエラーが発生しました', {
        variant: 'error',
      });
      Bugsnag.notify(statusContext.error || new Error('polling error'));
    }
  }, [statusContext.data?.pollCouponUsageLogJson.status]);

  // CSV URL クエリ出力をポーリングする
  // ポーリングクエリの onComplete は呼ばれないので useEffect でやるしかない
  useEffect(() => {
    const status = statusCsvUrlContext?.data?.pollCouponUsageLogCsv?.status;

    // 終了系ステータスの場合、ポーリングを止める
    if (status === 'CANCELLED' || status === 'FAILED' || status === 'SUCCEEDED') {
      if (statusCsvUrlContext && statusCsvUrlContext.stopPolling) {
        statusCsvUrlContext.stopPolling();
      }
    }

    // 正常に終了した場合は、CSVファイルをダウンロード
    if (
      status === 'SUCCEEDED' &&
      statusCsvUrlContext &&
      statusCsvUrlContext.data &&
      statusCsvUrlContext.data.pollCouponUsageLogCsv
    ) {
      enqueueSnackbar('明細取得処理が正常に終了しました。CSVファイルをダウンロードします。', { variant: 'success' });
      setCsvLoading(false);
      downloadCsvFileWithBom(statusCsvUrlContext.data.pollCouponUsageLogCsv.preSignedUrl);
    } else if (status === 'FAILED' || status === 'CANCELLED') {
      setCsvLoading(false);
      enqueueSnackbar('明細取得処理でエラーが発生しました', {
        variant: 'error',
      });
      Bugsnag.notify(statusCsvUrlContext.error || new Error('polling error'));
    }
  }, [
    statusCsvUrlContext.data?.pollCouponUsageLogCsv.status,
    statusCsvUrlContext.data?.pollCouponUsageLogCsv.preSignedUrl,
  ]);

  // 検索
  const onSearchJsonSubmit = useCallback(
    (form: CouponUsageLogFormValues) => {
      setOffset(0);
      setLoading(true);
      invokeJsonQuery({
        variables: {
          ...form,
          couponMasterId: queryParams.couponMasterId,
          organizationId: queryParams.organizationId,
          searchPolicy: 'latest',
          limit: Limit,
          offset: offset,
        },
      });
    },
    [invokeJsonQuery]
  );

  // CSV出力
  const onCsvOutSubmit = useCallback(
    (data: CouponUsageLogFormValues) => {
      if (!organization) {
        return;
      }
      setCsvLoading(true);
      invokeCsvQuery({
        variables: {
          ...data,
          couponMasterId: queryParams.couponMasterId,
          organizationId: queryParams.organizationId,
          searchPolicy: 'latest',
        },
      });
    },
    [organization, invokeCsvQuery]
  );

  // 更に読み込む
  const onFetchMoreJson = useCallback(
    async (data: CouponUsageLogFormValues) => {
      if (statusContext && statusContext.stopPolling) {
        statusContext?.stopPolling();
      }
      if (organization && getJsonQueryContext && getJsonQueryContext.fetchMore && getJsonQueryContext.variables) {
        setLoading(true);
        setFetching(true);
        await invokeJsonQuery({
          variables: {
            ...data,
            couponMasterId: queryParams.couponMasterId,
            organizationId: queryParams.organizationId,
            searchPolicy: 'latest',
            limit: Limit,
            offset: offset,
          },
        });
      }
    },
    [statusContext, getJsonQueryContext, organization]
  );

  return {
    organization,
    organizationLoading,
    userInfo,
    onSearch: onSearchJsonSubmit,
    onCsvOut: onCsvOutSubmit,
    data: getJsonQueryContext.data,
    loading,
    csvLoading,
    onFetchMore: onFetchMoreJson,
    queryParams,
    allAcquired,
  };
};
