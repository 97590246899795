import {
  Box,
  Button,
  CircularProgress,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Theme,
} from '@material-ui/core';
import { ArrowRightIcon } from '@material-ui/pickers/_shared/icons/ArrowRightIcon';
import { Create } from '@material-ui/icons';
import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { lightGreen } from '@material-ui/core/colors';
import { useHistory, useLocation } from 'react-router';
import { Organization } from '../../../graphql/generated';
import { formatOrganizationTypeString } from '../../../components/contexts/organization';
import { UserContext } from '../../../components/contexts/user/user-context';

export type OrganizationInfoProps = {
  data?: Organization;
  loading: boolean;
};

const useStyles = makeStyles((theme: Theme) => ({
  infoArea: {
    borderColor: theme.palette.primary.light,
    backgroundColor: lightGreen['50'],
  },
}));

export const OrganizationInfo: React.FC<OrganizationInfoProps> = ({ data }) => {
  const classes = useStyles();
  const { pathname } = useLocation();
  const history = useHistory();
  const { userInfo } = useContext(UserContext);

  if (!data) {
    return (
      <Box display="flex" alignItems="center" justifyContent="center">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Paper variant="outlined" square className={classes.infoArea}>
          <List dense>
            <ListItem>
              <ListItemAvatar>
                <ArrowRightIcon />
              </ListItemAvatar>
              <ListItemText primary="組織ID" secondary={data.id} />
            </ListItem>
            <ListItem>
              <ListItemAvatar>
                <ArrowRightIcon />
              </ListItemAvatar>
              <ListItemText primary="組織名" secondary={data.name} />
            </ListItem>
            <ListItem>
              <ListItemAvatar>
                <ArrowRightIcon />
              </ListItemAvatar>
              <ListItemText
                primary="組織種別"
                secondary={`${formatOrganizationTypeString(data.type)}（${data.type}）`}
              />
            </ListItem>
            {data.memberShopCode ? (
              <ListItem>
                <ListItemAvatar>
                  <ArrowRightIcon />
                </ListItemAvatar>
                <ListItemText primary="加盟店コード" secondary={data.memberShopCode} />
              </ListItem>
            ) : (
              <></>
            )}{' '}
            {data.simpleClearingCode ? (
              <ListItem>
                <ListItemAvatar>
                  <ArrowRightIcon />
                </ListItemAvatar>
                <ListItemText primary="店舗消込番号" secondary={data.simpleClearingCode} />
              </ListItem>
            ) : (
              <></>
            )}{' '}
            {data.shopCode ? (
              <ListItem>
                <ListItemAvatar>
                  <ArrowRightIcon />
                </ListItemAvatar>
                <ListItemText primary="店舗コード" secondary={data.shopCode} />
              </ListItem>
            ) : (
              <></>
            )}{' '}
            {data.shopNumber ? (
              <ListItem>
                <ListItemAvatar>
                  <ArrowRightIcon />
                </ListItemAvatar>
                <ListItemText primary="店舗番号" secondary={data.shopNumber} />
              </ListItem>
            ) : (
              <></>
            )}
          </List>
        </Paper>
      </Grid>
      {(() => {
        if (data?.type === 'SHOP' && userInfo?.permissions.includes('write:shop')) {
          return (
            <Grid item xs={12}>
              <Button
                fullWidth
                type="button"
                size="large"
                color="secondary"
                variant="contained"
                onClick={() => {
                  history.push({
                    pathname: `${pathname}/shop/edit`,
                    search: history.location.search,
                  });
                }}
                startIcon={<Create />}
              >
                この組織を編集する
              </Button>
            </Grid>
          );
        } else {
          if (userInfo?.permissions.includes('write:shop-group')) {
            return (
              <Grid item xs={12}>
                <Button
                  fullWidth
                  type="button"
                  size="large"
                  color="secondary"
                  variant="contained"
                  onClick={() => {
                    history.push({
                      pathname: `${pathname}/shop-group/edit`,
                      search: history.location.search,
                    });
                  }}
                  startIcon={<Create />}
                >
                  この組織を編集する
                </Button>
              </Grid>
            );
          }
        }
      })()}
    </Grid>
  );
};
