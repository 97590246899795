import { useSnackbar } from 'notistack';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import {
  CouponStatus,
  useCouponLazyQuery,
  useCouponMasterLazyQuery,
  useUpdateCouponStatusMutation,
} from '../../../../graphql/generated';
import { ApolloError } from '@apollo/client';
import { GraphQLError } from 'graphql';
import { CouponDetailProps } from '../CouponDetail';
import { OrganizationContext } from '../../../../components/contexts/organization';

export type CouponDetailUseCase = CouponDetailProps;

export function useCouponDetail(couponMasterId: string, couponId: string): CouponDetailUseCase {
  const orgContext = useContext(OrganizationContext);
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);
  const [loadingStatus, setLoadingStatus] = useState(false);
  const [editingStatus, setEditingStatus] = useState(false);

  const [masterQuery, masterContext] = useCouponMasterLazyQuery({
    fetchPolicy: 'cache-first',
  });
  const [query, queryContext] = useCouponLazyQuery({
    fetchPolicy: 'network-only', // これがないとステータス更新後、同じクーポンIDで再取得してくれない
    onCompleted: () => {
      setLoading(false);
      setLoadingStatus(false);
    },
  });
  const data = queryContext?.data;
  const [updateCouponStatusMutation] = useUpdateCouponStatusMutation({
    onCompleted: () => {
      query({
        variables: {
          couponId,
        },
      });
    },
  });

  // 初回
  useEffect(() => {
    setLoading(true);
    masterQuery({
      variables: {
        couponMasterId,
      },
    });
    query({
      variables: {
        couponId,
      },
    });
  }, [query]);

  // 現在の組織とクーポンマスタの組織が異なる場合、なりすます
  useEffect(() => {
    if (!orgContext.loading && data?.coupon && orgContext.organization?.id !== data.coupon.publishedOrganizationId) {
      orgContext.setOrganization(data.coupon.publishedOrganizationId);
      enqueueSnackbar(`このクーポンを発行した組織として操作します`, {
        variant: 'success',
      });
    }
  }, [orgContext, data?.coupon, enqueueSnackbar, couponMasterId]);

  const onSubmit = useCallback(
    (status: string) => {
      const submit = async (status: string): Promise<void> => {
        if (!orgContext.organization) {
          return;
        }
        setEditingStatus(false);
        setLoadingStatus(true);
        try {
          await updateCouponStatusMutation({
            variables: {
              input: {
                couponId: couponId,
                status: status as CouponStatus,
                usedFrom: 'admin',
                usedOrganizationId: orgContext.organization?.id,
                usedOrganizationName: orgContext.organization?.name,
              },
            },
          });
          enqueueSnackbar('クーポンステータスを更新しました', {
            variant: 'success',
          });
        } catch (err) {
          console.error('error', err);
          if (err instanceof ApolloError) {
            const appsyncError = err.graphQLErrors[0] as GraphQLError & {
              errorType: string;
            };

            if (appsyncError.errorType && appsyncError.errorType.startsWith('4')) {
              enqueueSnackbar('クーポンステータスの更新に失敗しました。内容を再度確認してください', {
                variant: 'error',
              });
            } else {
              enqueueSnackbar('サーバの問題で更新に失敗しました。時間をおいてから再度更新してください', {
                variant: 'error',
              });
            }
          } else {
            enqueueSnackbar({ open: true, severity: 'error', message: 'クーポンステータスの更新に失敗しました' });
          }
        }
      };
      submit(status);
    },
    [orgContext, enqueueSnackbar, updateCouponStatusMutation]
  );

  return {
    loading: loading || orgContext.loading || masterContext.loading,
    loadingStatus: loadingStatus || orgContext.loading,
    editingStatus,
    setEditingStatus,
    data,
    couponMaster: masterContext.data?.couponMaster,
    onSubmitStatus: onSubmit,
  };
}
