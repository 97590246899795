import { OrganizationContext } from '../../../components/contexts/organization';
import { UserListConditionFormValues } from '../UserList';
import { UsersQuery, useUsersLazyQuery } from '../../../graphql/generated';
import { useCallback, useContext, useEffect, useState } from 'react';

export type ListUsers = {
  organization?: OrganizationContext;
  organizationLoading: boolean;
  loading: boolean;
  onSearchUsers: (condition: UserListConditionFormValues) => void;
  onFetchMore: (newPage: number) => void;
  data?: UsersQuery;
  initialValues: UserListConditionFormValues;
  page: number;
  setPage: (page: number) => void;
  refetch: () => void;
};

export const useListUsers = (): ListUsers => {
  const orgContext = useContext(OrganizationContext);
  const organization = orgContext.organization;
  const organizationLoading = orgContext.loading;
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState<number>(0);

  const initialValues: UserListConditionFormValues = {
    email: '',
    name: '',
  };

  const [listQuery, queryContext] = useUsersLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: () => {
      setPage(0);
      setLoading(false);
    },
    onError: (e) => console.log(e),
  });

  // 初回、検索条件なしで表示する
  useEffect(() => {
    if (organization) {
      setLoading(true);
      listQuery({
        variables: {
          organizationId: organization.id,
        },
      });
    }
  }, [listQuery, organization]);

  useEffect(() => {
    if (loading && queryContext.data && queryContext.data.users.edges.length >= 0) {
      setLoading(false);
    }
  }, [queryContext.data]);

  // ユーザーをユーザー名で検索する
  const onSearchUsers = useCallback(
    (data: UserListConditionFormValues) => {
      if (organization) {
        setLoading(true);
        listQuery({
          variables: {
            organizationId: organization.id,
            email: data.email,
            name: data.name,
            page: String(page),
          },
        });
      }
    },
    [listQuery, organization]
  );

  // ページング FetchMore
  const onFetchMore = useCallback(
    async (newPage: number) => {
      if (organization && queryContext?.fetchMore && queryContext.variables) {
        setLoading(true);
        await queryContext.fetchMore({
          variables: {
            page: String(newPage),
          },
          updateQuery: (prevQuery, { fetchMoreResult }) => {
            setLoading(false);
            if (!fetchMoreResult) {
              return prevQuery;
            }
            return {
              users: {
                __typename: fetchMoreResult.users.__typename,
                edges: fetchMoreResult.users.edges,
                pageInfo: fetchMoreResult.users.pageInfo,
              },
            };
          },
        });
      }
    },
    [queryContext, organization]
  );

  const refetch = useCallback(async () => {
    if (organization && queryContext?.refetch) {
      setLoading(true);
      await queryContext.refetch();
    }
  }, [organization, queryContext]);

  return {
    organization,
    organizationLoading,
    loading,
    onSearchUsers,
    data: queryContext.data,
    initialValues,
    onFetchMore,
    page,
    setPage,
    refetch,
  };
};
