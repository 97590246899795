import { useSnackbar } from 'notistack';
import { useCallback } from 'react';
import Bugsnag from '@bugsnag/js';
import { useUpdateUserNameMutation } from '../../../graphql/generated';
import { UpdateUserNameFormValues } from '../dialogs/UpdateUserName';

export type UpdateUserNameUseCase = {
  loading: boolean;
  onUpdate: (input: UpdateUserNameFormValues) => void;
};

export const useUpdateUserNameUseCase = (setOpen: (open: boolean) => void): UpdateUserNameUseCase => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [updateUserNameMutation, { loading, error }] = useUpdateUserNameMutation();

  const onUpdate = useCallback(
    async (input: UpdateUserNameFormValues) => {
      closeSnackbar();

      console.log('input', input);

      await updateUserNameMutation({
        variables: {
          id: input.id,
          name: input.name,
        },
      });

      if (error) {
        Bugsnag.notify(error);
        console.error(error);
        enqueueSnackbar(`ユーザー名の更新に失敗しました: ${error}`, {
          variant: 'error',
          persist: true,
        });
        return;
      }

      setOpen(false);

      enqueueSnackbar(`ユーザー名を更新しました`, {
        variant: 'success',
      });
    },
    [updateUserNameMutation, enqueueSnackbar]
  );

  return {
    loading,
    onUpdate,
  };
};
