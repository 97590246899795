import React from 'react';
import { createStyles, CssBaseline, makeStyles, Theme } from '@material-ui/core';
import { HeaderProps, MenuHeader } from '../organisms/general/MenuHeader';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
  })
);

export type MenuHeaderTemplate = {
  header: HeaderProps;
};

export const MenuHeaderTemplate: React.FC<MenuHeaderTemplate> = ({ header, children }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CssBaseline />
      <MenuHeader {...header} />
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {children}
      </main>
    </div>
  );
};
